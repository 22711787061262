/**
=========================================================
* Aztek Portal
=========================================================



Coded by Vinod kumar J

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { createContext, useContext, useReducer, useMemo, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import '../app.css'
import IconButton  from "@mui/material/IconButton"
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import TextField from '@mui/material/TextField';
import { Typography } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/lab/Autocomplete';
import Button from '@mui/material/Button';
import flatpickr from "assets/theme/components/flatpickr";
import { URL } from "../constants";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Icon from "@mui/material/Icon";
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Download  from "@mui/icons-material/Download";
import readXlsxFile from 'read-excel-file';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
const useStyles = makeStyles({
  customLabel: {
    fontSize: '14px', // Set your desired font size here
  },
});
import Compressor from 'compressorjs';
import { PDFDocument } from 'pdf-lib';
function Sampled_files() {
  const classes = useStyles();
  const { sales, tasks } = reportsLineChartData;
  const [rac_name, setrac_name] = useState("");
  const [case_id, setcase_id] = useState("");
  const [branch_name, setbranch_name] = useState("");
  const [company_name, setcompany_name] = useState("");
  const [sender_name, setsender_name] = useState("");   
  const [reffernce_no, setreffernce_no] = useState("");
  const [aplicant_name, setaplicant_name] = useState("");
  const [product_type, setproduct_type] = useState("");
  const [mobile_no, setmobile_no] = useState("");
  const [a_mobile_no, seta_mobile_no] = useState("");
  const [adress1, setadress1] = useState("");
  const [applicant_permnent_address, setapplicant_permnent_address] = useState({address:"", pincode:"", area:""})
  const [applicant_current_address, setapplicant_current_address] = useState({address:"", pincode:"", area:""})
  const [verificationtype, setverificationtype] = useState("");
  const [rac_nameerror, setrac_nameerror] = useState(false);
  const [case_id_error, setcase_id_error] = useState(false);
  const [branch_nameerror, setbranch_nameerror] = useState(false);
  const [file_data, setfile_data] = useState();
  const [sortOrder, setSortOrder] = useState('asc');
  const [applicant_name_error, setapplicant_nameerror] = useState(false);
  const [aplicant_nameerror, setaplicant_nameerror] = useState(false);
  const [cleint_nameerror, setclient_nameerror] = useState(false);
  const [verification_typeerror, setverification_typeerror] = useState(false);
  const [applicant_p__error, setapplicant_p_address_error] = useState({address:false, pincode:false, area:false});
 
  const [token, settoken] = useState("");
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [success, setsuccess] = useState(false) 
  const [Result, setResult] = useState(false) 
  const [rows, setRows] = useState( [
    {
      id: 1,
    
      filedata: '',
     
    },
  ]);
  const [errors, setErrors] = useState({});
  const[bulkdialog, setbulkdialog] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [branch_deatils, setbranch_deatils] = useState([]);
  const [client_name, setclient_name] = useState([]);
  const [product_deatils, setproduct_deatils] = useState([]);
  const [message, setMessage] = useState('');
  const [casedata, setcasedata] = useState();
  const [isMobile, setIsMobile] = useState(false);
  const [sortedColumn, setSortedColumn] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileDevice());
    console.log('mobile',isMobileDevice())
  }, []);
// download sample formate xls
const handleDownload = async () => {
  try {
    const response = await fetch(`${URL}/download`,);
    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'sample_excel_bulk_allocation.xlsx';
    link.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    //console.error('Error downloading file:', error);
  }
};
const getSortIndicator = (columnIndex) => {
  if (sortedColumn === columnIndex) {
    return sortOrder === 'asc' ? ' ▲' : ' ▼';
  }
  return null;
};


const read_details = () =>{
  const token = localStorage.getItem("token");
  // //console.log('hi')
  let headers = new Headers();

// headers.append('Content-Type', 'application/json');
headers.append('token', token);
const requestOptions1 = {
  method: "POST",
  headers: headers,
  body: JSON.stringify({'id':1}),

};
fetch(`${URL}/get_sampled_data`, requestOptions1)
  .then((response) => response.json())
  .then((data) => {
    setcasedata(data.data)
   
          //console.log('data',data)
  })
}

useEffect(() => {
  read_details();
    }, []);
  function checkDataPresence(data) {
    //console.log('data',data)
    for (let item of data) {
      if (!isObjectValid(item)) {
        return false;
      }
    }
    return true;
  }
  function isObjectValid(obj) {
    if (typeof obj === 'object' && obj !== null) {
      for (let key in obj) {
        if (!key || obj[key] === '' || obj[key] === null || obj[key] === 0 || obj[key] === '0') {
          return false;
        }
        if (typeof obj[key] === 'object' && !isObjectValid(obj[key])) {
          return false;
        }
      }
      return true;
    }
    return false;
  }
    
  // const handleFileInputChange = (event) => {
  //   const file = event.target.files[0];
  
  //   // Check the file extension to determine the file type
  //   const fileType = file.name.split('.').pop().toLowerCase();
  
  //   const reader = new FileReader();
  
  //   reader.onload = (e) => {
  //     const fileContents = e.target.result;
  
  //     if (fileType === 'xls' || fileType === 'xlsx') {
  //       // Handle both .xls and .xlsx files
  //       readXlsxFile(file).then((rows) => {
  //         // Assuming rows is an array of arrays representing each row of the Excel file
  //         if (rows.length > 1) {
  //           const headers = rows[0]; // First row contains headers
  //           const data = rows.slice(1); // Data starts from the second row
  
  //           const parsedData = data.map((row) => {
  //             const rowData = {};
  //             row.forEach((value, index) => {
  //               const headerName = headers[index];
  //               rowData[headerName] = value;
  //             });
  //             return rowData;
  //           });
  
  //           //console.log(parsedData);
  //           // Assuming setFileData expects an array of objects
  //           setFileData(parsedData);
  //         } else {
  //           //console.error('Excel file contains no data rows.');
  //         }
  //       }).catch(error => {
  //         //console.error('Error reading Excel file:', error);
  //       });
  //     } else {
  //       //console.error('Unsupported file type');
  //     }
  //   };
  
  //   // Read the file as array buffer
  //   reader.readAsArrayBuffer(file);
  // };
  const downloadFile = async (filepath) => {
    try {
      const response = await fetch(`${URL}/download_file`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ filename: filepath })
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const blob = await response.blob();
  
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filepath.split('/').pop(); // Extract the filename from the filepath
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };
  

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
  
    // Check the file extension to determine the file type
    const fileType = file.name.split('.').pop().toLowerCase();
  
    const reader = new FileReader();
  
    reader.onload = (e) => {
      const fileContents = e.target.result;
  
      if (fileType === 'xls' || fileType === 'xlsx') {
        // Handle both .xls and .xlsx files
        readXlsxFile(file).then((rows) => {
          // Assuming rows is an array of arrays representing each row of the Excel file
          if (rows.length > 1) {
            const headers = rows[0]; // First row contains headers
            const data = rows.slice(1); // Data starts from the second row
  
            const parsedData = data.map((row) => {
              const rowData = {};
              row.forEach((value, index) => {
                const headerName = headers[index];
                rowData[headerName] = value;
              });
              return rowData;
            });
  
            // Transform COAPLNUMBERS, COAPLPINCODES, COAPPLADDRESS, COAPPLAREAS, and COAPPLICANTNAMES into COAPPLICANTDETAILS
            const transformedData = parsedData.map((item) => {

              // //console.log(item)
              const coaplNumbersArr = typeof item.COAPLNUMBERS === 'string' ? item.COAPLNUMBERS.split(", ") : [item.COAPLNUMBERS];
              const coaplPincodesArr = typeof item.COAPLPINCODES === 'string' ? item.COAPLPINCODES.split(", ").map(pin => pin.replace(/\(|\)/g, "").trim()) :  [item.COAPLPINCODES];
              const coaplAddressArr =  item.COAPPLADDRESS.includes(",") ? item.COAPPLADDRESS.split("], [").map(addr => addr.replace(/\[|\]/g, "").trim()): [item.COAPPLADDRESS];
              const coaplAreasArr = item.COAPPLAREAS.includes(',') ? item.COAPPLAREAS.split(", "):[item.COAPPLAREAS];
              const coapplicantNamesArr = item.COAPPLICANTNAMES.includes(",") ? item.COAPPLICANTNAMES.split(", ") :[item.COAPPLICANTNAMES];
              
              const coapplicantDetails = coaplNumbersArr.map((num, index) => ({
                COAPLNUMBERS: num,
                COAPLPINCODES: coaplPincodesArr[index],
                COAPPLADDRESS: coaplAddressArr[index],
                COAPPLAREAS: coaplAreasArr[index],
                COAPPLICANTNAMES: coapplicantNamesArr[index]
              }));
  
              return { ...item, COAPPLICANTDETAILS: coapplicantDetails };
            });
  
            // //console.log(transformedData);
            // Assuming setFileData expects an array of objects
            setFileData(transformedData);
          } else {
            //console.error('Excel file contains no data rows.');
          }
        }).catch(error => {
          //console.error('Error reading Excel file:', error);
        });
      } else {
        //console.error('Unsupported file type');
      }
    };
  
    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  



  const bhandleClose = (event, reason) => {
    if (reason && reason === "backdropClick") 
        return;
    setLoading(false)
    setbulkdialog(false);
    // location.reload()
  }; 
  const addRow = () => {
    const newRow = {
      id: rows.length + 1,
      
      co_applicant_name: '',
      address: '',
      pincode: '',
      area:''
    };

    setRows([...rows, newRow]);
    // const result = checkDataPresence(rows);
    setResult(false)
    // //console.log(result)
  };

  const removeRow = (rowId) => {
    const updatedRows = rows.filter((row) => row.id !== rowId);
    setRows(updatedRows);
    const result = checkDataPresence(updatedRows);
    setResult(result)
    //console.log(result)
  };

  
  // const handleFileChange = (event, rowId, inputName, index) => {
  //   const file = event.target.files[0];
  //   setfile_data(file)
    
  // };
  

  const handleFileChange = async (event, rowId, inputName, index) => {
    const file = event.target.files[0];
  
    const compressImage = (file) => {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.6, // Adjust the quality as needed
          success: (compressedFile) => {
            resolve(compressedFile);
          },
          error: (err) => {
            reject(err);
          },
        });
      });
    };
  
    const compressPDF = async (file) => {
      const arrayBuffer = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);
  
      const pdfBytes = await pdfDoc.save({ 
        useObjectStreams: false, // This option can help with compression
      });
  
      // Ensure to set the correct MIME type for PDF
      return new Blob([pdfBytes], { type: 'application/pdf' });
    };
  
    try {
      let compressedFile;
  
      // Determine file type and compress accordingly
      if (file.type == "image/jpeg" || file.type == "image/png") {
        compressedFile = await compressImage(file);
      } else if (file.type === 'application/pdf') {
        compressedFile = await compressPDF(file);
      } else {
        console.log('Unsupported file type');
        return; // Exit function if file type is unsupported
      }
  
      // Assuming `setfile_data` correctly handles the Blob object
      setfile_data(compressedFile);
    } catch (err) {
      console.error(err.message);
    }
  };
  

  const handleSort = (id) => {
    //console.log(typeof id);
   // setActiveIndexop(index)
   setSortedColumn(id);
  
   if (sortOrder === 'asc') {
     setSortOrder('desc');
   } else {
     setSortOrder('asc');
   }
  let oparray =  [...casedata]
  
    switch(id){
      case 1:{
        if(sortOrder == 'asc'){
          oparray.sort((a, b) => {
            //console.log(a)
            const itemA = a['case_id'] ? a['case_id'].toUpperCase() : '';
            const itemB = b['case_id'] ? b['case_id'].toUpperCase() : '';
          
            if (itemA === null && itemB === null) {
              return 0; // Both are null, consider them equal
            }
            
            if (itemA === null) {
              return -1; // itemA is null, move it before itemB
            }
          
            if (itemB === null) {
              return 1; // itemB is null, move it before itemA
            }
          
            if (itemA < itemB) {
              return -1;
            }
            if (itemA > itemB) {
              return 1;
            }
            return 0;
          });
          setcasedata(oparray)
        } else{
          
          oparray.sort((a, b) => {
            const itemA = a['case_id'] ? a['case_id'].toUpperCase() : null;
            const itemB = b['case_id'] ? b['case_id'].toUpperCase() : null;
          
            if (itemA === null && itemB === null) {
              return 0; // Both are null, consider them equal
            }
            
            if (itemA === null) {
              return 1; // itemA is null, move it after itemB
            }
          
            if (itemB === null) {
              return -1; // itemB is null, move it after itemA
            }
          
            if (itemA < itemB) {
              return 1;
            }
            if (itemA > itemB) {
              return -1;
            }
            return 0;
          });
          
          setcasedata(oparray)
        }
  
        break;
      }
      case 2:{
        if(sortOrder == 'asc'){
          oparray.sort((a, b) => {
            //console.log(a)
            const itemA = a['applicant_name'] ? a['applicant_name'].toUpperCase() : '';
            const itemB = b['applicant_name'] ? b['applicant_name'].toUpperCase() : '';
          
            if (itemA === null && itemB === null) {
              return 0; // Both are null, consider them equal
            }
            
            if (itemA === null) {
              return -1; // itemA is null, move it before itemB
            }
          
            if (itemB === null) {
              return 1; // itemB is null, move it before itemA
            }
          
            if (itemA < itemB) {
              return -1;
            }
            if (itemA > itemB) {
              return 1;
            }
            return 0;
          });
          setcasedata(oparray)
        } else{
          
          oparray.sort((a, b) => {
            const itemA = a['applicant_name'] ? a['applicant_name'].toUpperCase() : null;
            const itemB = b['applicant_name'] ? b['applicant_name'].toUpperCase() : null;
          
            if (itemA === null && itemB === null) {
              return 0; // Both are null, consider them equal
            }
            
            if (itemA === null) {
              return 1; // itemA is null, move it after itemB
            }
          
            if (itemB === null) {
              return -1; // itemB is null, move it after itemA
            }
          
            if (itemA < itemB) {
              return 1;
            }
            if (itemA > itemB) {
              return -1;
            }
            return 0;
          });
          
          setcasedata(oparray)
        }
  
        break;
      }
      case 3:{
        if (sortOrder === 'asc') {
          oparray.sort((a, b) => {
            const dateA = new Date(a['e_date']);
            const dateB = new Date(b['e_date']);
        
            // Check for null or invalid dates
            if (isNaN(dateA.getTime()) && isNaN(dateB.getTime())) {
              return 0; // Both are invalid dates, consider them equal
            }
        
            if (isNaN(dateA.getTime())) {
              return -1; // dateA is invalid, move it before dateB
            }
        
            if (isNaN(dateB.getTime())) {
              return 1; // dateB is invalid, move it before dateA
            }
        
            return dateA - dateB; // Compare dates directly for ascending order
          });
          setcasedata(oparray);
        } else {
          oparray.sort((a, b) => {
            const dateA = new Date(a['e_date']);
            const dateB = new Date(b['e_date']);
        
            // Check for null or invalid dates
            if (isNaN(dateA.getTime()) && isNaN(dateB.getTime())) {
              return 0; // Both are invalid dates, consider them equal
            }
        
            if (isNaN(dateA.getTime())) {
              return -1; // dateA is invalid, move it before dateB
            }
        
            if (isNaN(dateB.getTime())) {
              return 1; // dateB is invalid, move it before dateA
            }
        
            return dateB - dateA; // Compare dates directly for descending order
          });
          setcasedata(oparray);
        }
        
  
        break;
      }
      case 4:{
        if(sortOrder == 'asc'){
          oparray.sort((a, b) => {
            //console.log(a)
            const itemA = a['client_name'] ? a['client_name'].toUpperCase() : '';
            const itemB = b['client_name'] ? b['client_name'].toUpperCase() : '';
          
            if (itemA === null && itemB === null) {
              return 0; // Both are null, consider them equal
            }
            
            if (itemA === null) {
              return -1; // itemA is null, move it before itemB
            }
          
            if (itemB === null) {
              return 1; // itemB is null, move it before itemA
            }
          
            if (itemA < itemB) {
              return -1;
            }
            if (itemA > itemB) {
              return 1;
            }
            return 0;
          });
          setcasedata(oparray)
        } else{
          
          oparray.sort((a, b) => {
            const itemA = a['client_name'] ? a['client_name'].toUpperCase() : null;
            const itemB = b['client_name'] ? b['client_name'].toUpperCase() : null;
          
            if (itemA === null && itemB === null) {
              return 0; // Both are null, consider them equal
            }
            
            if (itemA === null) {
              return 1; // itemA is null, move it after itemB
            }
          
            if (itemB === null) {
              return -1; // itemB is null, move it after itemA
            }
          
            if (itemA < itemB) {
              return 1;
            }
            if (itemA > itemB) {
              return -1;
            }
            return 0;
          });
          
          setcasedata(oparray)
        }
  
        break;
      }
      case 5:{
        if(sortOrder == 'asc'){
          oparray.sort((a, b) => {
            //console.log(a)
            const itemA = a['verification_type'] ? a['verification_type'].toUpperCase() : '';
            const itemB = b['verification_type'] ? b['verification_type'].toUpperCase() : '';
          
            if (itemA === null && itemB === null) {
              return 0; // Both are null, consider them equal
            }
            
            if (itemA === null) {
              return -1; // itemA is null, move it before itemB
            }
          
            if (itemB === null) {
              return 1; // itemB is null, move it before itemA
            }
          
            if (itemA < itemB) {
              return -1;
            }
            if (itemA > itemB) {
              return 1;
            }
            return 0;
          });
          setcasedata(oparray)
        } else{
          
          oparray.sort((a, b) => {
            const itemA = a['verification_type'] ? a['verification_type'].toUpperCase() : null;
            const itemB = b['verification_type'] ? b['verification_type'].toUpperCase() : null;
          
            if (itemA === null && itemB === null) {
              return 0; // Both are null, consider them equal
            }
            
            if (itemA === null) {
              return 1; // itemA is null, move it after itemB
            }
          
            if (itemB === null) {
              return -1; // itemB is null, move it after itemA
            }
          
            if (itemA < itemB) {
              return 1;
            }
            if (itemA > itemB) {
              return -1;
            }
            return 0;
          });
          
          setcasedata(oparray)
        }
  
        break;
      }
      default:{
  
      }
  
    }
  
  
  
  
  
  
  
  
  };

  const handleBlur = (index, fieldName, value) => {
    if (fieldName === 'file') {
      if (!value) {
        const updatedErrors = { ...errors };
        updatedErrors[index] = { ...updatedErrors[index], [fieldName]: true };
        setErrors(updatedErrors);
      }
    } else {
      if (!value.trim()) {
        const updatedErrors = { ...errors };
        updatedErrors[index] = { ...updatedErrors[index], [fieldName]: true };
        setErrors(updatedErrors);
      }
    }
  };
  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    location.reload()
  };


  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
   
  };

  const read_data = () =>{
    try{
      const token = localStorage.getItem("token");
      // //console.log('hi')
      let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('token', token);
    const requestOptions1 = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({'id':1}),
   
    };
    fetch(`${URL}/read_form_data`, requestOptions1)
      .then((response) => response.json())
      .then((data) => {
             if(data.length != 0){
              setbranch_deatils(data.branch_details)
              setclient_name(data.client_details)
              setproduct_deatils(data.product_types)
             }
              //console.log('data',data)
      })
    }
     catch (error) {
      // Code to handle the error
      //console.error("An error occurred:", error);
    } finally {
      // Optional: Code that runs regardless of whether an error occurred or not
      //console.log("This code runs regardless of errors.");
    }
  
  }

  useEffect(() => {
    read_data();
      }, []);



  const Companies = [
    { value: 10, label: 'Aztek Solutions LLP' },
    { value: 20, label: 'Synergy Enterprises' },
  
  ];
  const options = [
    { value: 10, label: 'Residence' },
    { value: 20, label: 'Employment' },
    { value: 30, label: 'Business' },
    { value: 40, label: 'Property' },
    { value: 50, label: 'Document' },
    { value: 60, label: 'Asset' },
  ];

  const states = [
    {value:29, label:'Delhi NCR'},
    { value: 1, label: 'Andhra Pradesh' },
    { value: 2, label: 'Arunachal Pradesh' },
    { value: 3, label: 'Assam' },
    { value: 4, label: 'Bihar' },
    { value: 5, label: 'Chhattisgarh' },
    { value: 6, label: 'Goa' },
    { value: 7, label: 'Gujarat' },
    { value: 8, label: 'Haryana' },
    { value: 9, label: 'Himachal Pradesh' },
    { value: 10, label: 'Jharkhand' },
    { value: 11, label: 'Karnataka' },
    { value: 12, label: 'Kerala' },
    { value: 13, label: 'Madhya Pradesh' },
    { value: 14, label: 'Maharashtra' },
    { value: 15, label: 'Manipur' },
    { value: 16, label: 'Meghalaya' },
    { value: 17, label: 'Mizoram' },
    { value: 18, label: 'Nagaland' },
    { value: 19, label: 'Odisha' },
    { value: 20, label: 'Punjab' },
    { value: 21, label: 'Rajasthan' },
    { value: 22, label: 'Sikkim' },
    { value: 23, label: 'Tamil Nadu' },
    { value: 24, label: 'Telangana' },
    { value: 25, label: 'Tripura' },
    { value: 26, label: 'Uttarakhand' },
    { value: 27, label: 'Uttar Pradesh' },
    { value: 28, label: 'West Bengal' }
    
];
const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};


  useEffect(() => {
    const token = localStorage.getItem("token");
 
     settoken(token)
      }, []);

      const handelecompany = (value) =>{
        // //console.log(value.target.value)
        setcompany_name(value)
      }
  const handelracinput = (value) =>{
    // //console.log(value.target.value)
    setrac_name(value)
  }
  const handlecaseidinputname = (value) =>{
    // //console.log(value.target.value)
    setcase_id(value.target.value)
  }
  
 
  const handelbranch_name = (value) =>{
    // //console.log(value.target.value)
    setbranch_name(value.target.value)
  }
  const handelapplicant_name = (value) =>{
    // //console.log(value.target.value)
    setaplicant_name(value.target.value)
  }
  
  const handle_clientname = (value) =>{
    // //console.log(value.target.value)
    setclient_name(value.target.value)
  }
  
  const handle_verificationtype = (value) =>{
    // //console.log(value.target.value)
    setverificationtype(value.target.value)
  }
  
 

  const onblurcaseidinputname = () =>{
    // //console.log(value.target.value)
    if(case_id == "" ){
      setcase_id_error(true)
     }else{
      setcase_id_error(false)
     }
  }
 
  const onblurbranch_name = () =>{
    // //console.log(value.target.value)
    if(branch_name == ""){
      setbranch_nameerror(true)
     }else{
      setbranch_nameerror(false)
     }
  }
  const onblurreapplicant_input = () =>{
    // //console.log(value.target.value)
    if(aplicant_name == ""){
      setapplicant_nameerror(true)
     }else{
      setapplicant_nameerror(false)
     }
  }
 
  const onblurclient_name = () =>{
    // //console.log(value.target.value)
    if(aplicant_name == ""){
      setclient_nameerror(true)
     }else{
      setclient_nameerror(false)
     }
  }
  const onblurverification_type = () =>{
    // //console.log(value.target.value)
    if(aplicant_name == ""){
      setverification_typeerror(true)
     }else{
      setverification_typeerror(false)
     }
  }

  const submit_aplicant = () =>{
  

  //  alert('hi')  
     if(case_id != "" && branch_name != ""  != "" && aplicant_name  != "" && client_name != "" && verificationtype != "" && file_data ){

      
      // //console.log(token)
      const headers = new Headers();
// headers.append('Content-Type', 'application/json');
headers.append('token', token);

const formData = new FormData();
formData.append('case_id', case_id);
formData.append('branch_name', branch_name);
formData.append('aplicant_name', aplicant_name);
formData.append('client_name', client_name);
formData.append('verification_type', verificationtype);
formData.append('file', file_data);

const requestOptions1 = {
  method: "POST",
  headers: headers,
  body: formData, // Use FormData for file upload
};

fetch(`${URL}/insert_sample_data`, requestOptions1)
  .then((response) => response.json())
  .then((data) => {
    //console.log(data);
    if (data && data.result === true) {
      handleClickOpen();
    } else {
      handleClickOpen1();
    }
  })
  .catch((error) => {
    //console.error('Error:', error);
  });


     }
    
   
   
    
   
    

  }

  const handleUpload = () => {
    setLoading(true);

    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('token', token);
    const requestOptions1 = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({'data':fileData}),
    };
    fetch(`${URL}/insert_bulk_applications`, requestOptions1)
      .then((response) => response.json())
      .then((data) => {
        //console.log(data)
          if(data && data.result == true){
            setLoading(false);
          }else{
            setLoading(false);
            setbulkdialog(false);
            setMessage({'successfulInsertions':data.successfulInsertions.length, 'failedInsertions':data.failedInsertions.length, 'failedInsertions_details': data.failedInsertions})
             setsuccess(true)
            //  alert('Bulk Upload Action  Completed !!!')
            // handleClickOpen1()
          }
      })


      // Handle upload logic here, using fileData state if needed
      //console.log("File data:", fileData);
      // handleClose(); // Close the dialog after upload
    };

    const handleKeyDown = (event) => {
      const isNumericKey = /^[0-9]$/;
      const isBackspace = event.key === 'Backspace';
  
      if (!isNumericKey.test(event.key) && !isBackspace) {
        event.preventDefault();
      }
    };
  return (
    <DashboardLayout>
      {/* {isMobile ? "":<DashboardNavbar /> } */}
      
      <DashboardNavbar />
      <MDBox py={3} style={{padding:isMobile?0:30}}> 
      {/* {isMobile ?  <Typography style={{textAlign:'center', fontSize:22, marginBottom:10}}>Sampled Files</Typography>:"" } */}
       
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Alert"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Case Details Submitted Successfully
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{backgroundColor:'blue', color:'white'}} >Ok</Button>
         
        </DialogActions>
      </Dialog>
      <Dialog
        open={open1}
        onClose={handleClose1}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Alert"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{color:'red'}}>
            Case Id can't be duplicated!!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1} style={{backgroundColor:'blue', color:'white'}} >Ok</Button>
         
        </DialogActions>
      </Dialog>
      <>
      <Dialog
        open={bulkdialog}
        onClose={bhandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEscapeKeyDown // Disable closing the dialog by pressing the escape key
        BackdropProps={{ onClick: null }} 
        // sx={{ Width: 600, maxWidth:800 }}
      >
        <DialogTitle id="alert-dialog-title" style={{color:'Green'}}>
          Bulk Upload
        </DialogTitle>
        <DialogContent>
          <span>Download Sample Formate File</span>
          
        <Button  onClick={handleDownload} style={{ backgroundColor: '#84b3a9', color: 'white', marginLeft:10 }}>Download</Button>
  <DialogContentText id="alert-dialog-description">
    Select Xls File
  </DialogContentText>
  <div style={{ boxShadow: '0 0 4px rgba(0, 0, 0, 0.5)', padding:10, borderRadius:5}}>
  <input
    type="file"
    accept=".xls, .xlsx"
    onChange={handleFileInputChange} // Define a function to handle file input change
  />
  </div>

</DialogContent>
<DialogActions>
  <Button onClick={bhandleClose} style={{ backgroundColor: '#f08269', color: 'white' }}>
    Cancel
  </Button>
 
  {loading ? <Button  style={{ backgroundColor: 'green', color: 'white' }}>
  <CircularProgress size={24} color="inherit" />
  </Button>  :  <Button onClick={handleUpload} style={{ backgroundColor: 'green', color: 'white' }}>
    Upload
  </Button>}
</DialogActions>

      </Dialog>
    
    </>
    {success && (
      <div className="centered-alert">
        <div>
        <Grid container direction="row" >
      <Grid item xs={9} container direction="column">
      <h3> Bulk Upload Report!!</h3>
      </Grid>
      <Grid item xs={3} container direction="column">
        <Button className="reportb" onClick={()=>{setsuccess(false)}}>Close</Button>
      </Grid>
      </Grid>

        </div>
       
        <p style={{color:'#4a6339', fontSize:20}}> * Successful Insertions : {message.successfulInsertions}</p>
        <p style={{color:'#854945', fontSize:20}}> * Failed Insertions : {message.failedInsertions}</p>
        <h4>Failed Insertions Details:</h4>
        {message.failedInsertions_details && message.failedInsertions_details.length != 0 && message.failedInsertions_details.map((item)=>(
          <div key={item} style={{flexDirection:'row'}}>
        <p> Row Number : {item['index']}</p>
        <p style={{color:'#b85851'}}> Error :{item['error']}</p>
        </div>
        ))}
       
       
      </div>
    )}
    {isMobile ?   <Grid  spacing={1}>

<Grid container direction="row" xs={12}>
<Grid container direction="row" xs={12} className="mainrowclass">
<Grid item xs={12} container direction="column" className="rowclass">
<TextField id="outlined-basic" label="Client Name" variant="outlined" className="form" onChange={(value)=>handle_clientname(value)} required onBlur={onblurclient_name} style={{borderColor:cleint_nameerror?'red':'', borderStyle:cleint_nameerror?'groove':'', borderWidth:3}}  onFocus={()=> {setclient_nameerror(false)}}  InputLabelProps={{
  classes: {
    root: classes.customLabel,
  },
 
}}/>

</Grid>
</Grid>

<Grid container direction="row" xs={12} className="mainrowclass">




<Grid item xs={12} container direction="column" className="rowclass">
<TextField id="outlined-basic" label="Branch Name" variant="outlined" className="form" onChange={(value)=>handelbranch_name(value)} required onBlur={onblurbranch_name}  InputLabelProps={{
  classes: {
    root: classes.customLabel,
  },
 
}}   style={{borderColor:branch_nameerror?'red':'', borderStyle:branch_nameerror?'groove':'', borderWidth:3}}  onFocus={()=> {setbranch_nameerror(false)}}/>

</Grid>




</Grid>
<Grid container direction="row" xs={12} className="mainrowclass">



<Grid item xs={12} container direction="column" className="rowclass">
<TextField id="outlined-basic" label="Case Id" variant="outlined" className="form" onChange={(value)=>handlecaseidinputname(value)} required onBlur={onblurcaseidinputname} style={{borderColor:case_id_error?'red':'', borderStyle:case_id_error?'groove':'', borderWidth:3}}  onFocus={()=> {setcase_id_error(false)}}  InputLabelProps={{ classes: {root: classes.customLabel,},}} />

</Grid>





</Grid>
<Grid container direction="row" xs={12} className="mainrowclass">
<Grid item xs={12} container direction="column" className="rowclass">
<TextField id="outlined-basic" label="Verification Type" variant="outlined" className="form" onChange={(value)=>handle_verificationtype(value)} required onBlur={onblurverification_type} style={{borderColor:verification_typeerror?'red':'', borderStyle:verification_typeerror?'groove':'', borderWidth:3}}  onFocus={()=> {setverification_typeerror(false)}}  InputLabelProps={{
  classes: {
    root: classes.customLabel,
  },
 
}}/>

</Grid>
</Grid>
<Grid container direction="row" xs={12} className="mainrowclass">
<Grid item xs={12} container direction="column" className="rowclass">
<TextField id="outlined-basic" label="Applicant Name" variant="outlined" className="form" onChange={(value)=>handelapplicant_name(value)} required onBlur={onblurreapplicant_input} style={{borderColor:applicant_name_error?'red':'', borderStyle:applicant_name_error?'groove':'', borderWidth:3}}  onFocus={()=> {setapplicant_nameerror(false)}}  InputLabelProps={{
  classes: {
    root: classes.customLabel,
  },
 
}}/>

</Grid>
</Grid>


<Grid item xs={12} container direction="row" style={{paddingLeft:0, paddingTop:0}}>
{rows.map((row,index)=>(
<Grid container spacing={2} className="addbox">
<Grid item xs={12} container direction="column" className="fieldclass">
<TextField
  type="file"
  id="outlined-basic"
  label="Document"
  variant="outlined"
  className="form"
  InputLabelProps={{ shrink: true }}
  InputProps={{
    inputProps: { accept: '.doc,.docx,.pdf,.png,.jpg,.jpeg' },
  }}
  onChange={(value)=>handleFileChange(value,row.id, 'file', index)} required onBlur={() => handleBlur(index, 'file', row.file)}
  // style={{ display: 'none' }}
/>

</Grid>

{row.id != 1 && (
<Grid item xs={2} container direction="column" >
<Button variant="contained" className="csbutton" onClick={()=> removeRow(row.id)}> <Icon>
<CancelIcon fontSize="large" />
</Icon></Button>
</Grid>
)}
</Grid>
))}
</Grid>

<Grid item xs={12} style={{textAlign:'center', }}>
<Button variant="contained" className="sbutton" onClick={() => {submit_aplicant()}} style={{width:180, marginLeft:'20%', marginBottom:50}}>Submit</Button>
</Grid>
</Grid>
<Grid container direction="row" xs={12}>
<div className="feaddbox_m" >
  <div style={{textAlign:'center'}}>   <Typography className="fe_title">Uploaded Data</Typography></div>



{/* <Grid item  xs={12} container direction="row" style={{marginBottom:5}}>
       <Grid item xs={4.5} container direction="column" className="fehfieldclass"><Typography className="ctext" style={{paddingTop:3}}>Case Id</Typography> </Grid>
      
       <Grid item xs={4} container direction="column" className="fehfieldclass"><Typography className="ctext" style={{paddingTop:3}}>Date</Typography></Grid>
       <Grid item xs={2} container direction="column" className="fehfieldclass"><Typography className="ctext" style={{paddingTop:3}}>Action</Typography></Grid>
    </Grid>
{casedata && casedata.map((item,index)=>(
    <Grid item  xs={12} container direction="row">
       <Grid item xs={4.5} container direction="column" className="fefieldclass"><Typography style={{paddingTop:5, fontSize:16}}>{item.case_id}</Typography> </Grid>
     
       <Grid item xs={4} container direction="column"  className="fefieldclasss" >
       <Typography style={{paddingTop:5,fontSize:16}}> {new Date(item.e_date).toLocaleDateString('en-GB', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
})}</Typography>

</Grid>
       <Grid item xs={2} container direction="column" className="fefieldclass">
         <IconButton aria-label="edit" style={{color:"#62a2b3"}}  onClick={()=>downloadFile(item.files_path)}>
    <Download />
  </IconButton></Grid>
     
  
    </Grid>
))} */}
 

 <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          <th style={{ width: '40%', padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>
            <div className="ctext" style={{ paddingTop: '3px' }}>Case Id</div>
          </th>
          <th style={{ width: '30%', padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>
            <div className="ctext" style={{ paddingTop: '3px' }}>Date</div>
          </th>
          <th style={{ width: '20%', padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>
            <div className="ctext" style={{ paddingTop: '3px' }}>Action</div>
          </th>
        </tr>
      </thead>
      <tbody>
        {casedata && casedata.map((item, index) => (
          <tr key={index}>
            <td style={{ width: '40%', padding: '8px', borderBottom: '1px solid #ddd', wordBreak: 'break-word', overflowWrap: 'break-word' }}>
              <div style={{ paddingTop: '5px', fontSize: '16px' }}>{item.case_id}</div>
            </td>
            <td style={{ width: '30%', padding: '8px', borderBottom: '1px solid #ddd', wordBreak: 'break-word', overflowWrap: 'break-word' }}>
              <div style={{ paddingTop: '5px', fontSize: '16px' }}>
                {new Date(item.e_date).toLocaleDateString('en-GB', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })}
              </div>
            </td>
            <td style={{ width: '20%', padding: '8px', borderBottom: '1px solid #ddd', wordBreak: 'break-word', overflowWrap: 'break-word' }}>
              <button
                aria-label="edit"
                style={{ color: "#62a2b3", background: 'none', border: 'none', cursor: 'pointer' }}
                onClick={() => downloadFile(item.files_path)}
              >
                Download
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>



</div>
</Grid>













</Grid> :
      <Grid container spacing={2}>

      <Grid container direction="row" xs={3}>
        <div>
    
  

<Grid container direction="row" xs={12} className="mainrowclass">
<Grid item xs={12} container direction="column" className="rowclass">
<TextField id="outlined-basic" label="Client Name" variant="outlined" className="form" onChange={(value)=>handle_clientname(value)} required onBlur={onblurclient_name} style={{borderColor:cleint_nameerror?'red':'', borderStyle:cleint_nameerror?'groove':'', borderWidth:3}}  onFocus={()=> {setclient_nameerror(false)}}  InputLabelProps={{
  classes: {
    root: classes.customLabel,
  },
 
}}/>

</Grid>
</Grid>
<Grid container direction="row" xs={12} className="mainrowclass">

  <Grid item xs={12} container direction="column" className="rowclass">
  <TextField id="outlined-basic" label="Branch Name" variant="outlined" className="form" onChange={(value)=>handelbranch_name(value)} required onBlur={onblurbranch_name}  InputLabelProps={{
        classes: {
          root: classes.customLabel,
        },
       
      }}   style={{borderColor:branch_nameerror?'red':'', borderStyle:branch_nameerror?'groove':'', borderWidth:3}}  onFocus={()=> {setbranch_nameerror(false)}}/>
  
  </Grid>
  

  
 
</Grid>
<Grid container direction="row" xs={12} className="mainrowclass">
  

 
  <Grid item xs={12} container direction="column" className="rowclass">
  <TextField id="outlined-basic" label="Case Id" variant="outlined" className="form" onChange={(value)=>handlecaseidinputname(value)} required onBlur={onblurcaseidinputname} style={{borderColor:case_id_error?'red':'', borderStyle:case_id_error?'groove':'', borderWidth:3}}  onFocus={()=> {setcase_id_error(false)}}  InputLabelProps={{ classes: {root: classes.customLabel,},}} />

  </Grid>

  

  
 
</Grid>
<Grid container direction="row" xs={12} className="mainrowclass">
<Grid item xs={12} container direction="column" className="rowclass">
<TextField id="outlined-basic" label="Verification Type" variant="outlined" className="form" onChange={(value)=>handle_verificationtype(value)} required onBlur={onblurverification_type} style={{borderColor:verification_typeerror?'red':'', borderStyle:verification_typeerror?'groove':'', borderWidth:3}}  onFocus={()=> {setverification_typeerror(false)}}  InputLabelProps={{
  classes: {
    root: classes.customLabel,
  },
 
}}/>

</Grid>
</Grid>
<Grid container direction="row" xs={12} className="mainrowclass">
<Grid item xs={12} container direction="column" className="rowclass">
  <TextField id="outlined-basic" label="Applicant Name" variant="outlined" className="form" onChange={(value)=>handelapplicant_name(value)} required onBlur={onblurreapplicant_input} style={{borderColor:applicant_name_error?'red':'', borderStyle:applicant_name_error?'groove':'', borderWidth:3}}  onFocus={()=> {setapplicant_nameerror(false)}}  InputLabelProps={{
        classes: {
          root: classes.customLabel,
        },
       
      }}/>
 
  </Grid>
</Grid>


<Grid item xs={12} container direction="row" style={{paddingLeft:0, paddingTop:0}}>
    {rows.map((row,index)=>(
      <Grid container spacing={2} className="addbox">
    <Grid item xs={12} container direction="column" className="fieldclass">
    <TextField
        type="file"
        id="outlined-basic"
        label="Document"
        variant="outlined"
        className="form"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputProps: { accept: '.doc,.docx,.pdf,.png,.jpg,.jpeg' },
        }}
        onChange={(value)=>handleFileChange(value,row.id, 'file', index)} required onBlur={() => handleBlur(index, 'file', row.file)}
        // style={{ display: 'none' }}
      />
 
  </Grid>
 
  {row.id != 1 && (
  <Grid item xs={2} container direction="column" >
  <Button variant="contained" className="csbutton" onClick={()=> removeRow(row.id)}> <Icon>
   <CancelIcon fontSize="large" />
 </Icon></Button>
  </Grid>
  )}
  </Grid>
   ))}
    </Grid>
   
    <Grid item xs={12} style={{textAlign:'center', }}>
  <Button variant="contained" className="sbutton" onClick={() => {submit_aplicant()}} style={{width:180, marginLeft:'20%'}}>Submit</Button>
  </Grid>
  </div>
      </Grid>
      <Grid container direction="row" xs={9}>
      <div className="feaddbox" >
        <div style={{textAlign:'center'}}>   <Typography className="fe_title">Uploaded Data</Typography></div>
{/*    
        <Grid item  xs={12} container direction="row" style={{marginBottom:5}}>
             <Grid item xs={3.5} container direction="column" className="fehfieldclass"><Typography className="ctext" style={{paddingTop:3}}onClick={() => handleSort(1)}>Case Id  {getSortIndicator(1)}</Typography> </Grid>
             <Grid item xs={3.5} container direction="column" className="fehfieldclass"><Typography className="ctext" style={{paddingTop:3}}onClick={() => handleSort(2)}>Applicant Name {getSortIndicator(2)}</Typography></Grid>
             <Grid item xs={2.5} container direction="column" className="fehfieldclass"><Typography className="ctext" style={{paddingTop:3}}onClick={() => handleSort(3)}>Date {getSortIndicator(3)}</Typography></Grid>
             <Grid item xs={1.4} container direction="column" className="fehfieldclass"><Typography className="ctext" style={{paddingTop:3}}>Action</Typography></Grid>
          </Grid>
      {casedata && casedata.map((item,index)=>(
          <Grid item  xs={12} container direction="row">
             <Grid item xs={3.5} container direction="column" className="fefieldclass" style={{wordBreak: 'break-word', overflowWrap: 'break-word'}}><Typography style={{paddingTop:5, fontSize:16, padding:5}}>{item.case_id}</Typography> </Grid>
             <Grid item xs={3.5} container direction="column" className="fefieldclass"><Typography style={{paddingTop:5,fontSize:16, padding:5}}>{item.applicant_name}</Typography></Grid>
             <Grid item xs={2.5} container direction="column"  className="fefieldclasss" >
             <Typography style={{paddingTop:5,fontSize:16}}> {new Date(item.e_date).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })}</Typography>
     
    </Grid>
             <Grid item xs={1.4} container direction="column" className="fefieldclass">
               <IconButton aria-label="edit" style={{color:"#62a2b3"}}  onClick={()=>downloadFile(item.files_path)}>
          <Download />
        </IconButton></Grid>
           
        
          </Grid>
      ))} */}
       {/* <table style={{ minWidth: 650, borderCollapse: 'collapse' }} className="table-class">
      <thead>
        <tr style={{ backgroundColor: '#93dbda', height: 50 }}>
        <th style={{ width: '150px', border: '1px solid black' ,fontSize:14}} onClick={() => handleSort(4)}>
            Client Name {getSortIndicator(4)}
          </th>
          <th style={{ width: '150px', border: '1px solid black', fontSize:14 }} onClick={() => handleSort(1)}>
            Case Id {getSortIndicator(1)}
          </th>
          <th style={{ width: '150px', border: '1px solid black',fontSize:14 }} onClick={() => handleSort(5)}>
            Verification Type {getSortIndicator(5)}
          </th>
          <th style={{ width: '150px', border: '1px solid black',fontSize:14 }} onClick={() => handleSort(2)}>
            Applicant Name {getSortIndicator(2)}
          </th>
         
          
          <th style={{ width: '150px', border: '1px solid black',fontSize:14 }} onClick={() => handleSort(3)}>
            Date {getSortIndicator(3)}
          </th>
          
          <th style={{ width: '100px', border: '1px solid black' ,fontSize:14}}>
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        {casedata && casedata.map((item, index) => (
          <tr key={index} style={{textAlign:'center'}}>
             <td style={{ padding: '5px', wordBreak: 'break-word', overflowWrap: 'break-word' ,border: '1px solid black', fontSize:14}}>
              {item.client_name}
            </td>
            <td style={{ padding: '5px', wordBreak: 'break-word', overflowWrap: 'break-word' ,border: '1px solid black', fontSize:14}}>
              {item.verification_type}
            </td>
           <td style={{ padding: '5px', wordBreak: 'break-word', overflowWrap: 'break-word' ,border: '1px solid black', fontSize:14}}>
              {item.case_id}
            </td>
            <td style={{ padding: '5px', wordBreak: 'break-word', overflowWrap: 'break-word' ,border: '1px solid black', fontSize:14}}>
              {item.applicant_name}
            </td>
           
           
            <td style={{ padding: '5px', wordBreak: 'break-word', overflowWrap: 'break-word' ,border: '1px solid black', fontSize:14}}>
              {new Date(item.e_date).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}
            </td>
            
            <td style={{ padding: '5px', wordBreak: 'break-word', overflowWrap: 'break-word' ,border: '1px solid black', fontSize:15}}>
              <Button onClick={() => downloadFile(item.files_path)} style={{ color: 'green' , padding:0,}}>
                Download
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </table> */}
    <div style={{ maxHeight: '400px', overflowY: 'auto', border: '1px solid black' }}>
  <table style={{ minWidth: 650, borderCollapse: 'collapse', width: '100%' }} className="table-class">
    <thead style={{ position: 'sticky', top: 0, zIndex: 1 }}>
      <tr style={{ backgroundColor: '#93dbda', height: 50 }}>
        <th style={{ width: '150px', border: '1px solid black', fontSize: 14 }} onClick={() => handleSort(4)}>
          Client Name {getSortIndicator(4)}
        </th>
        <th style={{ width: '150px', border: '1px solid black', fontSize: 14 }} onClick={() => handleSort(1)}>
          Case Id {getSortIndicator(1)}
        </th>
        <th style={{ width: '150px', border: '1px solid black', fontSize: 14 }} onClick={() => handleSort(5)}>
          Verification Type {getSortIndicator(5)}
        </th>
        <th style={{ width: '150px', border: '1px solid black', fontSize: 14 }} onClick={() => handleSort(2)}>
          Applicant Name {getSortIndicator(2)}
        </th>
        <th style={{ width: '150px', border: '1px solid black', fontSize: 14 }} onClick={() => handleSort(3)}>
          Date {getSortIndicator(3)}
        </th>
        <th style={{ width: '100px', border: '1px solid black', fontSize: 14 }}>
          Action
        </th>
      </tr>
    </thead>
    <tbody>
      {casedata && casedata.map((item, index) => (
        <tr key={index} style={{ textAlign: 'center' }}>
          <td style={{ padding: '5px', wordBreak: 'break-word', overflowWrap: 'break-word', border: '1px solid black', fontSize: 14 }}>
            {item.client_name}
          </td>
          <td style={{ padding: '5px', wordBreak: 'break-word', overflowWrap: 'break-word', border: '1px solid black', fontSize: 14 }}>
            {item.verification_type}
          </td>
          <td style={{ padding: '5px', wordBreak: 'break-word', overflowWrap: 'break-word', border: '1px solid black', fontSize: 14 }}>
            {item.case_id}
          </td>
          <td style={{ padding: '5px', wordBreak: 'break-word', overflowWrap: 'break-word', border: '1px solid black', fontSize: 14 }}>
            {item.applicant_name}
          </td>
          <td style={{ padding: '5px', wordBreak: 'break-word', overflowWrap: 'break-word', border: '1px solid black', fontSize: 14 }}>
            {new Date(item.e_date).toLocaleDateString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })}
          </td>
          <td style={{ padding: '5px', wordBreak: 'break-word', overflowWrap: 'break-word', border: '1px solid black', fontSize: 15 }}>
            <Button onClick={() => downloadFile(item.files_path)} style={{ color: 'green', padding: 0 }}>
              Download
            </Button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

      </div>
    </Grid>

      



   
  
  
 
 


 
      </Grid>
      }
      
      </MDBox>
     
    </DashboardLayout>
  );
}

export default Sampled_files;
