import React, { createContext, useContext, useReducer, useMemo, useState, useEffect } from "react";
import { makeStyles } from '@mui/styles';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import { CheckCircle,AssignmentTurnedIn,HourglassEmpty,Send,Schedule,AccessTime, Task, QueryBuilder, BarChart,Description, Checklist, ViewList } from '@mui/icons-material';
import '../app.css'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import TextField from '@mui/material/TextField';

import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/lab/Autocomplete';
import Button from '@mui/material/Button';

import { URL } from "../constants";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useReactToPrint } from 'react-to-print';
import { TailSpin } from 'react-loader-spinner';
const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '50vh',
    margin:50
  },
  card: {
    width: '100%',
    maxWidth: 345,
    display: 'flex',
    height:'180px' // Ensure card content is displayed as flex
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30%', // Adjust icon width here
    minWidth: 100, // Minimum width for the icon container
    padding: '20px', // Padding around the icon
  },
  titleContainer: {
    textAlign:'right',
    marginTop:'-30px',
    // flexGrow: 1,
    paddingLeft: '20px', // Padding between icon and title
  },
});

const iconMap = {
    AssignmentTurnedIn: AssignmentTurnedIn,
    CheckCircle: CheckCircle,
    HourglassEmpty: HourglassEmpty,
    Send: Send,
    Schedule: Schedule,
    AccessTime: AccessTime,
    Task:Task,
    QueryBuilder:QueryBuilder,
    BarChart:BarChart,
    Description:Description,
    Checklist:Checklist,
    ViewList:ViewList
};



const Home = () => {
  const classes = useStyles();
  const [data, setdata] = useState([]);
  const [dataloaded, setdataloaded] = useState(false)
  const cardsData = [
    { icon: 'ViewList', title: 'Card 1', content: 'Total Cases', color:'#529ceb' },
    { icon: 'CheckCircle', title: 'Card 2', content: 'Completed Cases' , color:'#92d495'},
    { icon: 'Checklist', title: 'Card 7', content: 'Visit Completed', color:'#7dbda9' },
    { icon: 'HourglassEmpty', title: 'Card 3', content: 'Pending Cases', color:'#b8726e' },
    { icon: 'Send', title: 'Card 4', content: 'Report Sent',color:'#6bbdc7' },
    { icon: 'Description', title: 'Card 5', content: 'Draft Report', color:'#a5b07b' },
    // { icon: 'AccessTime', title: 'Card 6', content: 'Average TAT', color:'#337AB7' },
  
  ];
  const read_data = () =>{
    try{
      const token = localStorage.getItem("token");
      //  //console.log('token', token)
      let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('token', token);
    const requestOptions1 = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({'id':1}),
   
    };
    fetch(`${URL}/status_fe_details`, requestOptions1)
      .then((response) => response.json())
      .then((data) => {
        //console.log('serverdata',data)
        setdata(data)
          setdataloaded(true)
       
      })
    }
     catch (error) {
      // Code to handle the error
      //console.log("An error occurred:", error);
    } finally {
      // Optional: Code that runs regardless of whether an error occurred or not
      //console.log("This code runs regardless of errors.");
    }
  
  }

  useEffect(() => {
    read_data();
    // const timer = setTimeout(() => {
    //   setdataloaded(true)
    // }, 1000);

    // Cleanup the timer if the component is unmounted
    // return () => clearTimeout(timer);
      }, []);


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className={classes.root}>
        {dataloaded ? 
      <Grid container spacing={2}>
        {cardsData.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card className={classes.card} style={{ backgroundColor: card['color']}}>
              <Box className={classes.iconContainer}>
                {React.createElement(iconMap[card.icon], { fontSize: 'Large' })}
              </Box>
              <CardContent>
                <Box className={classes.titleContainer}>
                  <Typography variant="h3" component="div">
                    {(index < 6)  ?  data[index]:0}
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary" style={{textAlign:'right', fontSize:20, color:'white', fontWeight:'bold'}}>
                  {card.content}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid> :   <div className="loading-container">
        <TailSpin
          height="80"
          width="80"
          color="#00BFFF"
          ariaLabel="loading"
        />
      </div>}
    </div>
    </DashboardLayout>
  );
};

export default Home;
