/**
Developer Name : Vinod kumar J
Email : vinodtech2023@gmail.com
Contact Number : 9110248064
 * @format
 */
// const dbConfig = {
// 	user: 'aztek',
// 	password: 'AztekData@2024',
// 	host: 'localhost',
// 	port: '5432',
// 	database: 'aztek',
// };

// @mui material components
import { createContext, useContext, useReducer, useMemo, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import '../app.css'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import TextField from '@mui/material/TextField';
import { Typography } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/lab/Autocomplete';
import Button from '@mui/material/Button';
import flatpickr from "assets/theme/components/flatpickr";
import { URL } from "../constants";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Icon from "@mui/material/Icon";
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { makeStyles } from '@mui/styles';
// Dashboard components
const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: '#a9ccbe', // Change the background color here
    minWidth: 300, // Change the maximum width of the tooltip here
  },
}));

function AppSettings() {
  const classes = useStyles();
  const [client_name, setclient_name] = useState("");
  const [branch_name, setbranch_name] = useState("");
  const [product_type, setproduct_type] = useState("");
  const [role, setrole] = useState("");
  const [verificationtype, setverificationtype] = useState("");
  const [branch_location, setlocation] = useState("");
  const [client_nameerror, setclient_nameerror] = useState(false);
  const [case_id_error, setcase_id_error] = useState(false);
  const [branch_nameerror, setbranch_nameerror] = useState(false);
  const [product_typeerror, setproduct_typeerror] = useState(false);
  const [verificationtypeerror, setverificationtypeerror] = useState(false);
  const [locationerror, setlocationerror] = useState(false);
  const [roleerror, setroleerror] = useState(false);
  const [token, settoken] = useState("");
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [message, setmessage] = useState(false) 
 
   
 

 
 




  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    location.reload()
  };


  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
   
  };

 
 

  useEffect(() => {
    const token = localStorage.getItem("token");
 
     settoken(token)
      }, []);

   
  const handelclient_nameinput = (value) =>{
    // //console.log(value.target.value)
    setclient_name(value.target.value)
  }
  const handelbranch_nameinput = (value) =>{
    // //console.log(value.target.value)
    setbranch_name(value.target.value)
  }
  const handelverification_typeinput = (value) =>{
    // //console.log(value.target.value)
    setverificationtype(value.target.value)
  }
  const handellocationinput = (value) =>{
    // //console.log(value.target.value)
    setlocation(value.target.value)
  }
  const handelproduct_typeinput = (value) =>{
    // //console.log(value.target.value)
    setproduct_type(value.target.value)
  }
  const handelrole_input = (value) =>{
    // //console.log(value.target.value)
    setrole(value.target.value)
  }
 

  const onblurbranch_nameinput = () =>{
    // //console.log(value.target.value)
    if(branch_name == ""){
      setbranch_nameerror(true)
     }else{
      setbranch_nameerror(false)
     }
  }
  const onblurverification_typeinput= () =>{
    // //console.log(value.target.value)
    if(verificationtype == ""){
      setverificationtypeerror(true)
     }else{
      setverificationtypeerror(false)
     }
  }
 
  const onblurlocationinput= () =>{
    // //console.log(value.target.value)
    if(branch_location == ""){
      setlocationerror(true)
     }else{
      setlocationerror(false)
     }
  }
  const onblurclient_nameinput  = () =>{
    // //console.log(value.target.value)
    //  alert(verificationtype)
    if(client_name == ""){
      setclient_nameerror(true)
     }else{
      setclient_nameerror(false)
     }
     
  }

  const onbluproduct_typeinput = () =>{
    // //console.log(value.target.value)
    //  alert(verificationtype)
    if(product_type == ""){
      setproduct_typeerror(true)
     }else{
      setproduct_typeerror(false)
     }
     
  }

  const onblurole = () =>{
    // //console.log(value.target.value)
    //  alert(verificationtype)
    if(role == ""){
      setroleerror(true)
     }else{
      setroleerror(false)
     }
     
  }
  

  

 



  const Add_data = (type) =>{
 

  //  alert('hi')  
   

  
      
      // //console.log(token)
      let headers = new Headers();

      headers.append('Content-Type', 'application/json');
      headers.append('token', token);

      switch(type){
        case 'client': {
          if(client_name != "" ){
            const requestOptions1 = {
              method: "POST",
              headers: headers,
              body: JSON.stringify({'client_name':client_name}),
            };
            fetch(`${URL}/insert_client_details`, requestOptions1)
              .then((response) => response.json())
              .then((data) => {
                setmessage('Client Name')
                //console.log(data)
                  if(data && data.result == true){
                    handleClickOpen()
                  }else{
                    // alert(data.result)
                  
                    handleClickOpen1('Client Name')
                  }
              })
          }

          break;
        }
        
        case 'branch':{
          if( branch_name != ""  ){
            const requestOptions1 = {
              method: "POST",
              headers: headers,
              body: JSON.stringify({ 'branch_name':branch_name}),
            };
            fetch(`${URL}/insert_branch_details`, requestOptions1)
              .then((response) => response.json())
              .then((data) => {
                setmessage('Branch Name')
                //console.log(data)
                  if(data && data.result == true){
                    handleClickOpen()
                  }else{
                    // alert(data.result)
                  
                    handleClickOpen1('Branch Name')
                  }
              })
          }

          break;
        }
        case 'product':{
          if(product_type != "" ){
            const requestOptions1 = {
              method: "POST",
              headers: headers,
              body: JSON.stringify({ 'product_type':product_type}),
            };
            fetch(`${URL}/insert_product_type`, requestOptions1)
              .then((response) => response.json())
              .then((data) => {
                setmessage('Product Name')
                //console.log(data)
                  if(data && data.result == true){
                    handleClickOpen()
                  }else{
                    // alert(data.result)
                  
                    handleClickOpen1('Product Type')
                  }
              })
          }
          break;
        }
        case 'verification':{
          if( verificationtype != ""  ){
            const requestOptions1 = {
              method: "POST",
              headers: headers,
              body: JSON.stringify({ 'verification_type':verificationtype}),
            };
            fetch(`${URL}/insert_verification_type`, requestOptions1)
              .then((response) => response.json())
              .then((data) => {
                setmessage('Verification Type')
                //console.log(data)
                  if(data && data.result == true){
                    handleClickOpen()
                  }else{
                    // alert(data.result)
                    
                    handleClickOpen1('Verification Type')
                  }
              })
          }
          break;
        } 
        case 'location':{
          if( location != ""  ){
            const requestOptions1 = {
              method: "POST",
              headers: headers,
              body: JSON.stringify({ 'location':branch_location}),
            };
            fetch(`${URL}/insert_location`, requestOptions1)
              .then((response) => response.json())
              .then((data) => {
                setmessage('Location')
                //console.log(data)
                  if(data && data.result == true){
                    handleClickOpen()
                  }else{
                    // alert(data.result)
                    
                    handleClickOpen1('Location')
                  }
              })
          }
          break;
        } 
        case 'role':{
          if( location != ""  ){
            const requestOptions1 = {
              method: "POST",
              headers: headers,
              body: JSON.stringify({ 'r_name':role}),
            };
            fetch(`${URL}/insert_roles`, requestOptions1)
              .then((response) => response.json())
              .then((data) => {
                setmessage('Role')
                //console.log(data)
                  if(data && data.result == true){
                    handleClickOpen()
                  }else{
                    // alert(data.result)
                    
                    handleClickOpen1('Role')
                  }
              })
          }
          break;
        } 
        default:''
      }

     


    }



  return (
    <DashboardLayout>
      <DashboardNavbar />
     
      <MDBox py={3} style={{padding:30}}> 
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Alert"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message} have been successfully Added..
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{backgroundColor:'blue', color:'white'}} >Ok</Button>
          {/* <Button onClick={handleClose} autoFocus>
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>
      <Dialog
        open={open1}
        onClose={handleClose1}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Alert"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{color:'red'}}>
            {message}   Already exists!!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1} style={{backgroundColor:'blue', color:'white'}} >Ok</Button>
          {/* <Button onClick={handleClose} autoFocus>
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>
      <Grid container spacing={2}>

      <Grid container direction="row" className="smainrowclass">
      <Grid item xs={4} container direction="column" className="rowclass">
    <Typography className="htext">Client Name</Typography>
    </Grid>
  <Grid item xs={5} container direction="column" className="rowclass">
  <TextField id="outlined-basic" label="CLIENT NAME" variant="outlined" className="form" onChange={(value)=>handelclient_nameinput(value)} required onBlur={onblurclient_nameinput} style={{borderColor:client_nameerror?'red':'', borderStyle:client_nameerror?'groove':'', borderWidth:3}}  onFocus={()=> {setclient_nameerror(false)}}/>

  </Grid>
  <Grid item xs={3} container direction="column" className="rowclass">
  <Button variant="contained" className="ssbutton" onClick={() => {Add_data('client')}}>Submit</Button>
  </Grid>
  

  {/* <Grid item xs={3} container direction="column" className="rowclass"> </Grid> */}

      </Grid>

     <Grid container direction="row" className="smainrowclass">
     <Grid item xs={4} container direction="column" className="rowclass">
    <Typography className="htext">Branch Name</Typography>
    </Grid>
  <Grid item xs={5} container direction="column" className="rowclass">
  <TextField id="outlined-basic" label="BRANCH NAME" variant="outlined" className="form" onChange={(value)=>handelbranch_nameinput(value)} required onBlur={onblurbranch_nameinput} style={{borderColor:branch_nameerror?'red':'', borderStyle:branch_nameerror?'groove':'', borderWidth:3}}  onFocus={()=> {setbranch_nameerror(false)}}/>

  </Grid>
  <Grid item xs={3} container direction="column" className="rowclass">
  <Button variant="contained" className="ssbutton" onClick={() => {Add_data('branch')}}>Submit</Button>
  
  </Grid>
  

  {/* <Grid item xs={3} container direction="column" className="rowclass"></Grid> */}

      </Grid>
  
      <Grid container direction="row" className="smainrowclass">
      <Grid item xs={4} container direction="column" className="rowclass">
    <Typography className="htext">Product Type</Typography>
    </Grid>
      <Grid item xs={5} container direction="column" className="rowclass">
      <TextField id="outlined-basic" label="PRODUCT NAME" variant="outlined" className="form" onChange={(value)=>handelproduct_typeinput(value)} required onBlur={onbluproduct_typeinput} style={{borderColor:product_typeerror?'red':'', borderStyle:product_typeerror?'groove':'', borderWidth:3}}  onFocus={()=> {setproduct_typeerror(false)}}/>
     
      </Grid>
      <Grid item xs={3} container direction="column" className="rowclass">
      <Button variant="contained" className="ssbutton" onClick={() => {Add_data('product')}}>Submit</Button>
      </Grid>
      
    
      {/* <Grid item xs={3} container direction="column" className="rowclass"></Grid> */}
    
          </Grid>
 
          {/* <Grid container direction="row" className="smainrowclass">
          <Grid item xs={4} container direction="column" className="rowclass">
    <Typography className="htext">Verification Type</Typography>
    </Grid>
      <Grid item xs={5} container direction="column" className="rowclass">
      <TextField id="outlined-basic" label="VERIFICATION TYPE" variant="outlined" className="form" onChange={(value)=>handelverification_typeinput(value)} required onBlur={onblurverification_typeinput} style={{borderColor:verificationtypeerror?'red':'', borderStyle:verificationtypeerror?'groove':'', borderWidth:3}}  onFocus={()=> {setverificationtypeerror(false)}}/>
   
      </Grid>
      <Grid item xs={3} container direction="column" className="rowclass">
      <Button variant="contained" className="ssbutton" onClick={() => {Add_data('verification')}}>Submit</Button>
      </Grid>
      
    
     
    
          </Grid> */}
          <Grid container direction="row" className="smainrowclass">
          <Grid item xs={4} container direction="column" className="rowclass">
    <Typography className="htext">Location</Typography>
    </Grid>
      <Grid item xs={5} container direction="column" className="rowclass">
      <TextField id="outlined-basic" label="LOCATION" variant="outlined" className="form" onChange={(value)=>handellocationinput(value)} required onBlur={onblurlocationinput} style={{borderColor:locationerror?'red':'', borderStyle:locationerror?'groove':'', borderWidth:3}}  onFocus={()=> {setlocationerror(false)}}/>
   
      </Grid>
      <Grid item xs={3} container direction="column" className="rowclass">
      <Button variant="contained" className="ssbutton" onClick={() => {Add_data('location')}}>Submit</Button>
      </Grid>
      
    
      {/* <Grid item xs={3} container direction="column" className="rowclass"></Grid> */}
    
          </Grid>
          <Grid container direction="row" className="smainrowclass">
          <Grid item xs={4} container direction="column" className="rowclass">
    <Typography className="htext">Role Type</Typography>
    </Grid>
      <Grid item xs={5} container direction="column" className="rowclass">
      <TextField id="outlined-basic" label="ROLE" variant="outlined" className="form" onChange={(value)=>handelrole_input(value)} required onBlur={onblurole} style={{borderColor:roleerror?'red':'', borderStyle:roleerror?'groove':'', borderWidth:3}}  onFocus={()=> {setroleerror(false)}}/>
   
      </Grid>
      <Grid item xs={3} container direction="column" className="rowclass">
      <Button variant="contained" className="ssbutton" onClick={() => {Add_data('role')}}>Submit</Button>
      </Grid>
      
    
      {/* <Grid item xs={3} container direction="column" className="rowclass"></Grid> */}
    
          </Grid>
</Grid>

      
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default AppSettings;
