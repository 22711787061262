/**
Developer Name : Vinod kumar J
Email : vinodtech2023@gmail.com
Contact Number : 9110248064
 * @format
 */
// const dbConfig = {
// 	user: 'aztek',
// 	password: 'AztekData@2024',
// 	host: 'localhost',
// 	port: '5432',
// 	database: 'aztek',
// };

// @mui material components
import React from 'react';
import { createContext, useContext, useReducer, useMemo, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import IconButton  from "@mui/material/IconButton"
import '../app.css'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import TextField from '@mui/material/TextField';
import { Typography } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/lab/Autocomplete';
import Button from '@mui/material/Button';
import flatpickr from "assets/theme/components/flatpickr";
import { URL } from "../constants";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Icon from "@mui/material/Icon";
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import readXlsxFile from 'read-excel-file';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Input,Switch } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';
// import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import { TailSpin } from 'react-loader-spinner';
const useStyles = makeStyles({
  customLabel: {
    fontSize: '14px', // Set your desired font size here
  },
});
// Dashboard components


function User_Manage() {

  const classes = useStyles();

 const [uploadedFile, setUploadedFile] = useState(null);
  const [adress2, setadress2] = useState(false);
  const [token, settoken] = useState("");
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [Result, setResult] = useState(false)
  const [isToggled, setIsToggled] = useState(false); 
  const [edit, setedit] = useState(false) 
  const [success, setsuccess] = useState(false)
  const [message, setMessage] = useState('');
  const [email, setemail] = useState('');
  const [branch_location, setbranchlocation] = useState('');
  const [user_email, setuser_mail] = useState();
  const [u_roles, setu_roles] = useState();
  // const [password, setpassword] = useState();
  const [rows, setRows] = useState( [
    {
      id: 1,
    
      userName: '',
      userEmail:'',
      userMobileNumber:'',
      address: '',
      pincode: '',
      key:0,
      password:'',
      userId:'',


     
    },
  ]);
  const [errors, setErrors] = useState({});
  const[bulkdialog, setbulkdialog] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fe_data, setfe_data] = useState(null);
  const [d_feid, setd_feid] = useState(null);
  const [role, setrole] = useState(null);
  const [Branch_locations, setBranch_locations] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedRoutes, setselectedRoutes] = useState([]);
  const [dataloaded, setdataloaded] = useState(false)
  const handleChange = (event, value) => {
    setSelectedOptions(value);
  //   setselectedRoutes(prevState => {
  //     const newValue = value.map(v => v.value);
  //     const uniqueValues = Array.from(new Set([...prevState, ...newValue]));
  //     return uniqueValues;
  // });
    // handleSelection(value);
     //console.log(selectedOptions)
  };
  const roles = [
    { value: 'admin', label: 'admin' },
    { value: 'backend', label: 'backend' },
    { value: 'client', label: 'client' },
   
  ];

  const routes = [
    { value: 'Home', label: 'Home' },
    { value: 'Sampled Files', label: 'Sampled Files' },
    { value: 'Customer Form', label: 'Customer Form' },
    { value: 'Pending Cases', label: 'Pending Cases' },
    { value: 'Visit Completed Cases', label: 'Visit Completed Cases' },
    { value: 'Audit Completed Cases', label: 'Audit Completed Cases' },
    { value: 'FE Details', label: 'FE Details' },
    { value: 'Settings', label: 'Settings' },
    { value: 'User Management', label: 'User Management' },
   
   
  ];
  const read_data = () =>{
    try{
      const token = localStorage.getItem("token");
      // //console.log('hi')
      let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('token', token);
    const requestOptions1 = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({'id':1}),
   
    };
    fetch(`${URL}/read_form_data`, requestOptions1)
      .then((response) => response.json())
      .then((data) => {
             if(data.length != 0){
            
              setBranch_locations(data.branch_locations)
             }
              //console.log('data',data)
      })
    }
     catch (error) {
      // Code to handle the error
      //console.error("An error occurred:", error);
    } finally {
      // Optional: Code that runs regardless of whether an error occurred or not
      //console.log("This code runs regardless of errors.");
    }
  
  }

  useEffect(() => {
    read_data();
      }, []);
  const handleDownload = async () => {
    try {
      const response = await fetch(`${URL}/downloadfe`,);
      const blob = await response.blob();
  
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'sample_excel_bulk_fe_details.xlsx';
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      //console.error('Error downloading file:', error);
    }
  };
  
  function checkDataPresence(data) {
    //console.log('data',data)
    for (let item of data) {
      if (!isObjectValid(item)) {
        return false;
      }
    }
    return true;
  }
  function isObjectValid(obj) {
    if (typeof obj === 'object' && obj !== null) {
      for (let key in obj) {
        if (!key || obj[key] === '' || obj[key] === null || obj[key] === 0 || obj[key] === '0') {
          return false;
        }
        if (typeof obj[key] === 'object' && !isObjectValid(obj[key])) {
          return false;
        }
      }
      return true;
    }
    return false;
  }
  const [switchStates, setSwitchStates] = useState();

  const handleToggle = (status,email) => {
    // //console.log(status,fid)
    fetch(`${URL}/user_status_update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'token': token, // Assuming token is defined elsewhere
      },
      body:JSON.stringify({'email':email, 'status':!status}) ,
    })
    .then(response => response.json())
    .then(data => {
      //console.log(data);
      if(data.result){
        read_fe_details();
      }
    })
    .catch(error => console.error('Error:', error));
    // setSwitchStates((prevStates) =>
    //   prevStates.map((state) =>
    //     state.id === id ? { ...state, isToggled: !state.isToggled } : state
    //   )
    // );
  };
  const handleFileInputChangeb = (event) => {
    const file = event.target.files[0];
  
    // Check the file extension to determine the file type
    const fileType = file.name.split('.').pop().toLowerCase();
  
    const reader = new FileReader();
  
    reader.onload = (e) => {
      const fileContents = e.target.result;
  
      if (fileType === 'xls' || fileType === 'xlsx') {
        // Handle both .xls and .xlsx files
        readXlsxFile(file).then((rows) => {
          // Assuming rows is an array of arrays representing each row of the Excel file
          if (rows.length > 1) {
            const headers = rows[0]; // First row contains headers
            const data = rows.slice(1); // Data starts from the second row
  
            const parsedData = data.map((row) => {
              const rowData = {};
              row.forEach((value, index) => {
                const headerName = headers[index];
                rowData[headerName] = value;
              });
              return rowData;
            });
  
            // Transform COAPLNUMBERS, COAPLPINCODES, COAPPLADDRESS, COAPPLAREAS, and COAPPLICANTNAMES into COAPPLICANTDETAILS
            // const transformedData = parsedData.map((item) => {

            //   // //console.log(item)
            //   const coaplNumbersArr = typeof item.COAPLNUMBERS === 'string' ? item.COAPLNUMBERS.split(", ") : [item.COAPLNUMBERS];
            //   const coaplPincodesArr = typeof item.COAPLPINCODES === 'string' ? item.COAPLPINCODES.split(", ").map(pin => pin.replace(/\(|\)/g, "").trim()) :  [item.COAPLPINCODES];
            //   const coaplAddressArr =  item.COAPPLADDRESS.includes(",") ? item.COAPPLADDRESS.split("], [").map(addr => addr.replace(/\[|\]/g, "").trim()): [item.COAPPLADDRESS];
            //   const coaplAreasArr = item.COAPPLAREAS.includes(',') ? item.COAPPLAREAS.split(", "):[item.COAPPLAREAS];
            //   const coapplicantNamesArr = item.COAPPLICANTNAMES.includes(",") ? item.COAPPLICANTNAMES.split(", ") :[item.COAPPLICANTNAMES];
              
            //   const coapplicantDetails = coaplNumbersArr.map((num, index) => ({
            //     COAPLNUMBERS: num,
            //     COAPLPINCODES: coaplPincodesArr[index],
            //     COAPPLADDRESS: coaplAddressArr[index],
            //     COAPPLAREAS: coaplAreasArr[index],
            //     COAPPLICANTNAMES: coapplicantNamesArr[index]
            //   }));
  
            //   return { ...item, COAPPLICANTDETAILS: coapplicantDetails };
            // });
  
            // //console.log(transformedData);
            // Assuming setFileData expects an array of objects
            setFileData(parsedData);
          } else {
            //console.error('Excel file contains no data rows.');
          }
        }).catch(error => {
          //console.error('Error reading Excel file:', error);
        });
      } else {
        //console.error('Unsupported file type');
      }
    };
  
    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  

  const read_roles = () =>{
    const token = localStorage.getItem("token");
    // //console.log('hi')
    let headers = new Headers();
  
  headers.append('Content-Type', 'application/json');
  headers.append('token', token);
  const requestOptions1 = {
    method: "POST",
    headers: headers,
    body: JSON.stringify({'id':1}),
  
  };
  fetch(`${URL}/get_roles`, requestOptions1)
    .then((response) => response.json())
    .then((data) => {
      setu_roles(data.data)
     
            //console.log('data',data)
    })
  }
  
  useEffect(() => {
    read_roles();
      }, []);








  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
     //console.log('file',file)
    // Check if a file was selected
    if (file) {
      const fileType = file.type.split('/')[0]; // Get the file type
  
      // Check if the file type is an image
      if (fileType === 'image') {
        const reader = new FileReader();
  
        reader.onload = (e) => {
          const imageData = e.target.result; // Base64-encoded image data
          setFileData(imageData); // Assuming setFileData expects the image data
        };
  
        // Read the file as Data URL
        reader.readAsDataURL(file);
      } else {
        //console.error('Unsupported file type');
      }
    }
  };
  
  const bhandleClose = (event, reason) => {
    if (reason && reason === "backdropClick") 
        return;
    setLoading(false)
    setbulkdialog(false);
    // location.reload()
  }; 


 

  const handleFileChange = (file) => {
    // Set the uploaded file to state
    setUploadedFile(file);

    // Perform any other operations with the file if needed
    //console.log('Uploaded file:', file);
  };



  const handleInputChange = (rowId, inputName, value, index) => {

    // alert(value.target.value)
    const updatedRows = rows.map((row) =>
      row.id === rowId ? { ...row, [inputName]: value } : row
    );

    setRows(updatedRows);

    const updatedErrors = { ...errors };
        updatedErrors[index] = { ...updatedErrors[index], [inputName]: !value.trim() };
        setErrors(updatedErrors);

    const result = checkDataPresence(updatedRows);
    setResult(result)
  };
  const handleBlur = (index, fieldName, value) => {
    if (!value.trim()) {
        const updatedErrors = { ...errors };
        updatedErrors[index] = { ...updatedErrors[index], [fieldName]: true };
        setErrors(updatedErrors);
    }
};
const handlefocus = (index, fieldName) => {
  
      const updatedErrors = { ...errors };
      updatedErrors[index] = { ...updatedErrors[index], [fieldName]: false };
      setErrors(updatedErrors);
  
};
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    location.reload()
  };


  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    location.reload()
   
  };
  const handleClose2 = () => {
    setOpen2(false)
   
  };
  const Companies = [
    { value: 10, label: 'Aztek Solutions LLP' },
    { value: 20, label: 'Synergy Enterprises' },
  
  ];
  const options = [
    { value: 10, label: 'Residence' },
    { value: 20, label: 'Employment' },
    { value: 30, label: 'Business' },
    { value: 40, label: 'Property' },
    { value: 50, label: 'Document' },
  ];

  const states = [
    { value: 1, label: 'Andhra Pradesh' },
    { value: 2, label: 'Arunachal Pradesh' },
    { value: 3, label: 'Assam' },
    { value: 4, label: 'Bihar' },
    { value: 5, label: 'Chhattisgarh' },
    { value: 6, label: 'Goa' },
    { value: 7, label: 'Gujarat' },
    { value: 8, label: 'Haryana' },
    { value: 9, label: 'Himachal Pradesh' },
    { value: 10, label: 'Jharkhand' },
    { value: 11, label: 'Karnataka' },
    { value: 12, label: 'Kerala' },
    { value: 13, label: 'Madhya Pradesh' },
    { value: 14, label: 'Maharashtra' },
    { value: 15, label: 'Manipur' },
    { value: 16, label: 'Meghalaya' },
    { value: 17, label: 'Mizoram' },
    { value: 18, label: 'Nagaland' },
    { value: 19, label: 'Odisha' },
    { value: 20, label: 'Punjab' },
    { value: 21, label: 'Rajasthan' },
    { value: 22, label: 'Sikkim' },
    { value: 23, label: 'Tamil Nadu' },
    { value: 24, label: 'Telangana' },
    { value: 25, label: 'Tripura' },
    { value: 26, label: 'Uttarakhand' },
    { value: 27, label: 'Uttar Pradesh' },
    { value: 28, label: 'West Bengal' }
];


  useEffect(() => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email")
     settoken(token)
     setemail(email)
      }, []);

      const read_fe_details = () =>{
        const token = localStorage.getItem("token");
        // //console.log('hi')
        let headers = new Headers();

      headers.append('Content-Type', 'application/json');
      headers.append('token', token);
      const requestOptions1 = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({'id':1}),
     
      };
      fetch(`${URL}/user_details`, requestOptions1)
        .then((response) => response.json())
        .then((data) => {
          setfe_data(data.data)
          setdataloaded(true)
         
                //console.log('data',data)
        })
      }

      useEffect(() => {
        read_fe_details();
          }, []);

          const handelroleinput = (value) =>{
            // //console.log(value.target.value)
            setrole(value)
          }
          const handelbranchlocationinput = (value) =>{
            // //console.log(value.target.value)
            setbranchlocation(value)
          }
          const handleUploadb = () => {
            setLoading(true);
        
            let headers = new Headers();
        
            headers.append('Content-Type', 'application/json');
            headers.append('token', token);
            const requestOptions1 = {
              method: "POST",
              headers: headers,
              body: JSON.stringify({'data':fileData}),
            };
            fetch(`${URL}/insert_bulk_fe_applications`, requestOptions1)
              .then((response) => response.json())
              .then((data) => {
                //console.log(data)
                  if(data && data.result == true){
                    setLoading(false);
                    read_fe_details();
                  }else{
                    setLoading(false);
                    setbulkdialog(false);
                    setMessage({'successfulInsertions':data.successfulInsertions.length, 'failedInsertions':data.failedInsertions.length, 'failedInsertions_details': data.failedInsertions})
                     setsuccess(true)
                     read_fe_details();
                    //  alert('Bulk Upload Action  Completed !!!')
                    // handleClickOpen1()
                  }
              })
        
        
              // Handle upload logic here, using fileData state if needed
              //console.log("File data:", fileData);
              // handleClose(); // Close the dialog after upload
            };



  const submit_aplicant = () =>{
    
    // const arrayOfValues = selectedOptions.map(obj => Object.values(obj)).flat();
    const arrayOfValues = [...new Set(selectedOptions.map(obj => Object.values(obj)).flat())];
    //console.log('selectedRoutes',arrayOfValues)
    if(rows[0].userName != "" && rows[0].userEmail != "" && rows[0].userMobileNumber != "" && rows[0].role != ""  && arrayOfValues.length != 0 && rows[0].password != "",rows[0].userId != "" && branch_location != "" ){

      // //console.log(rows, applicant_permnent_address)
      let headers = new Headers();

      headers.append('Content-Type', 'application/json');
      headers.append('token', token);
      const requestOptions1 = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({'login_id':rows[0].userId,'name':rows[0].userName,'email':rows[0].userEmail, 'number':rows[0].userMobileNumber, 'role':role, 'branch_location':branch_location, 'routes':arrayOfValues, password:rows[0].password}),
      };
      fetch(`${URL}/signUp`, requestOptions1)
        .then((response) => response.json())
      .then(data => {
        //console.log(data);
        if(data.result){
          setedit(false)
          setOpen1(true)
        }
      })
      .catch(error => console.error('Error:', error));
      


     }
    
   }
  
const onEdit = (data) => {
  setedit(true)
  //console.log(data)
    const updatedRows = rows.map((row) => {
      
        return {
          ...row,
          userName: data.name,
          userEmail: data.email,
          userMobileNumber: data.number,
          userId:data.login_id

         
        };
    
     
    });

    setRows(updatedRows);
    setrole(data.role)
  };




   function SaveChanges(){
    //console.log(rows)
    if(rows[0].userName != "" && rows[0].userEmail != "" && rows[0].userMobileNumber != ""      ){

      // //console.log(rows, applicant_permnent_address)
      let headers = new Headers();

      headers.append('Content-Type', 'application/json');
      headers.append('token', token);
      const requestOptions1 = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({'login_id':rows[0].userId,'name':rows[0].userName,'email':rows[0].userEmail, 'number':rows[0].userMobileNumber, password:rows[0].password}),
      };

      console.log('row',rows[0])
      fetch(`${URL}/updateUser`, requestOptions1)
        .then((response) => response.json())
      .then(data => {
        //console.log(data);
        if(data.result){
          setedit(false)
          setOpen1(true)
        }
      })
      .catch(error => console.error('Error:', error));
      


     }
     
    
   }

  function fedelete (user_email) {
   setOpen2(true)
   setuser_mail(user_email)
  }



   function onDelete(){
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('token', token);
    const requestOptions1 = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({'user_email':user_email}),
    };
    fetch(`${URL}/user_delete`, requestOptions1)
      .then((response) => response.json())
    .then(data => {
      //console.log(data);
      if(data.result){
        // setedit(false)
        setOpen2(false)
        read_fe_details()
      }
    })
    .catch(error => console.error('Error:', error));
   }

  const handleUpload = () => {
    setLoading(true);
      // Handle upload logic here, using fileData state if needed
      //console.log("File data:", fileData);
      // handleClose(); // Close the dialog after upload
    };
    const handleKeyDown = (event) => {
      const isNumericKey = /^[0-9]$/;
      const isBackspace = event.key === 'Backspace';
  
      if (!isNumericKey.test(event.key) && !isBackspace) {
        event.preventDefault();
      }
    };
  return (
    <DashboardLayout>
      <DashboardNavbar />
       {/* <div style={{ boxShadow: '0 0 4px rgba(0, 0, 0, 0.5)', marginTop:10, borderRadius:10, marginBottom:10 }}>
<Button
      sx={{ backgroundColor: '#8acfbe', width: 150, right: '15%', position: 'absolute', top: '7%',zIndex: 9999 }}
      className="actionb"
      onClick={() => setbulkdialog(true)}
      // disabled='true'
    >
      Bulk Upload
    </Button></div>  */}
     {dataloaded ? 
      <MDBox py={3} style={{padding:30}}> 
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Alert"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          User details added Successfully
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{backgroundColor:'blue', color:'white'}} >Ok</Button>
          {/* <Button onClick={handleClose} autoFocus>
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>
      <Dialog
        open={open1}
        onClose={handleClose1}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Alert"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{color:'black'}}>
            User Details Saved successfully!!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1} style={{backgroundColor:'blue', color:'white'}} >Ok</Button>
          {/* <Button onClick={handleClose} autoFocus>
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>
     
      <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Alert"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are You Sure!! you want to Delete the User
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} style={{backgroundColor:'gray', color:'white'}} >Cancel</Button>
          <Button onClick={onDelete} style={{backgroundColor:'red', color:'white'}} >Yes</Button>
          {/* <Button onClick={handleClose} autoFocus>
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>
      <>
      <Dialog
        open={bulkdialog}
        onClose={bhandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEscapeKeyDown // Disable closing the dialog by pressing the escape key
        BackdropProps={{ onClick: null }} 
        // sx={{ Width: 600, maxWidth:800 }}
      >
        <DialogTitle id="alert-dialog-title" style={{color:'Green'}}>
          Bulk Upload
        </DialogTitle>
        <DialogContent>
          <span>Download Sample Formate File</span>
          
        <Button  onClick={handleDownload} style={{ backgroundColor: '#84b3a9', color: 'white', marginLeft:10 }}>Download</Button>
  <DialogContentText id="alert-dialog-description">
    Select Xls File
  </DialogContentText>
  <div style={{ boxShadow: '0 0 4px rgba(0, 0, 0, 0.5)', padding:10, borderRadius:5}}>
  <input
    type="file"
    accept=".xls, .xlsx"
    onChange={handleFileInputChangeb} // Define a function to handle file input change
  />
  </div>

</DialogContent>
<DialogActions>
  <Button onClick={bhandleClose} style={{ backgroundColor: '#f08269', color: 'white' }}>
    Cancel
  </Button>
  {/* <Button onClick={handleUpload} style={{ backgroundColor: 'green', color: 'white' }}>
    Upload
  </Button> */}
  {loading ? <Button  style={{ backgroundColor: 'green', color: 'white' }}>
  <CircularProgress size={24} color="inherit" />
  </Button>  :  <Button onClick={handleUploadb} style={{ backgroundColor: 'green', color: 'white' }}>
    Upload
  </Button>}
</DialogActions>

      </Dialog>
      {/* {bulkdialog && <Backdrop open={bulkdialog} onClick={handleClose} sx={{ zIndex: 1200 }} />} */}
    </>
    {success && (
      <div className="centered-alert">
        <div>
        <Grid container direction="row" >
      <Grid item xs={9} container direction="column">
      <h3> Bulk Upload Report!!</h3>
      </Grid>
      <Grid item xs={3} container direction="column">
        <Button className="reportb" onClick={()=>{setsuccess(false)}}>Close</Button>
      </Grid>
      </Grid>

        </div>
       
        <p style={{color:'#4a6339', fontSize:20}}> * Successful Insertions : {message.successfulInsertions}</p>
        <p style={{color:'#854945', fontSize:20}}> * Failed Insertions : {message.failedInsertions}</p>
        <h4>Failed Insertions Details:</h4>
        {message.failedInsertions_details && message.failedInsertions_details.length != 0 && message.failedInsertions_details.map((item)=>(
          <div key={item} style={{flexDirection:'row'}}>
        <p> Row Number : {item['index']}</p>
        <p style={{color:'#b85851'}}> Error :{item['reason']}</p>
        </div>
        ))}
       
       
      </div>
    )}
      <Grid container spacing={2}>
     
      
    <Grid item xs={4} container direction="row" style={{paddingLeft:0, paddingTop:0, marginTop:-10}}>
    {rows.map((row,index)=>(
      <Grid container spacing={2} className="addbox">
<Grid item xs={12} container direction="column" className="fieldclass">
  <TextField 
    InputLabelProps={{ classes: { root: classes.customLabel } }}
    id="outlined-basic" 
    label="User Id" 
    variant="outlined" 
    className="form" 
    disabled={edit}
    value={row.userId}  
    onChange={(value) => handleInputChange(row.id, 'userId', value.target.value, index)} 
    required 
    onBlur={() => handleBlur(index, 'userId', row.userId)} 
    style={{
      paddingTop: 0,
      borderColor: errors[index]?.userId ? 'red' : '',
      borderStyle: errors[index]?.userId ? 'groove' : '',
      borderWidth: 3,
      borderRadius: 10
    }}  
    onFocus={() => handlefocus(index, 'userId')}
  />
  {/* {errors[index]?.userName && <span className="spanc">User Name can't be empty!</span>} */}
</Grid>

<Grid item xs={12} container direction="column" className="fieldclass">
  <TextField 
    InputLabelProps={{ classes: { root: classes.customLabel } }}
    id="outlined-basic" 
    label="User Name" 
    variant="outlined" 
    className="form" 
    value={row.userName}  
    onChange={(value) => handleInputChange(row.id, 'userName', value.target.value, index)} 
    required 
    onBlur={() => handleBlur(index, 'userName', row.userName)} 
    style={{
      paddingTop: 0,
      borderColor: errors[index]?.userName ? 'red' : '',
      borderStyle: errors[index]?.userName ? 'groove' : '',
      borderWidth: 3,
      borderRadius: 10
    }}  
    onFocus={() => handlefocus(index, 'userName')}
  />
  {/* {errors[index]?.userName && <span className="spanc">User Name can't be empty!</span>} */}
</Grid>

<Grid item xs={12} container direction="column" className="fieldclass">
  <TextField 
    InputLabelProps={{ classes: { root: classes.customLabel } }}
    id="outlined-basic" 
    label="User Email" 
    variant="outlined" 
    className="form" 
    value={row.userEmail}  
    onChange={(value) => handleInputChange(row.id, 'userEmail', value.target.value, index)} 
    required 
    onBlur={() => handleBlur(index, 'userEmail', row.userEmail)} 
    style={{
      paddingTop: 0,
      borderColor: errors[index]?.userEmail ? 'red' : '',
      borderStyle: errors[index]?.userEmail ? 'groove' : '',
      borderWidth: 3,
      borderRadius: 10
    }}  
    onFocus={() => handlefocus(index, 'userEmail')}
  />
  {/* {errors[index]?.userEmail && <span className="spanc">User Email can't be empty!</span>} */}
</Grid>

<Grid item xs={12} container direction="column" className="fieldclass">
  <TextField 
    InputLabelProps={{ classes: { root: classes.customLabel } }}
    id="outlined-basic" 
    label="User Mobile Number" 
    variant="outlined" 
    className="form" 
    type="number" 
    value={row.userMobileNumber}  
    onChange={(value) => handleInputChange(row.id, 'userMobileNumber', value.target.value, index)} 
    required 
    onBlur={() => handleBlur(index, 'userMobileNumber', row.userMobileNumber)} 
    style={{
      paddingTop: 0,
      borderColor: errors[index]?.userMobileNumber ? 'red' : '',
      borderStyle: errors[index]?.userMobileNumber ? 'groove' : '',
      borderWidth: 3,
      borderRadius: 10
    }}  
    onFocus={() => handlefocus(index, 'userMobileNumber')}
  />
  {/* {errors[index]?.userMobileNumber && <span className="spanc">User Mobile Number can't be empty!</span>} */}
</Grid>
<Grid item xs={12} container direction="column" className="fieldclass">
  <TextField 
    InputLabelProps={{ classes: { root: classes.customLabel } }}
    id="outlined-basic" 
    label="User Password" 
    variant="outlined" 
    className="form" 
    type="text" 
    value={row.password}  
    onChange={(value) => handleInputChange(row.id, 'password', value.target.value, index)} 
    required 
    onBlur={() => handleBlur(index, 'password', row.password)} 
    style={{
      paddingTop: 0,
      borderColor: errors[index]?.password ? 'red' : '',
      borderStyle: errors[index]?.password ? 'groove' : '',
      borderWidth: 3,
      borderRadius: 10
    }}  
    onFocus={() => handlefocus(index, 'password')}
  />
  {/* {errors[index]?.userMobileNumber && <span className="spanc">User Mobile Number can't be empty!</span>} */}
</Grid>

<Grid item xs={12} container direction="column" className="fieldclass">
<Autocomplete
       className="form"
          id="Role"
          options={u_roles}
          disabled={edit}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} {...params} label="Select Role" variant="outlined"   required   style={{paddingTop:0, borderWidth:3, borderRadius:10}}  /> } onChange={(event, value) => {
            handelroleinput(value ? value.label : '')
          }}
         
        />
  {/* {errors[index]?.userRole && <span className="spanc">User Role can't be empty!</span>} */}
</Grid>
<Grid item xs={12} container direction="column" className="fieldclass">
<Autocomplete
       className="form"
          id="Location"
          options={Branch_locations}
          disabled={edit}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} {...params} label="Select Branch Location" variant="outlined"   required   style={{paddingTop:0, borderWidth:3, borderRadius:10}}  /> } onChange={(event, value) => {
            handelbranchlocationinput(value ? value.label : '')
          }}
         
        />
  {/* {errors[index]?.userRole && <span className="spanc">User Role can't be empty!</span>} */}
</Grid>
<Grid item xs={12} container direction="column" className="fieldclass">
      <Autocomplete
        className="form"
        id="tab"
        options={routes}
        getOptionLabel={(option) => option.label}
        onChange={handleChange}
        multiple
        disabled={edit}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Tabs"
            variant="outlined"
            required
          />
        )}
        renderOption={(props, option, { selected }) => (
          <List  component="ul" {...props}>
            <Checkbox
              // icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              // checkedIcon={<CheckBoxIcon  fontSize="small" />}
              checked={selected}
            />
            <ListItemText primary={option.label} />
          </List >
        )}
      />
    </Grid>


  {/* <Grid item xs={6} container direction="column" className="fieldclass">
  <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} id="outlined-basic" label="MOBILE NUMBER" variant="outlined" className="form" value={row.mobile_no}  onChange={(value)=>handleInputChange(row.id, 'mobile_no',value.target.value, index)} required onBlur={() => handleBlur(index, 'mobile_no', row.mobile_no)} style={{paddingTop:0,borderColor:errors[index]?.mobile_no?'red':'', borderStyle:errors[index]?.mobile_no?'groove':'', borderWidth:3, borderRadius:10}}  onFocus={()=> {handlefocus(index, 'mobile_no')}}/>

  </Grid> */}
  {/* <Grid item xs={10} container direction="column" className="fieldclass">
    <span style={{fontSize:14, paddingLeft:5}}>ID PROOF</span>
 <div className="filec">
 <Input
    id="outlined-basic"
    type="file"
    onChange={ (e)=>handleFileChange(e.target.files[0])}
    // onBlur={() => )}
    // onFocus={() => handlefocus(index, 'mobile_no')}
    required
  />
 </div>
 

  </Grid> */}

  
  </Grid>
   ))}
   <Grid item xs={12} style={{textAlign:'center', width:200}}>
    {edit? <div><Button variant="contained" className="fecbutton" onClick={() => {location.reload()}}>Cancel</Button><Button variant="contained" className="fesbutton" onClick={() => {SaveChanges()}}>Save Changes</Button> </div>  :
  <Button variant="contained" className="fesbutton" onClick={() => {submit_aplicant()}}>Submit</Button>}
  </Grid>
    </Grid>
    <Grid item xs={8} container direction="row" style={{marginTop:-10}}>
      
      <div className="feaddbox" >
        <div style={{textAlign:'center'}}>   <Typography className="fe_title">User Details</Typography></div>
        <div className="header" style={{ position: 'fixed',width:'45%', zIndex: 1 }}>
      <Grid item  xs={12} container direction="row" style={{marginBottom:5}}>
             <Grid item xs={3} container direction="column" className="fehfieldclass"><Typography style={{paddingTop:3, fontSize:16,width:'100%', paddingLeft:10, paddingRight:10}}>User Name</Typography> </Grid>
             <Grid item xs={2.5} container direction="column" className="fehfieldclass"><Typography style={{paddingTop:3, fontSize:16,width:'100%', paddingLeft:10, paddingRight:10}}>User ID</Typography></Grid>
             <Grid item xs={2} container direction="column" className="fehfieldclass"><Typography style={{paddingTop:3, fontSize:16,width:'100%', paddingLeft:10, paddingRight:10}}>Role</Typography></Grid>
             <Grid item xs={1.2} container direction="column" className="fehfieldclass"><Typography style={{paddingTop:3, fontSize:16,width:'100%', paddingLeft:10, paddingRight:10}}>Status</Typography></Grid>
             <Grid item xs={2.2} container direction="column" className="fehfieldclass"><Typography style={{paddingTop:3, fontSize:16,width:'100%', paddingLeft:10, paddingRight:10}}>Action</Typography></Grid>
          </Grid>
          </div>
          <div className="content" style={{ marginTop: '60px', maxHeight: '400px', overflowY: 'auto' }}>
      {fe_data && fe_data.map((item,index)=>(
          <Grid item  xs={12} container direction="row">
             <Grid item xs={3} container direction="column" className="fefieldclass"><Typography style={{paddingTop:7, fontSize:16,width:'100%', paddingLeft:10, paddingRight:10}}>{item.name}</Typography> </Grid>
             <Grid item xs={2.5} container direction="column" className="fefieldclass"><Typography style={{paddingTop:7, fontSize:16,width:'100%', paddingLeft:10, paddingRight:10}}>{item.login_id}</Typography> </Grid>
             <Grid item xs={2} container direction="column" className="fefieldclass"><Typography style={{paddingTop:7, fontSize:16,width:'100%', paddingLeft:10, paddingRight:10}}>{item.role}</Typography></Grid>
             <Grid item xs={1.2} container direction="column"  className="fefieldclasss" style={{paddingRight:10, paddingLeft:5}} >
      <Switch
        color="primary" // Choose the color variant you prefer
        checked={item.status} // Controlled by state
        onChange={()=>handleToggle(item.status, item.email)}
        disabled = {email == item.email ? true : false}
      />
      {/* {isToggled ? 'Yes' : 'No'} */}
    </Grid>
             {/* <Grid item xs={1} container direction="column" className="fefieldclass"> <IconButton aria-label="edit" style={{color:"#62a2b3"}}  onClick={()=>onEdit(item)}>
          <EditIcon />
        </IconButton></Grid> */}
        <Grid item xs={1} container direction="column" className="fefieldclass"> <IconButton aria-label="edit" style={{color:"#62a2b3"}}  onClick={()=>onEdit(item)}>
          <EditIcon />
        </IconButton></Grid>
             <Grid item xs={1} container direction="column" className="fefieldclass"><IconButton aria-label="delete" style={{color:"red"}}  onClick={()=>fedelete(item.email)}>
          <DeleteIcon />
        </IconButton></Grid>
        
          </Grid>
      ))}
       </div>
      </div>
    </Grid>
  
 
 

  {/* <Grid item xs={6} container direction="column">
  <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} id="outlined-basic" label="MOBILE NO." type="tel"   variant="outlined" className="form" onChange={(value)=>handelmobile_noinput(value)} required onBlur={onblurmobile_noinput}   inputProps={{ maxLength: 10 }} />
  {mobile_noerror && <span className="spanc">Mobile No can't be empty!</span>}
  </Grid> */}
 
  {/* <Grid item xs={6} container direction="column">
  <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} id="outlined-basic" label="ADDRESS LINE 1" variant="outlined" className="form" required  onChange={(value)=>handeladress1input(value)} onBlur={onbluradress1input}/>
  {adress1error && <span className="spanc">ADDRESS can't be empty!</span>}
  </Grid>
  <Grid item xs={6} container direction="column">
  <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} id="outlined-basic" label="ADDRESS LINE 2" variant="outlined" className="form"   onChange={(value)=>handeladress2input(value)}/>
  </Grid> */}
 
</Grid>
  
      
      </MDBox> :
      <div className="loading-container">
        <TailSpin
          height="80"
          width="80"
          color="#00BFFF"
          ariaLabel="loading"
        />
      </div>}
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default User_Manage;
