// Import the functions you need from the Firebase SDKs
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCDtUZNrumpMvqRsJoYR2M-GrvsuZQIu8M",
  authDomain: "aztek-768f8.firebaseapp.com",
  projectId: "aztek-768f8",
  storageBucket: "aztek-768f8.appspot.com",
  messagingSenderId: "793968321199",
  appId: "1:793968321199:web:3804499accf2e0b68c2a5a",
  measurementId: "G-BY2ZT9SVWC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Storage
const storage = getStorage(app);

export { storage };
