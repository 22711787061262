/**
=========================================================
* Aztek Portal
=========================================================



Coded by Vinod kumar J

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { createContext, useContext, useReducer, useMemo, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import '../app.css'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import TextField from '@mui/material/TextField';
import { Typography } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/lab/Autocomplete';
import Button from '@mui/material/Button';
import flatpickr from "assets/theme/components/flatpickr";
import { URL } from "../constants";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Icon from "@mui/material/Icon";
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import readXlsxFile from 'read-excel-file';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  customLabel: {
    fontSize: '14px', // Set your desired font size here
  },
});
// import Excelfile from '../../assets/sample_excel_bulk_allocation.xlsx'
// Dashboard components


function Dashboard() {
  const classes = useStyles();
  const { sales, tasks } = reportsLineChartData;
  const [rac_name, setrac_name] = useState("");
  const [case_id, setcase_id] = useState("");
  const [branch_name, setbranch_name] = useState("");
  const [company_name, setcompany_name] = useState("");
  const [sender_name, setsender_name] = useState("");   
  const [reffernce_no, setreffernce_no] = useState("");
  const [aplicant_name, setaplicant_name] = useState("");
  const [product_type, setproduct_type] = useState("");
  const [mobile_no, setmobile_no] = useState("");
  const [a_mobile_no, seta_mobile_no] = useState("");
  const [branchlocation, setbranchlocation] = useState("");
  const [adress1, setadress1] = useState("");
  const [applicant_permnent_address, setapplicant_permnent_address] = useState({address:"", pincode:"", area:""})
  const [applicant_current_address, setapplicant_current_address] = useState({address:"", pincode:"", area:""})
  const [verificationtype, setverificationtype] = useState("");
  const [document_type, setdocument_type] = useState("");
  const [rac_nameerror, setrac_nameerror] = useState(false);
  const [case_id_error, setcase_id_error] = useState(false);
  const [branchlocationerror, setbranchlocationerror] = useState(false);
  const [branch_nameerror, setbranch_nameerror] = useState(false);
  const [company_nameerror, setcompany_nameerror] = useState(false);
  const [sender_nameerror, setsender_nameerror] = useState(false);
  const [reffernce_noerror, setreffernce_noerror] = useState(false);
  const [aplicant_nameerror, setaplicant_nameerror] = useState(false);
  const [applicant_p__error, setapplicant_p_address_error] = useState({address:false, pincode:false, area:false});
  const [applicant_c__error, setapplicant_c_address_error] = useState({address:false, pincode:false, area:false});
  const [product_type_error, setproduct_type_error] = useState("")
  const [mobile_noerror, setmobile_noerror] = useState(false);
  const [a_mobile_noerror, seta_mobile_noerror] = useState(false);
  const [verificationtype_error, setverificationtype_error] = useState(false);
  const [bulkdone, setbulkdone] = useState(false);
  const [adress2, setadress2] = useState(false);
  const [token, settoken] = useState("");
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [success, setsuccess] = useState(false) 
  const [Result, setResult] = useState(false) 
  const [invalidEntries, setInvalidEntries] = useState([]);
  const [showInvalidEntriesModal, setShowInvalidEntriesModal] = useState(false);
  const [rows, setRows] = useState( [
    {
      id: 1,
    
      co_applicant_name: '',
      mobile_no:'',
      address: '',
      pincode: '',
      area:''
    },
  ]);
  const [errors, setErrors] = useState({});
  const[bulkdialog, setbulkdialog] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [branch_deatils, setbranch_deatils] = useState([]);
  const [client_deatils, setclient_deatils] = useState([]);
  const [product_deatils, setproduct_deatils] = useState([]);
  const [Branch_locations, setBranch_locations] = useState([]);
  const [message, setMessage] = useState('');
// download sample formate xls
const handleDownload = async () => {
  try {
    // const response = await fetch(`${URL}/download`,);
    const response = await fetch(`${URL}/download`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data: 'data' })
    });
    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'sample_excel_bulk_allocation.xlsx';
    link.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    //console.error('Error downloading file:', error);
  }
};




  function checkDataPresence(data) {
    //console.log('data',data)
    for (let item of data) {
      if (!isObjectValid(item)) {
        return false;
      }
    }
    return true;
  }
  function isObjectValid(obj) {
    if (typeof obj === 'object' && obj !== null) {
      for (let key in obj) {
        if (!key || obj[key] === '' || obj[key] === null || obj[key] === 0 || obj[key] === '0') {
          return false;
        }
        if (typeof obj[key] === 'object' && !isObjectValid(obj[key])) {
          return false;
        }
      }
      return true;
    }
    return false;
  }
    
  // const handleFileInputChange = (event) => {
  //   const file = event.target.files[0];
  
  //   // Check the file extension to determine the file type
  //   const fileType = file.name.split('.').pop().toLowerCase();
  
  //   const reader = new FileReader();
  
  //   reader.onload = (e) => {
  //     const fileContents = e.target.result;
  
  //     if (fileType === 'xls' || fileType === 'xlsx') {
  //       // Handle both .xls and .xlsx files
  //       readXlsxFile(file).then((rows) => {
  //         // Assuming rows is an array of arrays representing each row of the Excel file
  //         if (rows.length > 1) {
  //           const headers = rows[0]; // First row contains headers
  //           const data = rows.slice(1); // Data starts from the second row
  
  //           const parsedData = data.map((row) => {
  //             const rowData = {};
  //             row.forEach((value, index) => {
  //               const headerName = headers[index];
  //               rowData[headerName] = value;
  //             });
  //             return rowData;
  //           });
  
  //           //console.log(parsedData);
  //           // Assuming setFileData expects an array of objects
  //           setFileData(parsedData);
  //         } else {
  //           //console.error('Excel file contains no data rows.');
  //         }
  //       }).catch(error => {
  //         //console.error('Error reading Excel file:', error);
  //       });
  //     } else {
  //       //console.error('Unsupported file type');
  //     }
  //   };
  
  //   // Read the file as array buffer
  //   reader.readAsArrayBuffer(file);
  // };
  

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
  
    // Check the file extension to determine the file type
    const fileType = file.name.split('.').pop().toLowerCase();
  
    const reader = new FileReader();
  
    reader.onload = (e) => {
      const fileContents = e.target.result;
  
      if (fileType === 'xls' || fileType === 'xlsx') {
        // Handle both .xls and .xlsx files
        readXlsxFile(file).then((rows) => {
          // Assuming rows is an array of arrays representing each row of the Excel file
          if (rows.length > 1) {
            const headers = rows[0]; // First row contains headers
            const data = rows.slice(1); // Data starts from the second row
  
            const parsedData = data.map((row) => {
              const rowData = {};
              row.forEach((value, index) => {
                const headerName = headers[index];
                rowData[headerName] = value;
              });
              return rowData;
            });
  
            // Transform COAPLNUMBERS, COAPLPINCODES, COAPPLADDRESS, COAPPLAREAS, and COAPPLICANTNAMES into COAPPLICANTDETAILS
            const transformedData = parsedData.map((item) => {

              // // //console.log(item)
              // const coaplNumbersArr = typeof item.COAPLNUMBERS === 'string' ? item.COAPLNUMBERS.split(", ") : [item.COAPLNUMBERS];
              // const coaplPincodesArr = typeof item.COAPLPINCODES === 'string' ? item.COAPLPINCODES.split(", ").map(pin => pin.replace(/\(|\)/g, "").trim()) :  [item.COAPLPINCODES];
              // const coaplAddressArr =  item.COAPPLADDRESS.includes(",") ? item.COAPPLADDRESS.split("], [").map(addr => addr.replace(/\[|\]/g, "").trim()): [item.COAPPLADDRESS];
              // const coaplAreasArr = item.COAPPLAREAS.includes(',') ? item.COAPPLAREAS.split(", "):[item.COAPPLAREAS];
              // const coapplicantNamesArr = item.COAPPLICANTNAMES.includes(",") ? item.COAPPLICANTNAMES.split(", ") :[item.COAPPLICANTNAMES];
              
              // const coapplicantDetails = coaplNumbersArr.map((num, index) => ({
              //   COAPLNUMBERS: num,
              //   COAPLPINCODES: coaplPincodesArr[index],
              //   COAPPLADDRESS: coaplAddressArr[index],
              //   COAPPLAREAS: coaplAreasArr[index],
              //   COAPPLICANTNAMES: coapplicantNamesArr[index]
              // }));
              // return item;
              return { ...item, COAPPLICANTDETAILS: {} };
            });
  
            // //console.log(transformedData);
            // Assuming setFileData expects an array of objects
            setFileData(transformedData);
          } else {
            //console.error('Excel file contains no data rows.');
          }
        }).catch(error => {
          //console.error('Error reading Excel file:', error);
        });
      } else {
        //console.error('Unsupported file type');
      }
    };
  
    // Read the file as array buffer
    reader.readAsArrayBuffer(file);
  };
  
  // const handleFileInputChange = (event) => {
  //   const file = event.target.files[0];
  
  //   // Check the file extension to determine the file type
  //   const fileType = file.name.split('.').pop().toLowerCase();
  
  //   const reader = new FileReader();
  
  //   reader.onload = (e) => {
  //     const fileContents = e.target.result;
  
  //     if (fileType === 'xls' || fileType === 'xlsx') {
  //       // Handle both .xls and .xlsx files
  //       readXlsxFile(file).then((rows) => {
  //         if (rows.length > 1) {
  //           const headers = rows[0]; // First row contains headers
  //           const data = rows.slice(1); // Data starts from the second row
  
  //           const parsedData = data.map((row) => {
  //             const rowData = {};
  //             row.forEach((value, index) => {
  //               const headerName = headers[index];
  //               rowData[headerName] = value;
  //             });
  //             return rowData;
  //           });
  
  //           // Define allowed verification types
  //           const allowedTypes = ["Residence", "Document", "Employment", "Property", "Asset", "Business"];
  
  //           // Filter data based on VERIFICATIONTYPE
  //           const filteredData = parsedData.filter((item) => allowedTypes.includes(item.VERIFICATIONTYPE));
  
  //           // Transform the data if necessary
  //           const transformedData = filteredData.map((item) => {
  //             // Your transformation logic here
  //             return { ...item, COAPPLICANTDETAILS: {} };
  //           });
  
  //           // Set the filtered and transformed data
  //           setFileData(transformedData);
  //         } else {
  //           console.error('Excel file contains no data rows.');
  //         }
  //       }).catch(error => {
  //         console.error('Error reading Excel file:', error);
  //       });
  //     } else {
  //       console.error('Unsupported file type');
  //     }
  //   };
  
  //   // Read the file as array buffer
  //   reader.readAsArrayBuffer(file);
  // };
  


  const bhandleClose = (event, reason) => {
    if (reason && reason === "backdropClick") 
        return;
    setLoading(false)
    setbulkdialog(false);
    // location.reload()
  }; 
  const addRow = () => {
    const newRow = {
      id: rows.length + 1,
      
      co_applicant_name: '',
      address: '',
      pincode: '',
      area:''
    };

    setRows([...rows, newRow]);
    // const result = checkDataPresence(rows);
    setResult(false)
    // //console.log(result)
  };

  const removeRow = (rowId) => {
    const updatedRows = rows.filter((row) => row.id !== rowId);
    setRows(updatedRows);
    const result = checkDataPresence(updatedRows);
    setResult(result)
    //console.log(result)
  };

  // const handleDropdownChange = (rowId, value) => {

        
  //   const updatedRows = rows.map((row) =>
  //     row.id === rowId ? { id:row.id,co_applicant_name:row.co_applicant_name,adress:row.adress,pincode:row.pincode, area:value } : row
  //   );

  //   setRows(updatedRows);
  //   const result = checkDataPresence(updatedRows);
  //   setResult(result)
  //   setselectedtool(value)
  //   //console.log(updatedRows)
  // };

  const handleInputChange = (rowId, inputName, value, index) => {

    // alert(value.target.value)
    const updatedRows = rows.map((row) =>
      row.id === rowId ? { ...row, [inputName]: value } : row
    );

    setRows(updatedRows);

    const updatedErrors = { ...errors };
        updatedErrors[index] = { ...updatedErrors[index], [inputName]: !value.trim() };
        setErrors(updatedErrors);

    const result = checkDataPresence(updatedRows);
    setResult(result)
  };
  const handleBlur = (index, fieldName, value) => {
    if (!value.trim()) {
        const updatedErrors = { ...errors };
        updatedErrors[index] = { ...updatedErrors[index], [fieldName]: true };
        setErrors(updatedErrors);
    }
};
const handlefocus = (index, fieldName) => {
  
      const updatedErrors = { ...errors };
      updatedErrors[index] = { ...updatedErrors[index], [fieldName]: false };
      setErrors(updatedErrors);
  
};
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    location.reload()
  };

  const handelerrorclose = () => {
    setShowInvalidEntriesModal(false);
    // location.reload()
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
   
  };

  const read_data = () =>{
    try{
      const token = localStorage.getItem("token");
      // //console.log('hi')
      let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('token', token);
    const requestOptions1 = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({'id':1}),
   
    };
    fetch(`${URL}/read_form_data`, requestOptions1)
      .then((response) => response.json())
      .then((data) => {
             if(data.length != 0){
              setbranch_deatils(data.branch_details)
              setclient_deatils(data.client_details)
              setproduct_deatils(data.product_types)
              setBranch_locations(data.branch_locations)
             }
              //console.log('data',data)
      })
    }
     catch (error) {
      // Code to handle the error
      //console.error("An error occurred:", error);
    } finally {
      // Optional: Code that runs regardless of whether an error occurred or not
      //console.log("This code runs regardless of errors.");
    }
  
  }

  useEffect(() => {
    read_data();
      }, []);



  const Companies = [
    { value: 10, label: 'AZTEK SOLUTIONS LLP' },
    { value: 20, label: 'SYNERGY ENTERPRISES' },
  
  ];
  const Document_types = [
    { value: 10, label: 'Bank Statement' },
    { value: 20, label: 'Financial And Balance Sheet' },
    { value: 10, label: 'Form-16' },
    { value: 20, label: 'ITR' },
    { value: 10, label: 'Quotation' },
    { value: 20, label: 'Salary Slip' },
  
  ];
  const options = [
    { value: 10, label: 'Residence' },
    { value: 20, label: 'Employment' },
    { value: 30, label: 'Business' },
    { value: 40, label: 'Property' },
    { value: 50, label: 'Document' },
    { value: 60, label: 'Asset' },
  ];

  const states = [
    {value:29, label:'Delhi NCR'},
    { value: 1, label: 'Andhra Pradesh' },
    { value: 2, label: 'Arunachal Pradesh' },
    { value: 3, label: 'Assam' },
    { value: 4, label: 'Bihar' },
    { value: 5, label: 'Chhattisgarh' },
    { value: 6, label: 'Goa' },
    { value: 7, label: 'Gujarat' },
    { value: 8, label: 'Haryana' },
    { value: 9, label: 'Himachal Pradesh' },
    { value: 10, label: 'Jharkhand' },
    { value: 11, label: 'Karnataka' },
    { value: 12, label: 'Kerala' },
    { value: 13, label: 'Madhya Pradesh' },
    { value: 14, label: 'Maharashtra' },
    { value: 15, label: 'Manipur' },
    { value: 16, label: 'Meghalaya' },
    { value: 17, label: 'Mizoram' },
    { value: 18, label: 'Nagaland' },
    { value: 19, label: 'Odisha' },
    { value: 20, label: 'Punjab' },
    { value: 21, label: 'Rajasthan' },
    { value: 22, label: 'Sikkim' },
    { value: 23, label: 'Tamil Nadu' },
    { value: 24, label: 'Telangana' },
    { value: 25, label: 'Tripura' },
    { value: 26, label: 'Uttarakhand' },
    { value: 27, label: 'Uttar Pradesh' },
    { value: 28, label: 'West Bengal' }
    
];


  useEffect(() => {
    const token = localStorage.getItem("token");
 
     settoken(token)
      }, []);

      const handelecompany = (value) =>{
        // //console.log(value.target.value)
        setcompany_name(value)
      }
  const handelracinput = (value) =>{
    // //console.log(value.target.value)
    setrac_name(value)
  }
  const handlebranch_locationinput = (value) =>{
    // //console.log(value.target.value)
    setbranchlocation(value)
  }
  const handlecaseidinput = (value) =>{
    // //console.log(value.target.value)
    setcase_id(value.target.value)
  }
  
  const handelbranch_nameinput = (value) =>{
    // //console.log(value.target.value)
    setbranch_name(value)
  }
  const handelsender_nameinput = (value) =>{
    // //console.log(value.target.value)
    setsender_name(value.target.value)
  }
  const handelreffernce_noinput = (value) =>{
    // //console.log(value.target.value)
    setreffernce_no(value.target.value)
  }
  const handelaplicant_nameinput = (value) =>{
    // //console.log(value.target.value)
    setaplicant_name(value.target.value)
  }
 
  const handelmobile_noinput = (value) =>{
    // //console.log(value.target.value)
    let input = value.target.value.replace(/\D/g, '');
    if (input.length <= 10) {
      setmobile_no(input);
    } // Remove non-numeric characters
    // input = input.slice(0, 10);
    // setmobile_no(input)
  }
  const handela_mobile_noinput = (value) =>{
    //console.log(value)
    seta_mobile_no(value.target.value)
  }
  const product_type_input = (value) =>{
    // //console.log(value.target.value)
    setproduct_type(value)
  }
  const handelverification = (value) =>{
    // //console.log(value.target.value)
    setverificationtype(value)
  }
  const handeldocument_type = (value) =>{
    // //console.log(value.target.value)
    setdocument_type(value)
  }
const handleapplicantpadress = (value, key) =>{
  // //console.log(value, key)
  let data = {...applicant_permnent_address}
  data[key] = value;
  setapplicant_permnent_address(data)
}
const handleapplicantcadress = (value, key) =>{
  let data = {...applicant_current_address}
  data[key] = value;
  setapplicant_current_address(data)
}

  const onblurracinput = () =>{
    // //console.log(value.target.value)
    if(rac_name == "" ){
      setrac_nameerror(true)
     }else{
      setrac_nameerror(false)
     }
  }

  const handleKeyDown = (event) => {
    const key = event.key;
    // Allow only numeric input and control keys
    if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight' && key !== 'Tab') {
      event.preventDefault();
    }
  };

  const handlePaste = (event) => {
    const paste = event.clipboardData.getData('text');
    if (!/^\d+$/.test(paste)) {
      event.preventDefault();
    }
  };

  const onblurbranchlocation = () =>{
    // //console.log(value.target.value)
    if(branchlocation == "" ){
      setbranchlocationerror(true)
     }else{
      setbranchlocationerror(false)
     }
  }
  const onblurcaseidinput = () =>{
    // //console.log(value.target.value)
    if(case_id == "" ){
      setcase_id_error(true)
     }else{
      setcase_id_error(false)
     }
  }
  const onblurbranch_nameinput = () =>{
    // //console.log(value.target.value)
    if(branch_name == ""){
      setbranch_nameerror(true)
     }else{
      setbranch_nameerror(false)
     }
  }
  const onblursender_nameinput = () =>{
    // //console.log(value.target.value)
    if(sender_name == ""){
      setsender_nameerror(true)
     }else{
      setsender_nameerror(false)
     }
  }
  const onblurreffernce_noinput = () =>{
    // //console.log(value.target.value)
    if(reffernce_no == ""){
      setreffernce_noerror(true)
     }else{
      setreffernce_noerror(false)
     }
  }
  const onbluraplicant_nameinput = () =>{
    // //console.log(value.target.value)
    if(aplicant_name == ""){
      setaplicant_nameerror(true)
    }else{
      setaplicant_nameerror(false)
    }
  }

  const onblurmobile_noinput = () =>{
    // //console.log(value.target.value)
    if(mobile_no == ""){
      setmobile_noerror(true)
     }else{
      setmobile_noerror(false)
     }
  }
  const onblura_mobile_noinput = () =>{
    // //console.log(value.target.value)
    if(a_mobile_no == ""){
      seta_mobile_noerror(true)
     }else{
      seta_mobile_noerror(false)
     }
  }
 
  const onblurverification = () =>{
    // //console.log(value.target.value)
    //  alert(verificationtype)
    if(verificationtype == ""){
      setverificationtype_error(true)
     }else{
      setverificationtype_error(false)
     }
     
  }

  const onblurcompany = () =>{
    // //console.log(value.target.value)
    //  alert(verificationtype)
    if(company_name == ""){
      setcompany_nameerror(true)
     }else{
      setcompany_nameerror(false)
     }
     
  }
  const onblurapplicantpaddress = (input) =>{
    //console.log(applicant_permnent_address[input])
    let data = {...applicant_p__error};
    if(applicant_permnent_address[input] == ""){
     
      data[input] = true
      //console.log(data)
      setapplicant_p_address_error(data)
    }else{
     
      data[input] = false
      setapplicant_p_address_error(data)
    }
  }

  const onblurapplicantcaddress = (input) =>{
    let data = {...applicant_c__error};
    if(applicant_current_address[input] == ""){
     
      data[input] = true
      setapplicant_c_address_error(data)
    }else{
     
      data[input] = false
      setapplicant_c_address_error(data)
    }
  }

  const onfocusapplicantpaddress = (input) =>{
    // //console.log(applicant_permnent_address[input])
    let data = {...applicant_p__error};
 
     
      data[input] = false
      //console.log(data)
      setapplicant_p_address_error(data)
  
  }

  const onfocusapplicantcaddress = (input) =>{
    let data = {...applicant_c__error};
   
     
      data[input] = false
      setapplicant_c_address_error(data)
  
  }



const onblur_producttype = () =>{
  if(product_type == ""){
    setproduct_type_error(true)
   }else{
    setproduct_type_error(false)
   }
}
  const submit_aplicant = () =>{
    const p_address = Object.values(applicant_p__error).some(value => value === true);
    const c_address = Object.values(applicant_c__error).some(value => value === true);

  //  alert('hi')  
     if(rac_name != "" && branchlocation != "" && branch_name != "" && reffernce_no != "" && aplicant_name  != ""  && verificationtype !="" && product_type !="" && sender_name !="" &&  c_address != true && case_id !="" && company_name != "" ){

      //console.log(rows, applicant_permnent_address)
      
      // //console.log(token)
      let headers = new Headers();

      headers.append('Content-Type', 'application/json');
      headers.append('token', token);
      const requestOptions1 = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({'client_name':rac_name, 'branch_location':branchlocation, 'branch_name':branch_name, 'file_reffrence_no':reffernce_no, 'aplicant_name':aplicant_name, 'verification_type':verificationtype, 'applicant_number':{'mobile_no':mobile_no, 'a_mobile_number':a_mobile_no}, 'sender_name':sender_name, 'Status':"0", 'Closed':false, 'AgentName':"", 'co_applicant_details':rows, 'product_type':product_type, 'applicant_permnent_address':applicant_permnent_address, 'applicant_current_address':applicant_current_address, "case_id":case_id, "company_name":company_name,'document_type':document_type}),
      };
      fetch(`${URL}/insert_application`, requestOptions1)
        .then((response) => response.json())
        .then((data) => {
          //console.log(data)
            if(data && data.result == true){
              handleClickOpen()
            }else{
              // alert(data.result)
              handleClickOpen1()
            }
        })


     }
    
   
   
    
   
    

  }

  const handleUpload = () => {
    
    setLoading(true);
    const allowedTypes = ["Residence", "Document", "Employee", "Property", "Asset", "Business"];

    const validEntries = fileData.filter(item => allowedTypes.includes(item.VERIFICATIONTYPE));
    const invalidEntries = fileData.filter(item => !allowedTypes.includes(item.VERIFICATIONTYPE));
console.log('invalidEntries',fileData)
    if (invalidEntries.length > 0) {
      setLoading(false);
      setInvalidEntries(invalidEntries);
      setShowInvalidEntriesModal(true);  // Show the modal with invalid entries
      return;
    }
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('token', token);
    const requestOptions1 = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({'data':fileData}),
    };
    fetch(`${URL}/insert_bulk_applications`, requestOptions1)
      .then((response) => response.json())
      .then((data) => {
        //console.log(data)
          if(data && data.result == true){
            setLoading(false);
          }else{
            setLoading(false);
            setbulkdialog(false);
            setMessage({'successfulInsertions':data.successfulInsertions.length, 'failedInsertions':data.failedInsertions.length, 'failedInsertions_details': data.failedInsertions})
             setsuccess(true)
            //  alert('Bulk Upload Action  Completed !!!')
            // handleClickOpen1()
          }
      })


      // Handle upload logic here, using fileData state if needed
      //console.log("File data:", fileData);
      // handleClose(); // Close the dialog after upload
    };

    // const handleKeyDown = (event) => {
    //   const isNumericKey = /^[0-9]$/;
    //   const isBackspace = event.key === 'Backspace';
  
    //   if (!isNumericKey.test(event.key) && !isBackspace) {
    //     event.preventDefault();
    //   }
    // };



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div style={{ boxShadow: '0 0 4px rgba(0, 0, 0, 0.5)', marginTop:10, borderRadius:10, marginBottom:10 }}>
<Button
      sx={{ backgroundColor: '#8acfbe', width: 150, right: '15%', position: 'absolute', top: '5%',zIndex: 9999 }}
      className="actionb"
      onClick={() => setbulkdialog(true)}
      // disabled='false'
    >
      Bulk Upload
    </Button></div>
      <MDBox py={3} style={{padding:30}}> 
      <Dialog open={showInvalidEntriesModal} onClose={handelerrorclose}>
      <DialogTitle>Invalid Entries</DialogTitle>
      <DialogContent>
        {invalidEntries.map((entry, index) => (
          <div key={index}>
            <p><strong>Entry {index + 1}:</strong></p>
            <p>VERIFICATIONTYPE: {entry.VERIFICATIONTYPE}</p>
            {/* Display other details as needed */}
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handelerrorclose}>Close</Button>
      </DialogActions>
    </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Alert"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Applicant Details have been successfully submitted
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{backgroundColor:'blue', color:'white'}} >Ok</Button>
          {/* <Button onClick={handleClose} autoFocus>
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>
      <Dialog
        open={open1}
        onClose={handleClose1}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Alert"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{color:'red'}}>
            Case Id can't be duplicated!!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1} style={{backgroundColor:'blue', color:'white'}} >Ok</Button>
          {/* <Button onClick={handleClose} autoFocus>
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>
      <>
      <Dialog
        open={bulkdialog}
        onClose={bhandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEscapeKeyDown // Disable closing the dialog by pressing the escape key
        BackdropProps={{ onClick: null }} 
        // sx={{ Width: 600, maxWidth:800 }}
      >
        <DialogTitle id="alert-dialog-title" style={{color:'Green'}}>
          Bulk Upload
        </DialogTitle>
        <DialogContent>
          <span>Download Sample Formate File</span>
          
        <Button  onClick={handleDownload} style={{ backgroundColor: '#84b3a9', color: 'white', marginLeft:10 }}>Download</Button>
  <DialogContentText id="alert-dialog-description">
    Select Xls File
  </DialogContentText>
  <div style={{ boxShadow: '0 0 4px rgba(0, 0, 0, 0.5)', padding:10, borderRadius:5}}>
  <input
    type="file"
    accept=".xls, .xlsx"
    onChange={handleFileInputChange} // Define a function to handle file input change
  />
  </div>

</DialogContent>
<DialogActions>
  <Button onClick={bhandleClose} style={{ backgroundColor: '#f08269', color: 'white' }}>
    Cancel
  </Button>
  {/* <Button onClick={handleUpload} style={{ backgroundColor: 'green', color: 'white' }}>
    Upload
  </Button> */}
  {loading ? <Button  style={{ backgroundColor: 'green', color: 'white' }}>
  <CircularProgress size={24} color="inherit" />
  </Button>  :  <Button onClick={handleUpload} style={{ backgroundColor: 'green', color: 'white' }}>
    Upload
  </Button>}
</DialogActions>

      </Dialog>
      {/* {bulkdialog && <Backdrop open={bulkdialog} onClick={handleClose} sx={{ zIndex: 1200 }} />} */}
    </>
    {success && (
      <div className="centered-alert">
        <div>
        <Grid container direction="row" >
      <Grid item xs={9} container direction="column">
      <h3> Bulk Upload Report!!</h3>
      </Grid>
      <Grid item xs={3} container direction="column">
        <Button className="reportb" onClick={()=>{setsuccess(false)}}>Close</Button>
      </Grid>
      </Grid>

        </div>
       
        <p style={{color:'#4a6339', fontSize:24}}> * Successful Insertions : {message.successfulInsertions}</p>
        <p style={{color:'#854945', fontSize:24}}> * Failed Insertions : {message.failedInsertions}</p>
        <h4>Failed Insertions Details:</h4>
        {message.failedInsertions_details && message.failedInsertions_details.length != 0 && message.failedInsertions_details.map((item)=>(
          <div key={item} style={{flexDirection:'row'}}>
        <p> Row Number : {item['index']}</p>
        <p style={{color:'#b85851'}}> Error :{item['error']}</p>
        </div>
        ))}
       
       
      </div>
    )}
      <Grid container spacing={2}>
      <Grid container direction="row" className="mainrowclass">
      <Grid item xs={3} container direction="column" className="rowclass">
 
      <Autocomplete
       className="form"
          id="COMPANY NAME"
          options={Companies}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => <TextField  InputLabelProps={{ classes: {root: classes.customLabel},}} {...params} label="Company Name" variant="outlined"  required onBlur={onblurcompany}  style={{paddingTop:0,borderColor:company_nameerror?'red':'', borderStyle:company_nameerror?'groove':'', borderWidth:3, borderRadius:10}}  onFocus={()=> {setcompany_nameerror(false)}}/> } onChange={(event, value) => {
            handelecompany(value ? value.label : '')
          }}
         
        />
  {/* {branch_nameerror && <span className="spanc">Branch Name can't be empty!</span>} */}
  </Grid>
  <Grid item xs={2} container direction="column" className="rowclass">
  {/* <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} id="outlined-basic" label="CLIENT NAME" variant="outlined" className="form" onChange={(value)=>handelracinput(value)} required onBlur={onblurracinput}   style={{borderColor:rac_nameerror?'red':'', borderStyle:rac_nameerror?'groove':'', borderWidth:3}}  onFocus={()=> {setrac_nameerror(false)}}/> */}

  <Autocomplete
       className="form"
          id="LOCATION"
          options={Branch_locations}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} {...params} label="Location" variant="outlined"   required onBlur={onblurbranchlocation}  style={{paddingTop:0,borderColor:branchlocationerror?'red':'', borderStyle:branchlocationerror?'groove':'', borderWidth:3, borderRadius:10}}  onFocus={()=> {setbranchlocationerror(false)}}/> } onChange={(event, value) => {
            handlebranch_locationinput(value ? value.label : '')
          }}
         
        />
  
  {/* {rac_nameerror && <span className="spanc">Client Name can't be empty!</span>} */}
</Grid>
      <Grid item xs={2} container direction="column" className="rowclass">
  {/* <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} id="outlined-basic" label="CLIENT NAME" variant="outlined" className="form" onChange={(value)=>handelracinput(value)} required onBlur={onblurracinput}   style={{borderColor:rac_nameerror?'red':'', borderStyle:rac_nameerror?'groove':'', borderWidth:3}}  onFocus={()=> {setrac_nameerror(false)}}/> */}

  <Autocomplete
       className="form"
          id="CLIENT NAME"
          options={client_deatils}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} {...params} label="Client Name" variant="outlined"   required onBlur={onblurracinput}  style={{paddingTop:0,borderColor:rac_nameerror?'red':'', borderStyle:rac_nameerror?'groove':'', borderWidth:3, borderRadius:10}}  onFocus={()=> {setrac_nameerror(false)}}/> } onChange={(event, value) => {
            handelracinput(value ? value.label : '')
          }}
         
        />
  
  {/* {rac_nameerror && <span className="spanc">Client Name can't be empty!</span>} */}
</Grid>

  <Grid item xs={2} container direction="column" className="rowclass">
  {/* <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} id="outlined-basic" label="BRANCH NAME" variant="outlined" className="form" onChange={(value)=>handelbranch_nameinput(value)} required onBlur={onblurbranch_nameinput} style={{borderColor:branch_nameerror?'red':'', borderStyle:branch_nameerror?'groove':'', borderWidth:3}}  onFocus={()=> {setbranch_nameerror(false)}}/> */}

  <Autocomplete
       className="form"
          id="BRANCH NAME"
          options={branch_deatils}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} {...params} label="Branch Name" variant="outlined"   required onBlur={onblurbranch_nameinput}  style={{paddingTop:0,borderColor:branch_nameerror?'red':'', borderStyle:branch_nameerror?'groove':'', borderWidth:3, borderRadius:10}}  onFocus={()=> {setbranch_nameerror(false)}}/> } onChange={(event, value) => {
            handelbranch_nameinput(value ? value.label : '')
          }}
         
        />
  {/* {branch_nameerror && <span className="spanc">Branch Name can't be empty!</span>} */}
  </Grid>
  <Grid item xs={3} container direction="column" className="rowclass">
  <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} id="outlined-basic" label="Case Id" variant="outlined" className="form" onChange={(value)=>handlecaseidinput(value)} required onBlur={onblurcaseidinput} style={{borderColor:case_id_error?'red':'', borderStyle:case_id_error?'groove':'', borderWidth:3}}  onFocus={()=> {setcase_id_error(false)}}/>
  {/* {branch_nameerror && <span className="spanc">Branch Name can't be empty!</span>} */}
  </Grid>
  <Grid item xs={3} container direction="column" className="rowclass">
  <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} id="outlined-basic" label="Sender Name W/ Mail Id" variant="outlined" className="form" onChange={(value)=>handelsender_nameinput(value)} required onBlur={onblursender_nameinput} style={{borderColor:sender_nameerror?'red':'', borderStyle:sender_nameerror?'groove':'', borderWidth:3}}  onFocus={()=> {setsender_nameerror(false)}}/>
  {/* {sender_nameerror && <span className="spanc">Sender Name Or Email Id can't be empty!</span>} */}
  </Grid>
  
  <Grid item xs={3} container direction="column" className="rowclass">
  {/* <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} id="outlined-basic" label="PRODUCT TYPE" variant="outlined" className="form" onChange={(value)=>product_type_input(value)} required onBlur={onblur_producttype} style={{borderColor:product_type_error?'red':'', borderStyle:product_type_error?'groove':'', borderWidth:3}}  onFocus={()=> {setproduct_type(false)}}/> */}

  <Autocomplete
       className="form"
          id="PRODUCT TYPE"
          options={product_deatils}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} {...params} label="Product Type" variant="outlined"   required onBlur={onblur_producttype}  style={{paddingTop:0,borderColor:product_type_error?'red':'', borderStyle:product_type_error?'groove':'', borderWidth:3, borderRadius:10}}  onFocus={()=> {setproduct_type_error(false)}}/> } onChange={(event, value) => {
            product_type_input(value ? value.label : '')
          }}
         
        />

  {/* {product_type_error && <span className="spanc">Product type can't be empty!</span>} */}
  </Grid>
  <Grid item xs={2} container direction="column" className="rowclass">
  <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} id="outlined-basic" label="File No/Reference No" variant="outlined" className="form" onChange={(value)=>handelreffernce_noinput(value)} required onBlur={onblurreffernce_noinput} style={{borderColor:reffernce_noerror?'red':'', borderStyle:reffernce_noerror?'groove':'', borderWidth:3}}  onFocus={()=> {setreffernce_noerror(false)}}/>
  {/* {reffernce_noerror && <span className="spanc">FILE No/CASE ID/REFERENCE No can't be empty!</span>} */}
  </Grid>
  <Grid item xs={2} container direction="column" className="rowclass">
        <Autocomplete
       className="form"
          id="VERIFICATION TYPE"
          options={options}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} {...params} label="Verification Type" variant="outlined"   required onBlur={onblurverification}  style={{paddingTop:0,borderColor:verificationtype_error?'red':'', borderStyle:verificationtype_error?'groove':'', borderWidth:3, borderRadius:10}}  onFocus={()=> {setverificationtype_error(false)}}/> } onChange={(event, value) => {
            handelverification(value ? value.label : '')
          }}
         
        />
        {/* {verificationtype_error && <span className="spanc">Select verification type</span>} */}
      </Grid>
    
  <Grid item xs={2} container direction="column" className="rowclass">
  <Autocomplete
 className="form"
    id="DOCUMENT TYPE"
    options={Document_types}
    getOptionLabel={(option) => option.label}
    renderInput={(params) => <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} {...params} label="Document Type" variant="outlined"   required  style={{paddingTop:0,borderColor:verificationtype_error?'red':'', borderStyle:verificationtype_error?'groove':'', borderWidth:3, borderRadius:10}}  /> } onChange={(event, value) => {
      handeldocument_type(value ? value.label : '')
    }}
   disabled = {verificationtype == "Document" ? false : true}
  />
  {/* {verificationtype_error && <span className="spanc">Select verification type</span>} */}
</Grid>
    
    
</Grid>

      <Grid container direction="row" className="mainrowclass">
  <Grid item xs={6} container direction="column" className="rowclass">
  <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} id="outlined-basic" label="Applicant Name" variant="outlined" className="form" onChange={(value)=>handelaplicant_nameinput(value)} required onBlur={onbluraplicant_nameinput} style={{paddingTop:0,borderColor:aplicant_nameerror?'red':'', borderStyle:aplicant_nameerror?'groove':'', borderWidth:3, borderRadius:10}}  onFocus={()=> {setaplicant_nameerror(false)}}/>
  {/* {aplicant_nameerror && <span className="spanc">Aplicant Name can't be empty!</span>} */}
  </Grid>
  <Grid item xs={3} container direction="column" className="rowclass">
  <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} id="outlined-basic" label="Mobile No" variant="outlined" className="form" 
  // type="numaric"
  // inputProps={{
  //         maxLength: 10,
  //         onKeyDown: handleKeyDown
  //       }}
        type="numaric"  // Use type text and validate input
      inputProps={{
        maxLength: 10,
        // onKeyDown: handleKeyDown,
        onPaste: handlePaste,
        pattern: "\\d*",
      }}
         onChange={(value)=>handelmobile_noinput(value)} required onBlur={onblurmobile_noinput} style={{paddingTop:0,borderColor:mobile_noerror?'red':'', borderStyle:mobile_noerror?'groove':'', borderWidth:3, borderRadius:10}}  onFocus={()=> {setmobile_noerror(false)}} />
  {/* {mobile_noerror && <span className="spanc">Mobile can't be empty!</span>} */}
  </Grid>
  <Grid item xs={3} container direction="column" className="rowclass">
  <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} id="outlined-basic" label="Alternative Mobile Number No" variant="outlined"  className="form" type="numaric"
 inputProps={{
  maxLength: 10,
  // onKeyDown: handleKeyDown,
  onPaste: handlePaste,
  pattern: "\\d*",
}}
         onChange={(value)=>handela_mobile_noinput(value)} required onBlur={onblura_mobile_noinput} style={{paddingTop:0,borderColor:a_mobile_noerror?'red':'', borderStyle:a_mobile_noerror?'groove':'', borderWidth:3, borderRadius:10}}  onFocus={()=> {seta_mobile_noerror(false)}}/>
  {/* {a_mobile_noerror && <span className="spanc">Mobile No can't be empty!</span>} */}
  </Grid>
</Grid>

<Grid container direction="row" className="mainrowclass">
  <Grid item xs={6} container direction="column" className="rowclass">
  <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} id="outlined-basic" label="Applicant Permanent Address" variant="outlined" className="form" onChange={(value)=>handleapplicantpadress(value.target.value, 'address')} required onBlur={()=> onblurapplicantpaddress('address')} style={{paddingTop:0,borderColor:applicant_p__error['address']?'red':'', borderStyle:applicant_p__error['address']?'groove':'', borderWidth:3, borderRadius:10}}  onFocus={()=> {onfocusapplicantpaddress('address')}}/>
  {/* {applicant_p__error['address'] && <span className="spanc">Address Name can't be empty!</span>} */}
  </Grid>
  <Grid item xs={3} container direction="column" className="rowclass">
  <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} id="outlined-basic" label="Pincode" variant="outlined" className="form" type="numaric"
  inputProps={{
    maxLength: 6,
    // onKeyDown: handleKeyDown,
    onPaste: handlePaste,
    pattern: "\\d*",
  }}
         onChange={(value)=>handleapplicantpadress(value.target.value, 'pincode')} required onBlur={()=> onblurapplicantpaddress('pincode')} style={{paddingTop:0,borderColor:applicant_p__error['pincode']?'red':'', borderStyle:applicant_p__error['pincode']?'groove':'', borderWidth:3, borderRadius:10}}  onFocus={()=> {onfocusapplicantpaddress('pincode')}}/>
  {/* {applicant_p__error['pincode'] && <span className="spanc">Pincode can't be empty!</span>} */}
  </Grid>
  <Grid item xs={3} container direction="column" className="rowclass">
  <Autocomplete
       className="form"
          id="Area"
          options={states}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} {...params} label="Select Area" variant="outlined" required onBlur={()=> onblurapplicantpaddress('area')} style={{paddingTop:0,borderColor:applicant_p__error['area']?'red':'', borderStyle:applicant_p__error['area']?'groove':'', borderWidth:3, borderRadius:10}}  onFocus={()=> {onfocusapplicantpaddress('area')}}/> } onChange={(event, value) => {
            handleapplicantpadress(value ? value.label : '', 'area')
          }}
        />
        
  {/* {applicant_p__error['area'] && <span className="spanc">Area Name can't be empty!</span>} */}
  </Grid>
</Grid>
<Grid container direction="row" className="submainrowclass">
  <Grid item xs={6} container direction="column" className="rowclass">
  <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} id="outlined-basic" label="Applicant Current Address" variant="outlined" className="form" onChange={(value)=>handleapplicantcadress(value.target.value, 'address')} required onBlur={()=> onblurapplicantcaddress('address')} style={{paddingTop:0,borderColor:applicant_c__error['address']?'red':'', borderStyle:applicant_c__error['address']?'groove':'', borderWidth:3, borderRadius:10}}  onFocus={()=> {onfocusapplicantcaddress('address')}}/>
  {/* {applicant_c__error['address'] && <span className="spanc">Address Name can't be empty!</span>} */}
  </Grid>
  <Grid item xs={3} container direction="column" className="rowclass">
  <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} id="outlined-basic" label="Pincode" variant="outlined" className="form" type="numaric"
  inputProps={{
    maxLength: 6,
    // onKeyDown: handleKeyDown,
    onPaste: handlePaste,
    pattern: "\\d*",
  }}
         onChange={(value)=>handleapplicantcadress(value.target.value, 'pincode')} required onBlur={()=> onblurapplicantcaddress('pincode')} style={{paddingTop:0,borderColor:applicant_c__error['pincode']?'red':'', borderStyle:applicant_c__error['pincode']?'groove':'', borderWidth:3, borderRadius:10}}  onFocus={()=> {onfocusapplicantcaddress('pincode')}}/>
  {/* {applicant_c__error['pincode'] && <span className="spanc">Pincode can't be empty!</span>} */}
  </Grid>
  <Grid item xs={3} container direction="column" className="rowclass">
  <Autocomplete
       className="form"
          id="area"
          options={states}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} {...params} label="Select Area" variant="outlined"   required onBlur={()=> onblurapplicantcaddress('area')} style={{paddingTop:0,borderColor:applicant_c__error['area']?'red':'', borderStyle:applicant_c__error['area']?'groove':'', borderWidth:3, borderRadius:10}}  onFocus={()=> {onfocusapplicantcaddress('area')}}/> } onChange={(event, value) => {
            handleapplicantcadress(value ? value.label : '', 'area')
          }}
        />
        {/* {verificationtype_error && <span className="spanc">Select verification type</span>} */}
  {/* {applicant_c__error['area'] && <span className="spanc">Area Name can't be empty!</span>} */}
  </Grid>
 </Grid>
 
    <Grid item xs={10} container direction="row" style={{paddingLeft:0, paddingTop:0}}>
    {rows.map((row,index)=>(
      <Grid container spacing={2} className="addbox">
    <Grid item xs={3} container direction="column" className="fieldclass">
  <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} id="outlined-basic" label="Co-Applicant Name" variant="outlined" className="form" onChange={(value)=>handleInputChange(row.id, 'co_applicant_name',value.target.value, index)} required onBlur={() => handleBlur(index, 'co_applicant_name', row.co_applicant_name)} style={{paddingTop:0,borderColor:errors[index]?.co_applicant_name?'red':'', borderStyle:errors[index]?.co_applicant_name?'groove':'', borderWidth:3, borderRadius:10}}  onFocus={()=> {handlefocus(index, 'co_applicant_name')}}/>
  {/* {errors[index]?.co_applicant_name && <span className="spanc">Co-APPLICANT Name can't be empty!</span>} */}
  </Grid>
  <Grid item xs={3} container direction="column" className="fieldclass">
  <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} id="outlined-basic" label="Mobile Number" variant="outlined" className="form" onChange={(value)=>handleInputChange(row.id, 'mobile_no',value.target.value, index)} required onBlur={() => handleBlur(index, 'mobile_no', row.mobile_no)} style={{paddingTop:0,borderColor:errors[index]?.mobile_no?'red':'', borderStyle:errors[index]?.mobile_no?'groove':'', borderWidth:3, borderRadius:10}}  onFocus={()=> {handlefocus(index, 'mobile_no')}} type="numaric"inputProps={{
          maxLength: 10,
          onKeyDown: handleKeyDown
        }}/>
  {/* {errors[index]?.mobile_no && <span className="spanc">Mobile Number can't be empty!</span>} */}
  </Grid>
  <Grid item xs={6} container direction="column" className="fieldclass">
  <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} id="outlined-basic" label="Address" variant="outlined" className="form" onChange={(value)=>handleInputChange(row.id, 'address',value.target.value, index)} required onBlur={() => handleBlur(index, 'address', row.address)} style={{paddingTop:0,borderColor:errors[index]?.address?'red':'', borderStyle:errors[index]?.address?'groove':'', borderWidth:3, borderRadius:10}}  onFocus={()=> {handlefocus(index, 'address')}} 
        rows={4}/>
  {/* {errors[index]?.address && <span className="spanc">Address can't be empty!</span>} */}
  </Grid>
  <Grid item xs={3} container direction="column" className="fieldclass">
  <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} id="outlined-basic" label="Pincode" variant="outlined" className="form" type="numaric"inputProps={{
          maxLength: 6,
          onKeyDown: handleKeyDown
        }} onChange={(value)=>handleInputChange(row.id, 'pincode',value.target.value, index)} required onBlur={() => handleBlur(index, 'pincode', row.pincode)} style={{paddingTop:0,borderColor:errors[index]?.pincode?'red':'', borderStyle:errors[index]?.pincode?'groove':'', borderWidth:3, borderRadius:10}}  onFocus={()=> {handlefocus(index, 'pincode')}}/>
  {/* {errors[index]?.pincode && <span className="spanc">Pincode Name can't be empty!</span>} */}
  </Grid>
  <Grid item xs={3} container direction="column" className="fieldclass">
  <Autocomplete
       className="form"
          id="area"
          options={states}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} {...params} label="Select Area" variant="outlined"   required onBlur={() => handleBlur(index, 'area', row.area)} style={{paddingTop:0,borderColor:errors[index]?.area?'red':'', borderStyle:errors[index]?.area?'groove':'', borderWidth:3, borderRadius:10}}  onFocus={()=> {handlefocus(index, 'area')}}/> } onChange={(event, value) => {
            handleInputChange(row.id, 'area',value ? value.label : '', index)
          }}
        />
        {/* {errors[index]?.area && <span className="spanc">Select verification type</span>} */}
  {/* {aplicant_nameerror && <span className="spanc">Area Name can't be empty!</span>} */}
  </Grid>
  {row.id != 1 && (
  <Grid item xs={2} container direction="column">
  <Button variant="contained" className="csbutton" onClick={()=> removeRow(row.id)}> <Icon>
   <CancelIcon fontSize="large" />
 </Icon></Button>
  </Grid>
  )}
  </Grid>
   ))}
    </Grid>
    <Grid item xs={2} container direction="row">
    <Grid item xs={3} container direction="column">
  <Button variant="contained" className="asbutton" title="Add Co Applicant" onClick={()=> addRow()} style={{marginLeft:30}}> <Icon>
   <AddCircleIcon fontSize="large" />
 </Icon></Button>
  </Grid>
    </Grid>
  
 
 

  {/* <Grid item xs={6} container direction="column">
  <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} id="outlined-basic" label="MOBILE NO." type="tel"   variant="outlined" className="form" onChange={(value)=>handelmobile_noinput(value)} required onBlur={onblurmobile_noinput}   inputProps={{ maxLength: 10 }} />
  {mobile_noerror && <span className="spanc">Mobile No can't be empty!</span>}
  </Grid> */}
 
  {/* <Grid item xs={6} container direction="column">
  <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} id="outlined-basic" label="ADDRESS LINE 1" variant="outlined" className="form" required  onChange={(value)=>handeladress1input(value)} onBlur={onbluradress1input}/>
  {adress1error && <span className="spanc">ADDRESS can't be empty!</span>}
  </Grid>
  <Grid item xs={6} container direction="column">
  <TextField  InputLabelProps={{ classes: {root: classes.customLabel,},}} id="outlined-basic" label="ADDRESS LINE 2" variant="outlined" className="form"   onChange={(value)=>handeladress2input(value)}/>
  </Grid> */}
  <Grid item xs={12} style={{textAlign:'center'}}>
  <Button variant="contained" className="sbutton" onClick={() => {submit_aplicant()}}>Submit</Button>
  </Grid>
</Grid>

      
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Dashboard;
