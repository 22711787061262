/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { createContext, useContext, useReducer, useMemo, useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,Input,CircularProgress ,Backdrop   } from '@mui/material';
import Grid from "@mui/material/Grid";
import '../app.css'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import TextField from '@mui/material/TextField';
import { Typography } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/lab/Autocomplete';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { URL } from "../constants";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import readXlsxFile from 'read-excel-file';
import * as XLSX from 'xlsx';
import { TailSpin } from 'react-loader-spinner';
const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: '#a9ccbe', // Change the background color here
    minWidth: 300, // Change the maximum width of the tooltip here
  },
}));

// Dashboard components


function Applications() {
  const classes = useStyles();
  const { sales, tasks } = reportsLineChartData;
 const [data, setdata] = useState([]);
 const [dataloaded, setdataloaded] = useState(false)
const[agent, setagent] = useState("")
const[agentid, setagentid] = useState("")
  const [token, settoken] = useState("");
  const [userLocation, setUserlocation] = useState("");
  const[dilog, setdilog] = useState(false);
  const[bulkdialog, setbulkdialog] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState('asc'); // Initial sort order is ascending
  const [sortedColumn, setSortedColumn] = useState(null);
  const [selectedCases, setSelectedCases] = useState([]);
  const [fedetails, setfedetails] = useState([])
  const [xldata, setxldata] = useState()
  const [searchid, setsearchid] = useState()
  const [searchname, setsearchname] = useState()
  const[search_start, setsearch_start] = useState(false)
  const[search_start1, setsearch_start1] = useState(false)
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month index
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const handleCheckboxChange = (event, caseId) => {
    // alert(caseId)
    if (event.target.checked) {
      // Case is checked, add to array
      setSelectedCases([...selectedCases, caseId]);
    } else {
      // Case is unchecked, remove from array
      setSelectedCases(selectedCases.filter(id => id !== caseId));
    }
  };
 const handleFileInputChange = (event) => {
  const file = event.target.files[0];
  
  // Check the file extension to determine the file type
  const fileType = file.name.split('.').pop().toLowerCase();

  const reader = new FileReader();

  reader.onload = (e) => {
    const fileContents = e.target.result;
    
    if (fileType === 'xls' || fileType === 'xlsx') {
      // Handle both .xls and .xlsx files
      readXlsxFile(file).then((rows) => {
        // rows is an array of arrays representing each row of the Excel file
        //console.log(rows);
        // Assuming setFileData expects an array of arrays
        setFileData(rows);
      }).catch(error => {
        //console.error('Error reading Excel file:', error);
      });
    } else {
      //console.error('Unsupported file type');
    }
  };

  // Read the file as array buffer
  reader.readAsArrayBuffer(file);
};
  const options = [
    { value: 9110248064, label: 'fe1' },
    { value: 9110248065, label: 'fe2' },
    { value: 9110248068, label: 'fe3' },
    { value: 9120248064, label: 'fe4' },
    { value: 6110248064, label: 'fe5' },
  ];
  const [tooltipOpen, setTooltipOpen] = useState();

  const handleCardClick = (index) => {
    const newTooltipOpen = [...tooltipOpen];
    newTooltipOpen[index] = !newTooltipOpen[index];
    setTooltipOpen(newTooltipOpen);
  };
  const handleCloseTooltip = (index) => {
    const newTooltipOpen = [...tooltipOpen];
    newTooltipOpen[index] = false;
    setTooltipOpen(newTooltipOpen);
  };
  const handleClickOpen = () => {
    setdilog(true);
  };

  const handleClose = () => {
    
    setdilog(false);
    
    // location.reload()
  };
 
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(xldata);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'report.xlsx');
  };
  
  const bhandleClose = (event, reason) => {
    if (reason && reason === "backdropClick") 
        return;
    setLoading(false)
    setbulkdialog(false);
    // location.reload()
  };
  const handelverification = (value,id) =>{
    //console.log(id)
    setagent(value)
    setagentid(id)
  }
  useEffect(() => {
    const token = localStorage.getItem("token");
 
     settoken(token)
      }, []);
      useEffect(() => {
        const token = localStorage.getItem("token");
        const key = localStorage.getItem("key");
    
        if (token) {
          let headers = new Headers();
          headers.append('Content-Type', 'application/json');
          headers.append('token', token);
    
          const requestOptions1 = {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ 'key': key }),
          };
    
          fetch(`${URL}/key_user_details`, requestOptions1)
            .then((response) => response.json())
            .then((data) => {
              //console.log('data', data);
              if (data && data.data.length !== 0) {
                const userLocation = data.data[0]['branch_location'];
                if (userLocation) {
                  setUserlocation(userLocation);
                  read_data(userLocation);
                } else {
                 
                }
              } else {
               
              }
            })
            .catch((error) => {
              //console.error('Error fetching user details:', error);
              
            });
        } else {
         
        }
      }, []);
      const searchstart = ()=>{
        setsearch_start(true)
        search_data()
      }
      const searchstart1 = ()=>{
        setsearch_start1(true)
       
        search_name_data()
      }
      const searchclose = ()=>{
        setsearch_start(false)
        setsearchid("")
        read_data(userLocation)
      }
        const searchclose1 = ()=>{
        setsearch_start1(false)
        setsearchname("")
        read_data(userLocation)
      }
      const read_data = (userLocation) =>{
        try{
          const token = localStorage.getItem("token");
          // //console.log('hi')
          let headers = new Headers();
  
        headers.append('Content-Type', 'application/json');
        headers.append('token', token);
        const requestOptions1 = {
          method: "POST",
          headers: headers,
          body: JSON.stringify({ 'location': userLocation }),
       
        };
        fetch(`${URL}/read_applications_locationwise`, requestOptions1)
          .then((response) => response.json())
          .then((data) => {
            setdata(data)
            setTooltipOpen(Array(data.length).fill(false))
            const mxldata = data.map(item => ({
              'Client Name': item.client_name,
              'Case Id': item.case_id,
              'REF No': item.file_refference_no,
              'Applicant Name': item.applicant_name,
              'Address': item['applicant_current_address']['address'],
              'Verification Type': item.verification_type,
              'Mobile No': item['applicant_number']['mobile_no'],
              'FE Id': item.fe_id,
              'FE Name': item.fe_name,
              'No.Of Attempts': item.reassign_count,
              'Initiation Date':  formatDate(item.i_date),
             
              
            }));
            setxldata(mxldata)
            setdataloaded(true)
                  //console.log('data',data)
          })
        }
         catch (error) {
          // Code to handle the error
          //console.error("An error occurred:", error);
        } finally {
          // Optional: Code that runs regardless of whether an error occurred or not
          //console.log("This code runs regardless of errors.");
        }
      
      }
      const search_data = () =>{
        try{
          const token = localStorage.getItem("token");
          // //console.log('hi')
          let headers = new Headers();
  
        headers.append('Content-Type', 'application/json');
        headers.append('token', token);
        const requestOptions1 = {
          method: "POST",
          headers: headers,
          body: JSON.stringify({ 'location': userLocation, case_id:searchid }),
       
        };
        fetch(`${URL}/search_assigned_applications`, requestOptions1)
          .then((response) => response.json())
          .then((data) => {
            setdata(data)
            setTooltipOpen(Array(data.length).fill(false))
            const mxldata = data.map(item => ({
              'Client Name': item.client_name,
              'Case Id': item.case_id,
              'REF No': item.file_refference_no,
              'Applicant Name': item.applicant_name,
              'Address': item['applicant_current_address']['address'],
              'Verification Type': item.verification_type,
              'Mobile No': item['applicant_number']['mobile_no'],
              'FE Id': item.fe_id,
              'FE Name': item.fe_name,
              'No.Of Attempts': item.reassign_count,
              'Initiation Date':  formatDate(item.i_date),
             
              
            }));
            setxldata(mxldata)
            setdataloaded(true)
                  //console.log('data',data)
          })
        }
         catch (error) {
          // Code to handle the error
          //console.error("An error occurred:", error);
        } finally {
          // Optional: Code that runs regardless of whether an error occurred or not
          //console.log("This code runs regardless of errors.");
        }
      
      }
      const search_name_data = () =>{
        try{
          const token = localStorage.getItem("token");
          // //console.log('hi')
          let headers = new Headers();
  
        headers.append('Content-Type', 'application/json');
        headers.append('token', token);
        const requestOptions1 = {
          method: "POST",
          headers: headers,
          body: JSON.stringify({ 'location': userLocation, name:searchname }),
       
        };
        fetch(`${URL}/search_assigned_applications_by_name`, requestOptions1)
          .then((response) => response.json())
          .then((data) => {
            setdata(data)
            setTooltipOpen(Array(data.length).fill(false))
            const mxldata = data.map(item => ({
              'Client Name': item.client_name,
              'Case Id': item.case_id,
              'REF No': item.file_refference_no,
              'Applicant Name': item.applicant_name,
              'Address': item['applicant_current_address']['address'],
              'Verification Type': item.verification_type,
              'Mobile No': item['applicant_number']['mobile_no'],
              'FE Id': item.fe_id,
              'FE Name': item.fe_name,
              'No.Of Attempts': item.reassign_count,
              'Initiation Date': formatDate(item.i_date),
             
              
            }));
            setxldata(mxldata)
            setdataloaded(true)
                  //console.log('data',data)
          })
        }
         catch (error) {
          // Code to handle the error
          //console.error("An error occurred:", error);
        } finally {
          // Optional: Code that runs regardless of whether an error occurred or not
          //console.log("This code runs regardless of errors.");
        }
      
      }
      const read_fe_data = () =>{
        try{
          const token = localStorage.getItem("token");
          // //console.log('hi')
          let headers = new Headers();
  
        headers.append('Content-Type', 'application/json');
        headers.append('token', token);
        const requestOptions1 = {
          method: "POST",
          headers: headers,
          body: JSON.stringify({'id':1}),
       
        };
        fetch(`${URL}/fe_details`, requestOptions1)
          .then((response) => response.json())
          .then((data) => {
            //console.log(data)
             setfedetails(data.data)
           
          })
        }
         catch (error) {
          // Code to handle the error
          //console.error("An error occurred:", error);
        } finally {
          // Optional: Code that runs regardless of whether an error occurred or not
          //console.log("This code runs regardless of errors.");
        }
      
      }

      useEffect(() => {
       
        read_fe_data();
          }, []);

 
 


  const update_status = (case_id) =>{
   
    if(agent != ""){

      //console.log(token)
      let headers = new Headers();

      headers.append('Content-Type', 'application/json');
      headers.append('token', token);
      const requestOptions1 = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({'CaseId':case_id, 'status':1, 'AgentName':agent,'AgentId':agentid}),
      };
      fetch(`${URL}/update_status`, requestOptions1)
        .then((response) => response.json())
        .then((data) => {
          read_data(userLocation);
          setSelectedCases([])
        })
    }else{
      setdilog(true)
    }
   }

   const bulk_update_status = () =>{
   //console.log(selectedCases)
    if(agent != ""){

      //console.log(token)
      let headers = new Headers();

      headers.append('Content-Type', 'application/json');
      headers.append('token', token);
      const requestOptions1 = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ 'status':1, 'AgentName':agent,'AgentId':agentid, 'CaseIds':selectedCases}),
      };
      fetch(`${URL}/bulk_update_status`, requestOptions1)
        .then((response) => response.json())
        .then((data) => {
          // location.reload()
           read_data(userLocation);
          // setSelectedCases([])
        })
    }else{
      setdilog(true)
    }
   }


   const updatereasign_status = (case_id) =>{
   
    if(agent != ""){

      //console.log(token)
      let headers = new Headers();

      headers.append('Content-Type', 'application/json');
      headers.append('token', token);
      const requestOptions1 = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({'CaseId':case_id, 'status':1, 'AgentName':agent, 'AgentId':agentid}),
      };
      fetch(`${URL}/update_reassign_status`, requestOptions1)
        .then((response) => response.json())
        .then((data) => {
          // location.reload()
           read_data(userLocation);
        })
    }else{
      setdilog(true)
    }
   }
 const handleUpload = () => {
  setLoading(true);
    // Handle upload logic here, using fileData state if needed
    //console.log("File data:", fileData);
    // handleClose(); // Close the dialog after upload
  };
  const handleBackdropClick = (event) => {
    // Prevent closing the dialog when clicking outside
    event.stopPropagation();
  };

  const getSortIndicator = (columnIndex) => {
    if (sortedColumn === columnIndex) {
      return sortOrder === 'asc' ? ' ▲' : ' ▼';
    }
    return null;
  };



//sorting logic
const handleSort = (id) => {
  //console.log(typeof id);
 // setActiveIndexop(index)
 setSortedColumn(id);

 if (sortOrder === 'asc') {
   setSortOrder('desc');
 } else {
   setSortOrder('asc');
 }
let oparray =  [...data]

  switch(id){
    case 0:{
      if(sortOrder == 'asc'){
        oparray.sort((a, b) => {
          //console.log(a)
          const itemA = a['case_id'] ? a['case_id'].toUpperCase() : '';
          const itemB = b['case_id'] ? b['case_id'].toUpperCase() : '';
        
          if (itemA === null && itemB === null) {
            return 0; // Both are null, consider them equal
          }
          
          if (itemA === null) {
            return -1; // itemA is null, move it before itemB
          }
        
          if (itemB === null) {
            return 1; // itemB is null, move it before itemA
          }
        
          if (itemA < itemB) {
            return -1;
          }
          if (itemA > itemB) {
            return 1;
          }
          return 0;
        });
        setdata(oparray)
      } else{
        
        oparray.sort((a, b) => {
          const itemA = a['case_id'] ? a['case_id'].toUpperCase() : null;
          const itemB = b['case_id'] ? b['case_id'].toUpperCase() : null;
        
          if (itemA === null && itemB === null) {
            return 0; // Both are null, consider them equal
          }
          
          if (itemA === null) {
            return 1; // itemA is null, move it after itemB
          }
        
          if (itemB === null) {
            return -1; // itemB is null, move it after itemA
          }
        
          if (itemA < itemB) {
            return 1;
          }
          if (itemA > itemB) {
            return -1;
          }
          return 0;
        });
        
        setdata(oparray)
      }

      break;
    }
    case 1:{
      if(sortOrder == 'asc'){
        oparray.sort((a, b) => {
          //console.log(a)
          const itemA = a['applicant_name'] ? a['applicant_name'].toUpperCase() : '';
          const itemB = b['applicant_name'] ? b['applicant_name'].toUpperCase() : '';
        
          if (itemA === null && itemB === null) {
            return 0; // Both are null, consider them equal
          }
          
          if (itemA === null) {
            return -1; // itemA is null, move it before itemB
          }
        
          if (itemB === null) {
            return 1; // itemB is null, move it before itemA
          }
        
          if (itemA < itemB) {
            return -1;
          }
          if (itemA > itemB) {
            return 1;
          }
          return 0;
        });
        setdata(oparray)
      } else{
        
        oparray.sort((a, b) => {
          const itemA = a['applicant_name'] ? a['applicant_name'].toUpperCase() : null;
          const itemB = b['applicant_name'] ? b['applicant_name'].toUpperCase() : null;
        
          if (itemA === null && itemB === null) {
            return 0; // Both are null, consider them equal
          }
          
          if (itemA === null) {
            return 1; // itemA is null, move it after itemB
          }
        
          if (itemB === null) {
            return -1; // itemB is null, move it after itemA
          }
        
          if (itemA < itemB) {
            return 1;
          }
          if (itemA > itemB) {
            return -1;
          }
          return 0;
        });
        
        setdata(oparray)
      }

      break;
    }
    case 2:{
     
      if(sortOrder == 'asc'){
        oparray.sort((a, b) => {
          //console.log(a)
          const itemA = a['applicant_current_address']['area'] ? a['applicant_current_address']['area'].toUpperCase() : '';
          const itemB = b['applicant_current_address']['area'] ? b['applicant_current_address']['area'].toUpperCase() : '';
        
          if (itemA === null && itemB === null) {
            return 0; // Both are null, consider them equal
          }
          
          if (itemA === null) {
            return -1; // itemA is null, move it before itemB
          }
        
          if (itemB === null) {
            return 1; // itemB is null, move it before itemA
          }
        
          if (itemA < itemB) {
            return -1;
          }
          if (itemA > itemB) {
            return 1;
          }
          return 0;
        });
        setdata(oparray)
      } else{
        
        oparray.sort((a, b) => {
          const itemA = a['applicant_current_address']['area'] ? a['applicant_current_address']['area'].toUpperCase() : null;
          const itemB = b['applicant_current_address']['area'] ? b['applicant_current_address']['area'].toUpperCase() : null;
        
          if (itemA === null && itemB === null) {
            return 0; // Both are null, consider them equal
          }
          
          if (itemA === null) {
            return 1; // itemA is null, move it after itemB
          }
        
          if (itemB === null) {
            return -1; // itemB is null, move it after itemA
          }
        
          if (itemA < itemB) {
            return 1;
          }
          if (itemA > itemB) {
            return -1;
          }
          return 0;
        });
        
        setdata(oparray)
      }
      break;
    }
    case 3:{
     
      if(sortOrder == 'asc'){
        oparray.sort((a, b) => {
          //console.log(a)
          const itemA = a['verification_type'] ? a['verification_type'].toUpperCase() : '';
          const itemB = b['verification_type'] ? b['verification_type'].toUpperCase() : '';
        
          if (itemA === null && itemB === null) {
            return 0; // Both are null, consider them equal
          }
          
          if (itemA === null) {
            return -1; // itemA is null, move it before itemB
          }
        
          if (itemB === null) {
            return 1; // itemB is null, move it before itemA
          }
        
          if (itemA < itemB) {
            return -1;
          }
          if (itemA > itemB) {
            return 1;
          }
          return 0;
        });
        setdata(oparray)
      } else{
        
        oparray.sort((a, b) => {
          const itemA = a['verification_type'] ? a['verification_type'].toUpperCase() : null;
          const itemB = b['verification_type'] ? b['verification_type'].toUpperCase() : null;
        
          if (itemA === null && itemB === null) {
            return 0; // Both are null, consider them equal
          }
          
          if (itemA === null) {
            return 1; // itemA is null, move it after itemB
          }
        
          if (itemB === null) {
            return -1; // itemB is null, move it after itemA
          }
        
          if (itemA < itemB) {
            return 1;
          }
          if (itemA > itemB) {
            return -1;
          }
          return 0;
        });
        
        setdata(oparray)
      }
      break;
    }
    case 4:{
     
      if(sortOrder == 'asc'){
               oparray.sort((a, b) => {
                //console.log(a[id])
                   const itemA = a['applicant_number']['mobile_no'] !== undefined ? a['applicant_number']['mobile_no'] : null;
                   const itemB = b['applicant_number']['mobile_no'] !== undefined ? b['applicant_number']['mobile_no'] : null;
               
                 if (itemA === null && itemB === null) {
                   return 0; // Both are null, consider them equal
                 }
                 
                 if (itemA === null) {
                   return -1; // itemA is null, move it before itemB
                 }
               
                 if (itemB === null) {
                   return 1; // itemB is null, move it before itemA
                 }
               
                 if (itemA < itemB) {
                   return -1;
                 }
                 if (itemA > itemB) {
                   return 1;
                 }
                 return 0;
               });
               //console.log(oparray)
               setdata(oparray)
             } else{
               // alert('d')
               oparray.sort((a, b) => {
                   const itemA = a['applicant_number']['mobile_no'] !== undefined ? a['applicant_number']['mobile_no'] : null;
                   const itemB = b['applicant_number']['mobile_no'] !== undefined ? b['applicant_number']['mobile_no'] : null;
               
                 if (itemA === null && itemB === null) {
                   return 0; // Both are null, consider them equal
                 }
                 
                 if (itemA === null) {
                   return 1; // itemA is null, move it after itemB
                 }
               
                 if (itemB === null) {
                   return -1; // itemB is null, move it after itemA
                 }
               
                 if (itemA < itemB) {
                   return 1;
                 }
                 if (itemA > itemB) {
                   return -1;
                 }
                 return 0;
               });
               
        
               //console.log(oparray)
               setdata(oparray)
             }
          
      break;
    }

    case 5:{
      if(sortOrder == 'asc'){
        oparray.sort((a, b) => {
          //console.log(a)
          const itemA = a['fe_id'] ? a['fe_id'].toUpperCase() : '';
          const itemB = b['fe_id'] ? b['fe_id'].toUpperCase() : '';
        
          if (itemA === null && itemB === null) {
            return 0; // Both are null, consider them equal
          }
          
          if (itemA === null) {
            return -1; // itemA is null, move it before itemB
          }
        
          if (itemB === null) {
            return 1; // itemB is null, move it before itemA
          }
        
          if (itemA < itemB) {
            return -1;
          }
          if (itemA > itemB) {
            return 1;
          }
          return 0;
        });
        setdata(oparray)
      } else{
        
        oparray.sort((a, b) => {
          const itemA = a['fe_id'] ? a['fe_id'].toUpperCase() : null;
          const itemB = b['fe_id'] ? b['fe_id'].toUpperCase() : null;
        
          if (itemA === null && itemB === null) {
            return 0; // Both are null, consider them equal
          }
          
          if (itemA === null) {
            return 1; // itemA is null, move it after itemB
          }
        
          if (itemB === null) {
            return -1; // itemB is null, move it after itemA
          }
        
          if (itemA < itemB) {
            return 1;
          }
          if (itemA > itemB) {
            return -1;
          }
          return 0;
        });
        
        setdata(oparray)
      }

      break;
    }

    case 6:{
     
      if(sortOrder == 'asc'){
               oparray.sort((a, b) => {
                //console.log(a[id])
                   const itemA = a['reassign_count'] !== undefined ? a['reassign_count'] : null;
                   const itemB = b['reassign_count'] !== undefined ? b['reassign_count'] : null;
               
                 if (itemA === null && itemB === null) {
                   return 0; // Both are null, consider them equal
                 }
                 
                 if (itemA === null) {
                   return -1; // itemA is null, move it before itemB
                 }
               
                 if (itemB === null) {
                   return 1; // itemB is null, move it before itemA
                 }
               
                 if (itemA < itemB) {
                   return -1;
                 }
                 if (itemA > itemB) {
                   return 1;
                 }
                 return 0;
               });
               //console.log(oparray)
               setdata(oparray)
             } else{
               // alert('d')
               oparray.sort((a, b) => {
                   const itemA = a['reassign_count'] !== undefined ? a['reassign_count'] : null;
                   const itemB = b['reassign_count'] !== undefined ? b['reassign_count'] : null;
               
                 if (itemA === null && itemB === null) {
                   return 0; // Both are null, consider them equal
                 }
                 
                 if (itemA === null) {
                   return 1; // itemA is null, move it after itemB
                 }
               
                 if (itemB === null) {
                   return -1; // itemB is null, move it after itemA
                 }
               
                 if (itemA < itemB) {
                   return 1;
                 }
                 if (itemA > itemB) {
                   return -1;
                 }
                 return 0;
               });
               
        
               //console.log(oparray)
               setdata(oparray)
             }
          
      break;
    }
    case 7:{
      if (sortOrder === 'asc') {
        oparray.sort((a, b) => {
          const dateA = new Date(a['i_date']);
          const dateB = new Date(b['i_date']);
      
          // Check for null or invalid dates
          if (isNaN(dateA.getTime()) && isNaN(dateB.getTime())) {
            return 0; // Both are invalid dates, consider them equal
          }
      
          if (isNaN(dateA.getTime())) {
            return -1; // dateA is invalid, move it before dateB
          }
      
          if (isNaN(dateB.getTime())) {
            return 1; // dateB is invalid, move it before dateA
          }
      
          return dateA - dateB; // Compare dates directly for ascending order
        });
        setdata(oparray);
      } else {
        oparray.sort((a, b) => {
          const dateA = new Date(a['i_date']);
          const dateB = new Date(b['i_date']);
      
          // Check for null or invalid dates
          if (isNaN(dateA.getTime()) && isNaN(dateB.getTime())) {
            return 0; // Both are invalid dates, consider them equal
          }
      
          if (isNaN(dateA.getTime())) {
            return -1; // dateA is invalid, move it before dateB
          }
      
          if (isNaN(dateB.getTime())) {
            return 1; // dateB is invalid, move it before dateA
          }
      
          return dateB - dateA; // Compare dates directly for descending order
        });
        setdata(oparray);
      }
      
    

      break;
    }
    case 8:{
      if(sortOrder == 'asc'){
        oparray.sort((a, b) => {
          //console.log(a)
          const itemA = a['client_name'] ? a['client_name'].toUpperCase() : '';
          const itemB = b['client_name'] ? b['client_name'].toUpperCase() : '';
        
          if (itemA === null && itemB === null) {
            return 0; // Both are null, consider them equal
          }
          
          if (itemA === null) {
            return -1; // itemA is null, move it before itemB
          }
        
          if (itemB === null) {
            return 1; // itemB is null, move it before itemA
          }
        
          if (itemA < itemB) {
            return -1;
          }
          if (itemA > itemB) {
            return 1;
          }
          return 0;
        });
        setdata(oparray)
      } else{
        
        oparray.sort((a, b) => {
          const itemA = a['client_name'] ? a['client_name'].toUpperCase() : null;
          const itemB = b['client_name'] ? b['client_name'].toUpperCase() : null;
        
          if (itemA === null && itemB === null) {
            return 0; // Both are null, consider them equal
          }
          
          if (itemA === null) {
            return 1; // itemA is null, move it after itemB
          }
        
          if (itemB === null) {
            return -1; // itemB is null, move it after itemA
          }
        
          if (itemA < itemB) {
            return 1;
          }
          if (itemA > itemB) {
            return -1;
          }
          return 0;
        });
        
        setdata(oparray)
      }

      break;
    }
    case 9:{
     
      if(sortOrder == 'asc'){
               oparray.sort((a, b) => {
                //console.log(a[id])
                   const itemA = a['status'] !== undefined ? a['status'] : null;
                   const itemB = b['status'] !== undefined ? b['status'] : null;
               
                 if (itemA === null && itemB === null) {
                   return 0; // Both are null, consider them equal
                 }
                 
                 if (itemA === null) {
                   return -1; // itemA is null, move it before itemB
                 }
               
                 if (itemB === null) {
                   return 1; // itemB is null, move it before itemA
                 }
               
                 if (itemA < itemB) {
                   return -1;
                 }
                 if (itemA > itemB) {
                   return 1;
                 }
                 return 0;
               });
               //console.log(oparray)
               setdata(oparray)
             } else{
               // alert('d')
               oparray.sort((a, b) => {
                   const itemA = a['status'] !== undefined ? a['status'] : null;
                   const itemB = b['status'] !== undefined ? b['status'] : null;
               
                 if (itemA === null && itemB === null) {
                   return 0; // Both are null, consider them equal
                 }
                 
                 if (itemA === null) {
                   return 1; // itemA is null, move it after itemB
                 }
               
                 if (itemB === null) {
                   return -1; // itemB is null, move it after itemA
                 }
               
                 if (itemA < itemB) {
                   return 1;
                 }
                 if (itemA > itemB) {
                   return -1;
                 }
                 return 0;
               });
               
        
               //console.log(oparray)
               setdata(oparray)
             }
          
      break;
    }
    default:{

    }

  }







// if(id == 7){
//  if(sortOrder == 'asc'){
//    oparray.sort((a, b) => {
//      const dateA = new Date(`${a[id].split("-")[2]}-${a[id].split("-")[1]}-${a[id].split("-")[0]}`);
//      const dateB = new Date(`${b[id].split("-")[2]}-${b[id].split("-")[1]}-${b[id].split("-")[0]}`);
//      // //console.log(dateA)
//      //  alert(dateA)

//      // Check for null or invalid dates
//      if (isNaN(dateA.getTime()) && isNaN(dateB.getTime())) {
//        return 0; // Both are invalid dates, consider them equal
//      }
   
//      if (isNaN(dateA.getTime())) {
//        return -1; // dateA is invalid, move it before dateB
//      }
   
//      if (isNaN(dateB.getTime())) {
//        return 1; // dateB is invalid, move it before dateA
//      }
   
//      return dateA - dateB; // Compare dates directly
//    });
   
//    setdata(oparray)
//  } else{
//    oparray.sort((a, b) => {
//      const dateA = new Date(`${a[id].split("-")[2]}-${a[id].split("-")[1]}-${a[id].split("-")[0]}`);
//      const dateB = new Date(`${b[id].split("-")[2]}-${b[id].split("-")[1]}-${b[id].split("-")[0]}`);
   
//      if (isNaN(dateA.getTime()) && isNaN(dateB.getTime())) {
//        return 0; // Both are invalid dates, consider them equal
//      }
   
//      if (isNaN(dateA.getTime())) {
//        return -1; // dateA is invalid, move it before dateB
//      }
   
//      if (isNaN(dateB.getTime())) {
//        return 1; // dateB is invalid, move it before dateA
//      }
   
//      return dateB - dateA; // Compare dates directly for descending order
//    });
   
   
//    setdata(oparray)
//  }
// }else if(id == 12 ){
// //console.log('hi')
//    if(sortOrder == 'asc'){
//      oparray.sort((a, b) => {
//        const convertToSortableTime = (timeString) => {
//          const [time, period] = timeString.split(' ');
//          const [hours, minutes] = time.split(':');
//          const formattedHours = period === 'AM' ? hours : parseInt(hours, 10) + 12;
//          return `${formattedHours.toString().padStart(2, '0')}:${minutes}`;
//        };
     
//        const sortableTimeA = convertToSortableTime(a[id]);
//        const sortableTimeB = convertToSortableTime(b[id]);
     
//        return sortableTimeA.localeCompare(sortableTimeB);
//      });
     
     
     
     
//      setdata(oparray)
//    } else{
//      oparray.sort((a, b) => {
//        const convertToSortableTime = (timeString) => {
//          const [time, period] = timeString.split(' ');
//          const [hours, minutes] = time.split(':');
//          const formattedHours = period === 'AM' ? hours : parseInt(hours, 10) + 12;
//          return `${formattedHours.toString().padStart(2, '0')}:${minutes}`;
//        };
     
//        const sortableTimeA = convertToSortableTime(a[id]);
//        const sortableTimeB = convertToSortableTime(b[id]);
     
//        return sortableTimeB.localeCompare(sortableTimeA);
//      });
     
     
//      setdata(oparray)
//    }

// }

// else{
//   if(id !== 4 && id !== 6){
    
//    if(sortOrder == 'asc'){
//        oparray.sort((a, b) => {
//          const itemA = a[id] ? a[id].toUpperCase() : '';
//          const itemB = b[id] ? b[id].toUpperCase() : '';
       
//          if (itemA === null && itemB === null) {
//            return 0; // Both are null, consider them equal
//          }
         
//          if (itemA === null) {
//            return -1; // itemA is null, move it before itemB
//          }
       
//          if (itemB === null) {
//            return 1; // itemB is null, move it before itemA
//          }
       
//          if (itemA < itemB) {
//            return -1;
//          }
//          if (itemA > itemB) {
//            return 1;
//          }
//          return 0;
//        });
//        setdata(oparray)
//      } else{
       
//        oparray.sort((a, b) => {
//          const itemA = a[id] ? a[id].toUpperCase() : null;
//          const itemB = b[id] ? b[id].toUpperCase() : null;
       
//          if (itemA === null && itemB === null) {
//            return 0; // Both are null, consider them equal
//          }
         
//          if (itemA === null) {
//            return 1; // itemA is null, move it after itemB
//          }
       
//          if (itemB === null) {
//            return -1; // itemB is null, move it after itemA
//          }
       
//          if (itemA < itemB) {
//            return 1;
//          }
//          if (itemA > itemB) {
//            return -1;
//          }
//          return 0;
//        });
       
//        setdata(oparray)
//      }
//   } else{
//     // alert('d')
//    if(sortOrder == 'asc'){
//        oparray.sort((a, b) => {
//         //console.log(a[id])
//            const itemA = a[id] !== undefined ? a[id] : null;
//            const itemB = b[id] !== undefined ? b[id] : null;
       
//          if (itemA === null && itemB === null) {
//            return 0; // Both are null, consider them equal
//          }
         
//          if (itemA === null) {
//            return -1; // itemA is null, move it before itemB
//          }
       
//          if (itemB === null) {
//            return 1; // itemB is null, move it before itemA
//          }
       
//          if (itemA < itemB) {
//            return -1;
//          }
//          if (itemA > itemB) {
//            return 1;
//          }
//          return 0;
//        });
//        //console.log(oparray)
//        setdata(oparray)
//      } else{
//        // alert('d')
//        oparray.sort((a, b) => {
//            const itemA = a[id] !== undefined ? a[id] : null;
//            const itemB = b[id] !== undefined ? b[id] : null;
       
//          if (itemA === null && itemB === null) {
//            return 0; // Both are null, consider them equal
//          }
         
//          if (itemA === null) {
//            return 1; // itemA is null, move it after itemB
//          }
       
//          if (itemB === null) {
//            return -1; // itemB is null, move it after itemA
//          }
       
//          if (itemA < itemB) {
//            return 1;
//          }
//          if (itemA > itemB) {
//            return -1;
//          }
//          return 0;
//        });
       

//        //console.log(oparray)
//        setdata(oparray)
//      }
//   }
//  }


 // Handle sorting based on the selected option (asc or desc)
 //console.log('Sorting order:', id);
 // Add logic to perform sorting
};



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div> 
      <Dialog
        open={dilog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{color:'red'}}>
          {"Alert"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Select an agent to assign the task !!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{backgroundColor:'blue', color:'white'}} >Ok</Button>
          {/* <Button onClick={handleClose} autoFocus>
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>
      {/* <Grid container spacing={2} style={{marginBottom:20}}>
      <Grid item xs={2.5}  container direction="column" style={{textAlign:'center'}}>
      <Card sx={{ minWidth: 180,backgroundColor:'#a6dcff' }}>
     
      <Typography className="heddings" color="text.secondary" gutterBottom>
      Case ID
        </Typography>
      
    
      </Card></Grid>
      <Grid item xs={3.9}  container direction="column" style={{textAlign:'center'}}>
      <Card sx={{ minWidth: 180,backgroundColor:'#a6dcff' }}>
     
      <Typography className="heddings" color="text.secondary" gutterBottom>
      Address
        </Typography>
      
    
      </Card></Grid>
      <Grid item xs={2}  container direction="column" style={{textAlign:'center'}}>
      <Card sx={{ minWidth: 180,backgroundColor:'#a6dcff' }}>
   
      <Typography className="heddings" color="text.secondary" gutterBottom>
      FE
        </Typography>
      
 
      </Card></Grid>
      <Grid item xs={1.5}  container direction="column" style={{textAlign:'center', marginRight:10}}>
      <Card sx={{ minWidth: 160,backgroundColor:'#a6dcff' }}>
  
      <Typography className="heddings" color="text.secondary" gutterBottom>
      status
        </Typography>
      
  
      </Card></Grid>
      <Grid item xs={1.5}  container direction="column" style={{textAlign:'center', marginLeft:10}}>
      <Card sx={{ minWidth: 160,backgroundColor:'#a6dcff' }}>
   
      <Typography className="heddings" color="text.secondary" gutterBottom>
      Action
        </Typography>
      
 
      </Card></Grid>

      

      </Grid> */}
      <>
      <Dialog
        open={bulkdialog}
        onClose={bhandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEscapeKeyDown // Disable closing the dialog by pressing the escape key
        BackdropProps={{ onClick: null }} 
        // sx={{ Width: 600, maxWidth:800 }}
      >
        <DialogTitle id="alert-dialog-title" style={{color:'Green'}}>
          Bulk Upload
        </DialogTitle>
        <DialogContent>
  <DialogContentText id="alert-dialog-description">
    Select Xls File
  </DialogContentText>
  <div style={{ boxShadow: '0 0 4px rgba(0, 0, 0, 0.5)', padding:10, borderRadius:5}}>
  <input
    type="file"
    accept=".xls, .xlsx"
    onChange={handleFileInputChange} // Define a function to handle file input change
  />
  </div>

</DialogContent>
<DialogActions>
  <Button onClick={bhandleClose} style={{ backgroundColor: '#f08269', color: 'white' }}>
    Cancel
  </Button>
  {/* <Button onClick={handleUpload} style={{ backgroundColor: 'green', color: 'white' }}>
    Upload
  </Button> */}
  {loading ? <Button  style={{ backgroundColor: 'green', color: 'white' }}>
  <CircularProgress size={24} color="inherit" />
  </Button>  :  <Button onClick={handleUpload} style={{ backgroundColor: 'green', color: 'white' }}>
    Upload
  </Button>}
</DialogActions>

      </Dialog>
      {/* {bulkdialog && <Backdrop open={bulkdialog} onClick={handleClose} sx={{ zIndex: 1200 }} />} */}
    </>
  

{/* <TableContainer component={Paper}> */}
{/* <Grid container direction="row" className="mainrowclass" style={{right: '15%', position: 'absolute', top: '13%',zIndex: 9999}}>
      <Grid item xs={3} container direction="column" className="rowclass"> */}
       
      {/* <Grid item xs={3} container direction="column" className="rowclass"> */}
      {data && data.length == 0 ? '':
      <div style={{ boxShadow: '0 0 4px rgba(0, 0, 0, 0.5)', marginTop:0, borderRadius:10, marginBottom:5 }}>
      <Grid container direction="row" className="sbmainrowclass">
      {/* <Grid item xs={1} container direction="column" className="rowclass"></Grid> */}
      <Grid item xs={3} container direction="column" className="rowclass" style={{marginRight:10, paddingTop:2}}>
      {/* <Grid container spacing={2} alignItems="center" justifyContent="flex-start"> */}
      <Grid item xs={9.5}>
        <TextField
          variant="outlined"
          name="searchid"
          placeholder="Enter Applicant Name"
          value={searchname}
          onChange={(e) => setsearchname(e.target.value.trim())}
          style={{width:'90%'}}
        />
      </Grid>
      <Grid item  xs={2.5}>
        {!search_start1 ?  <Button
                sx={{ backgroundColor: "#81b0e6", width: 90 }}
                className="actionb"
                onClick={() => searchstart1()}
              >
               Search
              </Button>:  <Button
                sx={{ backgroundColor: "red", width: 90 }}
                className="actionb"
                onClick={() => searchclose1()}
              >
               Back
              </Button>}
    
             
      {/* </Grid> */}
    </Grid>
      </Grid>
      <Grid item xs={3} container direction="column" className="rowclass" style={{marginRight:10, paddingTop:2}}>
      {/* <Grid container spacing={2} alignItems="center" justifyContent="flex-start"> */}
      <Grid item xs={9.5}>
        <TextField
          variant="outlined"
          name="searchid"
          placeholder="Enter Case Id"
          value={searchid}
          onChange={(e) => setsearchid(e.target.value.trim())}
          style={{width:'90%'}}
        />
      </Grid>
      <Grid item  xs={2.5}>
        {!search_start ?  <Button
                sx={{ backgroundColor: "#81b0e6", width: 90 }}
                className="actionb"
                onClick={() => searchstart()}
              >
               Search
              </Button>:  <Button
                sx={{ backgroundColor: "red", width: 90 }}
                className="actionb"
                onClick={() => searchclose()}
              >
               Back
              </Button>}
    
             
      </Grid>
    {/* </Grid> */}
      </Grid>
     
      <Grid item xs={2} container direction="column" className="rowclass" style={{paddingTop:2}}><Button
      sx={{ backgroundColor: '#8acfbe', width: 180, }}
      className="actionbb"
      onClick={() => exportToExcel()}
      
    >
     Export Data to Excel
    </Button></Grid>
      
      <Grid item xs={2} container direction="column" className="rowclass" style={{paddingTop:2}}>
      <Card style={{}}>
  {/* {(item['fe_name'] == "" || item['fe_name'] == null)  ?  */}
  <Autocomplete
       className="form"
          id="AGENT DETAILS"
          options={fedetails}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
        
          renderInput={(params) => <TextField {...params} label="Select FE" variant="outlined"  style={{paddingTop:0}}  /> } onChange={(event, value) => {
            handelverification( value?value.label:'', value?value.value:'' )
          }}
        />
         {/* : <Typography sx={{ fontSize: 16, textAlign:'center', paddingTop:1, paddingBottom:0}} color="text.secondary" gutterBottom>
        {item['fe_name']}
          </Typography> } */}

        </Card>
      </Grid>
      <Grid item xs={1} container direction="column" className="rowclass" style={{paddingTop:2}}><Button
      sx={{ backgroundColor: '#8acfbe', width: 140, }}
      className="actionbb"
      onClick={() => bulk_update_status()}
       disabled={selectedCases.length != 0 ? false:true}
    >
      Bulk Assign
    </Button></Grid>
      </Grid>

    </div>}
      {/* </Grid>
</Grid>       */}

{dataloaded ? 
   data && data.length == 0 ? <div style={{textAlign:'center', fontSize:32, marginRight:30, marginTop:'15%'}}>No cases available.</div> :
<div style={{ boxShadow: '0 0 4px rgba(0, 0, 0, 0.5)', marginTop:10 }}>
<table style={{ minWidth: 650, borderCollapse: 'collapse' }} className="table-class">
  <thead>
    <tr style={{backgroundColor:'#93dbda', height:70}}>
      <th className="trclass" style={{ width: '50px', border: '1px solid black', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }}>S/No</th>
      <th className="trclass" style={{ width: '150px', border: '1px solid black' }} onClick={() => handleSort(8)}>Client Name {getSortIndicator(8)}</th>
      <th className="trclass" style={{ width: '180px', border: '1px solid black' }} onClick={() => handleSort(0)}> <div > Case Id {getSortIndicator(0)}</div>   <div>REF No</div> </th>
      <th className="trclass" style={{ width: '150px', border: '1px solid black' }} onClick={() => handleSort(1)}>Applicant Name {getSortIndicator(1)}</th>
      <th className="trclass" style={{ width: '140px', border: '1px solid black' }} onClick={() => handleSort(2)}>Address {getSortIndicator(2)}</th>
      <th className="trclass" style={{ width: '120px', border: '1px solid black' }} onClick={() => handleSort(3)}>Verification Type {getSortIndicator(3)}</th>
      <th className="trclass" style={{ width: '150px', border: '1px solid black',wordWrap: 'break-word', whiteSpace: 'normal' }} onClick={() => handleSort(4)}>Mobile No {getSortIndicator(4)}</th>
      <th className="trclass" style={{ width: '150px', border: '1px solid black' }} onClick={() => handleSort(5)}><div >FE Id {getSortIndicator(5)}</div>   <div>FE Name</div></th>
      <th className="trclass" style={{ width: '70px', border: '1px solid black' }} onClick={() => handleSort(6)}>No.Of Attempts {getSortIndicator(6)}</th>
      <th className="trclass" style={{ width: '90px', border: '1px solid black' }} onClick={() => handleSort(7)}>Initiation Date {getSortIndicator(7)}</th>
      <th className="trclass" style={{ width: '170px', border: '1px solid black' }} >Action</th>
      <th className="trclass" style={{ width: '80px', border: '1px solid black' }} onClick={() => handleSort(9)}>Status {getSortIndicator(9)}</th>
     
     
    </tr>
  </thead>
  <tbody>
    {data.map((item, index) => (
      <tr key={index} style={{backgroundColor:(item['case_status'] !== null && !item['case_status']) ? '#fadbca' :'' }}>
        <td className="tdclass"> <span>{index + 1}</span>
  <br />
  <br />
  
  { item['status'] === 0 && (
    <input type="checkbox" id={`checkbox-${index}`}  style={{ width: '15px', height: '15px' }}  onChange={e => handleCheckboxChange(e, item['case_id'])}/>
  )}
  </td>
  <td className="tdclass"><Typography className="crossed_text">{item['client_name']}</Typography></td>
        <td className="tdclass"> <div className="caseiddiv"> {item['case_id']}</div><div style={{paddingTop:10}}> {item['file_refference_no']}</div>       </td>
        <td className="tdclass">{item['applicant_name']}</td>
        <td className="tdclass" onClick={() => handleCardClick(index)} style={{ cursor: 'pointer' }}> <div>{item['applicant_current_address']['area']}</div><div>{item['applicant_current_address']['pincode']}</div>  <Tooltip  title={
                <div>
        <Typography style={{color:'black', padding:10}} >
       <strong>Address:</strong>    {item['applicant_current_address']['address']}
        </Typography>
        <IconButton aria-label="close" onClick={() => handleCloseTooltip(index)}>
                  <CloseIcon />
                </IconButton>
        </div>
      } arrow placement="top"  classes={{ tooltip: classes.customTooltip }}   open={tooltipOpen[index]}>
              </Tooltip>  </td>
       
        <td className="tdclass" >{item['verification_type']}</td>
        <td className="tdclass" style={{ wordBreak: 'break-all', whiteSpace: 'normal' }}>
  {item['applicant_number']['mobile_no']}
</td>
        {item['fe_id'] == null ?  <td className="tdclass">  ------  </td> :  <td className="tdclass">  <div className="caseiddiv">{item['fe_id']}</div><div style={{paddingTop:10}}>{item['fe_name']}</div>   </td>}
       
        <td className="tdclass">{item['reassign_count']}</td>
     <td className="tdclass">  {item['i_date'] ? new Date(item['i_date']).toLocaleDateString('en-GB') : '-------'}</td>
        <td className="tdclass"> <div style={{marginTop:10, marginLeft:5, marginRight:5}}>  <Card >
  {/* {(item['fe_name'] == "" || item['fe_name'] == null)  ?  */}
  <Autocomplete
       className="form"
          id="AGENT DETAILS"
          options={fedetails}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
        
          renderInput={(params) => <TextField {...params} label="Select FE" variant="outlined"  style={{paddingTop:0}}  /> } onChange={(event, value) => {
            handelverification( value?value.label:'', value?value.value:'' )
          }}
        />
         {/* : <Typography sx={{ fontSize: 16, textAlign:'center', paddingTop:1, paddingBottom:0}} color="text.secondary" gutterBottom>
        {item['fe_name']}
          </Typography> } */}

        </Card></div> <div style={{marginTop:10, marginLeft:15, marginRight:5, marginBottom:10}}> <Card sx={{ maxWidth: 130 }}>
     
     {item['status'] == 3 ? <Button sx={{  backgroundColor:'#129e12'}}  gutterBottom className="actionb">
     Download
       </Button> : item['status'] == 1  ?  <Button sx={{  backgroundColor: '#a5c4d9', width:130}} gutterBottom className="actionb" onClick={()=>updatereasign_status(item['case_id'])}>
     Re Assign
       </Button> : <Button sx={{  backgroundColor:item['status'] == 0 ? '#8181ff' : item['status'] == 1 ? '#8ec9d4' :'#b0b0b0', width:130}} disabled = {item['status'] == 0 ? false : true} gutterBottom className="actionb" onClick={()=>update_status(item['case_id'])}>
     {item['status'] == 0 ? 'Assign':item['status'] == 3 ? 'Download' : 'Re Assign'}
       </Button>
        }
     

     
    
     </Card></div>   </td>
    
     <td className="tdclass" style={{backgroundColor:item['status'] == 0 ? '#ffff85':item['status'] == 1 ? '#ffd382': item['status'] == 2 ? '#34ebc6' : '#97ff97' }}><Typography className="crossed_text">{item['status'] === 1 ? 'Assigned' :item['status'] === 3 ? 'Completed': 'Created'}</Typography></td>
       
        {/* Add Action cell content here */}
      </tr>
    ))}
  </tbody>
</table>
</div>
:   <div className="loading-container">
        <TailSpin
          height="80"
          width="80"
          color="#00BFFF"
          ariaLabel="loading"
        />
      </div>}
      {/* </TableContainer> */}



      {/* <Grid item xs={2} key={index} container direction="column">
          
  
  <Card >
     
      <Typography sx={{ fontSize: 16, textAlign:'center', paddingTop:1, paddingBottom:0}} color="text.secondary" gutterBottom>
      {item['case_id']}
        </Typography>
      
     
      </Card>
</Grid>
<Grid item xs={2} key={index} container direction="column">
          
          
          <Card >
             
              <Typography sx={{ fontSize: 16, textAlign:'center', paddingTop:1, paddingBottom:0}} color="text.secondary" gutterBottom>
              {item['applicant_name']}
                </Typography>
              
             
              </Card>
        </Grid>
        <Grid item xs={1} key={index} container direction="column">
          
          
          <Card >
             
              <Typography sx={{ fontSize: 16, textAlign:'center', paddingTop:1, paddingBottom:0}} color="text.secondary" gutterBottom>
              {item['applicant_number']['mobile_no']}
                </Typography>
              
             
              </Card>
        </Grid>
        <Grid item xs={1} key={index} container direction="column">
          
          
          <Card >
             
              <Typography sx={{ fontSize: 16, textAlign:'center', paddingTop:1, paddingBottom:0}} color="text.secondary" gutterBottom>
              {item['verification_type']}
                </Typography>
              
             
              </Card>
        </Grid>
<Grid item xs={2} key={index} container direction="column">

          <Card sx={{ minWidth: 180 }}   onClick={() => handleCardClick(index)}>
             
              
                
                <Typography sx={{ fontSize: 16, textAlign:'center', paddingTop:1, paddingBottom:0}} color="text.secondary" gutterBottom>
                {item['applicant_current_address']['area']} : {item['applicant_current_address']['pincode']} 
                </Typography>
                
                
             

              </Card>
              <Tooltip  title={
                <div>
        <Typography style={{color:'black', padding:10}} >
       <strong>Address:</strong>    {item['applicant_current_address']['address']}
        </Typography>
        <IconButton aria-label="close" onClick={() => handleCloseTooltip(index)}>
                  <CloseIcon />
                </IconButton>
        </div>
      } arrow placement="top"  classes={{ tooltip: classes.customTooltip }}   open={tooltipOpen[index]}>
              </Tooltip>
        </Grid>
<Grid item xs={1} key={index} container direction="column">
          
  
  <Card >
  {(item['fe_name'] == "" || item['fe_name'] == null)  ? <Autocomplete
       className="form"
          id="VERIFICATION TYPE"
          options={options}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          // value = {}
          // value={(() => {
          //   let parsedValue;
          //   try {
          //     parsedValue = JSON.parse(item['AgentName']);
          //   } catch (error) {
          //     //console.error('Error parsing JSON:', error);
          //   }
          //   return parsedValue || null; // Provide a default value if parsing fails
          // })()}
          renderInput={(params) => <TextField {...params} label="Select FE" variant="outlined"  style={{paddingTop:0}}  /> } onChange={(event, value) => {
            handelverification( value?value.label:'')
          }}
        /> : <Typography sx={{ fontSize: 16, textAlign:'center', paddingTop:1, paddingBottom:0}} color="text.secondary" gutterBottom>
        {item['fe_name']}
          </Typography> }

        </Card>
        </Grid>
  <Grid item xs={1} key={index} container direction="column" style={{marginRight:10}}>
          
  <Card sx={{ minWidth: 120 , backgroundColor:item['status'] == 0 ? '#ffff85':item['status'] == 1 ? '#ffd382': item['status'] == 2 ? '#34ebc6' : '#97ff97' }}>
     
      <Typography sx={{ fontSize: 16, textAlign:'center', paddingTop:1, paddingBottom:0}} color="text.secondary" gutterBottom>
      {item['status'] == 0 ? 'Created': item['status'] == 1 ? 'Asigned':item['status'] == 2 ? 'Initiated': 'Closed'}
        </Typography>
      
     
      </Card>
</Grid>

<Grid item xs={1} key={index} container direction="column" style={{marginLeft:10}}>
          
  <Card sx={{ minWidth: 120 }}>
     
      {item['status'] == 3 ? <Button sx={{  backgroundColor:'#129e12'}}  gutterBottom className="actionb">
      Download
        </Button> :  <Button sx={{  backgroundColor:item['status'] == 0 ? '#8181ff':'#b0b0b0',}} disabled = {item['status'] == 0 ? false : true} gutterBottom className="actionb" onClick={()=>update_status(item['file_refference_no'])}>
      {item['status'] == 0 ? 'Asign':item['status'] == 3 ? 'Download' : 'Asign'}
        </Button>
         }
      

      
     
      </Card>
</Grid> */}


   
  

      
      </div>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Applications;
