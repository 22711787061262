/**
Developer Name : Vinod kumar J
Email : vinodtech2023@gmail.com
Contact Number : 9110248064
 * @format
 */
// const dbConfig = {
// 	user: 'aztek',
// 	password: 'AztekData@2024',
// 	host: 'localhost',
// 	port: '5432',
// 	database: 'aztek',
// };
// @mui material components
import React, {
  createContext,
  useContext,
  useReducer,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Box,
} from "@mui/material";
import PdfContent_Employee from "./PdfContent_Employee";
import PdfContent_Asset from "./PdfContent_Asset";
import PdfContent_BankStatement from "./PdfContent_BankStatement";
import PdfContent_Business from "./PdfContent_Business";
import PdfContent_Property from "./PdfContent_Property";
import PdfContent_Residence from "./PdfContent_Rescidence";
import Grid from "@mui/material/Grid";
import Select from 'react-select';
import { Pagination } from "@mui/material";
import "../app.css";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import TextField from "@mui/material/TextField";
import { Typography, InputAdornment } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Autocomplete from "@mui/lab/Autocomplete";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { URL } from "../constants";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {

  CircularProgress
} from '@mui/material';
import Tooltip from "@mui/material/Tooltip";
import Switch from '@mui/material/Switch';
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useReactToPrint } from "react-to-print";
import * as XLSX from "xlsx";
import { TailSpin } from 'react-loader-spinner';
import SearchIcon from '@mui/icons-material/Search';
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import axios from 'axios';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import PdfContent_Financial from "./PdfContent_Financial ";
import PdfContent_Form16 from "./PdfContent_Form16 ";
import PdfContent_ITR from "./PdfContent_ITR ";
import PdfContent_QuotationVerification from "./PdfContent_QuotationVerification ";
import PdfContent_SalarySlipVerification from "./PdfContent_SalarySlipVerification ";
import Compressor from 'compressorjs';
import { PDFDocument } from 'pdf-lib';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CopyAllOutlined } from "@mui/icons-material";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from '../../firebase'; 

// const handleKeyDown = (event, params) => {
//   if (event.key === "Enter") {
//     event.preventDefault();
//     params.inputProps.onFocus();  // Open the dropdown on Enter key press
//   }
// };


const customStyles = {
  control: (provided) => ({
    ...provided,
    textAlign: 'center', // Center text similar to your style
  }),
};
const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "#a9ccbe", // Change the background color here
    minWidth: 300, // Change the maximum width of the tooltip here
  },
}));
const containerStyle = {
  width: "150%",
  height: "40vh",
  marginLeft: "50%",
};
// Dashboard components

function Application_logs() {
  const selectRefs = useRef({});
  const pdfRef = useRef(null);
  const classes = useStyles();
  const { sales, tasks } = reportsLineChartData;
  const [data, setdata] = useState([]);
  const [dataloaded, setdataloaded] = useState(false)
  const [agent, setagent] = useState("");
  const [userLocation, setUserlocation] = useState("");
  const [token, settoken] = useState("");
  const [dilog, setdilog] = useState("");
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [open, setOpen] = useState(false);
  const [auditdata, setauditdata] = useState();
  const [dpdfData, setPdfData] = useState({});
  const [dialogReady, setDialogReady] = useState(false);
  const [opend, setopend] = useState(false);
  const [openempd, setopenempd] = useState();
  const [openbusd, setopenbusd] = useState();
  const [openppd, setopenppd] = useState();
  const [openrcd, setopenrcd] = useState();
  const [openastd, setopenastd] = useState();
  const [openbstd, setopebstd] = useState();
  const [openfbd, setopenfbd] = useState();
  const [openf16d, setopenf16d] = useState();
  const [openitrd, setopenitrd] = useState();
  const [openqtd, setopenqtd] = useState();
  const [openssd, setopenssd] = useState();
  const [openverification_type, setopenverification_type] = useState();
  const [opendocument_type, setopenopendocument_type] = useState();
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [loadopen, setloadopen] = useState();
  const [loadopen1, setloadopen1] = useState();
  const [searchid, setsearchid] = useState()
  const [searchname, setsearchname] = useState()
  const[search_start, setsearch_start] = useState(false)
  const[search_start1, setsearch_start1] = useState(false)
  const[a_user, seta_user] = useState('')
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [assessmentYears, setAssessmentYears] = useState([
    {
      id: 1,
      year: "",
      received: { amountCredited: "", taxDeposited: "" },
      verified: { amountCredited: "", taxDeposited: "" },
    },
    {
      id: 2,
      year: "",
      received: { amountCredited: "", taxDeposited: "" },
      verified: { amountCredited: "", taxDeposited: "" },
    },
    {
      id: 3,
      year: "",
      received: { amountCredited: "", taxDeposited: "" },
      verified: { amountCredited: "", taxDeposited: "" },
    },
  ]);
  const [quotationdata, setquotationdata] = useState([
    {
      id: 1,
      asset_name: "",
      value_as_received: "",
      value_as_verified: ""
    },
   
  ]);
  const [assessmentYearsitr, setAssessmentYearsitr] = useState([
    {
      id: 1,
      year: "",
      total_income_received: "",
      tax_paid_received: "",
      total_income_verified: "",
      tax_paid_verified: "",
    },
    {
      id: 2,
      year: "",
      total_income_received: "",
      tax_paid_received: "",
      total_income_verified: "",
      tax_paid_verified: "",
    },
    {
      id: 3,
      year: "",
      total_income_received: "",
      tax_paid_received: "",
      total_income_verified: "",
      tax_paid_verified: "",
    },
  ]);
  const [salaryDetails, setSalaryDetails] = useState([
    {
      id: 1,
      month: "",
      amount_received: "",
      amount_verified: "",
    },
    {
      id: 2,
      month: "",
      amount_received: "",
      amount_verified: "",
    },
    {
      id: 3,
      month: "",
      amount_received: "",
      amount_verified: "",
    },
    {
      id: 4,
      month: "",
      amount_received: "",
      amount_verified: "",
    },
  ]);
  const [transactions, setTransactions] = useState([
    {
      id: 1,
      date: "",
      amount_received: "",
      amount_verified: "",
    },
    {
      id: 2,
      date: "",
      amount_received: "",
      amount_verified: "",
    },
    {
      id: 3,
      date: "",
      amount_received: "",
      amount_verified: "",
    },
  ]);
  const [financialYears, setFinancialYears] = useState([
    {
      id: 1,
      year: "",
      received: {
        grossIncome: "",
        totalIncome: "",
        sundryCreditors: "",
        sundryDebtors: "",
      },
      verified: {
        grossIncome: "",
        totalIncome: "",
        sundryCreditors: "",
        sundryDebtors: "",
      },
    },
    {
      id: 2,
      year: "",
      received: {
        grossIncome: "",
        totalIncome: "",
        sundryCreditors: "",
        sundryDebtors: "",
      },
      verified: {
        grossIncome: "",
        totalIncome: "",
        sundryCreditors: "",
        sundryDebtors: "",
      },
    },
    {
      id: 3,
      year: "",
      received: {
        grossIncome: "",
        totalIncome: "",
        sundryCreditors: "",
        sundryDebtors: "",
      },
      verified: {
        grossIncome: "",
        totalIncome: "",
        sundryCreditors: "",
        sundryDebtors: "",
      },
    },
  ]);
  const cellStyle = {
    border: '1px solid black',
  };
  const [xldata, setxldata] = useState();
  const  type_of_locality = [
    { value: 'Commercial', label: 'Commercial' },
    { value: 'Residential', label: 'Residential' },
    { value: 'Resi cum Commercial', label: 'Resi cum Commercial' },
    { value: 'NA', label: 'NA' },
  
  ];
  const easy_to_locate = [
    { value: 'Easy', label: 'Easy' },
    { value: 'Difficult', label: 'Difficult' },
    { value: 'NA', label: 'NA' },
  
  ];
  
  const  type_of_organisaton = [
    { value: 'Govt/ Public Ltd', label: 'Govt/ Public Ltd' },
    { value: 'MNC', label: 'MNC' },
    { value: 'Pvt Ltd', label: 'Pvt Ltd' },
   
    { value: 'Partnership', label: 'Partnership' },
    { value: 'NGO', label: 'NGO' },
    { value: 'Proprietorship', label: 'Proprietorship' },
    { value: 'Other', label: 'Other' },
    { value: 'NA', label: 'NA' },
  
  ];
  const  nature_of_business = [
    { value: 'Professional', label: 'Professional' },
    { value: 'Manufactures', label: 'Manufactures' },
    { value: 'Trader', label: 'Trader' },
    { value: 'Shopkeeper', label: 'Shopkeeper' },
    { value: 'Service Provider', label: 'Service Provider' },
    { value: 'NA', label: 'NA' },
  
  ];
  const  type_of_job = [
    { value: 'Permanent', label: 'Permanent' },
    { value: 'Manufactures', label: 'Manufactures' },
    { value: 'Trader', label: 'Trader' },
    { value: 'Shopkeeper', label: 'Shopkeeper' },
    { value: 'Service Provider', label: 'Service Provider' },
    { value: 'NA', label: 'NA' },
  
  ];
  const  type_of_job_emp = [
    { value: 'Permanent', label: 'Permanent' },
    { value: 'Probation', label: 'Probation' },
    { value: 'Contract Worker', label: 'Contract Worker' },
    { value: 'Temporary Worker', label: 'Temporary Worker' },
    // { value: 'Service Provider', label: 'Service Provider' },
    { value: 'Other', label: 'Other' },
  
  ];
  const  working_as = [
    { value: 'Executive', label: 'Executive' },
    { value: 'Supervisor', label: 'Supervisor' },
    { value: 'Junior Management', label: 'Junior Management' },
    { value: 'Middle/ Sr. Management', label: 'Middle/ Sr. Management' },
    { value: 'Other Management', label: 'Other Management' },
   
  
  ];
  const  tpc_confirmation = [
    { value: 'Positive', label: 'Positive' },
    { value: 'Neutral', label: 'Neutral' },
    { value: 'Negative', label: 'Negative' },
    { value: 'NA', label: 'NA' },
   
  
  ];
  const  overall_status = [
    { value: 'RECOMMENDED', label: 'RECOMMENDED' },
    { value: 'NOT RECOMMENDED', label: 'NOT RECOMMENDED' },
    { value: 'REFFERAL', label: 'REFFERAL' },
    { value: 'UNTRACED', label: 'UNTRACED' },
    { value: 'OGL (NON SERVICEABLE AREA)', label: 'OGL (NON SERVICEABLE AREA)' }
  
    ];
  const  reason_for_negative = [
    { value: 'NA', label: 'NA' },
    { value: 'Not Working', label: 'Not Working' },
    { value: 'Office Does not Exist', label: 'Office Does not Exist' },
    { value: 'Not Ready to Co-operate', label: 'Not Ready to Co-operate' },
    { value: 'Other Reason', label: 'Other Reason' },
   ];
   const  reason_for_negative_r = [
    { value: 'Address doesn’t exist', label: 'Address doesn’t exist' },
    { value: 'Property doesn’t belong to seller', label: 'Property doesn’t belong to seller' },
    { value: '⁠Buyer doesn’t know the seller', label: '⁠Buyer doesn’t know the seller' },
    { value: 'Disputed Property', label: 'Disputed Property' },
    { value: 'Others', label: 'Others' },
   ];
  const yesno = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
  ]
  const yesnona = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
    { value: 'NA', label: 'NA' }
  ]
  const  office_ownership = [
    { value: 'Owned', label: 'Owned' },
    { value: 'Rented', label: 'Rented' },
    { value: 'Leased', label: 'Leased' },
    { value: 'Other', label: 'Other' },
   ];
   const  applicants_name_verified_from = [
    { value: 'Self', label: 'Self' },
    { value: 'Co Worker', label: 'Co Worker' },
    { value: 'Reception', label: 'Reception' },
    { value: 'Security', label: 'Security' },
    { value: 'NA', label: 'NA' },
   ];
   const  contact_verification_feedback = [
    { value: 'Known to applicant', label: 'Known to applicant' },
    { value: 'Do not know the applicant', label: 'Do not know the applicant' },
    { value: 'NA', label: 'NA' }
   ];
   const  relation_with_applicant = [
    { value: 'Self', label: 'Self' },
    { value: 'Spouse', label: 'Spouse' },
    { value: 'Children', label: 'Children' },
    { value: 'Mother', label: 'Mother' },
    { value: 'Father', label: 'Father' },
    { value: 'Sister', label: 'Sister' },
    { value: 'Brother', label: 'Brother' },
    { value: 'Bhabhi', label: 'Bhabhi' },
    { value: 'Neighbours', label: 'Neighbours' },
    { value: 'Employee', label: 'Employee' },
    { value: 'Relative', label: 'Relative' },
    { value: 'Others', label: 'Others' },
    { value: 'NA', label: 'NA' },
   ];
   const  type_of_house = [
    { value: 'Bunglow', label: 'Bunglow' },
    { value: 'Flat', label: 'Flat' },
    { value: 'Row House', label: 'Row House' },
    { value: 'Chawl or Hut', label: 'Chawl or Hut' },
    { value: 'Other', label: 'Other' },
   ];
   const  house_condition = [
    { value: 'Good', label: 'Good' },
    { value: 'Average', label: 'Average' },
    { value: 'Poor', label: 'Poor' },
    { value: 'Other', label: 'Other' },
    { value: 'NA', label: 'NA' },
   ];
   const  type_of_accommodation = [
    { value: 'With Parents', label: 'With Parents' },
    { value: 'Relatives', label: 'Relatives' },
    { value: 'Nuclear Family', label: 'Nuclear Family' },
    { value: 'Bachelor', label: 'Bachelor' },
    { value: 'Shared Accommodation', label: 'Shared Accommodation' },
    { value: 'NA', label: 'NA' },
   ];
   const merital_status = [
    { value: 'Married', label: 'Married' },
    { value: 'Single', label: 'Single' },
    { value: 'NA', label: 'NA' }
  ]
  const type_of_property = [
    { value: 'Independent', label: 'Independent' },
    { value: 'Flat', label: 'Flat' },
    { value: 'Bungalow', label: 'Bungalow' },
    { value: 'Freehold', label: 'Freehold' },
    { value: 'Others', label: 'Others' }
  ]
  const cooperative_applicant = [
    { value: 'Very cooperative', label: 'Very cooperative' },
    { value: 'Rude', label: 'Rude' },
    { value: 'Others', label: 'Others' }
  ]
  const ambience = [
    { value: 'Good', label: 'Good' },
    { value: 'Average', label: 'Average' },
    { value: 'Poor', label: 'Poor' },
  ]
  const educational_qualification = [
    { value: 'Professional', label: 'Professional' },
    { value: 'Post Graduate', label: 'Post Graduate' },
    { value: 'Undergraduate', label: 'Undergraduate' },
    { value: 'Graduate', label: 'Graduate' },
    { value: 'Others', label: 'Others' },
    { value: 'NA', label: 'NA' }
  ]
  const neighbour_feedback = [
    { value: 'Known to applicant', label: 'Known to applicant' },
    { value: 'Do not know the applicant', label: 'Do not know the applicant' },
    { value: 'NA', label: 'NA' }
  ]
  const [formData, setFormData] = useState({
    product_type: "",
    case_id: "",
    applicant_name: "",
    applicant_number: "",
    company_name: "",
    address: "",
    client_name: "",
    i_date: "",
    u_date: "",
    c_date: "",
    overallstatus: "",
    reasonfornegativefi: "",
    remarks: "",
    name_of_person_met: "",
    relation_with_applicant: "",
    applicant_age: "",
    educational_qualification: "",
    time_of_stay_current_address: "",
    total_family_members: "",
    earning_members: "",
    retired_pensioner_member: "",
    marital_status: "",
    spouse_earning: "",
    spouse_employment_details: "",
    applicant_stay_at_address: "",
    type_of_accommodation: "",
    type_of_house: "",
    house_condition: "",
    ownership: "",
    exterior: "",
    type_of_locality: "",
    standard_of_living: "",
    neighbour_name_1: "",
    neighbour_name_2: "",
    neighbour_comment_1: "",
    address_1: "",
    address_2: "",
    neighbour_feedback: "",
    ease_to_locate: "",
    vehicle_seen: "",
    political_link: "",
    latitude: "",
    longitude: "",
    field_agent_name: "",
    type_of_job: "",
    working_as: "",
    office_telephone: "",
    landmark: "",
    designation_of_person_met: "",
    contact_number: "",
    nature_of_business: "",
    type_of_organisaton: "",
    visiting_card_obtained: "",
    time_of_employment_of_applicant: "",
    applicant_work: "",
    number_of_employees: "",
    designation_of_applicant: "",
    work_transferable: "",
    office_name_board_seen: "",
    report_to: "",
    manager_designation: "",
    reporting_manager: "",
    sallary: "",
    tpc_check: "",
    name_of_person_contacted: "",
    number_of_years_present_address: "",
    number_of_branches: "",
    years_of_existence_of_business: "",
    business_activity_seen: "",
    business_board_seen: "",
    applicants_name_verified_from: "",
    contact_verification_1: "",
    contact_verification_2: "",
    contact_verification_3: "",
    address_proof_details: "",
    type_of_property: "",
    property_sold: "",
    remarks_about_purchaser: "",
    does_applicant_stay_this_address: "",
    area_sq: "",
    document_verified: "",
    cooperative_applicant: "",
    ambience: "",
    dependent_members: "",
    dependent_members_child:"",
    vehicle_model: "",
    loanamount: "",
    dma: "",
    rm_name: "",
    location: "",
    co_applcant_name: "",
    guarntor_name: "",
    pick_up_criteria: "",
    verification_type: "",
    status: "",
    reason_for_rejection: "",
    discrepant_document: "",
    discrepant_profile: "",
    negative: "",
    sourcing_issues: "",
    end_use: "",
    neighbour_check: "",
    branch_name: "",
    date_of_receipt: "",
    ref_no: "",
    reported_by: "",
    bank_name: "",
    account_no: "",
    account_opening_date: "",
    final_remarks: "",

    date_of_report: "",

    panno: "",
    reporting_manager_contact_number :"",
    acknowledgement_no: "",
    quotation_issued_by: "",
    quotation_issued_date: "",
    contact_person_name: "",
    employer_name: "",
    employer_address: "",
    verified_from_whom: "",
    residence_stability: "",
    doc_status:"",
    occupation_details : "",
    document_type:"",
    i_time:"",
    u_time:"",
    c_time:"",
    no_of_years_with_present_owner:'',
    deviation:'',
    a_company_name :''
    
  });

  const [tempDateRange, setTempDateRange] = useState([null, null]);
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [opendate, setOpendate] = useState(false);
  const ref = useRef(null);
  const [checked, setChecked] = useState(true);
  const [checked1, setChecked1] = useState(false);

  const [product_deatils, setproduct_deatils] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10; // You can adjust this value as needed

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const paginatedData = data.slice(startIndex, startIndex + rowsPerPage);
  
  const handleKeyDown = (event, id) => {
    if (event.key === 'Enter') {
      if (selectRefs.current[id]) {
        selectRefs.current[id].focus();  // Focus the correct select element
        selectRefs.current[id].onMenuOpen();  // Open the dropdown
      }
    }
  };

  const read_pdata = () =>{
    try{
      const token = localStorage.getItem("token");
      // //console.log('hi')
      let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('token', token);
    const requestOptions1 = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({'id':1}),
   
    };
    fetch(`${URL}/read_form_data`, requestOptions1)
      .then((response) => response.json())
      .then((data) => {
             if(data.length != 0){
              // setbranch_deatils(data.branch_details)
              // setclient_deatils(data.client_details)
              setproduct_deatils(data.product_types)
              // setBranch_locations(data.branch_locations)
             }
              //console.log('data',data)
      })
    }
     catch (error) {
      // Code to handle the error
      //console.error("An error occurred:", error);
    } finally {
      // Optional: Code that runs regardless of whether an error occurred or not
      //console.log("This code runs regardless of errors.");
    }
  
  }

  useEffect(() => {
    read_pdata();
      }, []);
const clearsearch =()=>{
  setStartDate(null)
  setEndDate(null)
  read_data(userLocation)
}

   const read_filter_data =  () =>{
    // alert(checked)
    if(checked){
      search_data_datewise_audit_completed()
    }else if(checked1){
      search_data_datewise_visit_completed()
    }else{
      search_data_datewise()
    }
   }


  const handleswitchChange = (event) => {
    setChecked(event.target.checked);
  
    // //alert(event.target.checked)
    // if(event.target.checked ==  false){
    //   read_data(userLocation)
    // }else{
    //   search_data_status(event.target.checked)
    // }
    
  };
  const handleswitchChange1 = (event) => {
    setChecked1(event.target.checked);
    // alert(event.target.checked)
    
    // //alert(event.target.checked)
    // if(event.target.checked ==  false){
    //   read_data(userLocation)
    // }else{
    //   search_data_status(event.target.checked)
    // }
    
  };
  // const convertTo12HourFormat = (time) => {
  //   const [hours, minutes] = time.split(':');
  //   const period = hours >= 12 ? 'PM' : 'AM';
  //   const adjustedHours = hours % 12 || 12;
  //   return `${String(adjustedHours).padStart(2, '0')}:${minutes} ${period}`;
  // };

  const onChangedate = (dates) => {
    const [start, end] = dates;
    setTempDateRange([start, end]);
  };

  const handleApply = () => {
    setSelectedDateRange(tempDateRange);
    setStartDate(tempDateRange[0]);
    setEndDate(tempDateRange[1]);
    setOpendate(false);
  };

  const handleCancel = () => {
    setTempDateRange(selectedDateRange);
    setOpendate(false);
  };
  const convertTo12HourFormat = (timeStr) => {
    // Parse the input time
    const [hours, minutes] = timeStr.split(':').map(Number);
  
    // Create a Date object for the input time in UTC
    const now = new Date();
    now.setUTCHours(hours);
    now.setUTCMinutes(minutes);
  
    // Convert to Indian Standard Time (IST)
    now.setMinutes(now.getMinutes() + 0); // 330 minutes = 5 hours and 30 minutes
  
    // Extract hours and minutes
    const istHours = now.getHours();
    const istMinutes = String(now.getMinutes()).padStart(2, '0');
  
    // Convert to 12-hour format
    const period = istHours >= 12 ? 'PM' : 'AM';
    const adjustedHours = istHours % 12 || 12;
  
    return `${String(adjustedHours).padStart(2, '0')}:${istMinutes} ${period}`;
  };
  const convertTo12HourFormataudit = (timeStr) => {
    // Parse the input time
    const [hours, minutes] = timeStr.split(':').map(Number);
  
    // Create a Date object for the input time in UTC
    const now = new Date();
    now.setUTCHours(hours);
    now.setUTCMinutes(minutes);
  
    // Convert to Indian Standard Time (IST)
    now.setMinutes(now.getMinutes() + 330); // 330 minutes = 5 hours and 30 minutes
  
    // Extract hours and minutes
    const istHours = now.getHours();
    const istMinutes = String(now.getMinutes()).padStart(2, '0');
  
    // Convert to 12-hour format
    const period = istHours >= 12 ? 'PM' : 'AM';
    const adjustedHours = istHours % 12 || 12;
  
    return `${String(adjustedHours).padStart(2, '0')}:${istMinutes} ${period}`;
  };
  const handleClickOpen1 = (data) => {
 
    const token = localStorage.getItem("token");

    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("token", token);

    const requestOptions1 = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ case_id: data.case_id }),
    };

    fetch(`${URL}/read_audit_data`, requestOptions1)
      .then((response) => response.json())
      .then(async (auditeddata) => {
          let auditdata = auditeddata[0]
          // setDialogReady(true);
      //  //alert(auditdata.latitude)
         if(auditeddata.length != 0){
        console.log('itrdata',auditdata.assessmentyearsitr)
          setFormData({
            product_type: auditdata.product_type || "",
            case_id: auditdata.case_id || "",
            applicant_name: auditdata.applicant_name || "",
            applicant_number: auditdata.applicant_number || "",
            company_name: auditdata.company_name || "",
            address:auditdata.address || "",
            client_name: auditdata.client_name || "",
            i_date: auditdata.i_date || new Date(),
            u_date: auditdata.u_date || new Date(),
            c_date: auditdata.c_date || new Date(),
            i_time:auditdata.i_time || '',
            u_time:auditdata.u_time || '',
            c_time:auditdata.c_time || '',
          
            overallstatus: auditdata.overallstatus || "",
            reasonfornegativefi: auditdata.reasonfornegativefi || "",
            remarks: auditdata.remarks || "",
            name_of_person_met: auditdata.name_of_person_met || "",
            relation_with_applicant: auditdata.relation_with_applicant || "",
            applicant_age: auditdata.applicant_age || 0,
            educational_qualification: auditdata.educational_qualification || "",
            time_of_stay_current_address: auditdata.time_of_stay_current_address || "",
            total_family_members: auditdata.total_family_members || 0,
            earning_members: auditdata.earning_members || 0,
            retired_pensioner_member: auditdata.retired_pensioner_member || 0,
            marital_status: auditdata.marital_status || "",
            spouse_earning: auditdata.spouse_earning || "",
            spouse_employment_details: auditdata.spouse_employment_details || "",
            applicant_stay_at_address: auditdata.applicant_stay_at_address || "",
            type_of_accommodation: auditdata.type_of_accommodation || "",
            type_of_house: auditdata.type_of_house || "",
            house_condition: auditdata.house_condition || "",
            ownership: auditdata.ownership || "",
            exterior: auditdata.exterior || "",
            type_of_locality: auditdata.type_of_locality || "",
            standard_of_living: auditdata.standard_of_living || "",
            neighbour_name_1: auditdata.neighbour_name_1 || "",
            neighbour_name_2: auditdata.neighbour_name_2 || "",
            neighbour_comment_1: auditdata.neighbour_comment_1 || "",
            address_1: auditdata.address_1 || "",
            address_2: auditdata.address_2 || "",
            neighbour_feedback: auditdata.neighbour_feedback || "",
            ease_to_locate: auditdata.ease_to_locate || "",
            vehicle_seen: auditdata.vehicle_seen || "",
            political_link: auditdata.political_link || "",
            latitude: auditdata.latitude || "",
            longitude: auditdata.longitude || "",
            field_agent_name: auditdata.field_agent_name || "",
            files_name: auditdata.files_name || [],
            type_of_job: auditdata.type_of_job || "",
            working_as: auditdata.working_as || "",
            office_telephone: auditdata.office_telephone || "",
            landmark: auditdata.landmark || "",
            designation_of_person_met: auditdata.designation_of_person_met || "",
            contact_number: auditdata.contact_number || "",
            nature_of_business: auditdata.nature_of_business || "",
            type_of_organisaton: auditdata.type_of_organisaton || "",
            visiting_card_obtained: auditdata.visiting_card_obtained || "",
            time_of_employment_of_applicant:
              auditdata.time_of_employment_of_applicant || "",
            applicant_work: auditdata.applicant_work || "",
            number_of_employees: auditdata.number_of_employees || 0,
            designation_of_applicant: auditdata.designation_of_applicant || "",
            work_transferable: auditdata.work_transferable || "",
            office_name_board_seen: auditdata.office_name_board_seen || "",
            report_to: auditdata.report_to || "",
            manager_designation: auditdata.manager_designation || "",
            reporting_manager: auditdata.reporting_manager || "",
            sallary: auditdata.sallary || "",
            tpc_check: auditdata.tpc_check || "",
            name_of_person_contacted: auditdata.name_of_person_contacted || "",
            number_of_years_present_address:auditdata.number_of_years_present_address || 0,
            number_of_branches: auditdata.number_of_branches || 0,
            years_of_existence_of_business: auditdata.years_of_existence_of_business || 0,
            business_activity_seen: auditdata.business_activity_seen || "",
            business_board_seen: auditdata.business_board_seen || "",
            applicants_name_verified_from: auditdata.applicants_name_verified_from || "",
            contact_verification_1: auditdata.contact_verification_1 || "",
            contact_verification_2: auditdata.contact_verification_2 || "",
            contact_verification_3: auditdata.contact_verification_3 || "",
            address_proof_details: auditdata.address_proof_details || "",
            type_of_property: auditdata.type_of_property || "",
            property_sold: auditdata.property_sold || "",
            remarks_about_purchaser: auditdata.remarks_about_purchaser || "",
            does_applicant_stay_this_address:
              auditdata.does_applicant_stay_this_address || "",
            area_sq: auditdata.area_sq || "",
            document_verified: auditdata.document_verified || "",
            cooperative_applicant: auditdata.cooperative_applicant || "",
            ambience: auditdata.ambience || "",
            dependent_members: auditdata.dependent_members || 0,
            dependent_members_child:auditdata.dependent_members_child || 0,
            vehicle_model: auditdata.vehicle_model || "",
            loanamount: auditdata.loanamount || 0,
            dma: auditdata.dma || "",
            rm_name: auditdata.rm_name || "",
            location: auditdata.location || "",
            co_applcant_name: auditdata.co_applcant_name || "",
            guarntor_name: auditdata.guarntor_name || "",
            pick_up_criteria: auditdata.pick_up_criteria || "",
            verification_type: auditdata.verification_type || "",
            status: auditdata.status || 0,
            reason_for_rejection: auditdata.reason_for_rejection || "",
            discrepant_document: auditdata.discrepant_document || "",
            discrepant_profile: auditdata.discrepant_profile || "",
            negative: auditdata.negative || "",
            sourcing_issues: auditdata.sourcing_issues || "",
            end_use: auditdata.end_use || "",
            neighbour_check: auditdata.neighbour_check || "",
            branch_name: auditdata.branch_name || "",
            date_of_receipt: auditdata.date_of_receipt || new Date(),
            ref_no: auditdata.file_refference_no || "",
            reported_by: auditdata.reported_by || "",
            bank_name: auditdata.bank_name || "",
            account_no: auditdata.account_no || "",
            account_opening_date: auditdata.account_opening_date || "",
            final_remarks: auditdata.final_remarks || "",
            reportedBy: auditdata.reportedBy || "",
            panno: auditdata.panno || "",
            finalRemarks: auditdata.finalRemarks || "",
            date_of_report: auditdata.date_of_report || new Date(),
            acknowledgement_no: auditdata.acknowledgement_no || "",
            quotation_issued_by: auditdata.quotation_issued_by || "",
            quotation_issued_date: auditdata.quotation_issued_date || new Date(),
            contact_person_name: auditdata.contact_person_name || "",
            employer_name: auditdata.employer_name || "",
            employer_address: auditdata.employer_address || "",
            verified_from_whom: auditdata.verified_from_whom || "",
            residence_stability: auditdata.residence_stability || "",
            doc_status:auditdata.doc_status || "",
            quotationdata:auditdata.quotationdata || [],
            assessmentYears:auditdata.assessmentyears || [],
            assessmentYearsitr:auditdata.assessmentyearsitr || [],
            salaryDetails:auditdata.salarydetails || [],
            transactions:auditdata.transactions || [],
            financialYears:auditdata.financialyears || [],
            occupation_details:auditdata.occupation_details || "",
            document_type:auditdata.document_type || "",
            reporting_manager_contact_number:auditdata.reporting_manager_contact_number || "",
            no_of_years_with_present_owner :auditdata.no_of_years_with_present_owner || "",
            deviation:auditdata.deviation || "",
            a_company_name : auditdata.a_company_name || "",
            audit_date:auditdata.audit_date,
            audit_time:auditdata.audit_time
            
        });
        setAssessmentYearsitr(auditdata.assessmentyearsitr || [])
        setSalaryDetails(auditdata.salarydetails || [])
        setTransactions(auditdata.transactions || [])
        setFinancialYears(auditdata.financialyears || [])
        setAssessmentYears(auditdata.assessmentyears || [])
        setauditdata(auditdata);
        setopenverification_type(auditdata.verification_type);
         setopenopendocument_type(auditdata.document_type);
        //console.log("data data data", data);
        console.log('Setting dialog ready');
     
          setDialogReady(true);
         
      
         }else{
          //alert(data.status)
          setFormData({
            product_type: data.product_type || "",
            case_id: data.case_id || "",
            applicant_name: data.applicant_name || "",
            applicant_number: data.applicant_number?.mobile_no || "",
            company_name: data.company_name || "",
            address: `${data.applicant_current_address?.address || ""} ${
              data.applicant_current_address?.pincode || ""
            }`,
            client_name: data.client_name || "",
            i_date: data.i_date || new Date(),
            u_date: data.u_date || new Date(),
            c_date: data.c_date || new Date(),
            i_time:  data.i_time != null ? convertTo12HourFormat(data.i_time.split('.')[0]) : '' || '',
            u_time:  data.c_time!= null ? convertTo12HourFormat(data.c_time.split('.')[0]) : '' || '',
            c_time:  data.c_time != null ? convertTo12HourFormat(data.c_time.split('.')[0]) : '' || '',
            overallstatus: data.overallstatus || "",
            reasonfornegativefi: data.reasonfornegativefi || "",
            remarks: data.remarks || "",
            name_of_person_met: data.name_of_person_met || "",
            relation_with_applicant: data.relation_with_applicant || "",
            applicant_age: data.applicant_age || 0,
            educational_qualification: data.educational_qualification || "",
            time_of_stay_current_address: data.time_of_stay_current_address || "",
            total_family_members: data.total_family_members || 0,
            earning_members: data.earning_members || 0,
            retired_pensioner_member: data.retired_pensioner_member || 0,
            marital_status: data.marital_status || "",
            spouse_earning: data.spouse_earning || "",
            spouse_employment_details: data.spouse_employment_details || "",
            applicant_stay_at_address: data.time_of_stay_current_address || "",
            type_of_accommodation: data.type_of_residence || "",
            type_of_house: data.type_of_house || "",
            house_condition: data.house_condition || "",
            ownership: data.type_of_business_address || "",
            exterior: data.exterior || "",
            type_of_locality: data.type_of_locality || "",
            standard_of_living: data.standard_of_living || "",
            neighbour_name_1: data.neighbour_name_1 || "",
            neighbour_name_2: data.neighbour_name_2 || "",
            neighbour_comment_1: data.neighbour_comment_1 || "",
            address_1: data.address_1 || "",
            address_2: data.address_2 || "",
            neighbour_feedback: data.neighbour_feedback || "",
            ease_to_locate: data.ease_to_locate || "",
            vehicle_seen: data.vehicle_seen === null ? "" : data.vehicle_seen ? "Yes" : "No",
            political_link: data.political_link || "",
            latitude: data.latitude || "",
            longitude: data.longitude || "",
            field_agent_name: data.fe_name || "",
            files_name: data.files_name || [],
            type_of_job: data.type_of_employment || "",
            working_as: data.working_as || "",
            office_telephone: data.office_telephone || "",
            landmark: data.landmark || "",
            designation_of_person_met: data.designation_of_person_met || "",
            contact_number: data.applicant_number?.mobile_no || "",
            nature_of_business: data.nature_of_business || "",
            type_of_organisaton: data.type_of_organisaton || "",
            visiting_card_obtained: data.business_card_shared || "",
            time_of_employment_of_applicant:
              data.time_of_employment_of_applicant || "",
            applicant_work: data.applicant_work || "",
            number_of_employees: data.number_of_employees || 0,
            designation_of_applicant: data.designation_of_applicant || "",
            work_transferable: data.work_transferable || "",
            office_name_board_seen: data.office_name_board_seen || data.name_of_company_verified || "",
            report_to: data.applicants_reporting_manager || "",
            manager_designation: data.manager_designation || "",
            reporting_manager: data.reporting_manager || "",
            sallary: data.sallary || "",
            tpc_check: data.tpc_check || "",
            name_of_person_contacted: data.name_of_person_contacted || "",
            number_of_years_present_address:data.number_of_years_present_address || 0,
            number_of_branches: data.number_of_branches || 0,
            years_of_existence_of_business: data.years_of_existence_of_business || 0,
            business_activity_seen: data.business_activity_seen === null ? "" : data.business_activity_seen ? "Yes" : "No",
            business_board_seen: data.business_board_seen === null ? "" : data.business_board_seen ? "Yes" : "No",
            applicants_name_verified_from: data.applicants_name_verified_from || "",
            contact_verification_1: data.contact_verification_1 || "",
            contact_verification_2: data.contact_verification_2 || "",
            contact_verification_3: data.contact_verification_3 || "",
            address_proof_details: data.address_proof_details || "",
            type_of_property: data.type_of_property || "",
            property_sold: data.property_sold || "",
            remarks_about_purchaser: data.remarks_about_purchaser || "",
            does_applicant_stay_this_address:
              data.does_applicant_stay_this_address || "",
            area_sq: data.area_sq || "",
            document_verified: data.document_verified || "",
            cooperative_applicant: data.cooperative_applicant || "",
            ambience: data.ambience || "",
            dependent_members: data.dependent_members || 0,
            dependent_members_child:data.dependent_members_child || 0,
            vehicle_model: data.vehicle_model_verified || "",
            loanamount: data.loanamount || 0,
            dma: data.dma || "",
            rm_name: data.rm_name || "",
            location: data.location || "",
            co_applcant_name: data.co_applcant_name || "",
            guarntor_name: data.guarntor_name || "",
            pick_up_criteria: data.pick_up_criteria || "",
            verification_type: data.verification_type || "",
            status: data.status || 0,
            reason_for_rejection: data.reason_for_rejection || "",
            discrepant_document: data.discrepant_document || "",
            discrepant_profile: data.discrepant_profile || "",
            negative: data.negative || "",
            sourcing_issues: data.sourcing_issues || "",
            end_use: data.end_use_of_vehicle || "",
            neighbour_check: data.neighbour_check || "",
            branch_name: data.branch_name || "",
            date_of_receipt: data.date_of_receipt || new Date(),
            ref_no: data.file_refference_no || "",
            reported_by: a_user || "",
            bank_name: data.bank_name || "",
            account_no: data.account_no || "",
            account_opening_date: data.account_opening_date || "",
            final_remarks: data.final_remarks || "",
            reportedBy: a_user || "",
            panno: data.panno || "",
            finalRemarks: data.finalRemarks || "",
            date_of_report: data.date_of_report || new Date(),
            acknowledgement_no: data.acknowledgement_no || "",
            quotation_issued_by: data.quotation_issued_by || "",
            quotation_issued_date: data.quotation_issued_date || new Date(),
            contact_person_name: data.contact_person_name || "",
            employer_name: data.employer_name || "",
            employer_address: data.employer_address || "",
            verified_from_whom: data.verified_from_whom || "",
            residence_stability: data.residence_stability || "",
            doc_status:data.doc_status || "",
            quotationdata:data.quotationdata || [],
            assessmentYears:data.assessmentyears || [],
            assessmentYearsitr:data.assessmentyearsitr || [],
            salaryDetails:data.salarydetails || [],
            transactions:data.transactions || [],
            financialYears:data.financialyears || [],
            occupation_details:data.occupation_details || "",
            document_type:data.document_type || "",
            reporting_manager_contact_number:data.reporting_manager_contact_number || "",
            no_of_years_with_present_owner:data.no_of_years_with_present_owner || "",
            deviation : (data.deviation !== undefined && data.deviation !== null && !isNaN(data.deviation)) 
                  ? (parseFloat(data.deviation).toFixed(2) + " KMS") 
                  : "",
            a_company_name:data.a_company_name || "",
            audit_date:"",
            audit_time:"",      

        });
       
        setauditdata(data);
        setopenverification_type(data.verification_type);
        setopenopendocument_type(data.document_type);
        //console.log("data data data", data);
        console.log('Setting dialog ready');
        setDialogReady(true);
         }
      })







   
   
  };



  const handlefbChange = (event) => {
    const { name, value, id } = event.target;

    // Split the name to handle nested properties
    const nameParts = name.split(".");

    const updatedRows = financialYears.map((row) => {
      if (row.id === parseInt(id)) {
        // Ensure id is parsed to an integer if it's coming as a string
        let updatedRow = { ...row };

        if (nameParts.length === 1) {
          // If the property is not nested
          updatedRow[name] = value;
        } else if (nameParts.length === 2) {
          // If the property is nested
          const [parent, child] = nameParts;
          updatedRow = {
            ...row,
            [parent]: {
              ...row[parent],
              [child]: value,
            },
          };
        }

        return updatedRow;
      }
      return row;
    });
    console.log(updatedRows)

    setFinancialYears(updatedRows);
  };

  const handlebsChange = (event) => {
    const { name, value, id } = event.target;

    // Split the name to handle nested properties
    const nameParts = name.split(".");

    const updatedRows = transactions.map((row) => {
      if (row.id === parseInt(id)) {
        // Ensure id is parsed to an integer if it's coming as a string
        let updatedRow = { ...row };

        if (nameParts.length === 1) {
          // If the property is not nested
          updatedRow[name] = value;
        } else if (nameParts.length === 2) {
          // If the property is nested
          const [parent, child] = nameParts;
          updatedRow = {
            ...row,
            [parent]: {
              ...row[parent],
              [child]: value,
            },
          };
        }

        return updatedRow;
      }
      return row;
    });

    setTransactions(updatedRows);
  };

  const handletirChange = (event) => {
    const { name, value, id } = event.target;

    // Split the name to handle nested properties
    const nameParts = name.split(".");

    const updatedRows = assessmentYearsitr.map((row) => {
      if (row.id === parseInt(id)) {
        // Ensure id is parsed to an integer if it's coming as a string
        let updatedRow = { ...row };

        if (nameParts.length === 1) {
          // If the property is not nested
          updatedRow[name] = value;
        } else if (nameParts.length === 2) {
          // If the property is nested
          const [parent, child] = nameParts;
          updatedRow = {
            ...row,
            [parent]: {
              ...row[parent],
              [child]: value,
            },
          };
        }

        return updatedRow;
      }
      return row;
    });

    setAssessmentYearsitr(updatedRows);
  };
  const getSortIndicator = (columnIndex) => {
    if (sortedColumn === columnIndex) {
      return sortOrder === 'asc' ? ' ▲' : ' ▼';
    }
    return null;
  };
  const handleqaChange = (event) => {
    const { name, value, id } = event.target;

    // Split the name to handle nested properties
    const nameParts = name.split(".");

    const updatedRows = quotationdata.map((row) => {
      if (row.id === parseInt(id)) {
        // Ensure id is parsed to an integer if it's coming as a string
        let updatedRow = { ...row };

        if (nameParts.length === 1) {
          // If the property is not nested
          updatedRow[name] = value;
        } else if (nameParts.length === 2) {
          // If the property is nested
          const [parent, child] = nameParts;
          updatedRow = {
            ...row,
            [parent]: {
              ...row[parent],
              [child]: value,
            },
          };
        }

        return updatedRow;
      }
      return row;
    });

    setquotationdata(updatedRows);
  };


  const handleSort = (id) => {
    //console.log(typeof id);
   // setActiveIndexop(index)
   setSortedColumn(id);
  
   if (sortOrder === 'asc') {
     setSortOrder('desc');
   } else {
     setSortOrder('asc');
   }
  let oparray =  [...data]
  
    switch(id){
      case 1:{
        if(sortOrder == 'asc'){
          oparray.sort((a, b) => {
            //console.log(a)
            const itemA = a['case_id'] ? a['case_id'].toUpperCase() : '';
            const itemB = b['case_id'] ? b['case_id'].toUpperCase() : '';
          
            if (itemA === null && itemB === null) {
              return 0; // Both are null, consider them equal
            }
            
            if (itemA === null) {
              return -1; // itemA is null, move it before itemB
            }
          
            if (itemB === null) {
              return 1; // itemB is null, move it before itemA
            }
          
            if (itemA < itemB) {
              return -1;
            }
            if (itemA > itemB) {
              return 1;
            }
            return 0;
          });
          setdata(oparray)
        } else{
          
          oparray.sort((a, b) => {
            const itemA = a['case_id'] ? a['case_id'].toUpperCase() : null;
            const itemB = b['case_id'] ? b['case_id'].toUpperCase() : null;
          
            if (itemA === null && itemB === null) {
              return 0; // Both are null, consider them equal
            }
            
            if (itemA === null) {
              return 1; // itemA is null, move it after itemB
            }
          
            if (itemB === null) {
              return -1; // itemB is null, move it after itemA
            }
          
            if (itemA < itemB) {
              return 1;
            }
            if (itemA > itemB) {
              return -1;
            }
            return 0;
          });
          
          setdata(oparray)
        }
  
        break;
      }
      case 2:{
        if(sortOrder == 'asc'){
          oparray.sort((a, b) => {
            //console.log(a)
            const itemA = a['applicant_name'] ? a['applicant_name'].toUpperCase() : '';
            const itemB = b['applicant_name'] ? b['applicant_name'].toUpperCase() : '';
          
            if (itemA === null && itemB === null) {
              return 0; // Both are null, consider them equal
            }
            
            if (itemA === null) {
              return -1; // itemA is null, move it before itemB
            }
          
            if (itemB === null) {
              return 1; // itemB is null, move it before itemA
            }
          
            if (itemA < itemB) {
              return -1;
            }
            if (itemA > itemB) {
              return 1;
            }
            return 0;
          });
          setdata(oparray)
        } else{
          
          oparray.sort((a, b) => {
            const itemA = a['applicant_name'] ? a['applicant_name'].toUpperCase() : null;
            const itemB = b['applicant_name'] ? b['applicant_name'].toUpperCase() : null;
          
            if (itemA === null && itemB === null) {
              return 0; // Both are null, consider them equal
            }
            
            if (itemA === null) {
              return 1; // itemA is null, move it after itemB
            }
          
            if (itemB === null) {
              return -1; // itemB is null, move it after itemA
            }
          
            if (itemA < itemB) {
              return 1;
            }
            if (itemA > itemB) {
              return -1;
            }
            return 0;
          });
          
          setdata(oparray)
        }
  
        break;
      }
      case 3:{
       
        if(sortOrder == 'asc'){
          oparray.sort((a, b) => {
            //console.log(a)
            const itemA = a['applicant_current_address']['area'] ? a['applicant_current_address']['area'].toUpperCase() : '';
            const itemB = b['applicant_current_address']['area'] ? b['applicant_current_address']['area'].toUpperCase() : '';
          
            if (itemA === null && itemB === null) {
              return 0; // Both are null, consider them equal
            }
            
            if (itemA === null) {
              return -1; // itemA is null, move it before itemB
            }
          
            if (itemB === null) {
              return 1; // itemB is null, move it before itemA
            }
          
            if (itemA < itemB) {
              return -1;
            }
            if (itemA > itemB) {
              return 1;
            }
            return 0;
          });
          setdata(oparray)
        } else{
          
          oparray.sort((a, b) => {
            const itemA = a['applicant_current_address']['area'] ? a['applicant_current_address']['area'].toUpperCase() : null;
            const itemB = b['applicant_current_address']['area'] ? b['applicant_current_address']['area'].toUpperCase() : null;
          
            if (itemA === null && itemB === null) {
              return 0; // Both are null, consider them equal
            }
            
            if (itemA === null) {
              return 1; // itemA is null, move it after itemB
            }
          
            if (itemB === null) {
              return -1; // itemB is null, move it after itemA
            }
          
            if (itemA < itemB) {
              return 1;
            }
            if (itemA > itemB) {
              return -1;
            }
            return 0;
          });
          
          setdata(oparray)
        }
        break;
      }
      case 4:{
       
        if(sortOrder == 'asc'){
          oparray.sort((a, b) => {
            //console.log(a)
            const itemA = a['verification_type'] ? a['verification_type'].toUpperCase() : '';
            const itemB = b['verification_type'] ? b['verification_type'].toUpperCase() : '';
          
            if (itemA === null && itemB === null) {
              return 0; // Both are null, consider them equal
            }
            
            if (itemA === null) {
              return -1; // itemA is null, move it before itemB
            }
          
            if (itemB === null) {
              return 1; // itemB is null, move it before itemA
            }
          
            if (itemA < itemB) {
              return -1;
            }
            if (itemA > itemB) {
              return 1;
            }
            return 0;
          });
          setdata(oparray)
        } else{
          
          oparray.sort((a, b) => {
            const itemA = a['verification_type'] ? a['verification_type'].toUpperCase() : null;
            const itemB = b['verification_type'] ? b['verification_type'].toUpperCase() : null;
          
            if (itemA === null && itemB === null) {
              return 0; // Both are null, consider them equal
            }
            
            if (itemA === null) {
              return 1; // itemA is null, move it after itemB
            }
          
            if (itemB === null) {
              return -1; // itemB is null, move it after itemA
            }
          
            if (itemA < itemB) {
              return 1;
            }
            if (itemA > itemB) {
              return -1;
            }
            return 0;
          });
          
          setdata(oparray)
        }
        break;
      }
      case 5:{
       
        if(sortOrder == 'asc'){
                 oparray.sort((a, b) => {
                  //console.log(a[id])
                     const itemA = a['applicant_number']['mobile_no'] !== undefined ? a['applicant_number']['mobile_no'] : null;
                     const itemB = b['applicant_number']['mobile_no'] !== undefined ? b['applicant_number']['mobile_no'] : null;
                 
                   if (itemA === null && itemB === null) {
                     return 0; // Both are null, consider them equal
                   }
                   
                   if (itemA === null) {
                     return -1; // itemA is null, move it before itemB
                   }
                 
                   if (itemB === null) {
                     return 1; // itemB is null, move it before itemA
                   }
                 
                   if (itemA < itemB) {
                     return -1;
                   }
                   if (itemA > itemB) {
                     return 1;
                   }
                   return 0;
                 });
                 //console.log(oparray)
                 setdata(oparray)
               } else{
                 // //alert('d')
                 oparray.sort((a, b) => {
                     const itemA = a['applicant_number']['mobile_no'] !== undefined ? a['applicant_number']['mobile_no'] : null;
                     const itemB = b['applicant_number']['mobile_no'] !== undefined ? b['applicant_number']['mobile_no'] : null;
                 
                   if (itemA === null && itemB === null) {
                     return 0; // Both are null, consider them equal
                   }
                   
                   if (itemA === null) {
                     return 1; // itemA is null, move it after itemB
                   }
                 
                   if (itemB === null) {
                     return -1; // itemB is null, move it after itemA
                   }
                 
                   if (itemA < itemB) {
                     return 1;
                   }
                   if (itemA > itemB) {
                     return -1;
                   }
                   return 0;
                 });
                 
          
                 //console.log(oparray)
                 setdata(oparray)
               }
            
        break;
      }
  
      case 6:{
        if(sortOrder == 'asc'){
          oparray.sort((a, b) => {
            //console.log(a)
            const itemA = a['fe_id'] ? a['fe_id'].toUpperCase() : '';
            const itemB = b['fe_id'] ? b['fe_id'].toUpperCase() : '';
          
            if (itemA === null && itemB === null) {
              return 0; // Both are null, consider them equal
            }
            
            if (itemA === null) {
              return -1; // itemA is null, move it before itemB
            }
          
            if (itemB === null) {
              return 1; // itemB is null, move it before itemA
            }
          
            if (itemA < itemB) {
              return -1;
            }
            if (itemA > itemB) {
              return 1;
            }
            return 0;
          });
          setdata(oparray)
        } else{
          
          oparray.sort((a, b) => {
            const itemA = a['fe_id'] ? a['fe_id'].toUpperCase() : null;
            const itemB = b['fe_id'] ? b['fe_id'].toUpperCase() : null;
          
            if (itemA === null && itemB === null) {
              return 0; // Both are null, consider them equal
            }
            
            if (itemA === null) {
              return 1; // itemA is null, move it after itemB
            }
          
            if (itemB === null) {
              return -1; // itemB is null, move it after itemA
            }
          
            if (itemA < itemB) {
              return 1;
            }
            if (itemA > itemB) {
              return -1;
            }
            return 0;
          });
          
          setdata(oparray)
        }
  
        break;
      }
  
      case 7:{
       
        if(sortOrder == 'asc'){
                 oparray.sort((a, b) => {
                  //console.log(a[id])
                     const itemA = a['reassign_count'] !== undefined ? a['reassign_count'] : null;
                     const itemB = b['reassign_count'] !== undefined ? b['reassign_count'] : null;
                 
                   if (itemA === null && itemB === null) {
                     return 0; // Both are null, consider them equal
                   }
                   
                   if (itemA === null) {
                     return -1; // itemA is null, move it before itemB
                   }
                 
                   if (itemB === null) {
                     return 1; // itemB is null, move it before itemA
                   }
                 
                   if (itemA < itemB) {
                     return -1;
                   }
                   if (itemA > itemB) {
                     return 1;
                   }
                   return 0;
                 });
                 //console.log(oparray)
                 setdata(oparray)
               } else{
                 // //alert('d')
                 oparray.sort((a, b) => {
                     const itemA = a['reassign_count'] !== undefined ? a['reassign_count'] : null;
                     const itemB = b['reassign_count'] !== undefined ? b['reassign_count'] : null;
                 
                   if (itemA === null && itemB === null) {
                     return 0; // Both are null, consider them equal
                   }
                   
                   if (itemA === null) {
                     return 1; // itemA is null, move it after itemB
                   }
                 
                   if (itemB === null) {
                     return -1; // itemB is null, move it after itemA
                   }
                 
                   if (itemA < itemB) {
                     return 1;
                   }
                   if (itemA > itemB) {
                     return -1;
                   }
                   return 0;
                 });
                 
          
                 //console.log(oparray)
                 setdata(oparray)
               }
            
        break;
      }
      case 8:{
        if (sortOrder === 'asc') {
          oparray.sort((a, b) => {
            // console.log(a['c_date'])
            const dateA = new Date(a['i_date']);
            const dateB = new Date(b['i_date']);
        
            // Check for null or invalid dates
            if (isNaN(dateA.getTime()) && isNaN(dateB.getTime())) {
              return 0; // Both are invalid dates, consider them equal
            }
        
            if (isNaN(dateA.getTime())) {
              return -1; // dateA is invalid, move it before dateB
            }
        
            if (isNaN(dateB.getTime())) {
              return 1; // dateB is invalid, move it before dateA
            }
        
            return dateA - dateB; // Compare dates directly for ascending order
          });
          setdata(oparray);
        } else {
          oparray.sort((a, b) => {
            const dateA = new Date(a['i_date']);
            const dateB = new Date(b['i_date']);
        
            // Check for null or invalid dates
            if (isNaN(dateA.getTime()) && isNaN(dateB.getTime())) {
              return 0; // Both are invalid dates, consider them equal
            }
        
            if (isNaN(dateA.getTime())) {
              return -1; // dateA is invalid, move it before dateB
            }
        
            if (isNaN(dateB.getTime())) {
              return 1; // dateB is invalid, move it before dateA
            }
        
            return dateB - dateA; // Compare dates directly for descending order
          });
          setdata(oparray);
        }
        
  
        break;
      }
      case 9: {
        const parseDate = (dateStr) => {
          const [day, month, year] = dateStr.split('/');
          return new Date(`${year}-${month}-${day}`); // Convert to "YYYY-MM-DD" format for Date object
        };
      
        const sortedArray = [...oparray].sort((a, b) => {
          const dateA = parseDate(a['audit_date']);
          const dateB = parseDate(b['audit_date']);
      
          // Check for invalid dates
          if (isNaN(dateA.getTime()) && isNaN(dateB.getTime())) {
            return 0; // Both are invalid dates, consider them equal
          }
      
          if (isNaN(dateA.getTime())) {
            return -1; // dateA is invalid, move it before dateB
          }
      
          if (isNaN(dateB.getTime())) {
            return 1; // dateB is invalid, move it before dateA
          }
      
          // Sort based on sortOrder
          return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
        });
      
        setdata(sortedArray);
        break;
      }
      
      case 10:{
        if(sortOrder == 'asc'){
          oparray.sort((a, b) => {
            //console.log(a)
            const itemA = a['client_name'] ? a['client_name'].toUpperCase() : '';
            const itemB = b['client_name'] ? b['client_name'].toUpperCase() : '';
          
            if (itemA === null && itemB === null) {
              return 0; // Both are null, consider them equal
            }
            
            if (itemA === null) {
              return -1; // itemA is null, move it before itemB
            }
          
            if (itemB === null) {
              return 1; // itemB is null, move it before itemA
            }
          
            if (itemA < itemB) {
              return -1;
            }
            if (itemA > itemB) {
              return 1;
            }
            return 0;
          });
          setdata(oparray)
        } else{
          
          oparray.sort((a, b) => {
            const itemA = a['client_name'] ? a['client_name'].toUpperCase() : null;
            const itemB = b['client_name'] ? b['client_name'].toUpperCase() : null;
          
            if (itemA === null && itemB === null) {
              return 0; // Both are null, consider them equal
            }
            
            if (itemA === null) {
              return 1; // itemA is null, move it after itemB
            }
          
            if (itemB === null) {
              return -1; // itemB is null, move it after itemA
            }
          
            if (itemA < itemB) {
              return 1;
            }
            if (itemA > itemB) {
              return -1;
            }
            return 0;
          });
          
          setdata(oparray)
        }
      }
      default:{
  
      }
  
    }
  
  
  
  
  
  
  
  // if(id == 7){
  //  if(sortOrder == 'asc'){
  //    oparray.sort((a, b) => {
  //      const dateA = new Date(`${a[id].split("-")[2]}-${a[id].split("-")[1]}-${a[id].split("-")[0]}`);
  //      const dateB = new Date(`${b[id].split("-")[2]}-${b[id].split("-")[1]}-${b[id].split("-")[0]}`);
  //      // //console.log(dateA)
  //      //  //alert(dateA)
  
  //      // Check for null or invalid dates
  //      if (isNaN(dateA.getTime()) && isNaN(dateB.getTime())) {
  //        return 0; // Both are invalid dates, consider them equal
  //      }
     
  //      if (isNaN(dateA.getTime())) {
  //        return -1; // dateA is invalid, move it before dateB
  //      }
     
  //      if (isNaN(dateB.getTime())) {
  //        return 1; // dateB is invalid, move it before dateA
  //      }
     
  //      return dateA - dateB; // Compare dates directly
  //    });
     
  //    setdata(oparray)
  //  } else{
  //    oparray.sort((a, b) => {
  //      const dateA = new Date(`${a[id].split("-")[2]}-${a[id].split("-")[1]}-${a[id].split("-")[0]}`);
  //      const dateB = new Date(`${b[id].split("-")[2]}-${b[id].split("-")[1]}-${b[id].split("-")[0]}`);
     
  //      if (isNaN(dateA.getTime()) && isNaN(dateB.getTime())) {
  //        return 0; // Both are invalid dates, consider them equal
  //      }
     
  //      if (isNaN(dateA.getTime())) {
  //        return -1; // dateA is invalid, move it before dateB
  //      }
     
  //      if (isNaN(dateB.getTime())) {
  //        return 1; // dateB is invalid, move it before dateA
  //      }
     
  //      return dateB - dateA; // Compare dates directly for descending order
  //    });
     
     
  //    setdata(oparray)
  //  }
  // }else if(id == 12 ){
  // //console.log('hi')
  //    if(sortOrder == 'asc'){
  //      oparray.sort((a, b) => {
  //        const convertToSortableTime = (timeString) => {
  //          const [time, period] = timeString.split(' ');
  //          const [hours, minutes] = time.split(':');
  //          const formattedHours = period === 'AM' ? hours : parseInt(hours, 10) + 12;
  //          return `${formattedHours.toString().padStart(2, '0')}:${minutes}`;
  //        };
       
  //        const sortableTimeA = convertToSortableTime(a[id]);
  //        const sortableTimeB = convertToSortableTime(b[id]);
       
  //        return sortableTimeA.localeCompare(sortableTimeB);
  //      });
       
       
       
       
  //      setdata(oparray)
  //    } else{
  //      oparray.sort((a, b) => {
  //        const convertToSortableTime = (timeString) => {
  //          const [time, period] = timeString.split(' ');
  //          const [hours, minutes] = time.split(':');
  //          const formattedHours = period === 'AM' ? hours : parseInt(hours, 10) + 12;
  //          return `${formattedHours.toString().padStart(2, '0')}:${minutes}`;
  //        };
       
  //        const sortableTimeA = convertToSortableTime(a[id]);
  //        const sortableTimeB = convertToSortableTime(b[id]);
       
  //        return sortableTimeB.localeCompare(sortableTimeA);
  //      });
       
       
  //      setdata(oparray)
  //    }
  
  // }
  
  // else{
  //   if(id !== 4 && id !== 6){
      
  //    if(sortOrder == 'asc'){
  //        oparray.sort((a, b) => {
  //          const itemA = a[id] ? a[id].toUpperCase() : '';
  //          const itemB = b[id] ? b[id].toUpperCase() : '';
         
  //          if (itemA === null && itemB === null) {
  //            return 0; // Both are null, consider them equal
  //          }
           
  //          if (itemA === null) {
  //            return -1; // itemA is null, move it before itemB
  //          }
         
  //          if (itemB === null) {
  //            return 1; // itemB is null, move it before itemA
  //          }
         
  //          if (itemA < itemB) {
  //            return -1;
  //          }
  //          if (itemA > itemB) {
  //            return 1;
  //          }
  //          return 0;
  //        });
  //        setdata(oparray)
  //      } else{
         
  //        oparray.sort((a, b) => {
  //          const itemA = a[id] ? a[id].toUpperCase() : null;
  //          const itemB = b[id] ? b[id].toUpperCase() : null;
         
  //          if (itemA === null && itemB === null) {
  //            return 0; // Both are null, consider them equal
  //          }
           
  //          if (itemA === null) {
  //            return 1; // itemA is null, move it after itemB
  //          }
         
  //          if (itemB === null) {
  //            return -1; // itemB is null, move it after itemA
  //          }
         
  //          if (itemA < itemB) {
  //            return 1;
  //          }
  //          if (itemA > itemB) {
  //            return -1;
  //          }
  //          return 0;
  //        });
         
  //        setdata(oparray)
  //      }
  //   } else{
  //     // //alert('d')
  //    if(sortOrder == 'asc'){
  //        oparray.sort((a, b) => {
  //         //console.log(a[id])
  //            const itemA = a[id] !== undefined ? a[id] : null;
  //            const itemB = b[id] !== undefined ? b[id] : null;
         
  //          if (itemA === null && itemB === null) {
  //            return 0; // Both are null, consider them equal
  //          }
           
  //          if (itemA === null) {
  //            return -1; // itemA is null, move it before itemB
  //          }
         
  //          if (itemB === null) {
  //            return 1; // itemB is null, move it before itemA
  //          }
         
  //          if (itemA < itemB) {
  //            return -1;
  //          }
  //          if (itemA > itemB) {
  //            return 1;
  //          }
  //          return 0;
  //        });
  //        //console.log(oparray)
  //        setdata(oparray)
  //      } else{
  //        // //alert('d')
  //        oparray.sort((a, b) => {
  //            const itemA = a[id] !== undefined ? a[id] : null;
  //            const itemB = b[id] !== undefined ? b[id] : null;
         
  //          if (itemA === null && itemB === null) {
  //            return 0; // Both are null, consider them equal
  //          }
           
  //          if (itemA === null) {
  //            return 1; // itemA is null, move it after itemB
  //          }
         
  //          if (itemB === null) {
  //            return -1; // itemB is null, move it after itemA
  //          }
         
  //          if (itemA < itemB) {
  //            return 1;
  //          }
  //          if (itemA > itemB) {
  //            return -1;
  //          }
  //          return 0;
  //        });
         
  
  //        //console.log(oparray)
  //        setdata(oparray)
  //      }
  //   }
  //  }
  
  
   // Handle sorting based on the selected option (asc or desc)
   //console.log('Sorting order:', id);
   // Add logic to perform sorting
  };


  const handlessChange = (event) => {
    const { name, value, id } = event.target;

    // Split the name to handle nested properties
    const nameParts = name.split(".");

    const updatedRows = salaryDetails.map((row) => {
      if (row.id === parseInt(id)) {
        // Ensure id is parsed to an integer if it's coming as a string
        let updatedRow = { ...row };

        if (nameParts.length === 1) {
          // If the property is not nested
          updatedRow[name] = value;
        } else if (nameParts.length === 2) {
          // If the property is nested
          const [parent, child] = nameParts;
          updatedRow = {
            ...row,
            [parent]: {
              ...row[parent],
              [child]: value,
            },
          };
        }

        return updatedRow;
      }
      return row;
    });
    //console.log('updatedRows',updatedRows)
    setSalaryDetails(updatedRows);
  };

  const handlef16Change = (event) => {
    const { name, value, id } = event.target;

    // Split the name to handle nested properties
    const nameParts = name.split(".");

    const updatedRows = assessmentYears.map((row) => {
      if (row.id === parseInt(id)) {
        // Ensure id is parsed to an integer if it's coming as a string
        let updatedRow = { ...row };

        if (nameParts.length === 1) {
          // If the property is not nested
          updatedRow[name] = value;
        } else if (nameParts.length === 2) {
          // If the property is nested
          const [parent, child] = nameParts;
          updatedRow = {
            ...row,
            [parent]: {
              ...row[parent],
              [child]: value,
            },
          };
        }

        return updatedRow;
      }
      return row;
    });

    setAssessmentYears(updatedRows);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
   
 
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };
  const handleChanged = (e, key) => {
    console.log(key,e)
    // const { name, innerText,id } = e.target;
   
    // var key = id.split('-')[0]
      // console.log("key", innerText);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: e.label
    }));
  };




  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(xldata);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "report.xlsx");
  };

  const handleSaveChanges = async () => {
       //alert(formData.status)
    setloadopen1(true)
 
    //console.log("formData", quotationdata);
    const token = localStorage.getItem("token");
    formData['salaryDetails'] = salaryDetails;
    formData['assessmentYears'] = assessmentYears;
    formData['assessmentYearsitr'] = assessmentYearsitr;
    formData['quotationdata'] = quotationdata;
    formData['transactions'] = transactions;
    formData['financialYears'] = financialYears;
    formData['a_user'] = a_user
    
      const apiKey = 'AIzaSyDnUfRzHWPaBBgG9n3byhr3yY2MGih8Q0E';
      const newAddresses = {};
      if(formData.files_name){
        for (const im of formData.files_name) {
          console.log('latitude', im.latitude)
          console.log('longitude', im.longitude)
          const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${im.latitude},${im.longitude}&key=${apiKey}`;
          try {
            const response = await axios.get(geocodeUrl);
            if (response.data.results && response.data.results.length > 0) {
              const addressComponents = response.data.results[0].address_components;
          
              let district = 'null';
              let city = 'null';
          
              addressComponents.forEach(component => {
                if (component.types.includes('administrative_area_level_2')) {
                  district = component.long_name;
                }
                if (component.types.includes('locality') || component.types.includes('administrative_area_level_1')) {
                  city = component.long_name;
                }
              });
          
              newAddresses[im.filepath] = {
                formattedAddress: response.data.results[0].formatted_address,
                district: district,
                city: city,
              };
            } else {
              newAddresses[im.filepath] = {
                formattedAddress: 'null',
                district: 'null',
                city: 'null',
              };
            }
          } catch (error) {
            console.error('Error fetching address:', error);
            newAddresses[im.filepath] = {
              formattedAddress: 'null',
              district: 'null',
              city: 'null',
            };
          }
        }
        formData['mapaddress'] = newAddresses;
      }
     
    // //console.log('hi')
    let headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("token", token);
    const requestOptions1 = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ auditdata: formData }),
    };
    fetch(`${URL}/insertaudit`, requestOptions1)
      .then((response) => response.json())
      .then((data) => {
        //console.log("daaaaaaaaaaaaaa", data);
        if (data.result) {
          setloadopen1(false)
           setopend(true)
          // //alert("Audit Successfully Completed");
          
        }
      });
  };
  // useEffect(async () => {
  //   const a_user = localStorage.getItem("name");
  //   seta_user(a_user)
  // },[]);


  useEffect(() => {
    if (dialogReady) {
      //  //alert(openverification_type)
      const timer = setTimeout(() => {
        switch (openverification_type) {
          case "Residence": {
            setopenrcd(true);
            break;
          }
          case "Employment": {
            setopenempd(true);
            break;
          }
          case "Business": {
            setopenbusd(true);
            break;
          }
          case "Property": {
            setopenppd(true);
            break;
          }
          case "Asset": {
            setopenastd(true);
            break;
          }
          case "Document": {
            // //alert('quotation')
            switch (opendocument_type) {
              case "Bank Statement": {
                setopebstd(true);
                break;
              }
              case "Financial And Balance Sheet": {
                setopenfbd(true);
                break;
              }
              case "Form-16": {
                setopenf16d(true);
                break;
              }
              case "ITR": {
                setopenitrd(true);
                break;
              }
              case "Quotation": {
                // //alert('quotation')
                setopenqtd(true);
                break;
              }
              case "Salary Slip": {
                setopenssd(true);
                break;
              }
              default: {
                ////console.log(type);
              }
            }
          }
          default: {
            ////console.log(type);
          }
        }

        setDialogReady(false); // Reset dialogReady after opening the dialog
      }, 1000);

      // Cleanup the timer if the component is unmounted before the timeout
      return () => clearTimeout(timer);
    }
  }, [dialogReady]);

  // const handleClose1 = () => {
  //   setOpen(false);
  // };
  const handleClose1 = (event, reason) => {
    if (reason !== "backdropClick") {
      // handleClose();
      setOpen(false);
    }
  };
  const handleCloseemp = (event, reason) => {
    if (reason !== "backdropClick") {
      // handleClose();
      setopenempd(false);
    }
  };
  const handleCloseresidence = (event, reason) => {
    if (reason !== "backdropClick") {
      // handleClose();
      setopenrcd(false);
    }
  };
  const handleClosebusiness = (event, reason) => {
    if (reason !== "backdropClick") {
      // handleClose();
      setopenbusd(false);
    }
  };
  const handleCloseproperty = (event, reason) => {
    if (reason !== "backdropClick") {
      // handleClose();
      setopenppd(false);
    }
  };
  const handleCloseasset = (event, reason) => {
    if (reason !== "backdropClick") {
      // handleClose();
      setopenastd(false);
    }
  };
  const handleClosebst = (event, reason) => {
    if (reason !== "backdropClick") {
      // handleClose();
      setopebstd(false);
    }
  };
  const handleClosefb = (event, reason) => {
    if (reason !== "backdropClick") {
      // handleClose();
      setopenfbd(false);
    }
  };
  const handleCloseitr = (event, reason) => {
    if (reason !== "backdropClick") {
      // handleClose();
      setopenitrd(false);
    }
  };
  const handleCloseform16 = (event, reason) => {
    if (reason !== "backdropClick") {
      // handleClose();
      setopenf16d(false);
    }
  };
  const handleClosequatation = (event, reason) => {
    if (reason !== "backdropClick") {
      // handleClose();
      setopenqtd(false);
    }
  };

  const handleClosesallaryslip = (event, reason) => {
    if (reason !== "backdropClick") {
      // handleClose();
      setopenssd(false);
    }
  };

   function closedialog(){
    setopenempd(false)
    setopenrcd(false)
    setopenbusd(false)
    setopenppd(false)
    setopenastd(false)
    setopebstd(false)
    setopenfbd(false)
    setopenitrd(false)
    setopenf16d(false)
    setopenqtd(false)
    setopenssd(false)
    location.reload()
    
   }


  const mdata = {
    productType: "Sample Product",
    caseNumber: "12345",
    applicantName: "John Doe",
    applicantPhone: "123-456-7890",
    companyName: "Sample Company",
    officeTelephone: "123-456-7890",
    address: "123 Main St",
    landmark: "Near Park",
    caseAllocationDate: "2024-01-01",
    visitedAt: "2024-01-05",
    submittedAt: "2024-01-10",
    overallstatus: "Completed",
    reasonfornegativefi: "",
    remarks: "No issues found.",
    personMet: "Jane Smith",
    designationPersonMet: "HR Manager",
    contactNumber: "123-456-7890",
    natureOfBusiness: "Software",
    typeOfOrganisation: "LLP",
    visitingCardObtained: "Yes",
    dateOfJoining: "2020-01-01",
    doesApplicantWork: "Yes",
    designationOfApplicant: "Software Engineer",
    numberOfEmployees: "50",
    typeOfJob: "Full-Time",
    workingAs: "Engineer",
    jobTransferable: "No",
    easeToLocate: "Easy",
    typeOfLocality: "Urban",
    localityLandmark: "Near Mall",
    areaOfOffice: "1000 sqft",
    officePhotoUrl: "https://via.placeholder.com/200x300",
    employeePhotoUrl: "https://via.placeholder.com/200x300",
    signatoryImage: "https://via.placeholder.com/100x50",
  };

  const handleButtonClick = () => {
    const isFirstPage = true;
    PdfContentBusiness({ mdata, isFirstPage });
  };
  const preloadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new window.Image();
      img.src = src;
      img.onload = resolve;
      img.onerror = reject;
    });
  };
  
  const handleDownload = (case_id, type, documenttype) => {
    setloadopen(true)
    const token = localStorage.getItem("token");

    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("token", token);

    const requestOptions1 = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ case_id: case_id }),
    };

    fetch(`${URL}/read_download_applications`, requestOptions1)
      .then((response) => response.json())
      .then(async (data) => {
        //console.log("downloaddata", documenttype);
        if (data) {
          // //console.log('images',data[0].files_name.length)
          // if(data[0].files_name != null && Object.keys(data[0].files_name).length !== 0){
          //   const imageUrls =  data[0].files_name.map(im => `${URL}/uploads/${im.filepath.split('\\')[1]}`);
          //   //console.log('images',imageUrls)
          //   await Promise.all(imageUrls.map(preloadImage));
          // }
          // const simageUrl =  `${URL}/uploads/stamp.png`;
          // //console.log('images',simageUrl)
          // await Promise.all(simageUrl.map(preloadImage));
          switch (type) {
            case "Residence": {
              const doc = <PdfContent_Residence data={data[0]} />;
              const asPdf = pdf([]);
              asPdf.updateContainer(doc);
              setTimeout(async () => {
              const blob = await asPdf.toBlob();
              saveAs(
                blob,
                `${data[0].case_id}_${data[0].applicant_name}_Residence_Verification.pdf`
              );
              setloadopen(false)
            }, 2000);
              break;
            }
            case "Employment": {
              const doc = <PdfContent_Employee data={data[0]} />;
              const asPdf = pdf([]);
              asPdf.updateContainer(doc);
              setTimeout(async () => {
              const blob = await asPdf.toBlob();
              saveAs(
                blob,
                `${data[0].case_id}_${data[0].applicant_name}_Employee_Verification.pdf`
              );
              setloadopen(false)
            }, 2000);
              break;
            }
            case "Business": {
              const doc = <PdfContent_Business data={data[0]} />;
              const asPdf = pdf([]);
              asPdf.updateContainer(doc);
              setTimeout(async () => {
              const blob = await asPdf.toBlob();
              saveAs(
                blob,
                `${data[0].case_id}_${data[0].applicant_name}_Business_Verification.pdf`
              );
              setloadopen(false)
            }, 2000);
              break;
            }
            case "Property": {
              const doc = <PdfContent_Property data={data[0]} />;
              const asPdf = pdf([]);
              asPdf.updateContainer(doc);
              setTimeout(async () => {
              const blob = await asPdf.toBlob();
              saveAs(
                blob,
                `${data[0].case_id}_${data[0].applicant_name}_Property_Verification.pdf`
              );
              setloadopen(false)
            }, 2000);
              break;
            }
            case "Asset": {
              const doc = <PdfContent_Asset data={data[0]} />;
              const asPdf = pdf([]);
              asPdf.updateContainer(doc);
              setTimeout(async () => {
              const blob = await asPdf.toBlob();
              saveAs(
                blob,
                `${data[0].case_id}_${data[0].applicant_name}_Assest_Verification.pdf`
              );
              setloadopen(false)
            }, 2000);
              break;
            }
            case "Document": {
              switch (documenttype) {
                case "Bank Statement": {
                  const doc = <PdfContent_BankStatement data={data[0]} />;
                  const asPdf = pdf([]);
                  asPdf.updateContainer(doc);
                  setTimeout(async () => {
                  const blob = await asPdf.toBlob();
                  saveAs(
                    blob,
                    `${data[0].case_id}_${data[0].applicant_name}_Bank_Statement_Verification.pdf`
                  );
                setloadopen(false)}, 2000);
                  break;
                }
                case "Financial And Balance Sheet": {
                  const doc = <PdfContent_Financial data={data[0]} />;
                  const asPdf = pdf([]);
                  asPdf.updateContainer(doc);
                  setTimeout(async () => {
                  const blob = await asPdf.toBlob();
                  saveAs(
                    blob,
                    `${data[0].case_id}_${data[0].applicant_name}_Financial_Verification.pdf`
                  );
                  setloadopen(false)
                }, 2000);
                  break;
                }
                case "Form-16": {
                  const doc = <PdfContent_Form16 data={data[0]} />;
                  const asPdf = pdf([]);
                  asPdf.updateContainer(doc);
                  setTimeout(async () => {
                  const blob = await asPdf.toBlob();
                  saveAs(
                    blob,
                    `${data[0].case_id}_${data[0].applicant_name}_Form16_Verification.pdf`
                  );
                  setloadopen(false)
                }, 2000);
                  break;
                }
                case "ITR": {
                  const doc = <PdfContent_ITR data={data[0]} />;
                  const asPdf = pdf([]);
                  asPdf.updateContainer(doc);
                  setTimeout(async () => {
                  const blob = await asPdf.toBlob();
                  saveAs(
                    blob,
                    `${data[0].case_id}_${data[0].applicant_name}_ITR_Verification.pdf`
                  );
                  setloadopen(false)
                }, 2000);
                  break;
                }
                case "Quotation": {
                  const doc = (
                    <PdfContent_QuotationVerification data={data[0]} />
                  );
                  const asPdf = pdf([]);
                  asPdf.updateContainer(doc);
                  setTimeout(async () => {
                  const blob = await asPdf.toBlob();
                  saveAs(
                    blob,
                    `${data[0].case_id}_${data[0].applicant_name}_Quotation_Verification.pdf`
                  );
                  setloadopen(false)
                }, 2000);
                  break;
                }
                case "Salary Slip": {
                  const doc = (
                    <PdfContent_SalarySlipVerification data={data[0]} />
                  );
                  const asPdf = pdf([]);
                  asPdf.updateContainer(doc);
                  setTimeout(async () => {
                  const blob = await asPdf.toBlob();
                  saveAs(
                    blob,
                    `${data[0].case_id}_${data[0].applicant_name}_Sallary_Slip_Verification.pdf`
                  );
                  setloadopen(false)
                }, 2000);
                  break;
                }
                default: {
                  ////console.log(type);
                }
              }
            }
            default: {
              ////console.log(type);
            }
          }
        } else {
        }
      })
      .catch((error) => {
        //console.error("Error fetching user details:", error);
      });
  };

  const printReport = useReactToPrint({
    content: (reportType) => {
      switch (reportType) {
        case "Employee":
          return componentRefEmployee.current;
        case "Business":
          return componentRefBusiness.current;
        case "Residence":
          return componentRefResidence.current;
        case "Asset":
          return componentRefAsset.current;
        case "Property":
          return componentRefProperty.current;
        default:
          return componentRefEmployee.current;
      }
    },
  });

  // const overall_status = [
  //   { value: "POSITIVE", label: "POSITIVE" },
  //   { value: "NEGATIVE", label: "NEGATIVE" },
  //   { value: "REFERRAL", label: "REFERRAL" },
   
  // ];
  const [tooltipOpen, setTooltipOpen] = useState();

  const handleCardClick = (index) => {
    const newTooltipOpen = [...tooltipOpen];
    newTooltipOpen[index] = !newTooltipOpen[index];
    setTooltipOpen(newTooltipOpen);
  };
  const handleCloseTooltip = (index) => {
    const newTooltipOpen = [...tooltipOpen];
    newTooltipOpen[index] = false;
    setTooltipOpen(newTooltipOpen);
  };
  const handleClickOpen = () => {
    setdilog(true);
  };

  const handleClose = () => {
    setdilog(false);
    // location.reload()
  };
  const handleloadClose = () => {
    setloadopen(false);
    // location.reload()
  };
  const handleloadClose1 = () => {
    setloadopen1(false);
    // location.reload()
  };
  const handleClosed = () => {
    setopend(false);
    closedialog();
    // location.reload()
  };
  const handelverification = (value) => {
    // //console.log(value.target.value)
    setagent(value);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const a_user = localStorage.getItem("name");
    seta_user(a_user)
    settoken(token);
  }, []);
  useEffect(() => {
    const token = localStorage.getItem("token");
    const key = localStorage.getItem("key");

    if (token) {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("token", token);

      const requestOptions1 = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ key: key }),
      };

      fetch(`${URL}/key_user_details`, requestOptions1)
        .then((response) => response.json())
        .then((data) => {
          //console.log("data", data);
          if (data && data.data.length !== 0) {
            const userLocation = data.data[0]["branch_location"];
            if (userLocation) {
              setUserlocation(userLocation);
              read_data(userLocation);
            } else {
            }
          } else {
          }
        })
        .catch((error) => {
          //console.error("Error fetching user details:", error);
        });
    } else {
    }
  }, []);

const searchstart = ()=>{
  setsearch_start(true)
  search_data()
}
const searchnamestart = ()=>{
  setsearch_start1(true)
  searchname_data()
}
const searchclose = ()=>{
  setsearch_start(false)
  setsearchid("")
  read_data(userLocation)
}
const searchnameclose = ()=>{
  setsearch_start1(false)
  setsearchname("")
  read_data(userLocation)
}
const formatDate = (dateStr) => {
  const date = new Date(dateStr);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month index
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};
const formatDate1 = (dateStr) => {
  if (!dateStr || typeof dateStr !== 'string') {
    return 'Invalid date';
  }

  const parts = dateStr.split('/');
  if (parts.length !== 3) {
    return 'Invalid date format';
  }

  const [day, month, year] = parts;
  return `${day}/${month}/${year}`;
};



  const read_data = (userLocation) => {
    const token = localStorage.getItem("token");
    // //console.log('hi')
    let headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("token", token);
    const requestOptions1 = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ location: userLocation }),
    };
    fetch(`${URL}/read__completed_applications`, requestOptions1)
      .then((response) => response.json())
      .then((data) => {
        console.log('dddddddddddddddddddddddddd', data)
        setdata(data);
        setTooltipOpen(Array(data.length).fill(false));
        const mxldata = data.map((item,index) => ({
          "S.No":index+1,
          "Branch Location":item.branch_location,
          "Client Name": item.client_name,
          "Case Id": item.case_id,
          "REF No": item.file_refference_no,
          "Applicant Name": item.applicant_name,
          "Address": item["applicant_current_address"]["address"],
          "Area":item["applicant_current_address"]["area"],
          "Location Type":item['ocl'] && item['ocl'] == "Yes" ?"OCL":"LOCAL",
          "Product Type":item['product_type'],
          "Verification Type": item.verification_type,
          "Mobile No": item["applicant_number"]["mobile_no"],
          "FE Id": item.fe_id,
          "FE Name": item.fe_name,
          "No.Of Attempts": item.reassign_count,
          "Initiation Date & Time": (item.i_date != null ?  formatDate(item.i_date) : "") + " " + (item.i_time != null ? convertTo12HourFormat(item.i_time.split('.')[0]) : '' || ''),
          "Visited Date & Time": (item.c_date != null ?  formatDate(item.c_date) : "") + " " + (item.c_time != null ? convertTo12HourFormat(item.c_time.split('.')[0]) : '' || ''),
          "Audit Date & Time": (item.audit_date || '')  + " " + (item.audit_time || ''),
          "Completed Date & Time": item.verification_type == "Document" ? ((item.audit_date || '')  + " " + (item.audit_time || '')) :  (item.c_date != null ?  formatDate(item.c_date) : "") + " " + (item.c_time != null ? convertTo12HourFormat(item.c_time.split('.')[0]) : '' || ''),
          "Status": "Completed",
          "Audited By":item.a_user,
          "OverAll Status":item.overallstatus || "",
          "Branch Location":item.branch_location,
          "Remarks":item.remarks
        }));
        setxldata(mxldata);
        setdataloaded(true)
        //console.log("data", data);
      });
  };
  const search_data = () => {
    const token = localStorage.getItem("token");
    console.log(searchid)
    let headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("token", token);
    const requestOptions1 = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ location: userLocation, case_id:searchid }),
    };
    fetch(`${URL}/search__completed_applications`, requestOptions1)
      .then((response) => response.json())
      .then((data) => {
        setdata(data);
        setTooltipOpen(Array(data.length).fill(false));
        const mxldata = data.map((item,index) => ({
          "S.No":index+1,
          "Branch Location":item.branch_location,
          "Client Name": item.client_name,
          "Case Id": item.case_id,
          "REF No": item.file_refference_no,
          "Applicant Name": item.applicant_name,
          "Address": item["applicant_current_address"]["address"],
          "Area":item["applicant_current_address"]["area"],
          "Location Type":item['ocl'] && item['ocl'] == "Yes" ?"OCL":"LOCAL",
          "Product Type":item['product_type'],
          "Verification Type": item.verification_type,
          "Mobile No": item["applicant_number"]["mobile_no"],
          "FE Id": item.fe_id,
          "FE Name": item.fe_name,
          "No.Of Attempts": item.reassign_count,
          "Initiation Date & Time": (item.i_date != null ?  formatDate(item.i_date) : "") + " " + (item.i_time != null ? convertTo12HourFormat(item.i_time.split('.')[0]) : '' || ''),
          "Visited Date & Time": (item.c_date != null ?  formatDate(item.c_date) : "") + " " + (item.c_time != null ? convertTo12HourFormat(item.c_time.split('.')[0]) : '' || ''),
          "Audit Date & Time": (item.audit_date || '')  + " " + (item.audit_time || ''),
          "Completed Date & Time": item.verification_type == "Document" ? ((item.audit_date || '')  + " " + (item.audit_time || '')) :  (item.c_date != null ?  formatDate(item.c_date) : "") + " " + (item.c_time != null ? convertTo12HourFormat(item.c_time.split('.')[0]) : '' || ''),
          "Status": "Completed",
          "Audited By":item.a_user,
          "OverAll Status":item.overallstatus || "",
          "Branch Location":item.branch_location,
          "Remarks":item.remarks
        }));
        setxldata(mxldata);
        setdataloaded(true)
        console.log("data", data);
      });
  };

  const searchname_data = () => {
    const token = localStorage.getItem("token");
    console.log(searchid)
    let headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("token", token);
    const requestOptions1 = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ location: userLocation, name:searchname }),
    };
    fetch(`${URL}/search__completed_applications_by_name`, requestOptions1)
      .then((response) => response.json())
      .then((data) => {
        setdata(data);
        setTooltipOpen(Array(data.length).fill(false));
        const mxldata = data.map((item,index) => ({
          "S.No":index+1,
          "Branch Location":item.branch_location,
          "Client Name": item.client_name,
          "Case Id": item.case_id,
          "REF No": item.file_refference_no,
          "Applicant Name": item.applicant_name,
          "Address": item["applicant_current_address"]["address"],
          "Area":item["applicant_current_address"]["area"],
          "Location Type":item['ocl'] && item['ocl'] == "Yes" ?"OCL":"LOCAL",
          "Product Type":item['product_type'],
          "Verification Type": item.verification_type,
          "Mobile No": item["applicant_number"]["mobile_no"],
          "FE Id": item.fe_id,
          "FE Name": item.fe_name,
          "No.Of Attempts": item.reassign_count,
          "Initiation Date & Time": (item.i_date != null ?  formatDate(item.i_date) : "") + " " + (item.i_time != null ? convertTo12HourFormat(item.i_time.split('.')[0]) : '' || ''),
          "Visited Date & Time": (item.c_date != null ?  formatDate(item.c_date) : "") + " " + (item.c_time != null ? convertTo12HourFormat(item.c_time.split('.')[0]) : '' || ''),
          "Audit Date & Time": (item.audit_date || '')  + " " + (item.audit_time || ''),
          "Completed Date & Time": item.verification_type == "Document" ? ((item.audit_date || '')  + " " + (item.audit_time || '')) :  (item.c_date != null ?  formatDate(item.c_date) : "") + " " + (item.c_time != null ? convertTo12HourFormat(item.c_time.split('.')[0]) : '' || ''),
          "Status": "Completed",
          "Audited By":item.a_user,
          "OverAll Status":item.overallstatus || "",
           "Remarks":item.remarks
        }));
        setxldata(mxldata);
        setdataloaded(true)
        console.log("data", data);
      });
  };
  // useEffect(() => {

  //     }, []);
  const search_data_datewise = () => {
    setSelectedDateRange(tempDateRange);
    setStartDate(tempDateRange[0]);
    setEndDate(tempDateRange[1]);
    const token = localStorage.getItem("token");
    console.log(searchid)
    let headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("token", token);
    const requestOptions1 = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ location: userLocation, start_date:tempDateRange[0], end_date:tempDateRange[1], completed_status:checked, visited_status:checked1 }),
    };
    fetch(`${URL}/search__completed_applications_datewise`, requestOptions1)
      .then((response) => response.json())
      .then((data) => {
        setdata(data);
        setTooltipOpen(Array(data.length).fill(false));
        const mxldata = data.map((item,index) => ({
          "S.No":index+1,
          "Branch Location":item.branch_location,
          "Client Name": item.client_name,
          "Case Id": item.case_id,
          "REF No": item.file_refference_no,
          "Applicant Name": item.applicant_name,
          "Address": item["applicant_current_address"]["address"],
          "Area":item["applicant_current_address"]["area"],
          "Location Type":item['ocl'] && item['ocl'] == "Yes" ?"OCL":"LOCAL",
          "Product Type":item['product_type'],
          "Verification Type": item.verification_type,
          "Mobile No": item["applicant_number"]["mobile_no"],
          "FE Id": item.fe_id,
          "FE Name": item.fe_name,
          "No.Of Attempts": item.reassign_count,
          "Initiation Date & Time": (item.i_date != null ?  formatDate(item.i_date) : "") + " " + (item.i_time != null ? convertTo12HourFormat(item.i_time.split('.')[0]) : '' || ''),
          "Visited Date & Time": (item.c_date != null ?  formatDate(item.c_date) : "") + " " + (item.c_time != null ? convertTo12HourFormat(item.c_time.split('.')[0]) : '' || ''),
          "Audit Date & Time": (item.audit_date || '')  + " " + (item.audit_time || ''),
          "Completed Date & Time": item.verification_type == "Document" ? ((item.audit_date || '')  + " " + (item.audit_time || '')) :  (item.c_date != null ?  formatDate(item.c_date) : "") + " " + (item.c_time != null ? convertTo12HourFormat(item.c_time.split('.')[0]) : '' || ''),
          "Status": "Completed",
          "Audited By":item.a_user,
          "OverAll Status":item.overallstatus || "",
          "Branch Location":item.branch_location,
          "Remarks":item.remarks
        }));
        setxldata(mxldata);
        setdataloaded(true)
        setOpendate(false);
        console.log("data", data);
      });
  };


  const search_data_datewise_visit_completed = () => {
    setSelectedDateRange(tempDateRange);
    setStartDate(tempDateRange[0]);
    setEndDate(tempDateRange[1]);
    const token = localStorage.getItem("token");
    // console.log(searchid)
    let headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("token", token);
    const requestOptions1 = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ location: userLocation, start_date:tempDateRange[0], end_date:tempDateRange[1], completed_status:false, visited_status:true }),
    };
    fetch(`${URL}/search__completed_applications_datewise_visit_completed`, requestOptions1)
      .then((response) => response.json())
      .then((data) => {
        setdata(data);
        setTooltipOpen(Array(data.length).fill(false));
        const mxldata = data.map((item,index) => ({
          "S.No":index+1,
          "Client Name": item.client_name,
          "Case Id": item.case_id,
          "REF No": item.file_refference_no,
          "Applicant Name": item.applicant_name,
          "Address": item["applicant_current_address"]["address"],
          "Area":item["applicant_current_address"]["area"],
          "Location Type":item['ocl'] && item['ocl'] == "Yes" ?"OCL":"LOCAL",
          "Product Type":item['product_type'],
          "Verification Type": item.verification_type,
          "Mobile No": item["applicant_number"]["mobile_no"],
          "FE Id": item.fe_id,
          "FE Name": item.fe_name,
          "No.Of Attempts": item.reassign_count,
          "Initiation Date & Time": (item.i_date != null ?  formatDate(item.i_date) : "") + " " + (item.i_time != null ? convertTo12HourFormat(item.i_time.split('.')[0]) : '' || ''),
          "Visited Date & Time": (item.c_date != null ?  formatDate(item.c_date) : "") + " " + (item.c_time != null ? convertTo12HourFormat(item.c_time.split('.')[0]) : '' || ''),
          "Audit Date & Time": (item.audit_date || '')  + " " + (item.audit_time || ''),
          "Completed Date & Time": item.verification_type == "Document" ? ((item.audit_date || '')  + " " + (item.audit_time || '')) :  (item.c_date != null ?  formatDate(item.c_date) : "") + " " + (item.c_time != null ? convertTo12HourFormat(item.c_time.split('.')[0]) : '' || ''),
          "Status": "Visit Completed",
          "Audited By":item.a_user,
          "OverAll Status":item.overallstatus || ""
        }));
         setxldata(mxldata);
        setdataloaded(true)
        setOpendate(false);
        console.log("data", data);
        // console.log('mxldata',mxldata)
      });
  };

  const search_data_datewise_audit_completed = () => {
    setSelectedDateRange(tempDateRange);
    setStartDate(tempDateRange[0]);
    setEndDate(tempDateRange[1]);
    const token = localStorage.getItem("token");
    console.log(searchid)
    let headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("token", token);
    const requestOptions1 = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ location: userLocation, start_date:tempDateRange[0], end_date:tempDateRange[1], completed_status:true, visited_status:false }),
    };
    fetch(`${URL}/search__completed_applications_datewise_audit_completed`, requestOptions1)
      .then((response) => response.json())
      .then((data) => {
        setdata(data);
        setTooltipOpen(Array(data.length).fill(false));
        const mxldata = data.map((item,index) => ({
          "S.No":index+1,
          "Branch Location":item.branch_location,
          "Client Name": item.client_name,
          "Case Id": item.case_id,
          "REF No": item.file_refference_no,
          "Applicant Name": item.applicant_name,
          "Address": item["applicant_current_address"]["address"],
          "Area":item["applicant_current_address"]["area"],
          "Location Type":item['ocl'] && item['ocl'] == "Yes" ?"OCL":"LOCAL",
          "Product Type":item['product_type'],
          "Verification Type": item.verification_type,
          "Mobile No": item["applicant_number"]["mobile_no"],
          "FE Id": item.fe_id,
          "FE Name": item.fe_name,
          "No.Of Attempts": item.reassign_count,
          "Initiation Date & Time": (item.i_date != null ?  formatDate(item.i_date) : "") + " " + (item.i_time != null ? convertTo12HourFormat(item.i_time.split('.')[0]) : '' || ''),
          "Visited Date & Time": (item.c_date != null ?  formatDate(item.c_date) : "") + " " + (item.c_time != null ? convertTo12HourFormat(item.c_time.split('.')[0]) : '' || ''),
          "Audit Date & Time": (item.audit_date || '')  + " " + (item.audit_time || ''),
          "Completed Date & Time": item.verification_type == "Document" ? ((item.audit_date || '')  + " " + (item.audit_time || '')) :  (item.c_date != null ?  formatDate(item.c_date) : "") + " " + (item.c_time != null ? convertTo12HourFormat(item.c_time.split('.')[0]) : '' || ''),
          "Status": "Completed",
          "Audited By":item.a_user,
          "OverAll Status":item.overallstatus || "",
          "Branch Location":item.branch_location,
          "Remarks":item.remarks
        }));
        setxldata(mxldata);
        setdataloaded(true)
        setOpendate(false);
        console.log("data", data);
      });
  };






  const search_data_status = (checked) => {
    
    const token = localStorage.getItem("token");
    console.log(searchid)
    let headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("token", token);
    const requestOptions1 = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ location: userLocation, completed_status:checked }),
    };
    fetch(`${URL}/search__completed_applications_status`, requestOptions1)
      .then((response) => response.json())
      .then((data) => {
        setdata(data);
        setTooltipOpen(Array(data.length).fill(false));
        const mxldata = data.map((item) => ({
          "Client Name": item.client_name,
          "Case Id": item.case_id,
          "REF No": item.file_refference_no,
          "Applicant Name": item.applicant_name,
          "Address": item["applicant_current_address"]["address"],
          "Area":item["applicant_current_address"]["area"],
          "Location Type":item['ocl'] && item['ocl'] == "Yes" ?"OCL":"LOCAL",
          "Product Type":item['product_type'],
          "Verification Type": item.verification_type,
          "Mobile No": item["applicant_number"]["mobile_no"],
          "FE Id": item.fe_id,
          "FE Name": item.fe_name,
          "No.Of Attempts": item.reassign_count,
          "Initiation Date & Time": (item.i_date != null ?  formatDate(item.i_date) : "") + " " + (item.i_time != null ? convertTo12HourFormat(item.i_time.split('.')[0]) : '' || ''),
          "Visited Date & Time": (item.c_date != null ?  formatDate(item.c_date) : "") + " " + (item.c_time != null ? convertTo12HourFormat(item.c_time.split('.')[0]) : '' || ''),
          "Audit Date & Time": (item.audit_date || '')  + " " + (item.audit_time || ''),
          "Completed Date & Time": item.verification_type == "Document" ? ((item.audit_date || '')  + " " + (item.audit_time || '')) :  (item.c_date != null ?  formatDate(item.c_date) : "") + " " + (item.c_time != null ? convertTo12HourFormat(item.c_time.split('.')[0]) : '' || ''),
          "Status": "Completed",
          "Audited By":item.a_user,
          "OverAll Status":item.overallstatus || ""
        }));
        setxldata(mxldata);
        setdataloaded(true)
        setOpendate(false);
        console.log("data", data);
      });
  };


  const update_status = (reffernce_no) => {
    if (agent != "") {
      //console.log(token);
      let headers = new Headers();

      headers.append("Content-Type", "application/json");
      headers.append("token", token);
      const requestOptions1 = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          RefferenceNo: reffernce_no,
          status: 1,
          AgentName: agent,
        }),
      };
      fetch(`${URL}/update_status`, requestOptions1)
        .then((response) => response.json())
        .then((data) => {
          read_data(userLocation);
        });
    } else {
      setdilog(true);
    }
  };

  const updatereasign_status = (reffernce_no) => {
    if (agent != "") {
      //console.log(token);
      let headers = new Headers();

      headers.append("Content-Type", "application/json");
      headers.append("token", token);
      const requestOptions1 = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          RefferenceNo: reffernce_no,
          status: 1,
          AgentName: agent,
        }),
      };
      fetch(`${URL}/update_status`, requestOptions1)
        .then((response) => response.json())
        .then((data) => {
          read_data(userLocation);
        });
    } else {
      setdilog(true);
    }
  };
  const pdfdata = {
    productType: "PERSONAL LOAN",
    caseNumber: "8412621874561028A",
    applicantName: "MOHD MUFEES",
    applicantPhone: "8800141206",
    companyName: "DELHI TRANSPORT CORPORATION",
    officeTelephone: "NA",
    address:
      "DELHI TRANSPORT CORPORATION, 7827201487 HARI NAGAR DEPOT, SHAHEED MAJOR RAJEEV LAL MARG, NEW DELHI DELHI, LANDMARK: RAJEEV LAL MARG RAJOURI GARDEN DELHI 110027",
    landmark: "BPO INDUSTRY",
    caseAllocationDate: "2022-06-11 10:51:01",
    visitedAt: "",
    submittedAt: "2022-06-11 17:05:36",
    overallstatus: "RECOMMENDED",
    reasonfornegativefi: "NA",
    remarks:
      "JOB VERIFICATION:- HAVE VISITED AT APPLICANT OFFICE ADDRESS MET WITH MR. MOHD MUFEES (SELF) HE CONFIRMED THAT HE IS WORKING IN THIS FIRM AS A CONDUCTOR FROM LAST 03 YEARS AND GETTING MONTHLY SALARY IS NOT DISCLOSED, NATURE OF WORK IS GOVERNMENT BUS SERVICES, COMPANY BOARD SEEN YES, SETUP SEEN YES, ACTIVITY SEEN ,TPC CONFIRMATION REQUIRED FROM MR. SUNIL (EMPLOYEE), CONFIRMED APPLICANT IS WORKING IN THIS FIRM. OVERALL STATUS OF THIS CASE IS POSITIVE. DIST-30 KM (FIELD EXECUTIVE NAME:- MR. NEERAJ WEST/ REPORTED BY:- MR. JATIN YADAV). ",
    signatoryImage: "https://via.placeholder.com/150x300",
    verificationImage1: "https://via.placeholder.com/150x300",
    verificationImage2: "https://via.placeholder.com/150x300",
    verificationImage3: "https://via.placeholder.com/150x300",
    verificationImage4: "https://via.placeholder.com/150x300",
    verificationImage5: "https://via.placeholder.com/150x300",
    verificationImage6: "https://via.placeholder.com/150x300",
  };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => {
      setIsFirstPage(true);
      return componentRef.current;
    },
  });
  const componentRefEmployee = useRef();
  const componentRefBusiness = useRef();
  const componentRefResidence = useRef();
  const componentRefAsset = useRef();
  const componentRefProperty = useRef();

  const handlePrintEmployee = useReactToPrint({
    content: () => {
      setIsFirstPage(true);
      return componentRefEmployee.current;
    },
    // content: () => componentRefEmployee.current,
  });

  const handlePrintBusiness = useReactToPrint({
    content: () => {
      setIsFirstPage(true);
      return componentRefBusiness.current;
    },
    // content: () => componentRefBusiness.current,
  });

  const handlePrintResidence = useReactToPrint({
    content: () => {
      setIsFirstPage(true);
      return componentRefResidence.current;
    },
    // content: () => componentRefResidence.current,
  });

  const handlePrintAsset = useReactToPrint({
    content: () => {
      setIsFirstPage(true);
      return componentRefAsset.current;
    },
    // content: () => componentRefAsset.current,
  });
  // const componentRef = React.useRef();

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });
 
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
      <Dialog
      open={loadopen1}
      onClose={handleloadClose1}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
     
    >
      
      <DialogContent  style={{width:300, height:300, textAlign:'center', alignItems:'center'}}>
      
          <CircularProgress style={{marginTop:'45%', color:'#096a80', width:80, height:80}} />
       
      </DialogContent>
      <DialogActions>
       
      </DialogActions>
    </Dialog>

      <Dialog
      open={loadopen}
      onClose={handleloadClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
     
    >
      
      <DialogContent  style={{width:300, height:300, textAlign:'center', alignItems:'center'}}>
      
          <CircularProgress style={{marginTop:'45%', color:'blue', width:80, height:80}} />
       
      </DialogContent>
      <DialogActions>
       
      </DialogActions>
    </Dialog>
        <Dialog
          open={dilog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ color: "red" }}>
            {"alert"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Select an agent to assign the task !!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              style={{ backgroundColor: "blue", color: "white" }}
            >
              Ok
            </Button>
            {/* <Button onClick={handleClose} autoFocus>
            Agree
          </Button> */}
          </DialogActions>
        </Dialog>

        <Dialog
          open={opend}
          onClose={handleClosed}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ color: "red" }}>
            {"alert"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Audit completed successfully.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClosed}
              style={{ backgroundColor: "blue", color: "white" }}
            >
              Ok
            </Button>
            {/* <Button onClick={handleClose} autoFocus>
            Agree
          </Button> */}
          </DialogActions>
        </Dialog>

        <Dialog
          open={openempd}
          onClose={handleCloseemp}
          id="audit"
          style={{ maxWidth: "100%", width: "100%", paddingBottom: 50 }}
        >
          <DialogTitle style={{ textAlign: "center", fontSize: 24 }}>
            <Grid item xs={12}>
              <Typography variant="h5" align="center" fontWeight="bold">
                {formData.company_name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" fontWeight="bold">
                Employee Verification Report
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ textAlign: "center", marginTop: "14px" }}
            >
              <Button
                onClick={handleCloseemp}
                style={{
                  backgroundColor: "red",
                  color: "white",
                  position: "absolute",
                  right: "2%",
                  top: "4%",
                }}
              >
                Cancel
              </Button>
            </Grid>
          </DialogTitle>
          <DialogContent className="dcp">
            <div className="pdf-container">
            

              <Grid
                container
                spacing={2}
                className="pdf-table"
                style={{
                  border: "1px solid black",
                  padding: "16px",
                  marginBottom: 10,
                }}
              >
                <Grid item xs={2}>
                  <Typography className="ap">Product Type</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="product_type"
                    value={formData.product_type}
                    onChange={handleChange}
                  /> */}
                    {/* <Autocomplete
       className="form"
          id="product_type"
          options={product_deatils}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.product_type, label:formData.product_type}}
        name="product_type"
        // value={formData ? ((formData.overallstatus == null || formData.overallstatus == undefined) ? "":formData.overallstatus):""}
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined" name="product_type" style={{paddingTop:0}} 
          // onKeyDown={(event) => {
          //   console.log(event)
          //   alert('pressed',event.key)
          //   // Check if the Enter key is pressed
          //   if (event.key === 'Enter') {
          //     // If Enter is pressed, trigger the dropdown to open
          //     params.inputProps.onClick?.();
          //   }
          //   handleKeyDown(event, params);
          // }}   
           onKeyDown={(event) => handleKeyDown(event, params)} 
          />  }
           onChange={handleChanged}
          
        /> */}
      <Select
        ref={(el) => selectRefs.current["product_type"] = el}  // Store ref by unique id
        options={product_deatils.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.product_type, label: formData.product_type }}
        onChange={(selectedOption) => handleChanged(selectedOption, "product_type")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "product_type")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Case Number</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="case_id"
                    value={formData.case_id}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Applicant Name</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="applicant_name"
                    value={formData.applicant_name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Applicant Phone No.</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="applicant_number"
                    value={formData.applicant_number}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Organisation’s name </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="a_company_name"
                    value={formData.a_company_name}
                    onChange={handleChange}
                    // disabled={true}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Office Telephone</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="office_telephone"
                    value={formData.office_telephone}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Address</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Landmark</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="landmark"
                    value={formData.landmark}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Client Name</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="client_name"
                    value={formData.client_name}
                    onChange={handleChange}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Case Allocation Date</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="i_date"
                    value={formatDate(formData.i_date) + "  " +  formData.i_time}
                    onChange={handleChange}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Visited At</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="u_date"
                    value={formatDate(formData.c_date) + "  " +  formData.c_time}
                    onChange={handleChange}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Submitted At</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="c_date"
                    value={formatDate1(formData.audit_date) + "  " +  formData.audit_time}
                    onChange={handleChange}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Overall Status</Typography>
                </Grid>
                <Grid item xs={4}>
                 
                    {/* <Autocomplete
       className="form"
          id="overallstatus"
          options={overall_status}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.overallstatus, label:formData.overallstatus}}
        name="overallstatus"
        // value={formData ? ((formData.overallstatus == null || formData.overallstatus == undefined) ? "":formData.overallstatus):""}
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined" name="overallstatus" style={{paddingTop:0}}  onKeyDown={(event) => handleKeyDown(event, params)}/> }
           onChange={handleChanged}
           
        /> */}
         <Select
        ref={(el) => selectRefs.current["overallstatus"] = el}  // Store ref by unique id
        options={overall_status.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.overallstatus, label: formData.overallstatus }}
        onChange={(selectedOption) => handleChanged(selectedOption, "overallstatus")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "overallstatus")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">
                    Reason for Negative FI (If Any)
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="reasonfornegativefi"
                    value={formData.reasonfornegativefi}
                    onChange={handleChange}
                  /> */}
                   {/* <Autocomplete
       className="form"
          id="reasonfornegativefi"
          options={reason_for_negative}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.reasonfornegativefi, label:formData.reasonfornegativefi}}
        name="reasonfornegativefi"
        // value={formData ? ((formData.overallstatus == null || formData.overallstatus == undefined) ? "":formData.overallstatus):""}
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined" name="reasonfornegativefi" style={{paddingTop:0}}  onKeyDown={(event) => handleKeyDown(event, params)}/> }
           onChange={handleChanged}
           
        /> */}
          <Select
        ref={(el) => selectRefs.current["reasonfornegativefi"] = el}  // Store ref by unique id
        options={reason_for_negative.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.reasonfornegativefi, label: formData.reasonfornegativefi }}
        onChange={(selectedOption) => handleChanged(selectedOption, "reasonfornegativefi")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "reasonfornegativefi")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>

                <Grid item xs={12}>
                  <Typography className="ap">Remarks</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="remarks"
                    value={formData.remarks}
                    multiline
                    rows={4}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">
                    FOLLOWING ARE BASED ON INFORMATION OBTAINED FROM HR/
                    AUTHORIZED EXECUTIVE OF THE ORGANIZATION
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Person Met</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="name_of_person_met"
                    value={formData.name_of_person_met}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">
                    Designation of Person Met
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="designation_of_person_met"
                    value={formData.designation_of_person_met}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Contact Number</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="contact_number"
                    value={formData.contact_number}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Nature of Business</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="nature_of_business"
                    value={formData.nature_of_business}
                    onChange={handleChange}
                  /> */}
                     {/* <Autocomplete
       className="form"
          id="nature_of_business"
          options={nature_of_business}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.nature_of_business, label:formData.nature_of_business}}
        name="nature_of_business"
        // value={formData ? ((formData.overallstatus == null || formData.overallstatus == undefined) ? "":formData.overallstatus):""}
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined" name="nature_of_business" style={{paddingTop:0}}  onKeyDown={(event) => handleKeyDown(event, params)}/> }
           onChange={handleChanged}
           
        /> */}
          <Select
        ref={(el) => selectRefs.current["nature_of_business"] = el}  // Store ref by unique id
        options={nature_of_business.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.nature_of_business, label: formData.nature_of_business }}
        onChange={(selectedOption) => handleChanged(selectedOption, "nature_of_business")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "nature_of_business")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Type of Organisation</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="type_of_organisaton"
                    value={formData.type_of_organisaton}
                    onChange={handleChange}
                  /> */}
                  {/* <Autocomplete
       className="form"
          id="type_of_organisaton"
          options={type_of_organisaton}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.type_of_organisaton, label:formData.type_of_organisaton}}
        name="type_of_organisaton"
        // value={formData ? ((formData.overallstatus == null || formData.overallstatus == undefined) ? "":formData.overallstatus):""}
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined" name="type_of_organisaton" style={{paddingTop:0}}  onKeyDown={(event) => handleKeyDown(event, params)}/> }
           onChange={handleChanged}
           
        /> */}
          <Select
        ref={(el) => selectRefs.current["type_of_organisaton"] = el}  // Store ref by unique id
        options={type_of_organisaton.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.type_of_organisaton, label: formData.type_of_organisaton }}
        onChange={(selectedOption) => handleChanged(selectedOption, "type_of_organisaton")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "type_of_organisaton")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Visiting Card Obtained</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="visiting_card_obtained"
                    value={formData.visiting_card_obtained}
                    onChange={handleChange}
                  /> */}
                   {/* <Autocomplete
       className="form"
          id="visiting_card_obtained"
          options={yesnona}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.visiting_card_obtained, label:formData.visiting_card_obtained}}
        name="visiting_card_obtained"
        // value={formData ? ((formData.overallstatus == null || formData.overallstatus == undefined) ? "":formData.overallstatus):""}
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined" name="visiting_card_obtained" style={{paddingTop:0}}  onKeyDown={(event) => handleKeyDown(event, params)}/> }
           onChange={handleChanged}
           
        /> */}
          <Select
        ref={(el) => selectRefs.current["visiting_card_obtained"] = el}  // Store ref by unique id
        options={yesnona.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.visiting_card_obtained, label: formData.visiting_card_obtained }}
        onChange={(selectedOption) => handleChanged(selectedOption, "visiting_card_obtained")}
        placeholder="Select option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "visiting_card_obtained")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">
                    Date of Joining / No. of years in present employment
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="time_of_employment_of_applicant"
                    value={formData.time_of_employment_of_applicant}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">
                    Does the applicant Work?
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="applicant_work"
                    value={formData.applicant_work}
                    onChange={handleChange}
                  /> */}
                   {/* <Autocomplete
       className="form"
          id="applicant_work"
          options={yesnona}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.applicant_work, label:formData.applicant_work}}
        name="applicant_work"
        // value={formData ? ((formData.overallstatus == null || formData.overallstatus == undefined) ? "":formData.overallstatus):""}
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined" name="applicant_work" style={{paddingTop:0}}  onKeyDown={(event) => handleKeyDown(event, params)}/> }
           onChange={handleChanged}
           
        /> */}
          <Select
        ref={(el) => selectRefs.current["applicant_work"] = el}  // Store ref by unique id
        options={yesnona.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.applicant_work, label: formData.applicant_work }}
        onChange={(selectedOption) => handleChanged(selectedOption, "applicant_work")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "applicant_work")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Number of Employees</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="number_of_employees"
                    value={formData.number_of_employees}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">
                    Designation of Applicant (s)
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="designation_of_applicant"
                    value={formData.designation_of_applicant}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">OTHER OBSERVATIONS:</Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Type of Job</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="type_of_job"
                    value={formData.type_of_job}
                    onChange={handleChange}
                  /> */}
                       {/* <Autocomplete
       className="form"
          id="type_of_job"
          options={type_of_job_emp}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.type_of_job, label:formData.type_of_job}}
        name="type_of_job"
        // value={formData ? ((formData.overallstatus == null || formData.overallstatus == undefined) ? "":formData.overallstatus):""}
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined" name="type_of_job" style={{paddingTop:0}}  onKeyDown={(event) => handleKeyDown(event, params)}/> }
           onChange={handleChanged}
           
        /> */}
          <Select
        ref={(el) => selectRefs.current["type_of_job"] = el}  // Store ref by unique id
        options={type_of_job_emp.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.type_of_job, label: formData.type_of_job }}
        onChange={(selectedOption) => handleChanged(selectedOption, "type_of_job")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "type_of_job")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
               

                <Grid item xs={2}>
                  <Typography className="ap">
                    Whether job is transferable
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="work_transferable"
                    value={formData.work_transferable}
                    onChange={handleChange}
                  /> */}
                     {/* <Autocomplete
       className="form"
          id="work_transferable"
          options={yesnona}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.work_transferable, label:formData.work_transferable}}
        name="work_transferable"
        // value={formData ? ((formData.overallstatus == null || formData.overallstatus == undefined) ? "":formData.overallstatus):""}
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined" name="work_transferable" style={{paddingTop:0}}  onKeyDown={(event) => handleKeyDown(event, params)}/> }
           onChange={handleChanged}
           
        /> */}
          <Select
        ref={(el) => selectRefs.current["work_transferable"] = el}  // Store ref by unique id
        options={yesnona.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.work_transferable, label: formData.work_transferable }}
        onChange={(selectedOption) => handleChanged(selectedOption, "work_transferable")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "work_transferable")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Ease To Locate</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="ease_to_locate"
                    value={formData.ease_to_locate}
                    onChange={handleChange}
                  /> */}
                   {/* <Autocomplete
       className="form"
          id="ease_to_locate"
          options={easy_to_locate}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.ease_to_locate, label:formData.ease_to_locate}}
        name="ease_to_locate"
        // value={formData ? ((formData.overallstatus == null || formData.overallstatus == undefined) ? "":formData.overallstatus):""}
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined" name="ease_to_locate" style={{paddingTop:0}}  onKeyDown={(event) => handleKeyDown(event, params)}/> }
           onChange={handleChanged}
           
        /> */}
          <Select
        ref={(el) => selectRefs.current["ease_to_locate"] = el}  // Store ref by unique id
        options={easy_to_locate.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.ease_to_locate, label: formData.ease_to_locate }}
        onChange={(selectedOption) => handleChanged(selectedOption, "ease_to_locate")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "ease_to_locate")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Type of Locality</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="type_of_locality"
                    value={formData.type_of_locality}
                    onChange={handleChange}
                  /> */}
                  {/* <Autocomplete
       className="form"
          id="type_of_locality"
          options={type_of_locality}
          getOptionLabel={(option) => option.label}
          value={{value:formData.type_of_locality, label:formData.type_of_locality}}
          style={{textAlign:'center'}}
        name="type_of_locality"
        // value={formData ? ((formData.overallstatus == null || formData.overallstatus == undefined) ? "":formData.overallstatus):""}
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined" name="type_of_locality" style={{paddingTop:0}}  onKeyDown={(event) => handleKeyDown(event, params)}/> }
           onChange={handleChanged}
           
        /> */}
          <Select
        ref={(el) => selectRefs.current["type_of_locality"] = el}  // Store ref by unique id
        options={type_of_locality.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.type_of_locality, label: formData.type_of_locality }}
        onChange={(selectedOption) => handleChanged(selectedOption, "type_of_locality")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "type_of_locality")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
               
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Office Name Board Seen</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="office_name_board_seen"
                    value={formData.office_name_board_seen}
                    onChange={handleChange}
                  /> */}
                   {/* <Autocomplete
       className="form"
          id="office_name_board_seen"
          options={yesnona}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.office_name_board_seen, label:formData.office_name_board_seen}}
        name="office_name_board_seen"
        // value={formData ? ((formData.overallstatus == null || formData.overallstatus == undefined) ? "":formData.overallstatus):""}
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined" name="office_name_board_seen" style={{paddingTop:0}}  onKeyDown={(event) => handleKeyDown(event, params)}/> }
           onChange={handleChanged}
           
        /> */}
          <Select
        ref={(el) => selectRefs.current["office_name_board_seen"] = el}  // Store ref by unique id
        options={yesnona.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.office_name_board_seen, label: formData.office_name_board_seen }}
        onChange={(selectedOption) => handleChanged(selectedOption, "office_name_board_seen")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "office_name_board_seen")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">
                    EMPLOYMENT DETAILS VERIFIED FROM WHOM:
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Report to</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="report_to"
                    value={formData.report_to}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Manager Designation</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="manager_designation"
                    value={formData.manager_designation}
                    onChange={handleChange}
                    
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">
                    Reporting Manager Contact No.
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="reporting_manager_contact_number"
                    value={formData.reporting_manager_contact_number}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Salary</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="sallary"
                    value={formData.sallary}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">TPC Confirmation</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="tpc_check"
                    value={formData.tpc_check}
                    onChange={handleChange}
                  /> */}
                             {/* <Autocomplete
       className="form"
          id="tpc_check"
          options={tpc_confirmation}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.tpc_check, label:formData.tpc_check}}
        name="tpc_check"
        // value={formData ? ((formData.overallstatus == null || formData.overallstatus == undefined) ? "":formData.overallstatus):""}
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined" name="tpc_check" style={{paddingTop:0}}  onKeyDown={(event) => handleKeyDown(event, params)}/> }
           onChange={handleChanged}
           
        /> */}
          <Select
        ref={(el) => selectRefs.current["tpc_check"] = el}  // Store ref by unique id
        options={tpc_confirmation.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.tpc_check, label: formData.tpc_check }}
        onChange={(selectedOption) => handleChanged(selectedOption, "tpc_check")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "tpc_check")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">TPC Person Name</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="name_of_person_contacted"
                    value={formData.name_of_person_contacted}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">MAP LOCATION:</Typography>
                </Grid>
                {/* <Grid item xs={2}>
                  <Typography className="ap">Lat-Long</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="lat_long"
                    value={formData.latitude + '-' + formData.longitude}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Field Agent Name</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="field_agent_name"
                    value={formData.field_agent_name}
                    onChange={handleChange}  disabled = {true}
                  />
                </Grid> */}
                 <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">MAP LOCATION:</Typography>
                </Grid>
                <Grid item xs={1} style={{paddingLeft:0}}>
                  <Typography className="ap">Lat-Long</Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="lat_long"
                    value={formData.latitude + '-' + formData.longitude}
                    onChange={handleChange}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Field Agent Name</Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="field_agent_name"
                    value={formData.field_agent_name}
                    onChange={handleChange}  disabled = {true}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Typography className="ap">Deviation</Typography>
                </Grid>
                <Grid item xs={3} style={{paddingLeft:30}}>

<TextField
  fullWidth
  variant="outlined"
  name="deviation"
  value={formData.deviation}
  onChange={handleChange}
  disabled={true}
/>
</Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={4}>
                  <MapComponent
                    latitude={parseFloat(auditdata?.latitude)}
                    longitude={parseFloat(auditdata?.longitude)}
                    style={{
                      borderRadius: 5,
                      borderWidth: 3,
                      borderColor: "gray",
                    }}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">
                    SNAPS TAKEN FROM VERIFICATION POINT:
                  </Typography>
                </Grid>
                <Grid container style={{ marginTop: 20 }}>
                  {auditdata?.files_name &&
                    auditdata?.files_name.map((item, index) => (
                      <Grid item xs={3}>
                        <img
                          // src={`${URL}/uploads/${item.filepath.split('\\')[1]}`}
                          src={`${item.imageUrl}`}
                          style={{
                            width: 250,
                            height: 350,
                            borderRadius: 5,
                            borderColor: "gray",
                            borderWidth: 2,
                            borderStyle: "groove",
                          }}
                        ></img>
                      </Grid>
                    ))}
                </Grid>

                <Grid
                  item
                  xs={6}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Button
                    style={{ backgroundColor: "blue", color: "white" }}
                    onClick={handleSaveChanges}
                  >
                    Save Changes
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Button
                    onClick={handleCloseemp}
                    style={{ backgroundColor: "red", color: "white" }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </div>
          </DialogContent> 
        </Dialog>

        <Dialog
          open={openbusd}
          onClose={handleClosebusiness}
          id="audit"
          style={{ maxWidth: "100%", width: "100%", paddingBottom: 50 }}
        >
          <DialogTitle style={{ textAlign: "center", fontSize: 24 }}>
            <Grid item xs={12}>
              <Typography variant="h5" align="center" fontWeight="bold">
                {formData.company_name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" fontWeight="bold">
                Business Verification Report
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ textAlign: "center", marginTop: "14px" }}
            >
              <Button
                onClick={handleClosebusiness}
                style={{
                  backgroundColor: "red",
                  color: "white",
                  position: "absolute",
                  right: "2%",
                  top: "4%",
                }}
              >
                Cancel
              </Button>
            </Grid>
          </DialogTitle>
          <DialogContent className="dcp">
            <div className="pdf-container">
              {/* <h5>{auditdata}</h5> */}

              <Grid
                container
                spacing={2}
                className="pdf-table"
                style={{
                  border: "1px solid black",
                  padding: "16px",
                  marginBottom: 10,
                }}
              >
                <Grid item xs={2}>
                  <Typography className="ap">Product Type</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="product_type"
                    value={formData.product_type}
                    onChange={handleChange}
                  /> */}
                   {/* <Autocomplete
       className="form"
          id="product_type"
          options={product_deatils}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.product_type, label:formData.product_type}}
        name="product_type"
        // value={formData ? ((formData.overallstatus == null || formData.overallstatus == undefined) ? "":formData.overallstatus):""}
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined" name="product_type" style={{paddingTop:0}}  /> }
           onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["product_type"] = el}  // Store ref by unique id
        options={product_deatils.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.product_type, label: formData.product_type }}
        onChange={(selectedOption) => handleChanged(selectedOption, "product_type")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "product_type")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Case Number</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="case_id"
                    value={formData.case_id}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Applicant Name</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="applicant_name"
                    value={formData.applicant_name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Applicant Phone No.</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="applicant_number"
                    value={formData.applicant_number}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Company Name</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="a_company_name"
                    value={formData.a_company_name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Office Telephone</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="office_telephone"
                    value={formData.office_telephone}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Address</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Landmark</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="landmark"
                    value={formData.landmark}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Client Name</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="client_name"
                    value={formData.client_name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Case Allocation Date</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="i_date"
                    value={formatDate(formData.i_date) + "  " +  formData.i_time}
                    onChange={handleChange}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Visited At</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="u_date"
                    value={formatDate(formData.c_date) + "  " +  formData.c_time}
                    onChange={handleChange}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Submitted At</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="c_date"
                    value={formatDate1(formData.audit_date) + "  " +  formData.audit_time}
                    onChange={handleChange}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Overall Status</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="overallstatus"
                    value={formData.overallstatus}
                    onChange={handleChange}
                  /> */}
                          {/* <Autocomplete
       className="form"
          id="overallstatus"
          options={overall_status}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.overallstatus, label:formData.overallstatus}}
        name="overallstatus"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="overallstatus" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["overallstatus"] = el}  // Store ref by unique id
        options={overall_status.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.overallstatus, label: formData.overallstatus }}
        onChange={(selectedOption) => handleChanged(selectedOption, "overallstatus")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "overallstatus")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">
                    Reason for Negative FI (If Any)
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="reasonfornegativefi"
                    value={formData.reasonfornegativefi}
                    onChange={handleChange}
                  /> */}
                        {/* <Autocomplete
       className="form"
          id="reasonfornegativefi"
          options={reason_for_negative}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.reasonfornegativefi, label:formData.reasonfornegativefi}}
        name="reasonfornegativefi"
        // value={formData ? ((formData.overallstatus == null || formData.overallstatus == undefined) ? "":formData.overallstatus):""}
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined" name="reasonfornegativefi" style={{paddingTop:0}}  /> }
           onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["reasonfornegativefi"] = el}  // Store ref by unique id
        options={reason_for_negative.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.reasonfornegativefi, label: formData.reasonfornegativefi }}
        onChange={(selectedOption) => handleChanged(selectedOption, "reasonfornegativefi")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "reasonfornegativefi")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>

                <Grid item xs={12}>
                  <Typography className="ap">Remarks</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="remarks"
                    value={formData.remarks}
                    multiline
                    rows={4}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">
                    FOLLOWING ARE BASED ON INFORMATION OBTAINED FROM APPLICANT/
                    COLLEAGUE:
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Person Met</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="name_of_person_met"
                    value={formData.name_of_person_met}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">
                    Designation of Person Met
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="designation_of_person_met"
                    value={formData.designation_of_person_met}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">
                    Number of Years at Present Address
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="number_of_years_present_address"
                    value={formData.number_of_years_present_address}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Visiting Card Obtained</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="visiting_card_obtained"
                    value={formData.visiting_card_obtained}
                    onChange={handleChange}
                  /> */}
                                    {/* <Autocomplete
       className="form"
          id="visiting_card_obtained"
          options={yesnona}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.visiting_card_obtained, label:formData.visiting_card_obtained}}
        name="visiting_card_obtained"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="visiting_card_obtained" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["visiting_card_obtained"] = el}  // Store ref by unique id
        options={yesnona.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.visiting_card_obtained, label: formData.visiting_card_obtained }}
        onChange={(selectedOption) => handleChanged(selectedOption, "visiting_card_obtained")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "visiting_card_obtained")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Nature of Busniess </Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="nature_of_business"
                    value={formData.nature_of_business}
                    onChange={handleChange}
                  /> */}
                   {/* <Autocomplete
       className="form"
          id="nature_of_business"
          options={nature_of_business}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.nature_of_business, label:formData.nature_of_business}}
        name="nature_of_business"
        // value={formData ? ((formData.overallstatus == null || formData.overallstatus == undefined) ? "":formData.overallstatus):""}
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined" name="nature_of_business" style={{paddingTop:0}}  /> }
           onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["nature_of_business"] = el}  // Store ref by unique id
        options={nature_of_business.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.nature_of_business, label: formData.nature_of_business }}
        onChange={(selectedOption) => handleChanged(selectedOption, "nature_of_business")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "nature_of_business")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Number of Branches</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="number_of_branches"
                    value={formData.number_of_branches}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Working Since</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="years_of_existence_of_business"
                    value={formData.years_of_existence_of_business}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">
                    Designation of Applicant (s)
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="designation_of_applicant"
                    value={formData.designation_of_applicant}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Office Ownership </Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="ownership"
                    value={formData.ownership}
                    onChange={handleChange}
                  /> */}
                    {/* <Autocomplete
       className="form"
          id="ownership"
          options={office_ownership}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.ownership, label:formData.ownership}}
        name="ownership"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="ownership" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["ownership"] = el}  // Store ref by unique id
        options={office_ownership.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.ownership, label: formData.ownership }}
        onChange={(selectedOption) => handleChanged(selectedOption, "ownership")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "ownership")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Number of Employees</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="number_of_employees"
                    value={formData.number_of_employees}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">
                    THE FOLLOWING ARE BASED ON VERIFIER'S OBSERVATION:
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Business Setup Seen</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="business_activity_seen"
                    value={formData.business_activity_seen}
                    onChange={handleChange}
                  /> */}
                   {/* <Autocomplete
       className="form"
          id="business_activity_seen"
          options={yesnona}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.business_activity_seen, label:formData.business_activity_seen}}
        name="business_activity_seen"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="business_activity_seen" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["business_activity_seen"] = el}  // Store ref by unique id
        options={yesnona.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.business_activity_seen, label: formData.business_activity_seen }}
        onChange={(selectedOption) => handleChanged(selectedOption, "business_activity_seen")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "business_activity_seen")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Business Board Seen</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="business_board_seen"
                    value={formData.business_board_seen}
                    onChange={handleChange}
                  /> */}
                    {/* <Autocomplete
       className="form"
          id="business_board_seen"
          options={yesnona}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.business_board_seen, label:formData.business_board_seen}}
        name="business_board_seen"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="business_board_seen" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["overallstatus"] = el}  // Store ref by unique id
        options={yesnona.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.business_board_seen, label: formData.business_board_seen }}
        onChange={(selectedOption) => handleChanged(selectedOption, "business_board_seen")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "business_board_seen")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Ease To Locate</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="ease_to_locate"
                    value={formData.ease_to_locate}
                    onChange={handleChange}
                  /> */}
                   {/* <Autocomplete
       className="form"
          id="ease_to_locate"
          options={easy_to_locate}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.ease_to_locate, label:formData.ease_to_locate}}
        name="ease_to_locate"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="ease_to_locate" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["ease_to_locate"] = el}  // Store ref by unique id
        options={easy_to_locate.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.ease_to_locate, label: formData.ease_to_locate }}
        onChange={(selectedOption) => handleChanged(selectedOption, "ease_to_locate")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "ease_to_locate")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Type of Locality</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="type_of_locality"
                    value={formData.type_of_locality}
                    onChange={handleChange}
                  /> */}
                       {/* <Autocomplete
       className="form"
          id="type_of_locality"
          options={type_of_locality}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.type_of_locality, label:formData.type_of_locality}}
        name="type_of_locality"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="type_of_locality" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["type_of_locality"] = el}  // Store ref by unique id
        options={type_of_locality.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.type_of_locality, label: formData.type_of_locality }}
        onChange={(selectedOption) => handleChanged(selectedOption, "type_of_locality")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "type_of_locality")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">
                    Applicants Name verified from
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="applicants_name_verified_from"
                    value={formData.applicants_name_verified_from}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Contact Verification 1</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="contact_verification_1"
                    value={formData.contact_verification_1}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Contact Verification 2</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="contact_verification_2"
                    value={formData.contact_verification_2}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">
                    Contact Verification Feedback
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="contact_verification_3"
                    value={formData.contact_verification_3}
                    onChange={handleChange}
                  /> */}
                    {/* <Autocomplete
       className="form"
          id="contact_verification_3"
          options={contact_verification_feedback}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.contact_verification_3, label:formData.contact_verification_3}}
        name="contact_verification_3"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="contact_verification_3" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["contact_verification_3"] = el}  // Store ref by unique id
        options={contact_verification_feedback.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.contact_verification_3, label: formData.contact_verification_3 }}
        onChange={(selectedOption) => handleChanged(selectedOption, "contact_verification_3")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "contact_verification_3")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Political Link</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="political_link"
                    value={formData.political_link}
                    onChange={handleChange}
                  /> */}
                  {/* <Autocomplete
       className="form"
          id="political_link"
          options={yesnona}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.political_link, label:formData.political_link}}
        name="political_link"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="political_link" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["political_link"] = el}  // Store ref by unique id
        options={yesnona.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.political_link, label: formData.political_link }}
        onChange={(selectedOption) => handleChanged(selectedOption, "political_link")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "political_link")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Address Proof Details</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="address_proof_details"
                    value={formData.address_proof_details}
                    onChange={handleChange}
                  />
                </Grid>
{/* 
                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">MAP LOCATION:</Typography>
                </Grid> */}
                {/* <Grid item xs={2}>
                  <Typography className="ap">Lat-Long</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="lat_long"
                    value={formData.latitude + '-' + formData.longitude}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Field Agent Name</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="field_agent_name"
                    value={formData.field_agent_name}
                    onChange={handleChange}  disabled = {true}
                  />
                </Grid> */}
                 <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">MAP LOCATION:</Typography>
                </Grid>
                <Grid item xs={1} style={{paddingLeft:0}}>
                  <Typography className="ap">Lat-Long</Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="lat_long"
                    value={formData.latitude + '-' + formData.longitude}
                    onChange={handleChange}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Field Agent Name</Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="field_agent_name"
                    value={formData.field_agent_name}
                    onChange={handleChange}  disabled = {true}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Typography className="ap">Deviation</Typography>
                </Grid>
                <Grid item xs={3} style={{paddingLeft:30}}>

<TextField
  fullWidth
  variant="outlined"
  name="deviation"
  value={formData.deviation}
  onChange={handleChange}
  disabled={true}
/>
</Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={4}>
                  <MapComponent
                    latitude={parseFloat(auditdata?.latitude)}
                    longitude={parseFloat(auditdata?.longitude)}
                    style={{
                      borderRadius: 5,
                      borderWidth: 3,
                      borderColor: "gray",
                    }}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">
                    SNAPS TAKEN FROM VERIFICATION POINT:
                  </Typography>
                </Grid>
                <Grid container style={{ marginTop: 20 }}>
                  {auditdata?.files_name &&
                    auditdata?.files_name.map((item, index) => (
                      <Grid item xs={3}>
                        <img
                          src={`${item.imageUrl}`}
                          style={{
                            width: 250,
                            height: 350,
                            borderRadius: 5,
                            borderColor: "gray",
                            borderWidth: 2,
                            borderStyle: "groove",
                          }}
                        ></img>
                      </Grid>
                    ))}
                </Grid>

                <Grid
                  item
                  xs={6}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Button
                    style={{ backgroundColor: "blue", color: "white" }}
                    onClick={handleSaveChanges}
                  >
                    Save Changes
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Button
                    onClick={handleClosebusiness}
                    style={{ backgroundColor: "red", color: "white" }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openppd}
          onClose={handleCloseproperty}
          id="audit"
          style={{ maxWidth: "100%", width: "100%", paddingBottom: 50 }}
        >
          <DialogTitle style={{ textAlign: "center", fontSize: 24 }}>
            <Grid item xs={12}>
              <Typography variant="h5" align="center" fontWeight="bold">
                {formData.company_name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" fontWeight="bold">
                Property Verification Report
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ textAlign: "center", marginTop: "14px" }}
            >
              <Button
                onClick={handleCloseproperty}
                style={{
                  backgroundColor: "red",
                  color: "white",
                  position: "absolute",
                  right: "2%",
                  top: "4%",
                }}
              >
                Cancel
              </Button>
            </Grid>
          </DialogTitle>
          <DialogContent className="dcp">
            <div className="pdf-container">
              {/* <h5>{auditdata}</h5> */}

              <Grid
                container
                spacing={2}
                className="pdf-table"
                style={{
                  border: "1px solid black",
                  padding: "16px",
                  marginBottom: 10,
                }}
              >
                <Grid item xs={2}>
                  <Typography className="ap">Product Type</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="product_type"
                    value={formData.product_type}
                    onChange={handleChange}
                  /> */}
                    {/* <Autocomplete
       className="form"
          id="product_type"
          options={product_deatils}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.product_type, label:formData.product_type}}
        name="product_type"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="product_type" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["product_type"] = el}  // Store ref by unique id
        options={product_deatils.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.product_type, label: formData.product_type }}
        onChange={(selectedOption) => handleChanged(selectedOption, "product_type")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "product_type")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Case Number</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="case_id"
                    value={formData.case_id}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Applicant Name</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="applicant_name"
                    value={formData.applicant_name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Applicant Phone No.</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="applicant_number"
                    value={formData.applicant_number}
                    onChange={handleChange}
                  />
                </Grid>

                {/* <Grid item xs={2}>
                  <Typography className="ap">Company Name</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="company_name"
                    value={formData.company_name}
                    onChange={handleChange}
                  />
                </Grid> */}
                <Grid item xs={2}>
                  <Typography className="ap">Office Telephone</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="office_telephone"
                    value={formData.office_telephone}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Address</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Landmark</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="landmark"
                    value={formData.landmark}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Client Name</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="client_name"
                    value={formData.client_name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Case Allocation Date</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="i_date"
                    value={formatDate(formData.i_date) + "  " +  formData.i_time}
                    onChange={handleChange}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Visited At</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="u_date"
                    value={formatDate(formData.c_date) + "  " +  formData.c_time}
                    onChange={handleChange}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Submitted At</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="c_date"
                    value={formatDate1(formData.audit_date) + "  " +  formData.audit_time}
                    onChange={handleChange}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Overall Status</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="overallstatus"
                    value={formData.overallstatus}
                    onChange={handleChange}
                  /> */}
                          {/* <Autocomplete
       className="form"
          id="overallstatus"
          options={overall_status}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.overallstatus, label:formData.overallstatus}}
          name="overallstatus"
        
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined" name="overallstatus"  style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["overallstatus"] = el}  // Store ref by unique id
        options={overall_status.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.overallstatus, label: formData.overallstatus }}
        onChange={(selectedOption) => handleChanged(selectedOption, "overallstatus")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "overallstatus")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">
                    Reason for Negative FI (If Any)
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="reasonfornegativefi"
                    value={formData.reasonfornegativefi}
                    onChange={handleChange}
                  /> */}
                  {/* <Autocomplete
       className="form"
          id="reasonfornegativefi"
          options={reason_for_negative_r}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.reasonfornegativefi, label:formData.reasonfornegativefi}}
        name="reasonfornegativefi"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="reasonfornegativefi" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["reasonfornegativefi"] = el}  // Store ref by unique id
        options={reason_for_negative_r.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.reasonfornegativefi, label: formData.reasonfornegativefi }}
        onChange={(selectedOption) => handleChanged(selectedOption, "reasonfornegativefi")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "reasonfornegativefi")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>

                <Grid item xs={12}>
                  <Typography className="ap">Remarks</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="remarks"
                    value={formData.remarks}
                    multiline
                    rows={4}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">
                    THE FOLLOWING INFORMATION IS TO BE OBTAINED FROM
                    APPLICANT(S) OR ANYBODY RESIDING AT THE ADDRESS:
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Person Met</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="name_of_person_met"
                    value={formData.name_of_person_met}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">
                    Relationship with Applicant
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="relation_with_applicant"
                    value={formData.relation_with_applicant}
                    onChange={handleChange}
                  /> */}
                     {/* <Autocomplete
       className="form"
          id="relation_with_applicant"
          options={relation_with_applicant}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.relation_with_applicant, label:formData.relation_with_applicant}}
        name="relation_with_applicant"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="relation_with_applicant" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["overallstatus"] = el}  // Store ref by unique id
        options={relation_with_applicant.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.relation_with_applicant, label: formData.relation_with_applicant }}
        onChange={(selectedOption) => handleChanged(selectedOption, "relation_with_applicant")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "relation_with_applicant")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Type of Property</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="type_of_property"
                    value={formData.type_of_property}
                    onChange={handleChange}
                  /> */}
    {/* <Autocomplete
       className="form"
          id="type_of_property"
          options={type_of_property}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.type_of_property, label:formData.type_of_property}}
          name="type_of_property"
        
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined" name="type_of_property"  style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["type_of_property"] = el}  // Store ref by unique id
        options={type_of_property.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.type_of_property, label: formData.type_of_property }}
        onChange={(selectedOption) => handleChanged(selectedOption, "type_of_property")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "type_of_property")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">
                    No. of Years with Present Owner
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="no_of_years_with_present_owner"
                    value={formData.no_of_years_with_present_owner}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">
                    Property Being Sold to Whom?
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="property_sold"
                    value={formData.property_sold}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">
                    Other Remarks about Purchaser
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="remarks_about_purchaser"
                    value={formData.remarks_about_purchaser}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">
                    THE FOLLOWING IS BASED ON NEIGHBOUR FEEDBACK:
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Neighbour Name 1</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="neighbour_name_1"
                    value={formData.neighbour_name_1}
                    onChange={handleChange}
                  />
                </Grid>
               

                <Grid item xs={2}>
                  <Typography className="ap">Address 1</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="address_1"
                    value={formData.address_1}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Neighbour Name 2</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="neighbour_name_2"
                    value={formData.neighbour_name_2}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Address 2</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="address_2"
                    value={formData.address_2}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Neighbour Feedback</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="neighbour_feedback"
                    value={formData.neighbour_feedback}
                    onChange={handleChange}
                  /> */}

{/* <Autocomplete
       className="form"
          id="neighbour_feedback"
          options={neighbour_feedback}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.neighbour_feedback, label:formData.neighbour_feedback}}
          name="neighbour_feedback"
        
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined" name="neighbour_feedback"  style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["neighbour_feedback"] = el}  // Store ref by unique id
        options={neighbour_feedback.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.neighbour_feedback, label: formData.neighbour_feedback }}
        onChange={(selectedOption) => handleChanged(selectedOption, "neighbour_feedback")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "neighbour_feedback")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">
                    Does applicant stay at this Address?
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="does_applicant_stay_this_address"
                    value={formData.does_applicant_stay_this_address}
                    onChange={handleChange}
                  /> */}
                    {/* <Autocomplete
       className="form"
          id="does_applicant_stay_this_address"
          options={yesnona}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.does_applicant_stay_this_address, label:formData.does_applicant_stay_this_address}}
        name="does_applicant_stay_this_address"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="does_applicant_stay_this_address" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["does_applicant_stay_this_address"] = el}  // Store ref by unique id
        options={yesnona.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.does_applicant_stay_this_address, label: formData.does_applicant_stay_this_address }}
        onChange={(selectedOption) => handleChanged(selectedOption, "does_applicant_stay_this_address")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "does_applicant_stay_this_address")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Ease To Locate</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="ease_to_locate"
                    value={formData.ease_to_locate}
                    onChange={handleChange}
                  /> */}
                    {/* <Autocomplete
       className="form"
          id="ease_to_locate"
          options={easy_to_locate}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.ease_to_locate, label:formData.ease_to_locate}}
        name="ease_to_locate"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="ease_to_locate" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["ease_to_locate"] = el}  // Store ref by unique id
        options={easy_to_locate.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.ease_to_locate, label: formData.ease_to_locate }}
        onChange={(selectedOption) => handleChanged(selectedOption, "ease_to_locate")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "ease_to_locate")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Area Sq Ft</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="area_sq"
                    value={formData.area_sq}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Document Verified</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="document_verified"
                    value={formData.document_verified}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">
                    How Cooperative Was Applicant
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="cooperative_applicant"
                    value={formData.cooperative_applicant}
                    onChange={handleChange}
                  /> */}
                   {/* <Autocomplete
       className="form"
          id="cooperative_applicant"
          options={cooperative_applicant}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.cooperative_applicant, label:formData.cooperative_applicant}}
          name="cooperative_applicant"
        
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined" name="cooperative_applicant"  style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["cooperative_applicant"] = el}  // Store ref by unique id
        options={cooperative_applicant.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.cooperative_applicant, label: formData.cooperative_applicant }}
        onChange={(selectedOption) => handleChanged(selectedOption, "cooperative_applicant")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "cooperative_applicant")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">OTHER OBSERVATIONS:</Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Type of Locality</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="type_of_locality"
                    value={formData.type_of_locality}
                    onChange={handleChange}
                  /> */}
                   {/* <Autocomplete
       className="form"
          id="type_of_locality"
          options={type_of_locality}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.type_of_locality, label:formData.type_of_locality}}
        name="type_of_locality"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="type_of_locality" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["type_of_locality"] = el}  // Store ref by unique id
        options={type_of_locality.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.type_of_locality, label: formData.type_of_locality }}
        onChange={(selectedOption) => handleChanged(selectedOption, "type_of_locality")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "type_of_locality")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Ambience</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="ambience"
                    value={formData.ambience}
                    onChange={handleChange}
                  /> */}
                  {/* <Autocomplete
       className="form"
          id="ambience"
          options={ambience}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.ambience, label:formData.ambience}}
          name="ambience"
        
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined" name="ambience"  style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["ambience"] = el}  // Store ref by unique id
        options={ambience.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.ambience, label: formData.ambience }}
        onChange={(selectedOption) => handleChanged(selectedOption, "ambience")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "ambience")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Vehicle Seen</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="vehicle_seen"
                    value={formData.vehicle_seen}
                    onChange={handleChange}
                  /> */}
                   {/* <Autocomplete
       className="form"
          id="vehicle_seen"
          options={yesnona}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.vehicle_seen, label:formData.vehicle_seen}}
        name="vehicle_seen"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="vehicle_seen" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["vehicle_seen"] = el}  // Store ref by unique id
        options={yesnona.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.vehicle_seen, label: formData.vehicle_seen }}
        onChange={(selectedOption) => handleChanged(selectedOption, "vehicle_seen")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "vehicle_seen")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Political Link</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="political_link"
                    value={formData.political_link}
                    onChange={handleChange}
                  /> */}
                   {/* <Autocomplete
       className="form"
          id="political_link"
          options={yesnona}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.political_link, label:formData.political_link}}
        name="political_link"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="political_link" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["political_link"] = el}  // Store ref by unique id
        options={yesnona.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.political_link, label: formData.political_link }}
        onChange={(selectedOption) => handleChanged(selectedOption, "political_link")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "political_link")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                {/* <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">MAP LOCATION:</Typography>
                </Grid> */}
                {/* <Grid item xs={2}>
                  <Typography className="ap">Lat-Long</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="lat_long"
                    value={formData.latitude + '-' + formData.longitude}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Field Agent Name</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="field_agent_name"
                    value={formData.field_agent_name}
                    onChange={handleChange}  disabled = {true}
                  />
                </Grid> */}
                 <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">MAP LOCATION:</Typography>
                </Grid>
                <Grid item xs={1} style={{paddingLeft:0}}>
                  <Typography className="ap">Lat-Long</Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="lat_long"
                    value={formData.latitude + '-' + formData.longitude}
                    onChange={handleChange}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Field Agent Name</Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="field_agent_name"
                    value={formData.field_agent_name}
                    onChange={handleChange}  disabled = {true}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Typography className="ap">Deviation</Typography>
                </Grid>
                <Grid item xs={3} style={{paddingLeft:30}}>

<TextField
  fullWidth
  variant="outlined"
  name="deviation"
  value={formData.deviation}
  onChange={handleChange}
  disabled={true}
/>
</Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={4}>
                  <MapComponent
                    latitude={parseFloat(auditdata?.latitude)}
                    longitude={parseFloat(auditdata?.longitude)}
                    style={{
                      borderRadius: 5,
                      borderWidth: 3,
                      borderColor: "gray",
                    }}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">
                    SNAPS TAKEN FROM VERIFICATION POINT:
                  </Typography>
                </Grid>
                <Grid container style={{ marginTop: 20 }}>
                  {auditdata?.files_name &&
                    auditdata?.files_name.map((item, index) => (
                      <Grid item xs={3}>
                        <img
                          src={`${item.imageUrl}`}
                          // src= 'https://firebasestorage.googleapis.com/v0/b/aztek-768f8.appspot.com/o/images%2Ftest12324_0.jpg?alt=media&token=ba2f3e23-01d4-42a7-9067-aa9a2d6016ae'
                          style={{
                            width: 250,
                            height: 350,
                            borderRadius: 5,
                            borderColor: "gray",
                            borderWidth: 2,
                            borderStyle: "groove",
                          }}
                        ></img>
                      </Grid>
                    ))}
                </Grid>

                <Grid
                  item
                  xs={6}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Button
                    style={{ backgroundColor: "blue", color: "white" }}
                    onClick={handleSaveChanges}
                  >
                    Save Changes
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Button
                    onClick={handleCloseproperty}
                    style={{ backgroundColor: "red", color: "white" }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openrcd}
          onClose={handleCloseresidence}
          id="audit"
          style={{ maxWidth: "100%", width: "100%", paddingBottom: 50 }}
        >
          <DialogTitle style={{ textAlign: "center", fontSize: 24 }}>
            <Grid item xs={12}>
              <Typography variant="h5" align="center" fontWeight="bold">
                {formData.company_name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" fontWeight="bold">
                Residence Verification Report
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ textAlign: "center", marginTop: "14px" }}
            >
              <Button
                onClick={handleCloseresidence}
                style={{
                  backgroundColor: "red",
                  color: "white",
                  position: "absolute",
                  right: "2%",
                  top: "4%",
                }}
              >
                Cancel
              </Button>
            </Grid>
          </DialogTitle>
          <DialogContent className="dcp">
            <div className="pdf-container">
              {/* <h5>{auditdata}</h5> */}

              <Grid
                container
                spacing={2}
                className="pdf-table"
                style={{
                  border: "1px solid black",
                  padding: "16px",
                  marginBottom: 10,
                }}
              >
                <Grid item xs={2}>
                  <Typography className="ap">Product Type</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="product_type"
                    value={formData.product_type}
                    onChange={handleChange}
                  /> */}
                  {/* <Autocomplete
       className="form"
          id="product_type"
          options={product_deatils}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.product_type, label:formData.product_type}}
        name="product_type"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="product_type" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["product_type"] = el}  // Store ref by unique id
        options={product_deatils.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.product_type, label: formData.product_type }}
        onChange={(selectedOption) => handleChanged(selectedOption, "product_type")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "product_type")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Case Number</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="case_id"
                    value={formData.case_id}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Applicant Name</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="applicant_name"
                    value={formData.applicant_name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Applicant Phone No.</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="applicant_number"
                    value={formData.applicant_number}
                    onChange={handleChange}
                  />
                </Grid>

                {/* <Grid item xs={2}>
                  <Typography className="ap">Company Name</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="company_name"
                    value={formData.company_name}
                    onChange={handleChange}
                  />
                </Grid> */}
                <Grid item xs={2}>
                  <Typography className="ap">Telephone</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="office_telephone"
                    value={formData.office_telephone}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Address</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Landmark</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="landmark"
                    value={formData.landmark}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Client Name</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="client_name"
                    value={formData.client_name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Case Allocation Date</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="i_date"
                    value={formatDate(formData.i_date) + "  " +  formData.i_time}
                    onChange={handleChange}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Visited At</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="u_date"
                    value={formatDate(formData.c_date) + "  " +  formData.c_time}
                    onChange={handleChange}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Submitted At</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="c_date"
                    value={formatDate1(formData.audit_date) + "  " +  formData.audit_time}
                    onChange={handleChange}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Overall Status</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="overallstatus"
                    value={formData.overallstatus}
                    onChange={handleChange}
                  /> */}
                          {/* <Autocomplete
       className="form"
          id="overallstatus"
          options={overall_status}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.overallstatus, label:formData.overallstatus}}
        name="overallstatus"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined" name="overallstatus"  style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["overallstatus"] = el}  // Store ref by unique id
        options={overall_status.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.overallstatus, label: formData.overallstatus }}
        onChange={(selectedOption) => handleChanged(selectedOption, "overallstatus")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "overallstatus")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">
                    Reason for Negative FI (If Any)
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="reasonfornegativefi"
                    value={formData.reasonfornegativefi}
                    onChange={handleChange}
                  /> */}
                     {/* <Autocomplete
       className="form"
          id="reasonfornegativefi"
          options={reason_for_negative_r}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.reasonfornegativefi, label:formData.reasonfornegativefi}}
        name="reasonfornegativefi"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="reasonfornegativefi" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["reasonfornegativefi"] = el}  // Store ref by unique id
        options={reason_for_negative_r.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.reasonfornegativefi, label: formData.reasonfornegativefi }}
        onChange={(selectedOption) => handleChanged(selectedOption, "reasonfornegativefi")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "reasonfornegativefi")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>

                <Grid item xs={12}>
                  <Typography className="ap">Remarks</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="remarks"
                    value={formData.remarks}
                    multiline
                    rows={4}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">
                    THE FOLLOWING INFORMATION IS TO BE OBTAINED FROM
                    APPLICANT(S) OR ANYBODY RESIDING AT THE ADDRESS:
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Person Met</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="name_of_person_met"
                    value={formData.name_of_person_met}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">
                    Relationship with Applicant
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="relation_with_applicant"
                    value={formData.relation_with_applicant}
                    onChange={handleChange}
                  /> */}
                   {/* <Autocomplete
       className="form"
          id="relation_with_applicant"
          options={relation_with_applicant}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.relation_with_applicant, label:formData.relation_with_applicant}}
        name="relation_with_applicant"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="relation_with_applicant" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["relation_with_applicant"] = el}  // Store ref by unique id
        options={relation_with_applicant.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.relation_with_applicant, label: formData.relation_with_applicant }}
        onChange={(selectedOption) => handleChanged(selectedOption, "relation_with_applicant")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "relation_with_applicant")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Applicant's Age</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="applicant_age"
                    value={formData.applicant_age}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">
                    Educational Qualification
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="educational_qualification"
                    value={formData.educational_qualification}
                    onChange={handleChange}
                  /> */}
                     {/* <Autocomplete
       className="form"
          id="educational_qualification"
          options={educational_qualification}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.educational_qualification, label:formData.educational_qualification}}
        name="educational_qualification"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="educational_qualification" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["educational_qualification"] = el}  // Store ref by unique id
        options={educational_qualification.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.educational_qualification, label: formData.educational_qualification }}
        onChange={(selectedOption) => handleChanged(selectedOption, "educational_qualification")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "educational_qualification")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Staying Since</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="time_of_stay_current_address"
                    value={formData.time_of_stay_current_address}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Address Proof Details</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="address_proof_details"
                    value={formData.address_proof_details}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">
                    DETAILS OF FAMILY MEMBER(S):
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Total Family Members</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="total_family_members"
                    value={formData.total_family_members}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Working Family Members</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="earning_members"
                    value={formData.earning_members}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">
                    Dependent (Adults) Member
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="dependent_members"
                    value={formData.dependent_members}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">
                    Dependent (Children) Member
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="dependent_members_child"
                    value={formData.dependent_members_child}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">
                    Retired / Pensioner Member
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="retired_pensioner_member"
                    value={formData.retired_pensioner_member}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">
                    Applicant's Marital Status
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="marital_status"
                    value={formData.marital_status}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Is Spouse Earning?</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="spouse_earning"
                    value={formData.spouse_earning}
                    onChange={handleChange}
                  /> */}
                      {/* <Autocomplete
       className="form"
          id="spouse_earning"
          options={yesnona}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.spouse_earning, label:formData.spouse_earning}}
        name="spouse_earning"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="spouse_earning" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["spouse_earning"] = el}  // Store ref by unique id
        options={yesnona.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.spouse_earning, label: formData.spouse_earning }}
        onChange={(selectedOption) => handleChanged(selectedOption, "spouse_earning")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "spouse_earning")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">
                    Spouse Employment Details
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="spouse_employment_details"
                    value={formData.spouse_employment_details}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">HOUSE DETAILS:</Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">
                    Does applicant stay at this Address?
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="applicant_stay_at_address"
                    value={formData.applicant_stay_at_address}
                    onChange={handleChange}
                  /> */}
                   {/* <Autocomplete
       className="form"
          id="applicant_stay_at_address"
          options={yesnona}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.applicant_stay_at_address, label:formData.applicant_stay_at_address}}
        name="applicant_stay_at_address"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="applicant_stay_at_address" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["applicant_stay_at_address"] = el}  // Store ref by unique id
        options={yesnona.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.applicant_stay_at_address, label: formData.applicant_stay_at_address }}
        onChange={(selectedOption) => handleChanged(selectedOption, "applicant_stay_at_address")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "applicant_stay_at_address")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Type Of Accommodation</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="type_of_accommodation"
                    value={formData.type_of_accommodation}
                    onChange={handleChange}
                  /> */}
                   {/* <Autocomplete
       className="form"
          id="type_of_accommodation"
          options={type_of_accommodation}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.type_of_accommodation, label:formData.type_of_accommodation}}
        name="type_of_accommodation"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="type_of_accommodation" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["type_of_accommodation"] = el}  // Store ref by unique id
        options={type_of_accommodation.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.type_of_accommodation, label: formData.type_of_accommodation }}
        onChange={(selectedOption) => handleChanged(selectedOption, "type_of_accommodation")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "type_of_accommodation")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Type of House</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="type_of_house"
                    value={formData.type_of_house}
                    onChange={handleChange}
                  /> */}
                   {/* <Autocomplete
       className="form"
          id="type_of_house"
          options={type_of_house}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.type_of_house, label:formData.type_of_house}}
        name="type_of_house"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="type_of_house" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["type_of_house"] = el}  // Store ref by unique id
        options={type_of_house.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.type_of_house, label: formData.type_of_house }}
        onChange={(selectedOption) => handleChanged(selectedOption, "type_of_house")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "type_of_house")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">House Condition</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="house_condition"
                    value={formData.house_condition}
                    onChange={handleChange}
                  /> */}
                  {/* <Autocomplete
       className="form"
          id="house_condition"
          options={house_condition}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.house_condition, label:formData.house_condition}}
        name="house_condition"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="house_condition" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["house_condition"] = el}  // Store ref by unique id
        options={house_condition.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.house_condition, label: formData.house_condition }}
        onChange={(selectedOption) => handleChanged(selectedOption, "house_condition")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "house_condition")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Ownership</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="ownership"
                    value={formData.ownership}
                    onChange={handleChange}
                  /> */}
                    {/* <Autocomplete
       className="form"
          id="ownership"
          options={office_ownership}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.ownership, label:formData.ownership}}
        name="ownership"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="ownership" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["ownership"] = el}  // Store ref by unique id
        options={office_ownership.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.ownership, label: formData.ownership }}
        onChange={(selectedOption) => handleChanged(selectedOption, "ownership")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "ownership")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Exterior</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="exterior"
                    value={formData.exterior}
                    onChange={handleChange}
                  /> */}
                     {/* <Autocomplete
       className="form"
          id="exterior"
          options={house_condition}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.exterior, label:formData.exterior}}
        name="exterior"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="exterior" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["exterior"] = el}  // Store ref by unique id
        options={house_condition.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.exterior, label: formData.exterior }}
        onChange={(selectedOption) => handleChanged(selectedOption, "exterior")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "exterior")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Type of Locality</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="type_of_locality"
                    value={formData.type_of_locality}
                    onChange={handleChange}
                  /> */}
                   {/* <Autocomplete
       className="form"
          id="type_of_locality"
          options={type_of_locality}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.type_of_locality, label:formData.type_of_locality}}
        name="type_of_locality"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="type_of_locality" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["type_of_locality"] = el}  // Store ref by unique id
        options={type_of_locality.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.type_of_locality, label: formData.type_of_locality }}
        onChange={(selectedOption) => handleChanged(selectedOption, "type_of_locality")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "type_of_locality")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Standard of Living</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="standard_of_living"
                    value={formData.standard_of_living}
                    onChange={handleChange}
                  /> */}
                   {/* <Autocomplete
       className="form"
          id="standard_of_living"
          options={house_condition}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.standard_of_living, label:formData.standard_of_living}}
        name="standard_of_living"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="standard_of_living" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["standard_of_living"] = el}  // Store ref by unique id
        options={house_condition.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.standard_of_living, label: formData.standard_of_living }}
        onChange={(selectedOption) => handleChanged(selectedOption, "standard_of_living")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "standard_of_living")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">
                    THE FOLLOWING IS BASED ON VERIFIER OBSERVATION:
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Neighbour Name 1</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="neighbour_name_1"
                    value={formData.neighbour_name_1}
                    onChange={handleChange}
                  />
                </Grid>
               

                <Grid item xs={2}>
                  <Typography className="ap">Address 1</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="address_1"
                    value={formData.address_1}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Neighbour Name 2</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="neighbour_name_2"
                    value={formData.neighbour_name_2}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Address 2</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="address_2"
                    value={formData.address_2}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Neighbour Feedback</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="neighbour_feedback"
                    value={formData.neighbour_feedback}
                    onChange={handleChange}
                  /> */}
                      {/* <Autocomplete
       className="form"
          id="neighbour_feedback"
          options={neighbour_feedback}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.neighbour_feedback, label:formData.neighbour_feedback}}
        name="neighbour_feedback"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="neighbour_feedback" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["neighbour_feedback"] = el}  // Store ref by unique id
        options={neighbour_feedback.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.neighbour_feedback, label: formData.neighbour_feedback }}
        onChange={(selectedOption) => handleChanged(selectedOption, "neighbour_feedback")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "neighbour_feedback")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Ease To Locate</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="ease_to_locate"
                    value={formData.ease_to_locate}
                    onChange={handleChange}
                  /> */}
                   {/* <Autocomplete
       className="form"
          id="ease_to_locate"
          options={easy_to_locate}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.ease_to_locate, label:formData.ease_to_locate}}
        name="ease_to_locate"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="ease_to_locate" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["ease_to_locate"] = el}  // Store ref by unique id
        options={easy_to_locate.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.ease_to_locate, label: formData.ease_to_locate }}
        onChange={(selectedOption) => handleChanged(selectedOption, "ease_to_locate")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "ease_to_locate")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Vehicle Seen</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="vehicle_seen"
                    value={formData.vehicle_seen}
                    onChange={handleChange}
                  /> */}
                   {/* <Autocomplete
       className="form"
          id="vehicle_seen"
          options={yesnona}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.vehicle_seen, label:formData.vehicle_seen}}
        name="vehicle_seen"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="vehicle_seen" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["vehicle_seen"] = el}  // Store ref by unique id
        options={yesnona.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.vehicle_seen, label: formData.vehicle_seen }}
        onChange={(selectedOption) => handleChanged(selectedOption, "vehicle_seen")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "vehicle_seen")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Loan Amount</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="loanamount"
                    value={formData.loanamount}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Political Link</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="political_link"
                    value={formData.political_link}
                    onChange={handleChange}
                  /> */}
                    {/* <Autocomplete
       className="form"
          id="political_link"
          options={yesnona}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.political_link, label:formData.political_link}}
        name="political_link"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="political_link" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["political_link"] = el}  // Store ref by unique id
        options={yesnona.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.political_link, label: formData.political_link }}
        onChange={(selectedOption) => handleChanged(selectedOption, "political_link")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "political_link")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">MAP LOCATION:</Typography>
                </Grid>
                <Grid item xs={1} style={{paddingLeft:0}}>
                  <Typography className="ap">Lat-Long</Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="lat_long"
                    value={formData.latitude + '-' + formData.longitude}
                    onChange={handleChange}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Field Agent Name</Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="field_agent_name"
                    value={formData.field_agent_name}
                    onChange={handleChange}  disabled = {true}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Typography className="ap">Deviation</Typography>
                </Grid>
                <Grid item xs={3} style={{paddingLeft:30}}>

                  <TextField
                    fullWidth
                    variant="outlined"
                    name="deviation"
                    value={formData.deviation}
                    onChange={handleChange}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={4}>
                  <MapComponent
                    latitude={parseFloat(auditdata?.latitude)}
                    longitude={parseFloat(auditdata?.longitude)}
                    style={{
                      borderRadius: 5,
                      borderWidth: 3,
                      borderColor: "gray",
                    }}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">
                    SNAPS TAKEN FROM VERIFICATION POINT:
                  </Typography>
                </Grid>
                <Grid container style={{ marginTop: 20 }}>
                  {auditdata?.files_name &&
                    auditdata?.files_name.map((item, index) => (
                      <Grid item xs={3}>
                        <img
                          src={`${item.imageUrl}`}
                              //  src= 'https://firebasestorage.googleapis.com/v0/b/aztek-768f8.appspot.com/o/images%2Ftest12324_0.jpg?alt=media&token=ba2f3e23-01d4-42a7-9067-aa9a2d6016ae'
                          style={{
                            width: 250,
                            height: 350,
                            borderRadius: 5,
                            borderColor: "gray",
                            borderWidth: 2,
                            borderStyle: "groove",
                          }}
                        ></img>
                      </Grid>
                    ))}
                </Grid>

                <Grid
                  item
                  xs={6}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Button
                    style={{ backgroundColor: "blue", color: "white" }}
                    onClick={handleSaveChanges}
                  >
                    Save Changes
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Button
                    onClick={handleCloseresidence}
                    style={{ backgroundColor: "red", color: "white" }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openastd}
          onClose={handleCloseasset}
          id="audit"
          style={{ maxWidth: "100%", width: "100%", paddingBottom: 50 }}
        >
          <DialogTitle style={{ textAlign: "center", fontSize: 24 }}>
            <Grid item xs={12}>
              <Typography variant="h5" align="center" fontWeight="bold">
                {formData.company_name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" fontWeight="bold">
                Asset Verification Report
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ textAlign: "center", marginTop: "14px" }}
            >
              <Button
                onClick={handleCloseasset}
                style={{
                  backgroundColor: "red",
                  color: "white",
                  position: "absolute",
                  right: "2%",
                  top: "4%",
                }}
              >
                Cancel
              </Button>
            </Grid>
          </DialogTitle>
          <DialogContent className="dcp">
            <div className="pdf-container">
              {/* <h5>{auditdata}</h5> */}

              <Grid
                container
                spacing={2}
                className="pdf-table"
                style={{
                  border: "1px solid black",
                  padding: "16px",
                  marginBottom: 10,
                }}
              >
                <Grid item xs={2}>
                  <Typography className="ap">Client Name</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="client_name"
                    value={formData.client_name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Product Type</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="product_type"
                    value={formData.product_type}
                    InputProps={{
                      readOnly: true,
                    }}
                  /> */}
                  {/* <Autocomplete
       className="form"
          id="product_type"
          options={product_deatils}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.product_type, label:formData.product_type}}
        name="product_type"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="product_type" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["product_type"] = el}  // Store ref by unique id
        options={product_deatils.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.product_type, label: formData.product_type }}
        onChange={(selectedOption) => handleChanged(selectedOption, "product_type")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "product_type")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Sample Date</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="i_date"
                    value={formatDate(formData.i_date) + "  " +  formData.i_time}
                    onChange={handleChange}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Report Date</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="c_date"
                    value={formatDate(formData.c_date)}
                    onChange={handleChange}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Application No.</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="case_id"
                    value={formData.case_id}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Applicant Name</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="applicant_name"
                    value={formData.applicant_name}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Vehicle Model</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="vehicle_model"
                    value={formData.vehicle_model}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Loan Amount</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="loanamount"
                    value={formData.loanamount}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">DMA / Dealer </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="dma"
                    value={formData.dma}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">RM Name</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="rm_name"
                    value={formData.rm_name}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Location</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Product</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="product_type"
                    value={formData.product_type}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap"> Co- Applicant Name</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="co_applcant_name"
                    value={formData.co_applcant_name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Guarantor Name</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="guarntor_name"
                    value={formData.guarntor_name}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Pick up Criteria</Typography>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="pick_up_criteria"
                    value={formData.pick_up_criteria}
                    multiline
                    rows={4}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Verification Type</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="verification_type"
                    value={formData.verification_type}
                    // multiline
                    // rows={4}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">RCU STATUS:</Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">STATUS</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="overallstatus"
                    value={formData.overallstatus}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Reason for Rejection</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="reason_for_rejection"
                    value={formData.reason_for_rejection}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Discrepant Document</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="discrepant_document"
                    value={formData.discrepant_document}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Discrepant Profile</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="discrepant_profile"
                    value={formData.discrepant_profile}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Negative</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="negative"
                    value={formData.negative}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Sourcing Issues</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="sourcing_issues"
                    value={formData.sourcing_issues}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">
                    RCU VERIFICATION CHECK POINTS:
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">
                    Applicant residing at given address
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="applicant_stay_at_address"
                    value={formData.applicant_stay_at_address}
                    onChange={handleChange}
                  /> */}
                  {/* <Autocomplete
       className="form"
          id="applicant_stay_at_address"
          options={yesnona}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.applicant_stay_at_address, label:formData.applicant_stay_at_address}}
        name="applicant_stay_at_address"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="applicant_stay_at_address" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["applicant_stay_at_address"] = el}  // Store ref by unique id
        options={yesnona.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.applicant_stay_at_address, label: formData.applicant_stay_at_address }}
        onChange={(selectedOption) => handleChanged(selectedOption, "applicant_stay_at_address")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "applicant_stay_at_address")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Residence Stability</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="residence_stability"
                    value={formData.residence_stability}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Residence ownership</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    variant="outlined"
                    name="ownership"
                    value={formData.ownership}
                    onChange={handleChange}
                  /> */}
                    {/* <Autocomplete
       className="form"
          id="ownership"
          options={office_ownership}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.ownership, label:formData.ownership}}
        name="ownership"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="ownership" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["ownership"] = el}  // Store ref by unique id
        options={office_ownership.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.ownership, label: formData.ownership }}
        onChange={(selectedOption) => handleChanged(selectedOption, "ownership")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "ownership")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Occupation details</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="occupation_details"
                    value={formData.occupation_details}
                    onChange={handleChange}
                  />
                  
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">
                    End Use – whether proposed TW is to be used by self/others
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="end_use"
                    value={formData.end_use}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Neighbor check</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="neighbour_check"
                    value={formData.neighbour_check}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography className="ap">Remarks</Typography>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="remarks"
                    value={formData.remarks}
                    onChange={handleChange}
                  />
                </Grid>
                {/* <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">MAP LOCATION:</Typography>
                </Grid> */}
                {/* <Grid item xs={2}>
                  <Typography className="ap">Lat-Long</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="lat_long"
                    value={formData.latitude + '-' + formData.longitude}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Field Agent Name</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="field_agent_name"
                    value={formData.field_agent_name}
                    onChange={handleChange}  disabled = {true}
                  />
                </Grid> */}
                 <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">MAP LOCATION:</Typography>
                </Grid>
                <Grid item xs={1} style={{paddingLeft:0}}>
                  <Typography className="ap">Lat-Long</Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="lat_long"
                    value={formData.latitude + '-' + formData.longitude}
                    onChange={handleChange}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="ap">Field Agent Name</Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="field_agent_name"
                    value={formData.field_agent_name}
                    onChange={handleChange}  disabled = {true}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Typography className="ap">Deviation</Typography>
                </Grid>
                <Grid item xs={3} style={{paddingLeft:30}}>

<TextField
  fullWidth
  variant="outlined"
  name="deviation"
  value={formData.deviation}
  onChange={handleChange}
  disabled={true}
/>
</Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={4}>
                  <MapComponent
                    latitude={parseFloat(auditdata?.latitude)}
                    longitude={parseFloat(auditdata?.longitude)}
                    style={{
                      borderRadius: 5,
                      borderWidth: 3,
                      borderColor: "gray",
                    }}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Typography className="aph">
                    SNAPS TAKEN FROM VERIFICATION POINT:
                  </Typography>
                </Grid>
                <Grid container style={{ marginTop: 20 }}>
                  {auditdata?.files_name &&
                    auditdata?.files_name.map((item, index) => (
                      <Grid item xs={3}>
                        <img
                          src={`${item.imageUrl}`}
                          style={{
                            width: 250,
                            height: 350,
                            borderRadius: 5,
                            borderColor: "gray",
                            borderWidth: 2,
                            borderStyle: "groove",
                          }}
                        ></img>
                      </Grid>
                    ))}
                </Grid>

                <Grid
                  item
                  xs={6}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Button
                    style={{ backgroundColor: "blue", color: "white" }}
                    onClick={handleSaveChanges}
                  >
                    Save Changes
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ textAlign: "center", marginTop: "14px" }}
                >
                  <Button
                    onClick={handleCloseasset}
                    style={{ backgroundColor: "red", color: "white" }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openbstd}
          onClose={handleClosebst}
         id="audit"
         
          style={{ maxWidth: "100%", width: "100%", paddingBottom: 50 }}
        >
          <DialogTitle>
            <Grid item xs={12}>
              <Typography variant="h5" align="center" fontWeight="bold">
                {formData.company_name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" fontWeight="bold">
                Bank Statement Verification Report
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ textAlign: "center", marginTop: "14px" }}
            >
              <Button
                onClick={handleClosebst}
                style={{
                  backgroundColor: "red",
                  color: "white",
                  position: "absolute",
                  right: "2%",
                  top: "4%",
                }}
              >
                Cancel
              </Button>
            </Grid>
          </DialogTitle>
          <DialogContent style={{ paddingTop: 30 }}>
            {/* <Grid container spacing={2} className="tableContainer" style={{paddingTop:20}}> */}
            <Grid
                container
                spacing={2}
                 className="pdf-table"
                style={{
                  border: "1px solid black",
                  padding: "16px",
                  marginBottom: 10,
                }}
              >
            <Grid item xs={2}>
                <Typography className="ap">Client Name</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="client_name"
                  value={formData.client_name}
                  onChange={handleChange}
                  label="Client Name"
                />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">Branch Name</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="branch_name"
                  value={formData.branch_name}
                  onChange={handleChange}
                  label="Branch Name"
                />
              </Grid>
              {/* <Grid item xs={2}>
                <Typography className="ap">
                  Agency Name
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="company_name"
                  value={formData.company_name}
                  onChange={handleChange}
                  label="Agency Name"
                />
              </Grid> */}
              <Grid item xs={2}>
                <Typography className="ap">
                Reference No
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="ref_no"
                  value={formData.ref_no}
                  onChange={handleChange}
                  label="Reference No."
                />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">
                Date of Receipt
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="date_of_receipt"
                  value={formatDate(formData.i_date) + "  " +  formData.i_time}
                  onChange={handleChange}
                  label="Date of Receipt"
                  type="text"
                  disabled={true}

                />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">
                Date of Report
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="date_of_report"
                  value={formData.date_of_report}
                  onChange={handleChange}
                  label="Date of Report"
                  type="text"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">
                Product Type
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="product_type"
                  value={formData.product_type}
                  onChange={handleChange}
                  label="Product Type"
                />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">
                Reported By
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="reported_by"
                  value={formData.reported_by}
                  onChange={handleChange}
                  label="Reported By"
                />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">Applicant Name</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="applicant_name"
                  value={formData.applicant_name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">Documents Type</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>Bank Statement</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">Bank Name</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="bank_name"
                  value={formData.bank_name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">Account No</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="account_no"
                  value={formData.account_no}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">Account Opening Date</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="account_opening_date"
                  value={formData.account_opening_date}
                  onChange={handleChange}
                  type="date"
                />
              </Grid>
              {/* <Grid container spacing={2} className="tableContainer" style={{marginTop:20, borderRadius:5, borderWidth:2, borderStyle:'groove', paddingBottom:20, paddingLeft:20, paddingRight:20, marginLeft:20}}>
              <Grid item xs={4}>
                <Typography className="ap">TRANSACTION DATE</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="ap">
                  TRANS. AMOUNT AS RECEIVED
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="ap">
                  TRANS. AMOUNT AS VERIFIED
                </Typography>
              </Grid>
              {transactions &&
                transactions.map((transaction, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name={`date`}
                        id={transaction.id}
                        value={transaction.date}
                        onChange={handlebsChange}
                        // label="Transaction Date"
                        type="date"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name={`amount_received`}
                        id={transaction.id}
                        value={transaction.amount_received}
                        onChange={handlebsChange}
                        label="Amount as Received"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name={`amount_verified`}
                        id={transaction.id}
                        value={transaction.amount_verified}
                        onChange={handlebsChange}
                        label="Amount as Verified"
                      />
                    </Grid>
                  </React.Fragment>
                ))}
         </Grid> */}
 <TableContainer component={Paper} style={{ marginTop: 20, borderRadius: 5, borderWidth: 2, borderStyle: 'groove', paddingBottom: 20, paddingLeft: 20, paddingRight: 20, marginLeft: 20 }}>
      <Table>
        <TableHead>
         
        </TableHead>
        <TableBody>
        <TableRow>
            <TableCell style={ {width:'40%',border: '1px solid black'}}><Typography className="ap">TRANSACTION DATE</Typography></TableCell>
            <TableCell style={ {width:'30%',border: '1px solid black'}}><Typography className="ap">TRANS. AMOUNT AS RECEIVED</Typography></TableCell>
            <TableCell style={ {width:'30%',border: '1px solid black'}}><Typography className="ap">TRANS. AMOUNT AS VERIFIED</Typography></TableCell>
          </TableRow>
          {transactions && transactions.map((transaction, index) => (
            <TableRow key={index}>
              <TableCell style={ {width:'40%',border: '1px solid black'}}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name={`date`}
                  id={transaction.id}
                  value={transaction.date}
                  onChange={handlebsChange}
                  type="date"
                />
              </TableCell>
              <TableCell style={ {width:'30%',border: '1px solid black'}}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name={`amount_received`}
                  id={transaction.id}
                  value={transaction.amount_received}
                  onChange={handlebsChange}
                  label="Amount as Received"
                />
              </TableCell>
              <TableCell style={{width:'30%',border: '1px solid black'}}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name={`amount_verified`}
                  id={transaction.id}
                  value={transaction.amount_verified}
                  onChange={handlebsChange}
                  label="Amount as Verified"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
         
              <Grid item xs={1}>
                <Typography className="ap">STATUS</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="overallstatus"
                  value={formData.overallstatus}
                  onChange={handleChange}
                  label="Status"
                />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">Final Remarks</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="final_remarks"
                  value={formData.final_remarks}
                  onChange={handleChange}
                  label="Final Remarks"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                style={{ textAlign: "center", marginTop: "30px" }}
              >
                <Button
                  style={{ backgroundColor: "blue", color: "white" }}
                  onClick={handleSaveChanges}
                >
                  Save Changes
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ textAlign: "center", marginTop: "30px" }}
              >
                <Button
                  onClick={handleClosebst}
                  style={{ backgroundColor: "red", color: "white" }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openfbd}
          onClose={handleClosefb}
          // fullWidth
          id="audit"
          maxWidth="md"
          style={{ maxWidth: "100%", width: "100%", paddingBottom: 20 }}
        >
          <DialogTitle>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h5" align="center" fontWeight="bold">
                {formData.company_name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" align="center" fontWeight="bold">
                  FINANCIAL AND BALANCE SHEET VERIFICATION REPORT
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ textAlign: "right", marginTop: "14px" }}
              >
                <Button
                  onClick={handleClosefb}
                  style={{
                    backgroundColor: "red",
                    color: "white",
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent style={{ paddingTop: 30 }}>
          <Grid
                container
                spacing={2}
                className="pdf-table"
                style={{
                  border: "1px solid black",
                  padding: "16px",
                  marginBottom: 10,
                }}
              >
              <Grid item xs={2}>
                <Typography style={{ fontSize: 16, fontWeight: "bold",textAlign: "left", }}>
                 Client Name
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="client_name"
                  value={formData.client_name}
                  onChange={handleChange}
                  label="CLIENT NAME"
                />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                 Branch Name
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="branch_name"
                  value={formData.branch_name}
                  onChange={handleChange}
                  label="BRANCH NAME"
                />
              </Grid>
              {/* <Grid item xs={6}>
                <Typography style={{ fontSize: 16, fontWeight: "bold" }}>
                  Agency Name: AZTEK SOLUTIONS LLP
                </Typography>
              </Grid> */}
              <Grid item xs={2}>
                <Typography
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  Refference No
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="ref_no"
                  value={formData.ref_no}
                  onChange={handleChange}
                  label="Reference No."
                />
              </Grid>
              <Grid item xs={2}>
                <Typography style={{ fontSize: 16, fontWeight: "bold" ,textAlign: "left",}}>
                  Date of Receipt:
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="date_of_receipt"
                  value={formatDate(formData.i_date) + "  " +  formData.i_time}
                  onChange={handleChange}
                  label="Date of Receipt"
                  type="text"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  Date of Report:
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="date_of_report"
                  value={formData.date_of_report}
                  onChange={handleChange}
                  label="Date of Report"
                  type="text"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography style={{ fontSize: 16, fontWeight: "bold",textAlign: "left", }}>
                  Product Type:
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="product_type"
                  value={formData.product_type}
                  onChange={handleChange}
                  label="Product Type"
                />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  Reported By:
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="reported_by"
                  value={formData.reported_by}
                  onChange={handleChange}
                  label="Reported By"
                />
              </Grid>
              <Grid item xs={2}>
                <Typography style={{ fontSize: 16, fontWeight: "bold" ,textAlign: "left",}}>
                  Applicant Name:
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="applicant_name"
                  value={formData.applicant_name}
                  onChange={handleChange}
                  label="Applicant Name"
                />
              </Grid>

              <Grid
                container
                spacing={2}
                style={{
                  borderWidth: 2,
                  borderStyle: "groove",
                  padding: 20,
                  marginLeft: 5,
                  marginTop: 10,
                  borderRadius: 5,
                }}
              >
                <Grid item xs={2}>
                  <Typography style={{ fontSize: 16, fontWeight: "bold" ,textAlign: "left",}}>
                    Documents Type
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography style={{ fontSize: 16 }}>
                    FINANCIALS/BALANCE SHEET
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography style={{ fontSize: 16, fontWeight: "bold",textAlign: "left", }}>
                    PAN NO:
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="panno"
                    value={formData.panno}
                    onChange={handleChange}
                    label="PAN NO"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography></Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    style={{
                      textAlign: "center",
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                  >
                    AS RECEIVED
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    style={{
                      textAlign: "center",
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                  >
                    AS VERIFIED
                  </Typography>
                </Grid>
                {/* <Grid item xs={2}>
                  <Typography
                    style={{
                      fontSize: 13,
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    FINANCIAL YEAR
                  </Typography>
                </Grid>
                <Grid item xs={1.25}>
                  <Typography className="shead">Gross Income</Typography>
                </Grid>
                <Grid item xs={1.25}>
                  <Typography className="shead">Total Income</Typography>
                </Grid>
                <Grid item xs={1.25}>
                  <Typography className="shead">Sundry Creditors</Typography>
                </Grid>
                <Grid item xs={1.25}>
                  <Typography className="shead">Sundry Debtors</Typography>
                </Grid>
                <Grid item xs={1.25}>
                  <Typography className="shead">Gross Income</Typography>
                </Grid>
                <Grid item xs={1.25}>
                  <Typography className="shead">Total Income</Typography>
                </Grid>
                <Grid item xs={1.25}>
                  <Typography className="shead">Sundry Creditors</Typography>
                </Grid>
                <Grid item xs={1.25}>
                  <Typography className="shead">Sundry Debtors</Typography>
                </Grid>
                {financialYears &&
                  financialYears.map((year, index) => (
                    <React.Fragment key={index}>
                      <Grid item xs={2}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          name={`year`}
                          value={year.year}
                          onChange={handlefbChange}
                          label="Year"
                          id={year.id}
                        />
                      </Grid>
                      <Grid item xs={1.25}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          name={`received.grossIncome`}
                          value={year.received.grossIncome}
                          onChange={handlefbChange}
                          label="Gross Income (Received)"
                          id={year.id}
                        />
                      </Grid>
                      <Grid item xs={1.25}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          name={`received.totalIncome`}
                          value={year.received.totalIncome}
                          onChange={handlefbChange}
                          label="Total Income (Received)"
                          id={year.id}
                        />
                      </Grid>
                      <Grid item xs={1.25}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          name={`received.sundryCreditors`}
                          value={year.received.sundryCreditors}
                          onChange={handlefbChange}
                          label="Sundry Creditors (Received)"
                          id={year.id}
                        />
                      </Grid>
                      <Grid item xs={1.25}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          name={`received.sundryDebtors`}
                          value={year.received.sundryDebtors}
                          onChange={handlefbChange}
                          label="Sundry Debtors (Received)"
                          id={year.id}
                        />
                      </Grid>
                      <Grid item xs={1.25}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          name={`verified.grossIncome`}
                          value={year.verified.grossIncome}
                          onChange={handlefbChange}
                          label="Gross Income (Verified)"
                          id={year.id}
                        />
                      </Grid>
                      <Grid item xs={1.25}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          name={`verified.totalIncome`}
                          value={year.verified.totalIncome}
                          onChange={handlefbChange}
                          label="Total Income (Verified)"
                          id={year.id}
                        />
                      </Grid>
                      <Grid item xs={1.25}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          name={`verified.sundryCreditors`}
                          value={year.verified.sundryCreditors}
                          onChange={handlefbChange}
                          label="Sundry Creditors (Verified)"
                          id={year.id}
                        />
                      </Grid>
                      <Grid item xs={1.25}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          name={`verified.sundryDebtors`}
                          value={year.verified.sundryDebtors}
                          onChange={handlefbChange}
                          label="Sundry Debtors (Verified)"
                          id={year.id}
                        />
                      </Grid>
                    </React.Fragment>
                  ))} */}
                   <TableContainer component={Paper} style={{ marginTop: 20, borderRadius: 5, borderWidth: 2, borderStyle: 'groove', paddingBottom: 20, paddingLeft: 20, paddingRight: 20, marginLeft: 0 }}>
      <Table>
        <TableHead>
        
        </TableHead>
        <TableBody>
        <TableRow>
            <TableCell style={{ ...cellStyle, width: '16.666%' }}>
              <Typography
                style={{
                  fontSize: 13,
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                FINANCIAL YEAR
              </Typography>
            </TableCell>
            {['Gross Income', 'Total Income', 'Sundry Creditors', 'Sundry Debtors', 'Gross Income', 'Total Income', 'Sundry Creditors', 'Sundry Debtors'].map((header, index) => (
              <TableCell key={index} style={{ ...cellStyle, width: '10.416%' }}>
                <Typography className="shead">{header}</Typography>
              </TableCell>
            ))}
          </TableRow>
          {financialYears && financialYears.map((year, index) => (
            <TableRow key={index}>
              <TableCell style={{border: '1px solid black', padding:5}}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name={`year`}
                  value={year.year}
                  onChange={handlefbChange}
                  label="Year"
                  id={year.id}
                />
              </TableCell>
              <TableCell style={{border: '1px solid black', padding:5}}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name={`received.grossIncome`}
                  value={year.received.grossIncome}
                  onChange={handlefbChange}
                  label="Gross Income (Received)"
                  id={year.id}
                />
              </TableCell>
              <TableCell style={{border: '1px solid black', padding:5}}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name={`received.totalIncome`}
                  value={year.received.totalIncome}
                  onChange={handlefbChange}
                  label="Total Income (Received)"
                  id={year.id}
                />
              </TableCell>
              <TableCell style={{border: '1px solid black', padding:5}}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name={`received.sundryCreditors`}
                  value={year.received.sundryCreditors}
                  onChange={handlefbChange}
                  label="Sundry Creditors (Received)"
                  id={year.id}
                />
              </TableCell>
              <TableCell style={{border: '1px solid black', padding:5}}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name={`received.sundryDebtors`}
                  value={year.received.sundryDebtors}
                  onChange={handlefbChange}
                  label="Sundry Debtors (Received)"
                  id={year.id}
                />
              </TableCell>
              <TableCell style={{border: '1px solid black', padding:5}}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name={`verified.grossIncome`}
                  value={year.verified.grossIncome}
                  onChange={handlefbChange}
                  label="Gross Income (Verified)"
                  id={year.id}
                />
              </TableCell>
              <TableCell style={{border: '1px solid black', padding:5}}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name={`verified.totalIncome`}
                  value={year.verified.totalIncome}
                  onChange={handlefbChange}
                  label="Total Income (Verified)"
                  id={year.id}
                />
              </TableCell>
              <TableCell style={{border: '1px solid black', padding:5}}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name={`verified.sundryCreditors`}
                  value={year.verified.sundryCreditors}
                  onChange={handlefbChange}
                  label="Sundry Creditors (Verified)"
                  id={year.id}
                />
              </TableCell>
              <TableCell style={{border: '1px solid black', padding:5}}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name={`verified.sundryDebtors`}
                  value={year.verified.sundryDebtors}
                  onChange={handlefbChange}
                  label="Sundry Debtors (Verified)"
                  id={year.id}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
              </Grid>

              {/* <Grid item xs={12} style={{ textAlign: "center", marginTop: "14px" }}>
        <Button
          style={{ backgroundColor: "blue", color: "white" }}
          onClick={addFinancialYear}
        >
          Add Financial Year
        </Button>
      </Grid> */}
              <Grid item xs={3}>
                <Typography style={{ fontSize: 16, fontWeight: "bold" }}>
                  STATUS:
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="overallstatus"
                  value={formData.overallstatus}
                  onChange={handleChange}
                  label="Status"
                />
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ fontSize: 16, fontWeight: "bold" }}>
                  Final Remarks
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="final_remarks"
                  value={formData.final_remarks}
                  onChange={handleChange}
                  label="Final Remarks"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                style={{ textAlign: "center", marginTop: "30px" }}
              >
                <Button
                  style={{ backgroundColor: "blue", color: "white" }}
                  onClick={handleSaveChanges}
                >
                  Save Changes
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ textAlign: "center", marginTop: "30px" }}
              >
                <Button
                  onClick={handleClosefb}
                  style={{ backgroundColor: "red", color: "white" }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openf16d}
          onClose={handleCloseform16}
          id="audit"
          maxWidth="md"
          style={{ maxWidth: "100%", width: "100%", paddingBottom: 50 }}
        >
          <DialogTitle className={classes.dialogTitle}>
            <Grid item xs={12}>
              <Typography variant="h5" align="center" fontWeight="bold">
                {formData.company_name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" fontWeight="bold">
                FORM-16 & 26AS Verification Report
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ textAlign: "center", marginTop: "14px" }}
            >
              <Button
                onClick={handleCloseform16}
                style={{
                  backgroundColor: "red",
                  color: "white",
                  position: "absolute",
                  right: "2%",
                  top: "4%",
                }}
              >
                Cancel
              </Button>
            </Grid>
          </DialogTitle>
          <DialogContent style={{ paddingTop: 30 }}>
          <Grid
                container
                spacing={2}
                className="pdf-table" 
                style={{
                  border: "1px solid black",
                  padding: "16px",
                  marginBottom: 10,
                }}
              >
            <Grid item xs={2}>
                <Typography className="ap">Client Name:</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="client_name"
                  value={formData.client_name}
                  onChange={handleChange}
                  label="Client Name"
                />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">Branch Name:</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="branch_name"
                  value={formData.branch_name}
                  onChange={handleChange}
                  label="Branch Name"
                />
              </Grid>
              {/* <Grid item xs={2}>
                <Typography className="ap">
                  Agency Name
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="company_name"
                  value={formData.company_name}
                  onChange={handleChange}
                  label="Agency Name"
                />
              </Grid> */}
              <Grid item xs={2}>
                <Typography className="ap">Reference No</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="ref_no"
                  value={formData.ref_no}
                  onChange={handleChange}
                  label="Reference No."
                />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">Date of Receipt:</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="date_of_receipt"
                  value={formatDate(formData.i_date) + "  " +  formData.i_time}
                  onChange={handleChange}
                  label="Date of Receipt"
                  type="text"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">Date of Report:</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="date_of_report"
                  value={formData.date_of_report}
                  onChange={handleChange}
                  label="Date of Report"
                  type="text"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">Product Type:</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="product_type"
                  value={formData.product_type}
                  onChange={handleChange}
                  label="Product Type"
                />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">Reported By:</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="reported_by"
                  value={formData.reported_by}
                  onChange={handleChange}
                  label="Reported By"
                />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">Applicant Name:</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="applicant_name"
                  value={formData.applicant_name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">Documents Type</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>FORM-16 & 26AS</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">PAN NO:</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="panno"
                  value={formData.panno}
                  onChange={handleChange}
                />
              </Grid>
              {/* <Grid container spacing={2} className="tableContainer" style={{marginTop:20, borderRadius:5, borderWidth:2, borderStyle:'groove', paddingBottom:20, paddingLeft:20, paddingRight:20, marginLeft:20}}>
              <Grid item xs={2}>
                <Typography className="ap">ASSESSMENT YEAR</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className={classes.tableCol} style={{textAlign:'center'}}>
                  AS RECEIVED
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className={classes.tableCol} style={{textAlign:'center'}}>
                  AS VERIFIED
                </Typography>
              </Grid>
              <Grid item xs={2}>
               
              </Grid>
              <Grid item xs={2.5}>
                <Typography className={classes.tableCol}>
                  Amount Credited
                </Typography>
              </Grid>
              <Grid item xs={2.5}>
                <Typography className={classes.tableCol}>
                  Tax Deposited
                </Typography>
              </Grid>
              <Grid item xs={2.5}>
                <Typography className={classes.tableCol}>
                  Amount Credited
                </Typography>
              </Grid>
              <Grid item xs={2.5}>
                <Typography className={classes.tableCol}>
                  Tax Deposited
                </Typography>
              </Grid>
              {assessmentYears.map((year, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name={`year`}
                      id={year.id}
                      value={year.year}
                      onChange={handlef16Change}
                      label="Year"
                    />
                  </Grid>
                  <Grid item xs={2.5}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id={year.id}
                      name={`received.amountCredited`}
                      value={year.received.amountCredited}
                      onChange={handlef16Change}
                      label="Amount Credited"
                    />
                  </Grid>
                  <Grid item xs={2.5}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id={year.id}
                      name={`received.taxDeposited`}
                      value={year.received.taxDeposited}
                      onChange={handlef16Change}
                      label="Tax Deposited"
                    />
                  </Grid>
                  <Grid item xs={2.5}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id={year.id}
                      name={`verified.amountCredited`}
                      value={year.verified.amountCredited}
                      onChange={handlef16Change}
                      label="Amount Credited"
                    />
                  </Grid>
                  <Grid item xs={2.5}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id={year.id}
                      name={`verified.taxDeposited`}
                      value={year.verified.taxDeposited}
                      onChange={handlef16Change}
                      label="Tax Deposited"
                    />
                  </Grid>
                </React.Fragment>
              ))}
              </Grid> */}
                <TableContainer component={Paper} style={{ marginTop: 20, borderRadius: 5, borderWidth: 2, borderStyle: 'groove', paddingBottom: 20, paddingLeft: 20, paddingRight: 20, marginLeft: 20 }}>
      <Table>
        <TableHead>
        
        </TableHead>
        <TableBody>
        <TableRow>
            <TableCell style={{ ...cellStyle, width: '16.666%' }}>
              <Typography className="ap">ASSESSMENT YEAR</Typography>
            </TableCell>
            <TableCell style={{ ...cellStyle, textAlign: 'center' }} colSpan={2}>
              <Typography className={classes.tableCol} style={{fontSize:16}}>AS RECEIVED</Typography>
            </TableCell>
            <TableCell style={{ ...cellStyle, textAlign: 'center' }} colSpan={2}>
              <Typography className={classes.tableCol}  style={{fontSize:16}}>AS VERIFIED</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={cellStyle}></TableCell>
            <TableCell style={cellStyle}>
              <Typography className={classes.tableCol} style={{textAlign:'center',fontSize:16}}>Amount Credited</Typography>
            </TableCell>
            <TableCell style={cellStyle}>
              <Typography className={classes.tableCol} style={{textAlign:'center',fontSize:16}}>Tax Deposited</Typography>
            </TableCell>
            <TableCell style={cellStyle}>
              <Typography className={classes.tableCol} style={{textAlign:'center',fontSize:16}}>Amount Credited</Typography>
            </TableCell>
            <TableCell style={cellStyle}>
              <Typography className={classes.tableCol} style={{textAlign:'center',fontSize:16}}>Tax Deposited</Typography>
            </TableCell>
          </TableRow>
          {assessmentYears.map((year, index) => (
            <TableRow key={index}>
              <TableCell style={cellStyle}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="year"
                  id={year.id}
                  value={year.year}
                  onChange={handlef16Change}
                  label="Year"
                />
              </TableCell>
              <TableCell style={cellStyle}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id={year.id}
                  name="received.amountCredited"
                  value={year.received.amountCredited}
                  onChange={handlef16Change}
                  label="Amount Credited"
                />
              </TableCell>
              <TableCell style={cellStyle}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id={year.id}
                  name="received.taxDeposited"
                  value={year.received.taxDeposited}
                  onChange={handlef16Change}
                  label="Tax Deposited"
                />
              </TableCell>
              <TableCell style={cellStyle}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id={year.id}
                  name="verified.amountCredited"
                  value={year.verified.amountCredited}
                  onChange={handlef16Change}
                  label="Amount Credited"
                />
              </TableCell>
              <TableCell style={cellStyle}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id={year.id}
                  name="verified.taxDeposited"
                  value={year.verified.taxDeposited}
                  onChange={handlef16Change}
                  label="Tax Deposited"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
              <Grid item xs={3}>
                <Typography className="ap">STATUS:</Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="overallstatus"
                  value={formData.overallstatus}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.tableColrh}>
                  Final Remarks
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="final_remarks"
                  value={formData.final_remarks}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                style={{ textAlign: "center", marginTop: "14px" }}
              >
                <Button
                  style={{ backgroundColor: "blue", color: "white" }}
                  onClick={handleSaveChanges}
                >
                  Save Changes
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ textAlign: "center", marginTop: "14px" }}
              >
                <Button
                  onClick={handleCloseform16}
                  style={{ backgroundColor: "red", color: "white" }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openitrd}
          onClose={handleCloseitr}
          id="audit"
          maxWidth="md"
          style={{ maxWidth: "100%", width: "100%", paddingBottom: 50 }}
        >
          <DialogTitle className={classes.dialogTitle}>
            <Grid item xs={12}>
              <Typography variant="h5" align="center" fontWeight="bold">
                {formData.company_name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" fontWeight="bold">
                ITR Verification Report
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ textAlign: "center", marginTop: "14px" }}
            >
              <Button
                onClick={handleCloseitr}
                style={{
                  backgroundColor: "red",
                  color: "white",
                  position: "absolute",
                  right: "2%",
                  top: "4%",
                }}
              >
                Cancel
              </Button>
            </Grid>
          </DialogTitle>
          <DialogContent className="dcp">
          <Grid
                container
                spacing={2}
                className="pdf-table"
                style={{
                  border: "1px solid black",
                  padding: "16px",
                  marginBottom: 10,
                }}
              >
            <Grid item xs={2}>
                <Typography className="ap">Client Name</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="client_name"
                  value={formData.client_name}
                  onChange={handleChange}
                  label="Client Name"
                />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">Branch Name</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="branch_name"
                  value={formData.branch_name}
                  onChange={handleChange}
                  label="Branch Name"
                />
              </Grid>
              {/* <Grid item xs={2}>
                <Typography align="center" className="ap">
                  Agency Name
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="company_name"
                  value={formData.company_name}
                  onChange={handleChange}
                  label="AZTEK SOLUTIONS LLP"
                />
              </Grid> */}
              <Grid item xs={2}>
                <Typography className="ap">Ref No</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="ref_no"
                  value={formData.ref_no}
                  onChange={handleChange}
                  label="Refference No"
                />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">Date Of Receipt</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="date_of_receipt"
                  value={formatDate(formData.i_date) + "  " +  formData.i_time}
                  onChange={handleChange}
                  label="Date of Receipt"
                  type="text"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">Date Of Report</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="date_of_report"
                  value={formData.date_of_report}
                  onChange={handleChange}
                  label="Date of Report"
                  type="text"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">Product Type</Typography>
              </Grid>
              <Grid item xs={4}>
                {/* <TextField
                  fullWidth
                  variant="outlined"
                  name="product_type"
                  value={formData.product_type}
                  onChange={handleChange}
                  label="Product Type"
                /> */}
                {/* <Autocomplete
       className="form"
          id="product_type"
          options={product_deatils}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.product_type, label:formData.product_type}}
        name="product_type"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="product_type" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["product_type"] = el}  // Store ref by unique id
        options={product_deatils.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.product_type, label: formData.product_type }}
        onChange={(selectedOption) => handleChanged(selectedOption, "product_type")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "product_type")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">Reported By</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="reported_by"
                  value={formData.reported_by}
                  onChange={handleChange}
                  label="Reported By"
                />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">Applicant Name</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="applicant_name"
                  value={formData.applicant_name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">Documents Type</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>ITR VERIFICATION</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">Acknowledgement No</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="acknowledgement_no"
                  value={formData.acknowledgement_no}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">PAN No</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="panno"
                  value={formData.panno}
                  onChange={handleChange}
                />
              </Grid>
              {/* <Grid container spacing={2} style={{marginTop:20, borderRadius:5, borderWidth:2, borderStyle:'groove', marginLeft:10, paddingBottom:20, paddingLeft:50}}>
              <Grid item xs={3}>
                <Typography className="ap">Assessment Year</Typography>
              </Grid>
              <Grid item xs={4.5}>
                <Typography className="ap" style={{textAlign:'center'}}>As Received</Typography>
              </Grid>
              <Grid item xs={4.5}>
                <Typography className="ap" style={{textAlign:'center'}}>As Verified</Typography>
              </Grid>

              {assessmentYearsitr.map((year, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name={`year`}
                      id={year.id}
                      value={year.year}
                      onChange={handletirChange}
                      label="Year"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id={year.id}
                      name={`total_income_received`}
                      value={year.total_income_received}
                      onChange={handletirChange}
                      label="Total Income (Received)"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id={year.id}
                      name={`tax_paid_received`}
                      value={year.tax_paid_received}
                      onChange={handletirChange}
                      label="Tax Paid (Received)"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id={year.id}
                      name={`total_income_verified`}
                      value={year.total_income_verified}
                      onChange={handletirChange}
                      label="Total Income (Verified)"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id={year.id}
                      name={`tax_paid_verified`}
                      value={year.tax_paid_verified}
                      onChange={handletirChange}
                      label="Tax Paid (Verified)"
                    />
                  </Grid>
                </React.Fragment>
              ))}
              </Grid> */}
               <TableContainer component={Paper} style={{ marginTop: 20, borderRadius: 5, borderWidth: 2, borderStyle: 'groove', marginLeft: 10, paddingBottom: 20, paddingLeft: 20,paddingRight: 20  }}>
      <Table>
        <TableHead>
        
        </TableHead>
        <TableBody>
        <TableRow>
            <TableCell style={{ ...cellStyle, width: '25%',textAlign: 'center' }}>
              <Typography className="itrap"  style={{ textAlign: 'center' }}>Assessment Year</Typography>
            </TableCell>
            <TableCell style={{ ...cellStyle, textAlign: 'center' }} colSpan={2}>
              <Typography className="itrap" style={{ textAlign: 'center' }}>As Received</Typography>
            </TableCell>
            <TableCell style={{ ...cellStyle, textAlign: 'center' }} colSpan={2}>
              <Typography className="itrap"  style={{ textAlign: 'center' }}>As Verified</Typography>
            </TableCell>
          </TableRow>
          {assessmentYearsitr.map((year, index) => (
            <TableRow key={index}>
              <TableCell style={cellStyle}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="year"
                  id={year.id}
                  value={year.year}
                  onChange={handletirChange}
                  label="Year"
                />
              </TableCell>
              <TableCell style={cellStyle}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id={year.id}
                  name="total_income_received"
                  value={year.total_income_received}
                  onChange={handletirChange}
                  label="Total Income (Received)"
                />
              </TableCell>
              <TableCell style={cellStyle}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id={year.id}
                  name="tax_paid_received"
                  value={year.tax_paid_received}
                  onChange={handletirChange}
                  label="Tax Paid (Received)"
                />
              </TableCell>
              <TableCell style={cellStyle}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id={year.id}
                  name="total_income_verified"
                  value={year.total_income_verified}
                  onChange={handletirChange}
                  label="Total Income (Verified)"
                />
              </TableCell>
              <TableCell style={cellStyle}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id={year.id}
                  name="tax_paid_verified"
                  value={year.tax_paid_verified}
                  onChange={handletirChange}
                  label="Tax Paid (Verified)"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
              <Grid item xs={3}>
                <Typography className="ap">Status</Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="overallstatus"
                  value={formData.overallstatus}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography className="ap">Final Remarks</Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="final_remarks"
                  value={formData.final_remarks}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                style={{ textAlign: "center", marginTop: "30px" }}
              >
                <Button
                  style={{ backgroundColor: "blue", color: "white" }}
                  onClick={handleSaveChanges}
                >
                  Save Changes
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ textAlign: "center", marginTop: "30px" }}
              >
                <Button
                  onClick={handleCloseitr}
                  style={{ backgroundColor: "red", color: "white" }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openqtd}
          onClose={handleClosequatation}
          id="audit"
          maxWidth="md"
          style={{
            maxWidth: "100%",
            width: "100%",
            paddingBottom: 50,
            paddingLeft: 50,
            paddingRight: 50,
          }}
        >
          <DialogTitle className={classes.dialogTitle}>
            <Grid item xs={12}>
              <Typography variant="h5" align="center" fontWeight="bold">
                {formData.company_name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" fontWeight="bold">
                Quotation Verification Report
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ textAlign: "center", marginTop: "14px" }}
            >
              <Button
                onClick={handleClosequatation}
                style={{
                  backgroundColor: "red",
                  color: "white",
                  position: "absolute",
                  right: "2%",
                  top: "4%",
                }}
              >
                Cancel
              </Button>
            </Grid>
          </DialogTitle>
          <DialogContent>
          <Grid
                container
                spacing={2}
                className="pdf-table" 
                style={{
                  border: "1px solid black",
                  padding: "16px",
                  marginBottom: 10,
                }}
              >
            <Grid item xs={2}>
                <Typography className="ap">Client Name</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="client_name"
                  value={formData.client_name}
                  onChange={handleChange}
                  label="Client Name"
                />
              </Grid>
               <Grid item xs={2}>
                <Typography className="ap">Branch Name</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="branch_name"
                  value={formData.branch_name}
                  onChange={handleChange}
                  label="Branch Name"
                />
              </Grid>

              {/* <Grid item xs={2}>
                <Typography className="ap">Agency Name</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="aph">AZTEK SOLUTIONS LLP</Typography>
              </Grid> */}
              <Grid item xs={2}>
                <Typography className="ap">Ref No</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="ref_no"
                  value={formData.ref_no}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">Product Type</Typography>
              </Grid>
              <Grid item xs={4}>
                {/* <TextField
                  fullWidth
                  variant="outlined"
                  name="product_type"
                  value={formData.product_type}
                  onChange={handleChange}
                /> */}
                {/* <Autocomplete
       className="form"
          id="product_type"
          options={product_deatils}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.product_type, label:formData.product_type}}
        name="product_type"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="product_type" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["product_type"] = el}  // Store ref by unique id
        options={product_deatils.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.product_type, label: formData.product_type }}
        onChange={(selectedOption) => handleChanged(selectedOption, "product_type")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "product_type")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">Reported By</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="reported_by"
                  value={formData.reported_by}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">Applicant Name</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="applicant_name"
                  value={formData.applicant_name}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={2}>
                <Typography className="ap">Documents Type</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>QUOTATION VERIFICATION</Typography>
              </Grid>

              <Grid item xs={2}>
                <Typography className="ap">Quotation Issued By</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="quotation_issued_by"
                  value={formData.quotation_issued_by}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={2}>
                <Typography className="ap">Quotation Issued Date</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  type="date"
                  variant="outlined"
                  name="quotation_issued_date"
                  value={formData.quotation_issued_date}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={2}>
                <Typography className="ap">Address</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={2}>
                <Typography className="ap">Name of Contact Person</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="contact_person_name"
                  value={formData.contact_person_name}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={2}>
                <Typography className="ap">Contact Number</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="contact_number"
                  value={formData.contact_number}
                  onChange={handleChange}
                />
              </Grid>
              {/* <Grid container spacing={2} className={classes.tableContainer} style={{marginTop:20}}>
              <Grid item xs={4}>
                <Typography className="ap">QUOTATION DETAILS:</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="ap">VALUE AS RECEIVED</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="ap">VALUE AS VERIFIED</Typography>
              </Grid>

             
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name={`asset_name`}
                     id={1}
                    value={quotationdata.asset_name}
                    onChange={handleqaChange}
                    label="Asset Name"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name={`value_as_received`}
                    id={1}
                    value={quotationdata.value_as_received}
                    onChange={handleqaChange}
                    label="Value as Received"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name={`value_as_verified`}
                    id={1}
                    value={quotationdata.value_as_verified}
                    onChange={handleqaChange}
                    label="Value as Verified"
                  />
                </Grid>
           
            </Grid> */}
             <TableContainer component={Paper} style={{ marginTop: 20 }}>
      <Table>
        <TableHead>
         
        </TableHead>
        <TableBody>
        <TableRow>
            <TableCell style={cellStyle}>
              <Typography className="ap">QUOTATION DETAILS:</Typography>
            </TableCell>
            <TableCell style={cellStyle}>
              <Typography className="ap">VALUE AS RECEIVED</Typography>
            </TableCell>
            <TableCell style={cellStyle}>
              <Typography className="ap">VALUE AS VERIFIED</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={cellStyle}>
              <TextField
                fullWidth
                variant="outlined"
                name="asset_name"
                id="1"
                value={quotationdata.asset_name}
                onChange={handleqaChange}
                label="Asset Name"
              />
            </TableCell>
            <TableCell style={cellStyle}>
              <TextField
                fullWidth
                variant="outlined"
                name="value_as_received"
                id="1"
                value={quotationdata.value_as_received}
                onChange={handleqaChange}
                label="Value as Received"
              />
            </TableCell>
            <TableCell style={cellStyle}>
              <TextField
                fullWidth
                variant="outlined"
                name="value_as_verified"
                id="1"
                value={quotationdata.value_as_verified}
                onChange={handleqaChange}
                label="Value as Verified"
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
              <Grid item xs={1}>
                <Typography className="ap">Status</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="overallstatus"
                  value={formData.overallstatus}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={2}>
                <Typography className="ap">Final Remarks</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="final_remarks"
                  value={formData.final_remarks}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                style={{ textAlign: "center", marginTop: "30px" }}
              >
                <Button
                  style={{ backgroundColor: "blue", color: "white" }}
                  onClick={handleSaveChanges}
                >
                  Save Changes
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ textAlign: "center", marginTop: "30px" }}
              >
                <Button
                  onClick={handleClosequatation}
                  style={{ backgroundColor: "red", color: "white" }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openssd}
          onClose={handleClosesallaryslip}
          id="audit"
          style={{ maxWidth: "100%", width: "100%", paddingBottom: 50 }}
        >
          <DialogTitle style={{ textAlign: "center", fontSize: 24 }}>
            <Grid item xs={12}>
              <Typography variant="h5" align="center" fontWeight="bold">
                {formData.company_name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" fontWeight="bold">
                SALARY SLIP/CERTIFICATE Verification Report
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ textAlign: "center", marginTop: "14px" }}
            >
              <Button
                onClick={handleClosesallaryslip}
                style={{
                  backgroundColor: "red",
                  color: "white",
                  position: "absolute",
                  right: "2%",
                  top: "4%",
                }}
              >
                Cancel
              </Button>
            </Grid>
          </DialogTitle>
          <DialogContent className="dcp">
            <Grid
              container
              spacing={2}
              className="pdf-table"
              style={{
                border: "1px solid black",
                padding: "16px",
                marginBottom: 10,
              }}
            >
             <Grid item xs={2}>
                <Typography className="ap">Client Name</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="client_name"
                  value={formData.client_name}
                  onChange={handleChange}
                  label="Client Name"
                />
              </Grid>
                <Grid item xs={2}>
                <Typography className="ap">Branch Name</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="branch_name"
                  value={formData.branch_name}
                  onChange={handleChange}
                  label="Branch Name"
                />
              </Grid>
              <Grid item xs={2}>
                <Typography className="ap">Ref No</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="ref_no"
                  value={formData.ref_no}
                  onChange={handleChange}
                  label="Ref No"
                />
              </Grid>
               <Grid item xs={2}>
                <Typography className="ap">Date of Receipt</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  // type="date"
                  variant="outlined"
                  name="date_of_receipt"
                  value={formatDate(formData.i_date) + "  " +  formData.i_time}
                  onChange={handleChange}
                  label="Date of Receipt"
                  type="text"
                  disabled={true}
                />
              </Grid>
               <Grid item xs={2}>
                <Typography className="ap">Date of Report</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  // type="date"
                  variant="outlined"
                  name="date_of_report"
                  value={formData.date_of_report}
                  onChange={handleChange}
                  label="Date of Report"
                  type="text"
                  disabled={true}
                />
              </Grid>
               <Grid item xs={2}>
                <Typography className="ap">Product Type</Typography>
              </Grid>
              <Grid item xs={4}>
                {/* <TextField
                  fullWidth
                  variant="outlined"
                  name="product_type"
                  value={formData.product_type}
                  onChange={handleChange}
                  label="Product Type"
                /> */}
                {/* <Autocomplete
       className="form"
          id="product_type"
          options={product_deatils}
          getOptionLabel={(option) => option.label}
          style={{textAlign:'center'}}
          value={{value:formData.product_type, label:formData.product_type}}
        name="product_type"
          renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined"  name="product_type" style={{paddingTop:0}}  /> }
          //  onChange={(event, value) => {
          //   handelverification( value?value.label:'', value?value.value:'' )
          // }}
          onChange={handleChanged}
        /> */}
          <Select
        ref={(el) => selectRefs.current["product_type"] = el}  // Store ref by unique id
        options={product_deatils.map(item => ({ value: item.value, label: item.label }))}
        value={{ value: formData.product_type, label: formData.product_type }}
        onChange={(selectedOption) => handleChanged(selectedOption, "product_type")}
        placeholder="Select Option"
        //isClearable
        isSearchable
        onKeyDown={(event) => handleKeyDown(event, "product_type")}
        styles={{
          control: (provided) => ({
            ...provided,
            textAlign: 'center',
            paddingTop: 0,
          }),
        }}
      />
              </Grid>
               <Grid item xs={2}>
                <Typography className="ap">Reported By</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="reported_by"
                  value={formData.reported_by}
                  onChange={handleChange}
                  label="Reported By"
                />
              </Grid>

              <Grid item xs={2}>
                <Typography className="ap">Applicant Name</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="applicant_name"
                  value={formData.applicant_name}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={2}>
                <Typography className="ap">Documents Type</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>SALARY SLIP/CERTIFICATE</Typography>
              </Grid>

              <Grid item xs={2}>
                <Typography className="ap">Employer’s Name</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="employer_name"
                  value={formData.employer_name}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={2}>
                <Typography className="ap">Employer’s Address</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="employer_address"
                  value={formData.employer_address}
                  onChange={handleChange}
                />
              </Grid>
       {/* <Grid container spacing={2} style={{marginTop:20, borderRadius:5, borderWidth:2, borderStyle:'groove', marginLeft:10, paddingBottom:20, paddingLeft:10}}>
              <Grid item xs={4}>
                <Typography className="ap">Salary Month</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="ap">As Received</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="ap">As Verified</Typography>
              </Grid>

              {salaryDetails.map((detail, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name={`month`}
                      id={detail.id}
                      value={detail.month}
                      onChange={(e) => handlessChange(e, index)}
                      label="Month"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name={`amount_received`}
                      id={detail.id}
                      value={detail.amount_received}
                      onChange={(e) => handlessChange(e, index)}
                      label="Amount Received"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id={detail.id}
                      name={`amount_verified`}
                      value={detail.amount_verified}
                      onChange={(e) => handlessChange(e, index)}
                      label="Amount Verified"
                    />
                  </Grid>
                </React.Fragment>
              ))}
          </Grid> */}
           <TableContainer component={Paper} style={{ marginTop: 20, borderRadius: 5, borderWidth: 2, borderStyle: 'groove', marginLeft: 10, paddingBottom: 20, paddingLeft: 10 }}>
      <Table>
        <TableHead>
        
        </TableHead>
        <TableBody>
        <TableRow>
            <TableCell style={cellStyle}>
              <Typography className="itrap">Salary Month</Typography>
            </TableCell>
            <TableCell style={cellStyle}>
              <Typography className="itrap">As Received</Typography>
            </TableCell>
            <TableCell style={cellStyle}>
              <Typography className="itrap">As Verified</Typography>
            </TableCell>
          </TableRow>
          {salaryDetails.map((detail, index) => (
            <TableRow key={index}>
              <TableCell style={cellStyle}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="month"
                  id={detail.id}
                  value={detail.month}
                  onChange={(e) => handlessChange(e, index)}
                  label="Month"
                />
              </TableCell>
              <TableCell style={cellStyle}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="amount_received"
                  id={detail.id}
                  value={detail.amount_received}
                  onChange={(e) => handlessChange(e, index)}
                  label="Amount Received"
                />
              </TableCell>
              <TableCell style={cellStyle}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id={detail.id}
                  name="amount_verified"
                  value={detail.amount_verified}
                  onChange={(e) => handlessChange(e, index)}
                  label="Amount Verified"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
              <Grid item xs={2}>
                <Typography className="ap">Verified From Whom</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="verified_from_whom"
                  value={formData.verified_from_whom}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={2}>
                <Typography className="ap">Status</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="overallstatus"
                  value={formData.overallstatus}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={2}>
                <Typography className="ap">Final Remarks</Typography>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="final_remarks"
                  value={formData.final_remarks}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                style={{ textAlign: "center", marginTop: "14px" }}
              >
                <Button
                  style={{ backgroundColor: "blue", color: "white" }}
                  onClick={handleSaveChanges}
                >
                  Save Changes
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ textAlign: "center", marginTop: "14px" }}
              >
                <Button
                  onClick={handleClosesallaryslip}
                  style={{ backgroundColor: "red", color: "white" }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        {data && data.length == 0 ? '':
        <div
          style={{
            boxShadow: "0 0 4px rgba(0, 0, 0, 0.5)",
            marginTop: 5,
            borderRadius: 10,
            marginBottom: 5,
            zIndex:1
          }}
        >
          <Grid container direction="row" className="sbmainrowclass" >
            
            
             <Grid
              item
              xs={2.5}
              >
              <Grid container spacing={2} alignItems="center" direction="row" style={{marginLeft:10}}>
      <Grid item xs={9.5}>
        <TextField
          variant="outlined"
          name="searchid"
          placeholder="Enter Applicant Name"
          value={searchname}
          onChange={(e) => setsearchname(e.target.value.trim())}
          style={{width:'100%'}}
        />
      </Grid>
      <Grid item  xs={2.5}>
        {!search_start1 ?  <Button
                sx={{ backgroundColor: "#81b0e6", width: 90 }}
                className="actionb"
                onClick={() => searchnamestart()}
              >
               Search
              </Button>:  <Button
                sx={{ backgroundColor: "red", width: 90 }}
                className="actionb"
                onClick={() => searchnameclose()}
              >
               Back
              </Button>}
    
             
      </Grid>
    </Grid>
            </Grid>
            <Grid
              item
              xs={2.5}
              
            >
               <Grid container spacing={2} alignItems="center" direction="row" style={{marginLeft:70}}>
      <Grid item xs={9.5}>
        <TextField
          variant="outlined"
          name="searchid"
          placeholder="Enter Case Id"
          value={searchid}
          onChange={(e) => setsearchid(e.target.value.trim())}
          style={{width:'100%'}}
        />
      </Grid>
      <Grid item  xs={2.5}>
        {!search_start ?  <Button
                sx={{ backgroundColor: "#81b0e6", width: 90 }}
                className="actionb"
                onClick={() => searchstart()}
              >
               Search
              </Button>:  <Button
                sx={{ backgroundColor: "red", width: 90 }}
                className="actionb"
                onClick={() => searchclose()}
              >
               Back
              </Button>}
    
             
      </Grid>
    </Grid>
            </Grid>
            <Grid
              item
              xs={2}
             
            >
              <Grid container spacing={2} alignItems="center" direction="row" style={{marginLeft:140,paddingTop:3}}>
      <Grid item xs={12}>
      <Button onClick={() => setOpendate(!open)} style={{backgroundColor:'#8acfbe',width:'98%', paddingLeft:5, paddingRight:5}}>
      <p style={{ fontSize:14, color:'black',paddingLeft:5, paddingRight:5}}> {startDate && endDate ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}` : 'Select Date Range'}</p> 
      </Button>
      {opendate && (
        <div style={{zIndex:999}}>
          <DatePicker
            ref={ref}
            selected={startDate}
            onChange={onChangedate}
            startDate={tempDateRange[0]}
            endDate={tempDateRange[1]}
            selectsRange
            inline
          />
          <div style={{ width: '19%',
  marginTop: '-8px',
  borderRadius: '5px',
  borderColor: '#baafaf',
  borderWidth: '1px',
  borderStyle: 'groove',backgroundColor:'white', position:'absolute'}}>
            <Button onClick={read_filter_data} style={{fontSize:14}}>Apply</Button>
            <Button onClick={handleCancel} style={{color:'red', marginLeft:'18%', fontSize:14}}>Cancel</Button>
          </div>
        </div>
      )}
      </Grid>
     
    </Grid>
            </Grid>
           
            <Grid item xs={1}  style={{marginLeft:160, paddingTop:3}}>
            <div> <Button onClick={clearsearch} style={{backgroundColor:'#81b0e6', color:'black'}} >clear</Button></div>
           
     
            </Grid>
            <Grid
              item
              xs={2}
              style={{marginLeft:20, paddingTop:3}}
            >
              <Button
                sx={{ backgroundColor: "#8acfbe", width: 190, }}
                className="actionb"
                onClick={() => exportToExcel()}
              >
               Export Data To Excel
              </Button>
            </Grid>
          </Grid>
          {/* <Grid item sx={{ position: 'relative', width: '100%', zIndex:999 }}>
          <div style={{ display: 'flex', alignItems: 'center', backgroundColor:'#8acfbe', borderRadius:10 ,position:'absolute', right:'15%', top:-120, width:'20%'}}>
        <Typography variant="body1" style={{ marginRight: 8, fontSize:14, fontWeight:'bold', marginLeft:'8%', color:'black' }}>
         Audit Completed Cases
        </Typography>
        <Switch
           checked={checked}
           onChange={handleswitchChange}
          color="primary"
          disabled={checked1}
        />
      </div>
      </Grid> */}
      {/* <Grid item sx={{ position: 'relative', width: '100%', zIndex:999 }}>
          <div style={{ display: 'flex', alignItems: 'center', backgroundColor:'#8acfbe', borderRadius:10 ,position:'absolute', right:'38%', top:-120, width:'20%'}}>
        <Typography variant="body1" style={{ marginRight: 8, fontSize:14, fontWeight:'bold', marginLeft:'8%', color:'black' }}>
         Visit Completed Cases
        </Typography>
        <Switch
           checked={checked1}
           onChange={handleswitchChange1}
          color="primary"
          disabled={checked}
        />
      </div>
      </Grid> */}
     
      {/* <Grid item sx={{ position: 'relative', width: '100%' , zIndex:999}}>
              
            </Grid> */}
        </div>}
        {dataloaded ? 
        data && data.length == 0 ? <div style={{textAlign:'center', fontSize:32, marginRight:30, marginTop:'15%'}}>No cases available.</div> :
        <div style={{ boxShadow: "0 0 4px rgba(0, 0, 0, 0.5)", marginTop: 10 ,zIndex:2}}>
          <table
            style={{ minWidth: 650, borderCollapse: "collapse" }}
            className="table-class"
          >
            <thead>
              <tr style={{ backgroundColor: "#93dbda", height: 70 }}>
                <th
                  className="trclass"
                  style={{
                    width: "50px",
                    border: "1px solid black",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                  }}
                >
                  S/No
                </th>
                <th
                  className="trclass"
                  style={{ width: "150px", border: "1px solid black" }}
                  onClick={() => handleSort(10)}
                >
                  Client Name {getSortIndicator(10)}
                </th>
                <th
                  className="trclass"
                  style={{ width: "180px", border: "1px solid black" }}
                  onClick={() => handleSort(1)}
                >
                  {" "}
                  <div> Case Id</div> <div>REF No</div>{" "}
                  {getSortIndicator(1)}
                </th>
                <th
                  className="trclass"
                  style={{ width: "150px", border: "1px solid black" }}
                  onClick={() => handleSort(2)}
                >
                  Applicant Name {getSortIndicator(2)}
                </th>
                <th
                  className="trclass"
                  style={{ width: "140px", border: "1px solid black" }}
                  onClick={() => handleSort(3)}
                >
                  Address {getSortIndicator(3)}
                </th>
                <th
                  className="trclass"
                  style={{ width: "120px", border: "1px solid black" }}
                  onClick={() => handleSort(4)}
                >
                  Verification Type {getSortIndicator(4)}
                </th>
                <th
                  className="trclass"
                  style={{ width: "200px", border: "1px solid black" }}
                  onClick={() => handleSort(5)}
                >
                  Mobile No {getSortIndicator(5)}
                </th>
                <th
                  className="trclass"
                  style={{ width: "150px", border: "1px solid black" }}
                  onClick={() => handleSort(6)}
                >
                  <div>FE Id</div> <div>FE Name</div>
                  {getSortIndicator(6)}
                </th>
                <th
                  className="trclass"
                  style={{ width: "70px", border: "1px solid black" }}
                  onClick={() => handleSort(7)}
                >
                  No.Of Attempts {getSortIndicator(7)}
                </th>
                <th
                  className="trclass"
                  style={{ width: "90px", border: "1px solid black" }}
                  onClick={() => handleSort(8)}
                >
                  Created Date {getSortIndicator(8)}
                </th>
                <th
                  className="trclass"
                  style={{ width: "90px", border: "1px solid black" }}
                  onClick={() => handleSort(9)}
                >
                  Completed Date {getSortIndicator(9)}
                </th>
                <th
                  className="trclass"
                  style={{ width: "150px", border: "1px solid black" }}
                >
                  Action
                </th>
                <th
                  className="trclass"
                  style={{ width: "80px", border: "1px solid black" }}
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td className="tdclass">
                    {" "}
                    <span>{index + 1}</span>
                    <br />
                    <br />
                { /*{item["status"] === 0 && (
                      <input
                        type="checkbox"
                        id={`checkbox-${index}`}
                        style={{ width: "15px", height: "15px" }}
                        onChange={(e) =>
                          handleCheckboxChange(e, item["case_id"])
                        }
                      />
                    )}*/}
                  </td>
                  <td className="tdclass">
                    <Typography className="crossed_text">
                      {item["client_name"]}
                    </Typography>
                  </td>
                  <td className="tdclass">
                    {" "}
                    <div className="caseiddiv"> {item["case_id"]}</div>
                    <div style={{ paddingTop: 10 }}>
                      {" "}
                      {item["file_refference_no"]}
                    </div>{" "}
                  </td>
                  <td className="tdclass">{item["applicant_name"]}</td>
                  <td
                    className="tdclass"
                    onClick={() => handleCardClick(index)}
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    <div>{item["applicant_current_address"]["area"]}</div>
                    <div>
                      {item["applicant_current_address"]["pincode"]}
                    </div>{" "}
                    <Tooltip
                      title={
                        <div>
                          <Typography style={{ color: "black", padding: 10 }}>
                            <strong>Address:</strong>{" "}
                            {item["applicant_current_address"]["address"]}
                          </Typography>
                          <IconButton
                            aria-label="close"
                            onClick={() => handleCloseTooltip(index)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                      }
                      arrow
                      placement="top"
                      classes={{ tooltip: classes.customTooltip }}
                      open={tooltipOpen[index]}
                    ></Tooltip>{" "}
                  </td>

                  <td className="tdclass">{item["verification_type"] == "Document" ? item["document_type"]:item["verification_type"]}</td>
                  <td className="tdclass" style={{ wordBreak: 'break-all', whiteSpace: 'normal' }}>
                    {item["applicant_number"]["mobile_no"]}
                  </td>
                  {item["fe_id"] == null ? (
                    <td className="tdclass"> ------ </td>
                  ) : (
                    <td className="tdclass">
                      {" "}
                      <div className="caseiddiv">{item["fe_id"]}</div>
                      <div style={{ paddingTop: 10 }}>
                        {item["fe_name"]}
                      </div>{" "}
                    </td>
                  )}

                  <td className="tdclass">{item["reassign_count"]}</td>
                  <td className="tdclass">
                    {" "}
                    {item["i_date"]
                      ? new Date(item["i_date"]).toLocaleDateString("en-GB")
                      : "-------"}
                  </td>
                  <td className="tdclass">
                    {" "}
                    {item["c_date"]
                      ? new Date(item["c_date"]).toLocaleDateString("en-GB")
                      :item["audit_date"] ? item["audit_date"] : "-------"}
                  </td>
                  <td className="tdclass">
                    {" "}
                    <div
                      style={{ marginTop: 10, marginLeft: 10, marginRight: 5 }}
                    >
                      {" "}
                      <Card sx={{ maxWidth: 130 }}>
                        <Button
                          sx={{ backgroundColor: "#97a64e" }}
                          gutterBottom
                          className="actionb"
                          onClick={() => handleClickOpen1(item)}
                        >
                          Audit
                        </Button>
                      </Card>
                    </div>{" "}
                    <div
                      style={{
                        marginTop: 10,
                        marginLeft: 10,
                        marginRight: 5,
                        marginBottom: 10,
                      }}
                    >
                      {" "}
                      <Card sx={{ maxWidth: 130 }}>
                        <Button
                          sx={{
                            backgroundColor:
                            item["audit_status"] == true && item["overallstatus"] != null && item["overallstatus"] != ""  ? "#8cd481" : "gray",
                          }}
                          gutterBottom
                          className="actionb"
                          disabled={item["audit_status"] == true && item["overallstatus"] != null && item["overallstatus"] != ""? false : true}
                          onClick={() =>
                            handleDownload(
                              item["case_id"],
                              item["verification_type"],
                              item["document_type"] ? item["document_type"] : ""
                            )
                          }
                        >
                          Download
                        </Button>
                      </Card>
                    </div>{" "}
                  </td>

                  <td
                    className="tdclass"
                    style={{
                      backgroundColor:
                        item["status"] == 1
                          ? "#ffd382"
                          : item["status"] == 2
                          ? "#34ebc6"
                          : "#97ff97",
                    }}
                  >
                    <Typography className="crossed_text">
                      {item["status"] === 1
                        ? "Assigned"
                        : item["status"] === 2
                        ? "Created"
                        : "Completed"}
                    </Typography>
                  </td>

                  {/* Add Action cell content here */}
                </tr>
              ))}
            </tbody>
          </table>
          {/* <Pagination
        count={Math.ceil(data.length / rowsPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        style={{ marginTop: 20 }}
      /> */}
          <div style={{ display: "none" }}>
            <PdfContent_Employee
              ref={componentRefEmployee}
              data={dpdfData}
              isFirstPage={isFirstPage}
            />
            <PdfContent_Business
              ref={componentRefBusiness}
              data={dpdfData}
              isFirstPage={isFirstPage}
            />
            <PdfContent_Residence
              ref={componentRefResidence}
              data={dpdfData}
              isFirstPage={isFirstPage}
            />
            <PdfContent_Property
              ref={componentRefProperty}
              data={dpdfData}
              isFirstPage={isFirstPage}
            />
            <PdfContent_Asset
              ref={componentRefAsset}
              data={dpdfData}
              isFirstPage={isFirstPage}
            />
          </div>
        </div>
      :   <div className="loading-container">
      <TailSpin
        height="80"
        width="80"
        color="#00BFFF"
        ariaLabel="loading"
      />
    </div>}
      </div>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}


const MapComponent = ({ latitude, longitude }) => {
  const center = {
    lat: latitude,
    lng: longitude,
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyDnUfRzHWPaBBgG9n3byhr3yY2MGih8Q0E">
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
        <Marker position={center} />
      </GoogleMap>
    </LoadScript>
  );
};

export default Application_logs;
