import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { URL } from "../constants";
import DejaVuSans from '../../fonts/DejaVuSans.ttf';
import DejaVuSansBold from '../../fonts/DejaVuSans-Bold.ttf';

// Register fonts
Font.register({ family: 'DejaVuSans', src: DejaVuSans });
Font.register({ family: 'DejaVuSans-Bold', src: DejaVuSansBold });
// Create styles
const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  table1: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop:10
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "center",
    padding: 5,
    fontSize: 11,
    fontFamily:'DejaVuSans-Bold',
    textDecoration: "underline",
    textTransform: 'uppercase'
  },
  tableColHeader1: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "center",
    padding: 5,
    fontSize: 10,
    fontFamily:'DejaVuSans-Bold',
    textDecoration: "underline",
    textTransform: 'uppercase'
  },
  tableColHeaderCenter: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "center",
    padding: 5,
    fontSize: 9,
    fontFamily:'DejaVuSans',
    textTransform: 'uppercase'
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 8,
    fontFamily:'DejaVuSans',
    textTransform: 'uppercase'
  },
  tableColt: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 7,
    fontFamily:'DejaVuSans',
    textTransform: 'uppercase'
  },
  tableColh: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 8,
    fontFamily:'DejaVuSans',
    textTransform: 'uppercase'
  },
  tableColb: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 8,
    fontFamily:'DejaVuSans-Bold',
    textTransform: 'uppercase'
  },

  tableColh: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 8,
    fontFamily:'DejaVuSans-Bold',
    textTransform: 'uppercase'
  },
  tableColb1: {
    width: "11%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 8,
    fontFamily:'DejaVuSans-Bold',
  },
  tableColb2: {
    width: "44.5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 8,
    fontFamily:'DejaVuSans-Bold',
     textAlign:'center',
     textTransform: 'uppercase'
  },
  tableColb3: {
    width: "44.5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 8,
    fontFamily:'DejaVuSans-Bold',
    textAlign:'center',
    textTransform: 'uppercase'
  },
  tableColrh: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 9,
    fontFamily:'DejaVuSans-Bold',
    textTransform: 'uppercase'
  },
  tableColr: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 9,
    textAlign: 'center',
    fontFamily:'DejaVuSans-Bold',
    textTransform: 'uppercase'
  },
  // sb: {
  //   borderStyle: "solid",
  //   borderWidth: 1,
  //   borderColor: "#000000",
  // },
  sb:{
    
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
   
    borderTopWidth: 0,
    padding: 5,
   
   

},
  tableColrr: {
    width: "75%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 8,
    fontFamily:'DejaVuSans',
    textTransform: 'uppercase'
  },
  signatory: {
    marginTop: 10,
    textAlign: 'right',
    alignContent: 'flex-end',
    alignSelf: 'flex-end',
  },
  image: {
    width: 70,
    height: 70,
    marginVertical:5,
    textAlign:'center',
    marginLeft:30
  },
  imageRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  }
});
function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

const PdfContent_Financial = ({ data }) => (
  <Document>
    <Page style={styles.container}>
      <View style={styles.table}>
      <>
            <View style={styles.tableRow}>
            <Text style={styles.tableColHeader}>{data.company_name}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableColHeader1}>FINANCIALS/BALANCE SHEET Verification Report</Text>
            </View>
          </>
        <View style={styles.tableRow}>
          <Text style={styles.tableColb}>CLIENT NAME</Text>
          <Text style={styles.tableColb}>{data.client_name}</Text>
          <Text style={styles.tableColb}>BRANCH NAME</Text>
          <Text style={styles.tableCol}>{data.branch_name}</Text>
        </View>
        <View style={styles.tableRow}>
          {/* <Text style={styles.tableColb}>Agency Name</Text>
          <Text style={styles.tableCol}>AZTEK SOLUTIONS LLP</Text> */}
          <Text style={styles.tableColrh}>Ref No</Text>
          <Text style={styles.tableColrr}>{data.file_refference_no}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColb}>Date of Receipt</Text>
          <Text style={styles.tableCol}>{formatDate(data.i_date) + " " + data.i_time} </Text>
          <Text style={styles.tableColb}>Date of Report</Text>
          <Text style={styles.tableCol}>{data.audit_date + " " + data.audit_time}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColb}>Product Type</Text>
          <Text style={styles.tableCol}>{data.product_type}</Text>
          <Text style={styles.tableColb}>Reported By</Text>
          <Text style={styles.tableCol}>{data.reported_by}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColrh}>Applicant Name</Text>
          <Text style={styles.tableColrr}>{data.applicant_name}</Text>
        </View>
        </View>
        <View style={styles.table1}>
        <View style={styles.tableRow}>
          <Text style={styles.tableColb}>Documents Type</Text>
          <Text style={styles.tableCol}>FINANCIALS/BALANCE SHEET</Text>
          <Text style={styles.tableColb}>PAN NO:</Text>
          <Text style={styles.tableCol}>{data.panno}</Text>
        </View>
        {/* <View style={styles.tableRow}>
        
        </View> */}
        <View style={styles.tableRow}>
          <Text style={styles.tableColb1}></Text>
          <Text style={styles.tableColb2}>AS RECEIVED</Text>
          <Text style={styles.tableColb3}>AS VERIFIED</Text>
        </View>
        <View style={styles.tableRow}>
        <Text style={styles.tableColt}>FINANCIAL YEAR</Text>
        <Text style={styles.tableColt}>
          <Text>Gross{"\n"}</Text>
          <Text>Income</Text>
        </Text>
        <Text style={styles.tableColt}>
          <Text>Total{"\n"}</Text>
          <Text>Income</Text>
        </Text>
        <View style={styles.tableColt}>
          <Text>Sundry{"\n"}</Text>
          <Text>Creditors</Text>
        </View>
        <View style={styles.tableColt}>
          <Text>Sundry{"\n"}</Text>
          <Text>Debtors</Text>
        </View>
        <Text style={styles.tableColt}>
          <Text>Gross{"\n"}</Text>
          <Text>Income</Text>
        </Text>
        <Text style={styles.tableColt}>
          <Text>Total{"\n"}</Text>
          <Text>Income</Text>
        </Text>
        <View style={styles.tableColt}>
          <Text>Sundry{"\n"}</Text>
          <Text>Creditors</Text>
        </View>
        <View style={styles.tableColt}>
          <Text>Sundry{"\n"}</Text>
          <Text>Debtors</Text>
        </View>
      </View>
        {data.financialyears.map((year, index) => (
          <View style={styles.tableRow} key={index}>
            <Text style={styles.tableColt}>{year.year}</Text>
            <Text style={styles.tableColt}>{year.received.grossIncome}</Text>
            <Text style={styles.tableColt}>{year.received.totalIncome}</Text>
            <Text style={styles.tableColt}>{year.received.sundryCreditors}</Text>
            <Text style={styles.tableColt}>{year.received.sundryDebtors}</Text>
            <Text style={styles.tableColt}>{year.verified.grossIncome}</Text>
            <Text style={styles.tableColt}>{year.verified.totalIncome}</Text>
            <Text style={styles.tableColt}>{year.verified.sundryCreditors}</Text>
            <Text style={styles.tableColt}>{year.verified.sundryDebtors}</Text>
          </View>
        ))}
        <View style={styles.tableRow}>
          <Text style={styles.tableColrh}>STATUS:</Text>
          <Text style={styles.tableColrr}>{data.overallstatus}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColrh}>Final Remarks</Text>
          <Text style={styles.tableColrr}>{data.final_remarks}</Text>
        </View>
      </View>
      {/* <View style={styles.tableRow}>
        <Text style={styles.tableColr}>Agency Stamp & Signature</Text>
      </View> */}
      <View style={styles.sb}>
        <View style={styles.signatory}>
        <Text style={{fontSize:10, paddingRight:10, marginBottom:-5,textTransform: 'uppercase'}}>For {data.company_name}</Text>
        {(data.company_name  != "SYNERGY ENTERPRISES" ) ? <Image style={styles.image} src={`${URL}/uploads/stamp.png`} /> : <Image style={styles.image} src={`${URL}/uploads/stamp1.png`} />}
          
          <Text style={{fontSize:10, paddingBottom:10,textTransform: 'uppercase',marginLeft:5}}>Authorized Signatory</Text>
        </View>
      </View>
    </Page>
  </Document>
);

export default PdfContent_Financial;
