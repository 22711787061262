import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ isAuthenticated, redirectPath = "/auth/login", children }) => {

  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }
  // if (roles && !roles.includes(role)) {
  //   return <Navigate to="/unauthorized" />; // or any other route you want to redirect to
  // }
  return children;
};

export default ProtectedRoute;
