import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image,Font  } from '@react-pdf/renderer';
import { URL } from "../constants";
import DejaVuSans from '../../fonts/DejaVuSans.ttf';
import DejaVuSansBold from '../../fonts/DejaVuSans-Bold.ttf';

// Register fonts
Font.register({ family: 'DejaVuSans', src: DejaVuSans });
Font.register({ family: 'DejaVuSans-Bold', src: DejaVuSansBold });

// Create styles
const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderRightWidth: 0,
    borderLeftWidth:0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
    
  },
  tableRows: {
    flexDirection: "row",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
  },
  tableColHeader: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 1,
    borderTopWidth: 0,
    textAlign: "center",
    padding: 5,
     fontSize: 11,
    fontFamily:'DejaVuSans-Bold',
    textDecoration: "underline", // Added this line,
    textTransform: 'uppercase'
  },
  tableColHeader1: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 1,
    borderTopWidth: 0,
    textAlign: "center",
    padding: 5,
    fontSize: 10,
    fontFamily:'DejaVuSans-Bold',
    textDecoration: "underline",
    textTransform: 'uppercase'
  },
 
  tableCol: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 8,
    fontFamily:'DejaVuSans',
    textTransform: 'uppercase'
    
  },
  tableColbl: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 1,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 8,
    fontFamily:'DejaVuSans-Bold',
    textTransform: 'uppercase'
  },
  tableCollatlong: {
    width: "75%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 8,
    fontFamily:'DejaVuSans',
    wordBreak: 'break-word', 
    overflowWrap: 'break-word',
    
  },
  tableColblotlong: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 1,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 8,
    fontFamily:'DejaVuSans-Bold',
    textTransform: 'uppercase'
  },
  tableColb: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontFamily:'DejaVuSans-Bold',
    fontSize: 8,
    textTransform: 'uppercase'
  },
  tableColmap: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 9,
    fontFamily:'DejaVuSans',
    textTransform: 'uppercase'
  },
  tableColmapl: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 1,
    borderRightWidth:0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 9,
    fontFamily:'DejaVuSans',
    textTransform: 'uppercase'
  },
  tableColim: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
     borderLeftWidth: 1,
    // borderTopWidth: 0,
    padding: 5,
    fontSize: 9,
    textAlign: 'center',
    paddingLeft: 60,
    fontFamily:'DejaVuSans',
    textTransform: 'uppercase'
  },
  tableColrh: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 1,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 9,
    fontFamily:'DejaVuSans-Bold',
    textTransform: 'uppercase'
  },
  tableColr: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 1,
    borderTopWidth: 1,
    padding: 5,
    fontSize: 9,
    textAlign: 'center',
    fontFamily:'DejaVuSans-Bold',
    textTransform: 'uppercase'
  },
  sb:{
    
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
   
    // borderTopWidth: 0,
    padding: 5,
   
   

},
  tableColrr: {
    width: "75%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 8,
    fontFamily:'DejaVuSans',
    textTransform: 'uppercase'
  },
  signatory: {
    marginTop: 10,
    textAlign: 'right',
    alignContent: 'flex-end',
    alignSelf: 'flex-end',
  },
  image: {
    width: 70,
    height: 70,
    marginVertical: 5,
    textAlign: 'center',
    marginLeft:30
  },
  simage: {
    width: 160,
    height: 180,
    marginVertical: 10,
    textAlign: 'center',
  },
  imageRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    borderLeftWidth:0,
    borderStyle: "solid"
  },
  mapImage: {
    width: "60%",
    height: 150
  },
  mapContainer: {
    flexDirection:'row',
    position: 'absolute',
    bottom: 15,
    left: 60,
    width: 40,
    height: 40,
    border: 1,
    overflow: 'hidden',
    borderColor:'gray'
  },
  mapContainer1: {
    textAlign:'left',
    flexDirection: 'column',
    position: 'absolute',
    bottom: 15,
    right: 56,
    width: 120,
    height: 40,
    borderWidth: 1, // Changed 'border' to 'borderWidth'
    overflow: 'hidden',
    backgroundColor: 'rgba(0, 0, 0, 0.4)', // Set black background with 40% opacity
    borderColor: 'gray',
    padding: 3,
  },
  mapAddressText: {
    fontSize: 5,
    color: 'white',
    textTransform: 'uppercase'
  },
  
  map: {
    width: 40,
    height: 50,
    objectFit: 'cover'
  }
});
function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}
const PdfContent_Asset = ({ data }) => (
  <Document>
    <Page style={styles.container}>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <Text style={styles.tableColHeader}>{data.company_name}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColHeader1}>Asset Verification Report</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>Sample Date</Text>
          <Text style={styles.tableCol}>{formatDate(data.i_date) + " " + data.i_time}</Text>
          <Text style={styles.tableColb}>Report Date</Text>
          <Text style={styles.tableCol}>{formatDate(data.c_date) + " " + data.c_time}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>Application No.</Text>
          <Text style={styles.tableCol}>{data.case_id}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>Applicant Name</Text>
          <Text style={styles.tableCol}>{data.applicant_name}</Text>
          <Text style={styles.tableColb}>Vehicle Model</Text>
          <Text style={styles.tableCol}>{data.vehicle_model}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>Loan Amount</Text>
          <Text style={styles.tableCol}>{data.loanamount}</Text>
          <Text style={styles.tableColb}>DMA / Dealer</Text>
          <Text style={styles.tableCol}>{data.dma}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>RM Name</Text>
          <Text style={styles.tableCol}>{data.rm_name}</Text>
          <Text style={styles.tableColb}>Location</Text>
          <Text style={styles.tableCol}>{data.location}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>Product</Text>
          <Text style={styles.tableCol}>{data.product_type}</Text>
          <Text style={styles.tableColb}>Co-Applicant Name</Text>
          <Text style={styles.tableCol}>{data.co_applcant_name}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>Guarantor Name</Text>
          <Text style={styles.tableCol}>{data.guarntor_name}</Text>
          <Text style={styles.tableColb}>Pick up Criteria</Text>
          <Text style={styles.tableCol}>{data.pick_up_criteria}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>Verification Type</Text>
          <Text style={styles.tableCol}>{data.verification_type}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColr}>
          RCU Status
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>Overall Status</Text>
          <Text style={styles.tableColb}>{data.overallstatus}</Text>
          <Text style={styles.tableColb}>Reason for Rejection</Text>
          <Text style={styles.tableCol}>{data.reason_for_rejection}</Text>
        </View>
        
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>Discrepant Document</Text>
          <Text style={styles.tableCol}>{data.discrepant_document}</Text>
          <Text style={styles.tableColb}>Discrepant Profile</Text>
          <Text style={styles.tableCol}>{data.discrepant_profile}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>Negative</Text>
          <Text style={styles.tableCol}>{data.negative}</Text>
          <Text style={styles.tableColb}>Sourcing Issues</Text>
          <Text style={styles.tableCol}>{data.sourcing_issues}</Text>
        </View>
       
       
        <View style={styles.tableRow} wrap={false}>
        <Text style={styles.tableColbl}>Applicant residing at given address</Text>
        <Text style={styles.tableCol}>{data.applicant_stay_at_address}</Text>
          <Text style={styles.tableColb}>Residence Stability</Text>
          <Text style={styles.tableCol}>{data.residence_stability}</Text>
          
        </View>
        <View style={styles.tableRow} wrap={false}>
        <Text style={styles.tableColbl}>Residence Ownership</Text>
        <Text style={styles.tableCol}>{data.ownership}</Text>
          <Text style={styles.tableColb}>Occupation Details</Text>
          <Text style={styles.tableCol}>{data.occupation_details}</Text>
        
        </View>
       
        <View style={styles.tableRow} wrap={false}>
        <Text style={styles.tableColbl}>End Use – whether proposed TW is to be used by self/others</Text>
        <Text style={styles.tableCol}>{data.end_use}</Text>
          <Text style={styles.tableColb}>Neighbor Check</Text>
          <Text style={styles.tableCol}>{data.neighbour_check}</Text>
        </View>
        <View style={styles.tableRow} wrap={false}>
        <Text style={styles.tableColbl}>Remarks</Text>
        <Text style={styles.tableColrr}>{data.remarks}</Text>
      </View>
      {/* </View> */}
     
      
     
    {/* </Page>
    <Page style={styles.container}> */}
        <View wrap={false}>
        <View style={styles.tableRow}>
          <Text style={styles.tableColr}>MAP LOCATION</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColblotlong}>Lat-Long</Text>
          <Text style={styles.tableCollatlong}>{data.latitude + '-'+ data.longitude}</Text>
          <Text style={styles.tableColb}>Field Agent Name</Text>
          <Text style={styles.tableCol}>{data.field_agent_name}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColmapl}></Text>
          <Image
          style={styles.mapImage}
          src={`https://maps.googleapis.com/maps/api/staticmap?center=${data.latitude},${data.longitude}&zoom=15&size=600x300&maptype=roadmap&markers=color:red%7C${data.latitude},${data.longitude}&key=AIzaSyDnUfRzHWPaBBgG9n3byhr3yY2MGih8Q0E`}
        />
          <Text style={styles.tableColmap}></Text>
        </View>
        </View>
        <View wrap={false}>
        <View style={styles.tableRow}>
          <Text style={styles.tableColr}>SNAPS TAKEN FROM VERIFICATION POINT:</Text>
        </View>
        
        <View style={styles.imageRow}>
  {data.files_name && data.files_name.map((im, index) => (
    <View key={index} style={styles.tableColim}>
      <Image style={styles.simage} src={`${URL}/${im.filepath}`} />
      <View style={styles.mapContainer}>
        <Image
          style={styles.map}
          src={`https://maps.googleapis.com/maps/api/staticmap?center=${im.latitude},${im.longitude}&zoom=13&size=200x200&maptype=roadmap&markers=color:red%7C${im.latitude},${im.longitude}&key=AIzaSyDnUfRzHWPaBBgG9n3byhr3yY2MGih8Q0E`}
          alt={`map-${index}`}
        />
      </View>
      <View style={styles.mapContainer1}>
        <Text style={styles.mapAddressText}>
          {data.mapaddress[im.filepath].city}, {data.mapaddress[im.filepath].district}
        </Text>
        <Text style={styles.mapAddressText}>
          {data.mapaddress[im.filepath].formattedAddress}
        </Text>
        <Text style={styles.mapAddressText}>
          lat: {im.latitude}
        </Text>
        <Text style={styles.mapAddressText}>
          long: {im.longitude}
        </Text>
        <Text style={styles.mapAddressText}>
          {im.c_time || 'null'}
        </Text>
      </View>
    </View>
  ))}

  {/* Check if the number of items is odd and add an empty column */}
  {data.files_name && data.files_name.length % 2 !== 0 && (
    <View style={styles.tableColim}></View> // Empty column to complete the row
  )}

  {/* Signatory Section */}
  <View style={styles.sb}>
    <View style={styles.signatory}>
      <Text style={{ fontSize: 10, paddingRight: 10, marginBottom: -5, textTransform: 'uppercase' }}>For {data.company_name}</Text>
      {data.company_name !== "SYNERGY ENTERPRISES" ? (
        <Image style={styles.image} src={`${URL}/uploads/stamp.png`} />
      ) : (
        <Image style={styles.image} src={`${URL}/uploads/stamp1.png`} />
      )}
      <Text style={{ fontSize: 10, paddingBottom: 10, textTransform: 'uppercase', marginLeft: 5 }}>Authorized Signatory</Text>
    </View>
  </View>
</View>
        
        </View>
     {/* <View wrap={false}>
     <View style={styles.sb}>
        <View style={styles.signatory}>
        <Text style={{fontSize:10, paddingRight:10, marginBottom:-5,textTransform: 'uppercase'}}>For {data.company_name}</Text>
        {(data.company_name  != "SYNERGY ENTERPRISES" ) ? <Image style={styles.image} src={`${URL}/uploads/stamp.png`} /> : <Image style={styles.image} src={`${URL}/uploads/stamp1.png`} />}
       
          
          <Text style={{fontSize:10, paddingBottom:10,textTransform: 'uppercase',marginLeft:5}}>Authorized Signatory</Text>
          
        </View>
        </View>
        </View> */}
        </View>
    </Page>
  </Document>
);

export default PdfContent_Asset;
