// RoleContext.js
import React, { createContext, useState, useContext } from 'react';

const RoleContext = createContext();

export const RoleProvider = ({ children }) => {
    const [role, setRole] = useState(null);

    const updateRole = (newRole) => {
        console.log('Setting role to:', newRole);
        setRole(newRole);
    };

    return (
        <RoleContext.Provider value={{ role, setRole: updateRole }}>
            {children}
        </RoleContext.Provider>
    );
};

export const useRole = () => useContext(RoleContext);
