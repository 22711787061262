/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";

// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import { URL } from "../../../layouts/constants";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

import AuthService from "services/auth-service";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import MDButton from "components/MDButton";
import { AuthContext } from "context";
import { Typography } from "@mui/material";
import { Padding } from "@mui/icons-material";

function DashboardNavbar({ absolute, light, isMini }) {
  const authContext = useContext(AuthContext);
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [role, setrole] = useState(null);
  const [UserName, setUserName] = useState(null);
  const [showLogoutButton, setShowLogoutButton] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };


  useEffect(() => {
    setIsMobile(isMobileDevice());
    console.log('mobile',isMobileDevice())
  }, []);
  const route = useLocation().pathname.split("/").slice(1);
  let navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    const key = localStorage.getItem("key");
    

    if (token) {
      let headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('token', token);

      const requestOptions1 = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ 'key': key }),
      };

      fetch(`${URL}/key_user_details`, requestOptions1)
        .then((response) => response.json())
        .then((data) => {
          console.log('data', data);
          if (data && data.data.length !== 0) {
            const userRole = data.data[0]['role'];
            if (userRole) {
              setrole(userRole);
              setUserName(data.data[0]['name'])
            
            } else {
             
            }
          } else {
            
          }
        })
        .catch((error) => {
          console.error('Error fetching user details:', error);
        
        });
    } else {
     
    }
  }, []);
  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }
   
  
    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const handleLogOut = async () => {
    // const response = await AuthService.logout();
    authContext.logout();
  };
  const handleIconClick = () => {
    setShowLogoutButton(!showLogoutButton);
  };
  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
      style={{zIndex:1}}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => (isMobile?'':navbarRow(theme, { isMini })) }>
         
            {/* <MDBox pr={1}>
              <MDInput label="Search here" />
            </MDBox> */}
            <MDBox display="flex" alignItems="center" color={light ? "white" : "inherit"}>
              {/* <Link to="/authentication/sign-in/basic">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon sx={iconsStyle}>notifications</Icon>
              </IconButton> */}
              {renderMenu()}
              <MDBox>
      <MDBox style={{ marginRight: 20, display: 'inline-block', position:isMobile ? 'absolute': 'relative', top:10, right:0 }}>
        <AccountCircleIcon
          fontSize="large"
          style={{ marginRight: '30px', cursor: 'pointer' }}
          onClick={handleIconClick}
        />
        {showLogoutButton && (
          <MDBox
            style={{
              position: 'absolute',
              top: '70%',
              left: '20%',
              width:200,
              transform: 'translateX(-50%)',
              marginTop: '8px', // Adjust this for spacing
              zIndex: 1,
              backgroundColor:'white',
              textAlign:'center',
              padding: 20,
              borderRadius:5
            }}
          >
             <MDBox style={{textAlign:'center', marginBottom:10}}>
             <h5>{UserName}</h5>
            <h6>{role}</h6>
            </MDBox>
            <MDButton
            style={{ backgroundColor: 'grey', color: 'white' }}
              variant="contained"
              // color="info"
              // fullWidth
              type="button"
              onClick={handleLogOut}
            >
              Log Out
            </MDButton>
          </MDBox>
        )}
      </MDBox>
    </MDBox>
              {/* <MDBox style ={{marginRight:20}}>
             
      <AccountCircleIcon fontSize="large" style={{ marginRight: '10px',marginTop:0  }}/>
              </MDBox>
              <MDBox>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  type="button"
                  onClick={handleLogOut}
                >
                  Log Out
                </MDButton>
              </MDBox> */}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
